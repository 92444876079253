
/**
 * Quarantined data is prepared for local storage and saved to the PendingData
 * entity to facilitate redownload to local storage.
 *
 * Export
 *     prepareDataForLocalStorage
 *
 * TOC
 *     PREPARE QUARANTINED DATA
 *         CORE ENTITY
 *         DETAIL ENTITY
 *         ADD ASTERISK TO NAME
 *         SET QUARANTINED RELATIONSHIPS
 *     PUSH MODIFIED PENDING-DATA
 */
import { _db, _u } from '~util';
import { DataEntryResults, FormConfig, FormFieldConfig } from '../data-entry/data-entry-sync';
import { EntityRecords, SerializedEntity, objectKeys } from '~types';
import { NormalizedPendingData, PendingDataEntity } from './process-pending';

export function initQuarantined (
    data: DataEntryResults,
    fConfg: FormConfig,
    PendingData: EntityRecords
): Promise<DataEntryResults> {
    modifyDataForStorage( data, fConfg.fields );
    return Promise.resolve( pushResultsToPendingData( data, PendingData ) )
        .then( () => data );
}
/* =================== PREPARE QUARANTINED DATA ============================= */
function modifyDataForStorage (
    data: DataEntryResults,
    fields: FormConfig["fields"]
): void {
    handleQuarantinedCoreEntity( data, data.coreEntity, fields );
    if ( data.detailEntity ) handleQuarantinedDetailEntity( data );
    normalizePendingRecord( data.pending as PendingDataEntity, data );
}
function normalizePendingRecord (
    pRcrd: PendingDataEntity,
    data: DataEntryResults | SerializedEntity
): void {
    const normalized = { id: pRcrd.id, stage: pRcrd.stage.name } as NormalizedPendingData;
    data.pending = normalized;
}
/* ----------------------- CORE ENTITY -------------------------------------- */
function handleQuarantinedCoreEntity (
    data: DataEntryResults,
    cEntity: SerializedEntity,
    fields: FormConfig["fields"]
) {
    handleQuarantineEntity( cEntity, data.pending as PendingDataEntity );
    data.coreId = cEntity.id;
    Object.values( fields ).forEach( f => ifQuarantinedRelink( f, cEntity, fields ) );
    normalizePendingRecord( data.pending as PendingDataEntity, data.coreEntity );
}
/* ---------------------- DETAIL ENTITY ------------------------------------- */
function handleQuarantinedDetailEntity ( data: DataEntryResults ): void {
    const dEntity = data.detailEntity as SerializedEntity;
    handleQuarantineEntity( dEntity, data.pending as PendingDataEntity );
    if ( dEntity.source ) dEntity.source = data.coreId;
    data.detailId = dEntity.id;
    data.coreEntity[_u.lcfirst( data.detail as string )] = dEntity.id;
}
function handleQuarantineEntity (
    entity: SerializedEntity,
    pRcrd: PendingDataEntity
): void {                                                           /*dbug-log*///console.log('--handleQuarantineEntity entity[%O]', entity);
    addNameAsterik( entity );
    if ( pRcrd.data.review.action !== 'create' ) return;
    entity.id = +`${ entity.id + _u.getRandomInt( 222222, 9999999 ) }000000`;
    // entity.id = parseInt( `${ entity.id + _u.getRandomInt( 222222, 9999999 ) }000000` );
}
/* --------------------- ADD ASTERIK TO NAME -------------------------------- */
function addNameAsterik ( entity: SerializedEntity ): void {
    const props = ['displayName', 'name'];
    props.forEach( addAsterik );

    function addAsterik ( prop: keyof SerializedEntity ): void {
        if ( !entity[prop] || entity[prop][0] === '*' ) return;
        entity[prop] = '*' + entity[prop];
    }
}
/* ------------------ SET QUARANTINED RELATIONSHIPS ------------------------- */
function ifQuarantinedRelink (
    field: FormFieldConfig,
    cEntity: SerializedEntity,
    fields: FormConfig["fields"]
): void {                                                           /*dbug-log*///console.log('--ifQuarantinedRelink field[%s][%O] cEntity[%O] fields[%O]', field.name, field, cEntity, fields);
    if ( !field.pending ) return;                                   /*dbug-log*///console.log('--ifQuarantinedRelink field[%s][%O] cEntity[%O] fields[%O]', field.name, field, cEntity, fields);
    if ( field.entity === 'Contributor' ) return relinkContributors( cEntity, fields );
    if ( field.prop?.core ) cEntity[field.prop.core] = parseInt( field.value );
}
function relinkContributors ( cEntity: SerializedEntity, fields: FormConfig["fields"] ): void {
    const contributors = cEntity.contributors;                      /*dbug-log*///console.log('--relinkContributors contributors[%O] fields[%O]', contributors, fields);
    if ( !contributors ) return;
    const field = getContributorField( cEntity, fields );
    objectKeys( contributors ).forEach( ifQuarantinedRelink );

    function ifQuarantinedRelink ( id: string ): void {
        const contrib = contributors[id];
        const fId = field.value[contrib.ord];                       /*dbug-log*///console.log('--ifQuarantinedRelink contrib[%O] id[%s] fId[%s]', contrib, id, fId);
        if ( fId == id ) return;    //Not quarantined
        relinkInContributorObj( fId, id, contributors, contrib );
        relinkInCoreEntity( fId, contrib, field, cEntity );
    }
}
function relinkInContributorObj (
    fId: string,
    id: string,
    contributors: { [id: string]: any; },
    contrib: { [prop: string]: any; },
): void {
    delete contributors[id];
    contributors[fId] = contrib;
}
function relinkInCoreEntity (
    fId: string,
    contrib: { [prop: string]: any; },
    field: FormFieldConfig,
    cEntity: SerializedEntity
): void {
    if ( !field.prop?.core ) throw Error( "Contributor property not found" );
    cEntity[field.prop.core][contrib.ord] = fId;
}
function getContributorField ( cEntity: SerializedEntity, fields: FormConfig["fields"] ): FormFieldConfig {
    const field = cEntity.authors ? fields.Author : fields.Editor;
    if ( !field ) throw Error( 'Contributor field not found' );
    return field;
}
/* =================== PUSH MODIFIED PENDING-DATA =========================== */
/**
 * Adds the entity-data prepared for quarantine to the PendingData entity, (this
 * will be used when redownloading and syncing all pending data.) Then stores the
 * updated PendingData entity in Local Storage.
 */
function pushResultsToPendingData (
    data: DataEntryResults,
    rcrds: EntityRecords
): Promise<void> | JQuery.jqXHR<void> {                             /*dbug-log*///console.log('--pushResultsToPendingData data[%O] rcrds[%O]', _u.snapshot(data), rcrds);
    // if ( data.pending?.stage !== 'Pending' ) return Promise.resolve();  //todo: Shouldn't be needed anymore?
    const qData = _u.snapshot( data );
    const storeRcrd = storePendingRcrd.bind( null, rcrds );
    return _u.sendAjaxQuery( qData, 'crud/pend/results', storeRcrd );
}
function storePendingRcrd ( rcrds: EntityRecords, results: { pending: string; } ): void {
    const pRcrd = JSON.parse( results.pending );                    /*dbug-log*///console.log('   --storePendingRcrd pRcrd = %O', pRcrd)
    pRcrd.data = JSON.parse( pRcrd.data );
    rcrds[pRcrd.id] = pRcrd;
    _db.storeData( 'pending', rcrds );
}
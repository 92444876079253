/**
 * @module app/util
 * App util methods.
 *
 * TOC
 *     APP ALERTS
 *     APP MODALS
 * 	   AJAX
 *     HTML ELEMS
 *     FIELDS AND ROWS
 *     STRINGS
 *     MISC
*/
export * as _cmbx from './elems/combobox/combobox-main';
export * as _db from './local-data/local-data-main';
export * as _el from './elems/elems-main';
export * as _opts from './elems/combobox/get-options';
export * as _u from './misc/misc-util-main';

import { initPopupOverlay } from './elems/elems-main';
import { alertIssue, initPrototypeExtensions } from './misc/misc-util-main';

export function initUtil () {
    initPrototypeExtensions();
    initPopupOverlay();
}
/** ===================== MODULE-EXECUTOR =================================== */
export function executeMethod<T extends { [key: string]: Function; }> (
    funcName: keyof T & string,
    mod: T,
    modName: string,
    caller: string,
    p: any[] = []
) {
    const params = prepParams( p );
    try {
        return mod[funcName]!( ...params );
    } catch ( e ) {
        if ( !( e instanceof Error ) ) throw new Error( "e is not an instance of Error" );
        alertError( modName, caller, funcName, e );
        if ( $( 'body' ).data( 'env' ) === 'prod' ) return;
        console.error( '[%s][%s] module: [%s] call failed.  params = %O, err = %O', caller, modName, funcName, params, e );
    }
}
/* Handles captures of event objects and returns wrapped in array. */
function prepParams ( params: string | any[] ) {
    return Array.isArray( params ) ? params : [params];
}
function alertError ( modName: string, caller: string, funcName: string, e: Error ) {
    const params = { module: modName, caller: caller, called: funcName, error: e.toString(), errMsg: e.message };
    alertIssue( 'facadeErr', params );
}
/**
 * Step configuration for the database tutorials utilizing the intro.js library.
 *
 * TOC
 *     TUTORIAL STEP CONFIG
 *         INTRO
 *         TABLE
 *         FILTER
 *         CSV
 *         MAP
 *         LISTS
 *         CONCLUSION
 *     DEEP-DIVE STEPS
 *         FILTER PANEL
 *         CUSTOM FILTER SETS
 *         CUSTOM DATA LISTS
 */
import * as tutorial from './db-tutorial.js';
/* =================== TUTORIAL STEP CONFIG ================================= */
export function getSteps () {
    return [
        /* ---------------------------- INTRO ------------------------------- */
        {
            element: '#help-opts', /* 1 */
            intro: `This tutorial demonstrates the features and tools available to
                explore the Bat Eco-Interaction data.<br><br>
                There are "Tips" here to help you refine your search.<br>
                <br>Move to the next slide or click an area to start.<br><br>
                <div style="text-align:center;">
                    <button class="intro-bttn" title="Table Data">Table Data</button>
                    <button class="intro-bttn" title="Filters">Filters</button>
                    <button class="intro-bttn" title="Map View"disabled="disabled">Map View</button>
                </div>`,
            position: 'right',
            setUpFunc: tutorial.addBttnEvents,
            title: 'Welcome to the BatBase.org Database',
        },
        {
            element: '#focus-opts',  /* 2 */
            intro: `<div style="text-align:center;">Interactions are displayed
                grouped by either Location, Source, or Taxon.</div><br><b>Location</b>
                - View by region/country or on a map.
                <br><br><b>Source</b> - View by publication, publisher, or author.
                <br><br><b>Taxon</b> - View by the taxa of the selected group.
                <br><br>(This tutorial will begin with the default Taxon table.)`,
            position: 'top',
            title: 'Data Groups'
        },
        /* ---------------------------- TABLE ------------------------------- */
        {
            element: '#focus-opts',  /* 3 */
            intro: `Select a sub-set of data to view in the table.<br><br>
                (The "Plant" view is selected for this tutorial.)`,
            position: 'right',
            setUpFunc: tutorial.setTableView.bind( null, 'Plants' ),
            title: 'Data View',
        },
        {
            element: '#search-tbl',  /* 4 */
            intro: `Columns can be resized by dragging the column header dividers
                and rearranged by dragging the header itself.<br><br>Hovering
                over a column header reveals the filter menu for that column.<br><br>
                Some columns can be filtered by text, others by selecting or
                deselecting values in that column.<br>`,
            position: 'top',
            title: 'Data Table',
        },
        {
            element: '#search-tbl',  /* 5 */
            intro: `<b>"Subject Taxon"</b> shows the active taxon in each interaction.<br><br>
                <b>"Object Taxon"</b> shows the taxon interacted with.
                <br><br>Note on Taxon names: Aside from genus species binomials,
                names at all other taxonomic ranks begin with the rank (e.g.,
                Family Acanthaceae for the plant family Acanthaceae).`,
            position: 'top',
            title: 'Table Columns',
        },
        {
            element: '#search-tbl',   /* 6 */
            intro: `<b>"Cnt"</b> (count) shows the number of interactions attributed
                to each Taxon, Location, or Source.<br><br>
                <b>“Type”</b> refers to the type of interaction. For a
                list of definitions, see the <a href="definitions" target="_blank">Definitions</a>
                page.<br><br>
                <b>“Tags”</b> refer to the part of the Object Taxon being
                interacted with. The “Secondary” tag refers to an interaction that the author did
                not witness directly, but instead is citing from another publication.`,
            position: 'top',
            title: 'Table Columns',
        },
        {
            element: '#xpand-tree',  /* 7 */
            intro: `The table can be expanded or collapsed by a single level or all at once.`,
            position: 'right',
            title: 'Toggle Table Rows',
        },
        /* ------------------------- FILTER --------------------------------- */
        {
            element: '#filter',  /* 8 */
            intro: `Click here to toggle the filter panel.`,
            position: 'bottom',
            setUpFunc: tutorial.toggleFilterPanelInTutorial,
            title: 'Search Filters',
        },
        {
            element: '#default-filters',   /* 9 */
            intro: `<b>Taxon Group</b>:Filter to selected groups, such as amphibians, fish, and reptiles.
                (A bat is in every interaction.)<br><br><b>Date Published/Entered</b>:
                Filter to interactions published, or entered/updated in our database, after a selected date.<br>
                <div style="text-align:center;">These filters are always available.</div>`,
            position: 'top',
            setUpFunc: tutorial.toggleFilterPanelInTutorial,
            title: 'Standard Filters',
        },
        {       //todo1: update to a taxon group with sub-groups
            element: '#focus-filters',   /* 11 */
            intro: `These dropdowns show all taxa in the selected group present in the table.</b><br><br>
                 - Select a specific taxon from one of the rank dropdowns and the table will
                update to show it at the top of the data tree. The other dropdowns will
                populate with related taxa.<br><br>Some groups have multiple root-taxa, such
                as Mammals and Parasites. These can be filtered to selected root taxa as well.`,
            position: 'top',
            setUpFunc: tutorial.toggleFilterPanelInTutorial,
            title: 'Dynamic Filters - Taxon',
        },
        {
            element: '#focus-filters',   /* 12 */
            intro: `<b>Locations</b> can be filtered by region, country, and display name.<br><br>
                <b>Sources</b> can be filtered by author, publisher, and by the type of publication.`,
            position: 'top',
            setUpFunc: tutorial.toggleFilterPanelInTutorial,
            title: 'Other view-specific filters',
        },
        {
            element: '#stored-filters',   /* 13 */
            intro: `After filtering data using the Filter menu and table columns, save the set to
                run the same search later.<br><br>For example, a set could show all journal
                articles tagged with "flower" in a "forest" habitat or all African "consumption" interactions in
                a "desert" habitat.<br><br>Click the "?" for a deeper dive into this feature.`,
            position: 'left',
            setUpFunc: tutorial.toggleFilterPanelInTutorial,
            title: 'Custom Filter Set',
        },
        {
            element: 'button[name="reset-tbl"]',   /* 14 */
            intro: `Click here to clear all filters and reset the interactions in the table.`,
            position: 'right',
            setUpFunc: tutorial.clearFilters,
            title: 'Clear Filters',
        },
        /* ----------------------------- CSV -------------------------------- */
        {
            element: 'button[name="csv"]',   /* 15 */
            intro: `<b>The filtered table-data can be exported in csv format.</b><br><br>The
                columns are exported in the order they are displayed.<br><br>For an explanation
                of the csv format and how to use the file, see the note at the bottom of the
                "Search Tips"<br><br> Please register and sign in to use this feature.`,
            position: 'left',
            setUpFunc: tutorial.toggleFilterPanelInTutorial.bind( null, 'close' ),
            title: 'Download CSV Data',
        },
        /* ---------------------------- MAP --------------------------------- */
        {
            element: '#shw-map',  /* 16 */
            intro: `After filtering the interactions, click here to display them geographically.`,
            position: 'left',
            title: 'Data Map',
        },
        {
            element: '#db-view', /* 17 */
            intro: `<b>Mouse over or click on a marker to see a popup summary of the
                interactions.</b><br><br>The summary shows details from up to 4
                interaction sets at the location: the name of the
                Taxon/Location/Source filtered to, the count of their interactions,
                and the top 3 most reported bats in the set of interactions.<br>
                <br><b>Click on a marker to keep its popup open.</b><br><br>
                Hover over truncated(...) text to show full text.`,
            position: 'top',
            setUpFunc: tutorial.loadIntsOnMap,
            title: 'Location Summaries',
        },
        {
            element: '#db-view',  /* 21 */
            intro: `<b>Search by clicking on the magnifying glass on the left side
                of the map and typing your search criteria.</b><br><br>The
                map will refocus and show interactions nearby.<br><br>(You can try
                this after exiting the tutorial.)`,
            position: 'top',
            setUpFunc: tutorial.loadLocView.bind( null, 'map' ),
            title: 'Search Map',
        },
        {
            element: '#shw-map',   /* 18 */
            intro: `Return to table view to filter the interactions further.`,
            position: 'top',
            title: 'Return to the Data Table',
        },
        {
            element: '#focus-opts',  /* 19 */
            intro: `Group interactions by "Location" and then select
                "Map Data" from the "View" box.<br><br><b>Every interaction with
                GPS data is loaded in the map with popup data specific to the location.</b>`,
            position: 'right',
            setUpFunc: tutorial.loadLocView.bind( null, 'tree' ),
            title: 'Show All Locations on Map',
        },
        {
            element: '#db-view',   /*22 */      //todo: update or remove step numbers
            intro: `The Location table also has a "map" column.
                <br>All locations with GPS data have a map pin in
                this column. Click one to load the Map View centered on that
                location with its popup opened.<br><br>You can try it
                now.` ,
            position: 'top',
            setUpFunc: tutorial.loadLocView.bind( null, 'tree' ),
            title: 'Map a Specific Location',
        },
        /* --------------------------- LISTS -------------------------------- */
        {
            element: '#list-opts',  /* 23 */
            intro: `Are you studying specific countries in Africa, data from specific
                publishers or authors, or a perhaps few habitats in particular? <br><br>
                You can save interactions to custom lists and use the filters and
                features to explore them as a group.<br><br> Please register and
                sign in to use this feature.`,
            position: 'right',
            title: 'Create Custom Lists of Interactions',
        },
        /* ------------------------ CONCLUSION ------------------------------ */
        {
            element: '#help-opts',    /* 24 */
            intro: `<div style="text-align:center;"><b>Thank you for taking the time to learn about the
                <br>Bat Eco-Interactions Database Page!</b><br><br>
                You can start this tutorial again at anytime.<br>
                <br><b>Register and log in to leave us feedback.<br>
                We'd love to hear from you!</b></div>` ,
            position: 'right',
        },
    ];
}
/* ====================== DEEP-DIVE STEPS =================================== */
/* ------------------------ FILTER PANEL ------------------------------------ */
export function getFilterPanelSteps () {
    return [{
        element: '#filter-col1',
        intro: `<b>Location:</b> Region, country,
            and name filters.<br><br><b>Source:</b> Author, publication, and publisher filters.
            <br><br><b>Taxon:</b> Name and taxonomic rank filters.<br>
             - The dropdowns show all taxa in the Taxon Tree<br>
             - Select a specific taxon and the Tree will update to show interactions
             for the taxon and lower related taxa. The other dropdowns
            will populate with the related taxa in the Tree.<br>(Filters will not work until
            the database is fully downloaded.)`,
        position: 'top',
        title: 'Dynamic Filters',
    },
    {
        element: '#shw-chngd-ints',
        intro: `Filter interaction data by publication date or date modified.<br>
            Use the calendar to select a date and only interactions published or
            created/updated after the selected date will be displayed.`,
        position: 'top',
        setUpFunc: tutorial.toggleFilterPanelInTutorial,
        title: 'Date Filters',
    },
    {
        element: '.ag-header-viewport',
        intro: `Hovering over a column header reveals the filter menu for that column.<br><br>
            Some columns can be filtered by text, others by selecting or
            deselecting values in that column.`,
        position: 'top',
        title: 'Column Filters',
    },
    {
        element: 'button[name="reset-tbl"]',
        intro: `<b>Clear all filters and reset the interactions in the table.</b>`,
        position: 'right',
        setUpFunc: tutorial.clearFilters,
        title: 'Clear Filters',
    }];
}
/* --------------------- CUSTOM FILTER SETS --------------------------------- */
export function getSavedFilterSteps () {
    return [{
        element: '#stored-filters',
        intro: `Filters can be saved and reapplied later as a set.<br>
            After filtering data using the Filter menu and table columns, save the set to
            run the same search later.<br><br>For example, a set could show all journal
            articles tagged with "flower" in a "forest" habitat or all African "consumption" interactions in
            a "desert" habitat.<br><br><b>Register and log in to use these features.</b>`,
        position: 'left',
        title: 'Custom Filter Set',
    }];
}
/* ---------------------- CUSTOM DATA LISTS --------------------------------- */
export function getSavedListSteps () {
    return [
        {
            element: '#list-pnl',
            intro: `Studying specific countries in Africa, data from specific publishers or authors,
                or a perhaps few habitats in particular? <br><br>Save interactions as a list and
                use the filters and features to explore them as a group.<br><br>`,
            position: 'top',
            title: 'Create Custom Lists of Interactions',
        },
        {
            element: '#sel-list-cntnr',
            intro: `Select a list to manage or enter a new name to create.<br>
                New users have preloaded examples to help demonstrate this feature.`,
            position: 'right',
            title: 'Create and Manage Lists',
        },
        {
            element: '#load-list',
            intro: `After selecting an existing list with interactions, show the
                interactions in the table by clicking
                "Load Interaction List in Table".<br><br>Once loaded, sort and view
                the interactions using the various filters and features of the page.<br><br>`,
            position: 'right',
            title: 'Interact with Your Data List',
        },
        {
            element: '#mod-list-pnl',
            intro: `<b>Add:</b> Select/create a list. Select interaction rows in the table,
                or the "All Shown" option, and click "Save List" to update the list.<br><br>
                <b>Remove:</b> Select a list and click "Load Interaction List in Table". Select
                interaction rows in the table or the "All Shown" option and click
                "Save List" to remove the selected interactions from the list.`,
            position: 'top',
            title: 'Add/Remove List Interactions',
        },
        {
            element: 'button[name="clear-list"]',
            intro: `Click here to reset table to all database interactions.`,
            position: 'bottom',
            title: 'Custom Data Set',
        }
    ];
}
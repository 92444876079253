/**
 * The first time a browser visits the search page all entity data is downloaded
 * from the server and stored locally using IndexedIdb.
 *
 * Entities downloaded with each ajax call:
 *   /geojson - GeoJson
 *   /interaction - Interaction, InteractionType, Tag *
 *   /location - HabitatType, Location, LocationType
 *   /source - Author, Citation, CitationType, Publication, PublicationType,
 *       Source, SourceType
 *   /taxon - Taxon, Group, Rank
 *   /user - UserNamed, User
 *
 * Export
 *     initLocalDatabase
 *     getAndSetData
 *     getFilterListGroupString
 *     setAvailablePendingDataAndStats
 *
 * TOC
 *     INIT FULL DATABASE
 *         INIT BASE TABLE
 *         DOWNLOAD REMAINING TABLE DATA
 *         DOWNLOAD REMAINING DATA
 *     STORE NEW USER-DATA
 */
export { isAvailableToCurrentUser, setAvailablePendingDataAndStats } from './entity/init-rvw-data';
export { getFilterListGroupString } from './entity/init-usr-data';
import { _db, _u } from '~util';
import { initSearchStateAndTable, showIntroAndLoadingMsg, _ui } from '~db';
import { modifyIntDataForLocalDb } from './entity/init-int-data';
import { modifyLocDataForLocalDb } from './entity/init-loc-data';
import { modifySrcDataForLocalDb } from './entity/init-src-data';
import { modifyTxnDataForLocalDb } from './entity/init-txn-data';
import * as rvw from './entity/init-rvw-data';
import * as user from './entity/init-usr-data';
import { ServerEntityData, objectKeys } from '~types';
/* ====================== INIT FULL DATABASE ================================ */
/**
 * The first time a browser visits the search page all entity data is downloaded
 * from the server and stored locally @initLocalData. A data-loading popup message
 * and feature walk-through are shown on the Search page.
 */
export function initStoredData ( reset?: boolean ) {
    showIntroAndLoadingMsg( reset );
    return initLocalDatabase();
}
type DataState = {
    state: {
        [name: string]: string;
    };
};
/** Downloads, stores, and loads all data for the database-page. */
export function initLocalDatabase (): Promise<void> {               /*perm-log*/console.log( "   *--initLocalDatabase" );
    return _db.fetchServerData( 'data-state' )
        .then( ( data: DataState ) => _db.storeData( 'lclDataUpdtdAt', data.state ) )
        .then( initTaxonDataAndLoadTable )
        .then( downloadRemainingTableData )
        .then( downloadRemainingEntityDataAndFullyEnableDb )
        .then( downloadPendingDataForUserRole )
        .then( _db.clearTempMemory )
        .then( () => initSearchStateAndTable() );
}
/* ---------------- INIT BASE TABLE ----------------------------------------- */
function initTaxonDataAndLoadTable (): Promise<void> {
    return getAndSetData( 'taxon' )
        .then( _db.setNewDataInLocalStorage )
        .then( () => initSearchStateAndTable( 'taxa', false ) );
}
/* -------------- DOWNLOAD REMAINING TABLE DATA ----------------------------- */
function downloadRemainingTableData (): Promise<void> {
    return getAndSetData( 'source' )
        .then( () => getAndSetData( 'location' ) )
        .then( () => getAndSetData( 'interaction' ) )
        .then( _db.setNewDataInLocalStorage );
}
/* --------------- DOWNLOAD REMAINING ENTITY DATA --------------------------- */
function downloadRemainingEntityDataAndFullyEnableDb (): Promise<void> {
    return getAndSetData( 'geoJson' )
        .then( () => getAndSetData( 'user' ) )
        .then( _db.setNewDataInLocalStorage )
        .then( () => _ui( 'onDataDownloadCompleteEnableUiFeatures' ) );
}
/* --------------- DOWNLOAD PENDING DATA ------------------------------------ */
/** All for DMgr or contributor data */
function downloadPendingDataForUserRole (): Promise<void> {
    return getAndSetData( 'pending' )
        .then( _db.setNewDataInLocalStorage )
        .then( () => _ui( 'initDataInReviewPanel', [_u.getUserRole()] ) );
}
/* -------------------------- HELPERS --------------------------------------- */
export function getAndSetData ( url: string ): Promise<void> {
    return _db.fetchServerData( url )
        .then( ( data: ServerEntityData ) => setData( url, data ) );
}
function setData ( url: string, data: ServerEntityData ): Promise<void> {
    _u.logInDevEnv( '           *-storing [%s] data = %O', url, data );
    _u.logInProdEnv( '           *-storing [%s] data[%s]', url, data ? Object.keys( data ).length : 'undefined' );
    const setDataFunc = {
        geoJson: Function.prototype,
        interaction: modifyIntDataForLocalDb,
        location: modifyLocDataForLocalDb,
        pending: rvw.modifyRvwDataForLocalDb,
        source: modifySrcDataForLocalDb,
        taxon: modifyTxnDataForLocalDb,
        user: user.modifyUsrDataForLocalDb,
    } as const;
    return storeServerData( data )
        .then( () => setDataFunc[url as keyof typeof setDataFunc]( data as any ) );
}
/**
 * Loops through the data object returned from the server, parsing and storing
 * the entity data.
 */
function storeServerData ( data: ServerEntityData ): Promise<void> {/*dbug-log*///console.log("storeServerData = %O", data);
    const ents = objectKeys( data );
    return ents.reduce( ( p, entity ) => {                          /*dbug-log*///console.log("     entity = %s, data = %O", entity, data[entity]);
        return p.then( p => _db.storeData( entity, _db.parseData( data[entity]! ) ) );
    }, Promise.resolve() );
}
/**
 * Random methods that affect various form-elems.
 */
import { _el } from '~util';
import * as status from './form-status-msg.js';
import * as toggle from './toggle-submit.js';

export function toggleSubmitBttn() {
    return toggle.toggleSubmitBttn( ...arguments );
}
export function toggleCancelBttn() {
    return toggle.toggleCancelBttn( ...arguments );
}
export function toggleFormStatusMsg() {
    status.toggleFormStatusMsg( ...arguments );
}
export function toggleWaitOverlay( show = true ) {
    if ( show ) { appendWaitingOverlay();
    } else { $( '#c-overlay' ).remove(); }
}
function appendWaitingOverlay() {
    const attr = { class: 'overlay', id: 'c-overlay' };
    $( '#b-overlay' ).append( _el.getElem( 'div', attr ) );
    $( '#c-overlay' ).css( {cursor: 'wait', 'z-index': '10000'} );
}
/**
 * Taxon form configuration.
 *
 * Two form "types": create (name field), edit (name, rank, parent fields)
 */
export default function( action, entity ) {
	return {
        data: {
            edit: ['group', 'orderedRanks', 'rankNames', 'taxon']
        },
        entity: 'Taxon',
        fields: {
            DisplayName: {
                info: {
                    tooltip: `Taxon names are verified using the Global Names Verifier.
Results can include the taxonomic hierarchy, synonyms, rank, and the source.
Partial matches also include the matched name.`,
                    intro: `Taxon  names are verified using the <a href="https://verifier.globalnames.org" target="_blank">Global Names Verifier</a>.
                        <br><br>The results can include the taxonomic hierarchy,
                        synonyms, rank, and the source. <br><br>Partial matches also include the
                        matched name.`
                },
                label: 'Name',
                name: 'DisplayName',
                prep: {
                    renameField: ['Name'],
                    buildTaxonDisplayName: [],
                    // verifyTaxonym: []
                },
                prop: {
                    core: 'name'
                },
                required: true,
                type: 'text',
            },
            Parent: {
                current: true, //data is always pushed with entity, but field is not always shown
                entity: 'Taxon',
                name: 'Parent',
                prep: {
                dataClasses: {
                    detail: false }, //necessary because the name and entity fields are different, signaling a detail class
                    setParent: ['Taxon']
                },
                prop: {
                    core: 'parent'
                },
                required: true,
                type: 'select'
            },
            Group: {
                name: 'Group',
                prep: {},
                prop: {
                    core: 'group'
                }
            },
            'Sub-Group': {
                name: 'Sub-Group',
                prep: {},
            },
            Rank: {
                current: true, //data is always pushed with entity, but field is not shown
                entity: 'Rank',
                name: 'Rank',
                prop: {
                    core: 'rank'
                },
                required: true,
                type: 'select'
            }
        },
        name: entity,
        types: {
            create: {
                views: {
                    all: [
                        ['Name']
                    ]
                }
            },
            edit: {
                views: {
                    all: [
                        ['Name'],
                        ['Rank'],
                        ['Parent'],
                    ]
                }
            }
        },
        views: {
            all: []
        }
    };
}
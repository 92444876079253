/**
 * The form side-panel is used to display additional data about the entity
 * record - showing all related entities, or the contained data within an
 * interaction (source and location details).
 */
import { _state } from '~form'
import * as int from './interaction-details.js';
import * as rel from './relational-details.js';
/* ===================== INIT DETAIL-ELEMS ================================== */
export function getEntityDetailElems(fConfg) {                      /*dbug-log*///console.log("--getEntityDetailElems fConfg[%O]", fConfg);
    const builder = _state('isEditForm', [fConfg.group]) && fConfg.name !== 'Interaction' ?
        rel.getSubEntityEditDetailElems : int.getInteractionDetailElems;
    return builder(fConfg.name);
}
/* ================== INTERACTION-FORM DETAILS ============================== */
export function setSubEntityDetails() {
    return int.setSubEntityDetails(...arguments);
}
export function clearSidePanelDetails() {
    return int.clearSidePanelDetails(...arguments);
}
/* ================== EDIT FORM RELATIONAL DETAILS ========================== */
export function fillEditEntitySidePanel() {
    rel.fillEditEntitySidePanel(...arguments);
}
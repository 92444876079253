/**
 * Manages form-field review-notes elems. Notes are added below the field.
 * For data-managers, an icon is added to each field that builds and toggles the
 * field's notes container.
 *
 * EXPORT
 *     initFieldNotesElem
 *     showFieldReviewNotes
 *     toggleFormReviewNotes
 *
 * TOC
 *     INIT FIELD-NOTES ICON
 *         ONCLICK
 *     FIELD-NOTES
 *         INIT NOTES
 *         ENABLE/DISABLE
 *         TOGGLE ELEMS
 */
import { _el, _u } from '~util';
import { _state } from '~form';
const pencilSvg = require( 'images/icons/edit.svg' );
const checkSvg = require( 'images/icons/check.svg' );
const iconSfx = 'rvw-notes-icon';
const noteSfx = 'rvw-notes';
const timeouts = {};
/* ===================== INIT FIELD-NOTES ICON ============================== */
export function initFieldNotesElem ( f ) {                             /*dbug-log*///console.log('-- initFieldNotesElem f[%O]', f);
    return buildFieldNotesElem( f );
}
function buildFieldNotesElem ( f ) {
    const fName = getFieldName( f );
    const iconId = `${ f.group }_${ fName }_${ iconSfx }`;
    const icon = buildFieldNotesIcon( fName, f.group, iconId );
    $( icon ).on( 'click', onClickToggleReviewNotes.bind( null, fName, iconId, f.group ) );
    return icon;
}
function getFieldName ( f ) {
    return _el.isMultiField( f ) ? ( f.id + f.count ) : f.id;
}
function buildFieldNotesIcon ( fName, fLvl, iconId ) {
    const cntnr = _el.getElem( 'span' );
    const icon = `<img id="${ iconId }" class="${ fLvl }_${ iconSfx }" src="${ pencilSvg }"
        title="Add Note to ${ fName }" alt="Add Note to ${ fName }">`;
    $( cntnr ).append( icon );
    return cntnr;
}
function getFieldNotesId ( fName, fLvl ) {
    return `${ fLvl }_${ fName }_${ noteSfx }`;
}
/* ----------------------- ONCLICK ------------------------------------------ */
function onClickToggleReviewNotes ( fName, iconId, fLvl ) {            /*dbug-log*///console.log('-- onClickToggleReviewNotes fName[%s] iconId[%s]', fName, iconId);
    const notesId = getFieldNotesId( fName, fLvl );
    if ( !$( `#${ notesId }` ).length ) { initReviewNotes( fName, iconId, notesId, fLvl ); }
    if ( timeouts[fName] ) { return; } //close is handled with change method
    toggleNotes( fName, iconId, notesId, fLvl, true );
}
/**
 * Note: Used the blur event initially, not sure why, and change works in testing...
 */
function initReviewNotes ( fName, iconId, notesId, fLvl ) {
    const notes = buildAndAppendNotesElem( fName, notesId, fLvl );
    $( notes ).change( onChangeSaveFieldNotes.bind( null, fName, iconId, notesId, fLvl ) );
}
/**
 * Notes are minimized and disabled, after a delay that allows a potential click
 * event to be processed before the elements are shifted. The timeout flag prevents
 * the notes from automatically reopening when the user clicks it's icon to close.
 */
function onChangeSaveFieldNotes ( fName, iconId, notesId, fLvl ) {
    timeouts[fName] = window.setTimeout( saveAndCloseNotes, 200 );

    function saveAndCloseNotes () {
        toggleNotes( fName, iconId, notesId, fLvl, false );
        timeouts[fName] = null;
    }
}
/* ======================== FIELD-NOTES ===================================== */
/* ------------------------ INIT NOTES -------------------------------------- */
/** During form-init, shows current field review-notes. */
export function showFieldReviewNotes ( field ) {
    const notesId = getFieldNotesId( getFieldName( field ), field.group );
    const notes = buildAndAppendNotesElem( getFieldName( field ), notesId, field.group );
    $( notes ).text( field.notes );
    toggleNotesInput( notesId, field, false );
}
function buildAndAppendNotesElem ( fName, notesId, fLvl ) {            /*dbug-log*///console.log('-- buildAndAppendNotesElem fName[%s] notesId[%s]', fName, notesId);
    const attr = { class: noteSfx, id: notesId, placeholder: 'Add data-review notes...' };
    const notes = _el.getElem( 'textarea', attr );
    $( `#${ fName }_f.${ fLvl }_f` ).append( notes );
    return notes;
}
/* ----------------------- ENABLE/DISABLE  ---------------------------------- */
/** Called during onBlur and onClick */
function toggleNotes ( fName, iconId, notesId, fLvl, enable ) {
    const field = getNotesFieldState( fName, fLvl );                  /*dbug-log*///console.log('   +-- toggleNotes name[%s] f[%O] enable?[%s]', fName, field, enable);
    toggleNotesIcon( iconId, enable );
    toggleNotesInput( notesId, field, enable );
}
function getNotesFieldState ( fName, fLvl ) {
    const last = fName.length - 1; //Multi-select field names end with their count
    const fId = isNaN( fName.charAt( last ) ) ? fName : fName.substring( 0, last );
    return _state( 'getFieldStateById', [fLvl, fId] );
}
/* ----------------------- TOGGLE ELEMS ------------------------------------- */
function toggleNotesIcon ( iconId, enable ) {
    const svg = enable ? checkSvg : pencilSvg;
    $( `#${ iconId }` ).attr( 'src', svg ).data( 'open', enable );
}
function toggleNotesInput ( notesId, field, enable ) {                 /*dbug-log*///console.log('       -- toggleNotesInput notesEl[%s] enable?[%s]', notesId, enable);
    const height = ( enable ? 5 : 2 ) + 'em';
    $( `#${ notesId }` ).attr( 'disabled', !enable ).css( 'height', height );
    field.notes = $( `#${ notesId }` ).val();                           /*dbug-log*///console.log('           -- fieldNotes?[%s]', field.notes);
    if ( enable ) { $( `#${ notesId }` ).focus(); }
}
export function toggleFormReviewNotes ( fLvl, enable = true ) {        /*dbug-log*///console.log('-- toggleFormReviewNotes group[%s] enable?[%s]', fLvl, enable);
    const opac = enable ? .8 : .4;
    const cursor = enable ? 'pointer' : 'inherit';
    $( `.${ fLvl }_${ iconSfx }` )
        .css( { opacity: opac, cursor: cursor } )
        .attr( 'disabled', !enable );
}
/**
 * Returns footer-container with submit and cancel buttons spaced to the right.
 *     footerDiv->(spacerDiv, submitButton, cancelButton)
 *
 * Export
 *     getFormFooter
 *
 * TOC
 *     CONTAINER
 *     BUTTONS
 *         SUBMIT BUTTON
 *         CANCEL BUTTON
 *         SHARED
 */
import { _el, _u } from '~util';
/**
 * @param  {String}   formName
 * @param  {String}   action
 * @param  {Function} onSubmit
 * @param  {Function} onCancel
 * @param  {String}   submitText
 * @return {Node}     footerDiv->(spacerDiv, submitButton, cancelButton)
 */
type FooterConfig = {
    formName: string,
    action?: string,
    onSubmit: EventListener,
    onCancel: EventListener,
    submitText?: string;
};
let config: FooterConfig;

export function getFormFooter ( footerConfig: FooterConfig ): HTMLDivElement {
    config = footerConfig;
    return buildFormFooter();
}
function buildFormFooter (): HTMLDivElement {
    const container = getFooterContainer();
    const spacer = $( '<div></div>' ).css( 'flex-grow', 2 );
    const buttons = [getSubmitButton(), getCancelButton()];
    $( container ).append( [spacer, ...buttons] );
    return container;
}
/* ======================== CONTAINER ======================================= */
function getFooterContainer (): HTMLDivElement {
    const attrs = { class: 'flex-row bttn-cntnr', id: `${ config.formName }_footer` };
    return _el.getElem( 'div', attrs ) as HTMLDivElement;
}
/* ========================== BUTTONS ======================================= */
/* -------------------------- SUBMIT BUTTON --------------------------------- */
function getSubmitButton (): HTMLInputElement {
    const styles = { opacity: '0.6' } as Partial<CSSStyleDeclaration>;
    const button = getActionButton( 'submit', styles, true );
    return button;
}
function getSubmitText (): string {
    if ( config.submitText ) { return config.submitText; }
    const text = { create: 'Create', edit: 'Update' };
    const actionText = text[config.action as keyof typeof text];
    return `${ actionText } ${ _u.ucfirst( config.formName ) }`;
}
/* -------------------------- CANCEL BUTTON --------------------------------- */
function getCancelButton (): HTMLInputElement {
    const styles = { cursor: 'pointer' };
    const button = getActionButton( 'cancel', styles );
    return button;
}
/* ---------------------------- SHARED -------------------------------------- */
function getActionButton ( action: 'submit' | 'cancel', styles: Partial<CSSStyleDeclaration>, disabled = false ): HTMLInputElement {
    const onClick = config[`on${ _u.ucfirst( action ) }` as keyof FooterConfig];
    const attrs = {
        disabled: disabled,
        id: `${ config.formName }-${ action }`,
        onClick: onClick as EventListener,
        styles: styles,
        type: 'button',
        value: getButtonActionText( action )
    };
    return _el.getElem( 'input', attrs ) as HTMLInputElement;
}
function getButtonActionText ( action: 'submit' | 'cancel' ): string {
    return action === 'submit' ? getSubmitText() : 'Cancel';
}
/**
 * If field is pending review, a generic option is set in the field's combo.
 * If edited, the field is highlighted and the previous field-value displayed.
 *
 * Export
 *     finishReviewFieldInit
 *     setPendingComboOpt
 *
 * TOCh
 *     INIT
 *         DISABLE
 *         REINIT FORMATTING
 *     SET FIELD-STAGE
 *         MULTI-FIELD COMBOS
 *         SET COMBO STAGE
 *     RELATED FIELDS
 *         RESOLVED
 *         UNRESOLVED
 */
import { _cmbx, _el, _u } from '~util';
import { _elems, _form, _state } from '~form';
import highlightEditsForReview from './edited-fields.js';

let isDisplayFormOnly = null
/* ============================ INIT ======================================== */
export function finishReviewFieldInit( pRcrd ) {
    isDisplayFormOnly = _state( 'isDisplayFormOnly', [pRcrd.inst.group] );
    initReviewFields( pRcrd.inst.group, pRcrd );
}
function initReviewFields( fLvl, pRcrd ) {
    const fields = _state( 'getDisplayedFields', [fLvl, 'fields'] );  /*dbug-log*///console.log('   +--initReviewFields fLvl[%s] isDisplayFormOnly?[%s] pRcrd[%O] fields[%O]', fLvl, isDisplayFormOnly, pRcrd, fields);
    Object.values( fields ).forEach( f => initReviewFieldUi( f, pRcrd ) );
}
function initReviewFieldUi( field, pRcrd ) {                          /*dbug-log*///console.log('       -- initReviewFieldUi field[%s %O] pRcrd[%O]', field.name, field, pRcrd);
    fillReviewField( field );
    ifEditFormHighlightEdits( field, pRcrd );
    if ( field.notes ) { addFieldReviewNotes( field ); }
    if ( isDisplayFormOnly ) { disableFieldInput( pRcrd.inst.group, field ); }
}
function ifEditFormHighlightEdits( field, pRcrd ) {
    if ( pRcrd.data.review.action !== 'edit' ) { return; }
    highlightEditsForReview( field, pRcrd.data.quarantined );
}
/* --------------------------- NOTES ---------------------------------------- */
function addFieldReviewNotes( field ) {
    if ( !field.notes ) { return; }
    _elems( 'showFieldReviewNotes', [field] );
}
/* -------------------------- DISABLE --------------------------------------- */
function disableFieldInput( fLvl, field ) {                           /*dbug-log*///console.log('       --disableFieldInput fLvl[%s] field[%s][%O]', fLvl, field.name, _u.snapshot(field));
    if ( _el.isMultiField( field ) ) { return disableEachSubField( fLvl, field ); }
    if ( field.combo ) { return _cmbx.enableCombobox( field.combo, false ); }
    $( `#${ fLvl }-form #${ field.id }_f .f-input` ).attr( {'disabled': true} );
}
function disableEachSubField( fLvl, field ) {
    for ( let i = field.count; i > 0; i-- ) {
        _cmbx.enableCombobox( field.name+i, false );
    }
}
/* ---------------------- REINIT FORMATTING --------------------------------- */
export function reinitReviewFormatting( fLvl ) {                      /*dbug-log*///console.log('+-- reinitReviewFormatting fLvl[%s]', fLvl);
    if ( !_state( 'isDisplayFormOnly', [fLvl] ) ) { return; }
    const comboFields = _state( 'getComboFields', [fLvl] );
    comboFields.forEach( reformatReviewField );
}
function reformatReviewField( field ) {                               /*dbug-log*///console.log('   -- reformatReviewField field[%s][%O]', field.name, field);
    if ( field.count ) { return reformatMultiFields( field ); }
    _cmbx.enableCombobox( field.combo, false );
}
/**
 * Note: Extra fields must be removed before fields disabled to prevent errors
 * due to the dynamic field build after author selected.
 */
function reformatMultiFields( field ) {
    _form( 'rmvExtraMultiFields', [field.name, field.count, field.group, false] );
    disableFieldInput( field.group, field );
}
/* ========================= SET FIELD-STAGE ================================ */
/** If field is pending review, a generic option is set in the field's combo. */
function fillReviewField( field ) {
    if ( !field.pending ) { return; }                                 /*dbug-log*///console.log('       -- fillReviewField field[%s][%O]', field.name, field);
    if ( _el.isMultiField( field ) ) { return fillPendingMultiField( field ); }
    if ( field.pending.entityId ) { return handleRelatedFields( field ); } // Field has approved data
    if ( field.value ) { return; } // Field has quarantined data
    setPendingComboOpt( field.combo, field.pending.stage.name );
    fillRelatedPendingFields( field );
}
/* ---------------------- MULTI-FIELD COMBOS -------------------------------- */
function fillPendingMultiField( field ) {                             /*dbug-log*///console.log('           -- fillPendingMultiField field[%O]', field);
    Object.keys( field.pending ).forEach( ord => ifReviewCompleteSetStageOpt( field, ord ) );
    _form( 'rmvExtraMultiFields', [field.name, field.count, field.group, isDisplayFormOnly] );
}
/**
 * During contributor confirmation, the resulting stage is added to field's with
 * sub-data that has been reviewed and rejected, for their information.
 */
function ifReviewCompleteSetStageOpt( field, ord ) {                  /*dbug-log*///console.log('               -- ifReviewCompleteSetStageOpt ord[%s] field[%O]', ord, field);
    if ( _cmbx.getSelVal( field.name+ord ) ) { return; } //Approved-entity set elsewhere
    const stageName = field.pending[ord].stage.name;
    setPendingComboOpt( field.name+ord, stageName );
}
/* ---------------------- SET COMBO STAGE ----------------------------------- */
export function setPendingComboOpt( comboField, stage ) {             /*dbug-log*///console.log('  +--setPendingComboOpt comboField[%s] stage[%s]', comboField, stage);
    const opt = getOptForStage( stage );                              /*dbug-log*///console.log('       --stage[%s] opt[%O]', stage, opt);
    if ( !opt ) { return; }
    _cmbx.addOpt( comboField, opt ); _cmbx.setSelVal( comboField, opt.value, 'silent' );
    _cmbx.enableCombobox( comboField, opt.value !== 'pending' );
}
function getOptForStage( stage ) {
    const map = {
        pending: 'Pending Data...',
        rejected: 'REJECTED. Click to replace...',
        returned: 'RETURNED. Click to replace...',
        skipped: 'SKIPPED. Click to replace...',
    };
    stage = _u.lcfirst( stage );
    if ( !map[stage] ) { return; } //Set elsewhere
    const opt = { text: map[stage], value: stage };
    return opt;
}
/* ======================== RELATED FIELDS ================================== */
/** Set review-fields related to fields with approved data. */
function handleRelatedFields( field ) {                               /*dbug-log*///console.log('         -- handleRelatedFields field[%s %O]', field.name, field);
    if ( field.combo === 'Publication' ) { return fillCitationCombo( field ); }
    if ( field.combo === 'CitationTitle' ) { return selectCitation( field ); }
}
/* ---------------------------- RESOLVED ------------------------------------ */
function fillCitationCombo( field ) {
    _form( 'fillCitationCombo', [field.value] );
}
function selectCitation( field ) {
    _cmbx.setSelVal( field.combo, field.pending.entityId, 'silent' );
}
/* -------------------------- UNRESOLVED ------------------------------------ */
/** Set pending-fields dependent on unresolved parent data. */
function fillRelatedPendingFields( field ) {                        /*dbug-log*///console.log('           -- fillRelatedPendingFields field[%s][%O]', field.name, field);
    if ( field.combo === 'Publication' ) { return ifPubUnresolvedSkipCitation( field ); }
    if ( field.entity === 'Taxon' ) { return setPendingTaxonFieldCombo( field ); }
}
/**
 * Adds 'skpped' to Citation combo if Publication field is in an incompatible stage.
 */
function ifPubUnresolvedSkipCitation( field ) {
    const stage = _state( 'getReviewStage', [null, field.pending] );  /*dbug-log*///console.log('           -- ifPubUnresolvedSkipCitation field[%s][%O] stage[%s]', field.name, field, stage);
    const validStages = _u.isManagerUser() ? ['Approved', 'Pending'] : ['Approved'];
    if ( validStages.indexOf( stage ) !== -1 ) { return; }
    setPendingComboOpt( 'CitationTitle', 'skipped' );
}
/**
 * Sets the current stage relevant to the taxon field. Ie, when a pending parent taxon
 * has been returned or rejected, the selected "pending" taxon can not be reviewed
 * until the parent data has been resolved.
 */
function setPendingTaxonFieldCombo( field ) {                         /*dbug-log*///console.log('           -- setPendingTaxonFieldCombo field[%s][%O]', field.name, field);
    const pendingRcrds = _state( 'getEntityRcrds', ['pending'] );
    const stage = getStageFromTaxonomy( field.pending, pendingRcrds );
    if ( stage ) { setPendingComboOpt( field.combo, stage ); }
}
function getStageFromTaxonomy( pRcrd, pendingRcrds ) {
    if ( pRcrd.entityId ) { return false;  } // Has approved data
    const stage = _state( 'getReviewStage', [null, pRcrd] );          /*dbug-log*///console.log('               -- getStageFromTaxonomy stage[%s] pRcrd[%O]', stage, pRcrd);
    const prntPending = pRcrd.data.review.fields.Parent.pending;
    if ( stage !== 'Pending' || !prntPending ) { return stage; }
    return getStageFromTaxonomy( pendingRcrds[prntPending.id], pendingRcrds );
}
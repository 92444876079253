/**
 * Initializes form-state data.
 *
 * TOC
 *     INIT FORM-STATE
 *         STATE CORE
 *     BUILD STATE
 *         INIT
 *         FORM VALUES
 *         ENTITY FORM
 *             TAXON
 *             SOURCE
 */
import { _db, _u } from '~util';
import { _confg, _data, _state } from '~form';
/* ========================= INIT FORM-STATE ================================ */
/* ----------------------- STATE CORE --------------------------------------- */
/**
 * @param  {obj} p              Root-form params
 * @param  {str} p.action
 *
 * @param  {str} *p.name        Entity class
 * @param  {fnc} p.initCombos
 * @param  {fnc} p.onFormClose
 * @param  {fnc} p.beforeFormClose
 * @param  {fnc} p.submit
 *         ... MANY MORE POSSIBLE
 *
 * @return {obj}     Root form-state
 */
export function initFormState( p ) {                                  /*temp-log*///console.log("   >>> initFormState params[%O] entity[%s] id?[%s] action[%s] ", _u.snapshot(p), p.name, p.id, p.action);
    const fS = getMainStateObj( p.name );
    p.confg = getInitFormConfg( p );                                  /*dbug-log*///console.log('    -- confg[%O]', p.confg);
    return _db.getData( p.confg.data )
        .then( data => addRecordData( fS, data ) )
        .then( () => buildNewFormState( fS, p ) )
        .then( () => fS );
}
function getMainStateObj() {
    return {
        forms: {},
        levels: ['top', 'sub', 'sub2'],
    };
}
function addRecordData( fS, data ) {
    fS.records = data;
}
/* ======================= BUILD STATE ====================================== */
/* ----------------------- INIT --------------------------------------------- */
export function buildNewFormState( fS, p ) {                          /*temp-log*///console.log("    #--buildNewFormState fS[%O] params[%O]", fS, _u.snapshot(p));
    fS.forms[p.name] = p.group;
    fS.forms[p.group] = getBaseFormState( fS, p );                    /*dbug-log*///console.log('-- baseFormState confg[%O]', _u.snapshot(fS.forms[p.group]));
    setFieldInitValues( fS.records, fS.forms[p.group] );
    return addEntityFormState( fS, fS.forms[p.group] );
}
function getBaseFormState( fS, p ) {
    if ( p.id ) { p.editing = { core: p.id, detail: null }; }
    const base = { ...p, ...getEntityBaseConfg( p ) };
    delete base.confg;
    groupFormHandlers( base );
    return base;
}
function getEntityBaseConfg( p ) {                                    /*dbug-log*///console.log('--getEntityBaseConfg p[%O]', _u.snapshot(p));
    if ( !p.confg ) { return getInitFormConfg( p ); }
    delete p.confg.data;
    return p.confg;
}
function getInitFormConfg( p ) {                                      /*dbug-log*///console.log('--getInitFormConfg p[%O]', _u.snapshot(p));
    const confg = _confg( 'getBaseConfg', [p.action, p.name, p.type] );
    if ( p.pending ) { modifyForReview( confg, p.pending ); }
    return confg;
}
/** Adds all functions passed in init params to a handlers object in the form-config. */
function modifyForReview( confg, pRcrd ) {                            /*dbug-log*///console.log('-- modifyForReview confg[%O]', _u.snapshot(confg), pRcrd);
    confg.action = 'review';
    if ( confg.data ) { confg.data.push( 'pending', 'pendingStages' ); } //pending entity-data
    if ( pRcrd.data.review.action !== 'edit' ) { return; }
    confg.editing = {  //Necessary to handle detail entity id.
        core: pRcrd.data.quarantined.coreId,
        detail: pRcrd.data.quarantined.detailId || null
    };
}
/** Adds all functions passed in init params to a handlers object in the form-config. */
function groupFormHandlers( confg ) {
    const funcs = {};
    Object.keys( confg ).forEach( sortOutHandlers );
    confg.handlers = funcs;                                         /*dbug-log*///console.log('-- groupFormHandlers confg[%O] handlers[%O]', _u.snapshot(confg), funcs);

    function sortOutHandlers( key ) {
        if ( typeof confg[key] !== 'function' ) { return; }           /*dbug-log*///console.log('-- sortOutHandlers handler[%O]', confg[key]);
        funcs[key] = confg[key];
        delete confg[key];
    }
}
/* ------------------------ FORM VALUES ------------------------------------- */
function setFieldInitValues( data, f ) {
    if ( f.pending ) { return addFieldReviewData( f.pending.data.review.fields, f ); }
    if ( f.id ) { return _data( 'setEditFieldValues', [data, f] ); }
    if ( !f.vals ) { return; }
    setInitValues( f.fields, f.vals );
    delete f.vals;
}
/** Review-form values */
function addFieldReviewData( pFields, f ) {                           /*dbug-log*///console.log('-- addFieldReviewData pFields[%O] fields[%O]', pFields, f.fields);
    Object.keys( pFields ).forEach( addDataToField );

    function addDataToField( fName ) {                                /*dbug-log*///console.log('-- addFieldReviewData fName[%s]', fName);
        const field = { ...f.fields[fName], ...pFields[fName] };    /*dbug-log*///console.log('field[%s] after merge[%O]', fName, _u.snapshot(field));
        f.fields[fName] = _u.snapshot( field );
        ifSourceTypeFieldSetFormType( field )
    }
    function ifSourceTypeFieldSetFormType( field ) {
        if ( f.core !== 'Source' || field.name !== f.name+'Type' ) { return; }
        f.type = field.value.text;
    }
}
function setInitValues( fields, vals ) {                              /*dbug-log*///console.log('--setFieldInitValues fields[%O] vals?[%O]', fields, vals);
    Object.keys( vals ).forEach( setFieldInitData );

    function setFieldInitData( fName ) {
        _state( 'setFieldValue', [fields[fName], vals[fName]] );      /*dbug-log*///console.log('       --setFieldInitData field[%s][%O] vals?[%O]', fName, fields[fName], vals[fName]);
        if ( !_data( 'isPossiblyQuarantined', [fields[fName]] ) ) { return; }
        ifQuarantinedSetPendingRcrd( fields[fName] );
    }
}
function ifQuarantinedSetPendingRcrd( field ) {
    const rcrd = getFieldEntityRcrd( field );
    if ( !rcrd.pending ) { return; }
    _state( 'setFieldPending', [field, rcrd.pending] );
}
function getFieldEntityRcrd( field ) {                                /*dbug-log*///console.log('-- getFieldEntityRcrd[%O]', field);
    const coreClass = _state( 'getFieldClassData', [field] )[0];
    return _state( 'getRcrd', [coreClass, field.value] );
}
/* ----------------------- ENTITY FORM -------------------------------------- */
/**
 * Adds the properties and confg that will be used throughout the code for
 * generating, validating, and submitting entity sub-forms.
 *
 * -- Property descriptions: TODO2: UPDATE
 * > action - create || edit || review
 * > combo - The name of the form parent-combo.
 * > entity - Edit-form entity record
 * > handlers - Custom functions for the entity-form (can be chained)
 *     afterFormClose - Final handler type in close-chain (after onFormClose)
 *         - Review-forms: loadNextPendingSetForReview
 *     afterSubmit - Called after submitted data is processed locally
 *         - Interaction (create): Resets form-data, rather than closing form
 *     appendForm - Deleted after form-elems appended
 *     beforeFormClose - Handles preparation before closiing the form (before form-state cleared)
 *         - Taxon (create-review): set select-form close handler
 *     cancel - Click handler for the cancel button
 *         - Author (create): reset parent-form elems
 *         - Review-forms: confirm modal and/or unlock data-set and load next
 *         - Taxon: name validation suggestions removed (TODO)
 *     initCombos - Inits the form's comboboxes
 *     onFormFieldChange - Custom handler(s) called after default change-handler
 *         - Alerts: cleared on field changes (TODO)
 *     onFormClose - Handles actions that happen after form-state cleared
 *         - Citation (sub): enable publication form-field
 *         - Location (sub): enable country field
 *         - Taxon select-forms (and taxon create form within taxon edit forms during review)
 *         - DUPLICATE ENTITY ERRORS: resolved by selecting existing entity
 *     submit - Click handler for the submit button
 *         - Location (create): checks if location data is unique and alrts if existing entity
 *         - Source: If url data present, shows modal prompting user to check links and then submit
 *         - Taxon
 *             (create/edit): name validation (TODO)
 *             (select): select in parent-form
 *             (create within edit): validate parent-change
 *         - Review-forms: show submit modal and/or continue data-review
 *         - DELETE ENTITY (edit): triggers removal of the entity being edited
 * > misc - Obj to hold the various special-case props
 * > name - Name of this form's entity.
 * > pending - Container for the data-review process
 * > selElems - Contains all selElems until they are initialized with selectize.
 * > simple - All fields are shown unless simple default-display confg present
 *
 * --- Entity-specific properties
 * > Citation forms:
 *         rcrds - { src: pubSrc, pub: pub } (parent publication)
 * > Interaction create form:
 *         unchanged - exists after form submit and before any changes
 *         valData - ValidInteraction data for the selected subject and object groups
 *             [{id, subjectGroupRoot(id), objectGroupRoot(id), interactionType(id), tags(id array)}]
 * > Location forms:
 *         geoJson - geoJson entity for this location, if it exists.
 * > Taxon forms:
 *     todo2...
 */
function addEntityFormState( fS, f ) {                                /*dbug-log*///console.log("    #--addEntityFormState entity[%s] params[%O] forms[%O]", f.name, _u.snapshot(f), fS);
    return initEntityState( fS, f )
        .then( () => finishEntityFormStateInit( fS, f ) );
}
function initEntityState( fS, f ) {
    const map = {
        Citation: storeSourceData,
        Subject: initTaxonState,
        Object: initTaxonState,
        Parent: initTaxonState,
        Taxon: initTaxonState
    };
    if ( !map[f.name] ) { return Promise.resolve(); }
    return Promise.resolve( map[f.name]( fS.records, f ) );
}
function finishEntityFormStateInit( fS, f ) {                         /*dbug-log*///console.log("    --finishEntityFormStateInit form[%O]", _u.snapshot(f));
    _confg( 'finishFormStateInit', [f] );                             /*perm-log*/console.log( '   >>> NEW FORM entity[%s] fS[%O] initConfg[%O] curConfg[%O]', f.name, fS, _u.snapshot( f ), f );
    return f;
}
/* ___________________________ TAXON ________________________________________ */
export function initTaxonState( rcrds, f ) {                          /*dbug-log*///console.log('   --initTaxonState rcrds[%O] f[%O]', rcrds, f);
    _state( 'setTaxonGroupState', [rcrds, f] );
}
/* ____________________________ SOURCE ______________________________________ */
function storeSourceData( rcrds, f ) {                                /*dbug-log*///console.log('--storeSourceData rcrds[%O] f[%O]', rcrds, f);
    initParentSourceFieldObj( f.fields );
    addPubDataToParentSourceField( rcrds, f, f.fields.ParentSource.value );
    if ( f.action !== 'edit' ) { return; }
    if ( f.fields.Year.value ) { return; }
    _state( 'setFieldValue', [f.fields.Year, f.fields.ParentSource.misc.src.year] );//Temp: fixes Citations without years
}
function initParentSourceFieldObj( fields ) {
    if ( !fields.ParentSource ) { fields.ParentSource = {}; }
    fields.ParentSource.misc = {};
}
function addPubDataToParentSourceField( rcrds, f, pId ) {
    const pSrc = rcrds.source[pId];                                 /*dbug-log*///console.log('--addPubDataToParentSourceField [%s][%O]', pId, pSrc);
    const pub = rcrds.publication[pSrc.publication];
    const data = { pub: pub, pubType: pub.publicationType, src: pSrc };/*dbug-log*///console.log('--pubData[%O]', data);
    f.fields.ParentSource.misc = data;
}
/**
 * Utility methods used for the search-options panels.
 */
import * as confgs from './panel-confgs.js';
import * as select from './table-row-select.js';
// import * as tab from './style-tab.js';

export function getPanelConfigs() {
    return confgs.getPanelConfigs(...arguments);
}
export function newTableRowSelect() {
    return new select.TableRowSelect(...arguments);
}
// export function stylePanelTab() {
//     return tab.stylePanelTab(...arguments);
// }
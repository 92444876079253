/**
 * Returns side-panel assembled with passed form-entity and data-review elems.
 */
import { _el, _u } from '~util';
/* ===================== INIT SIDE-PANEL ================================== */
export default function buildSidePanel( fConfg, fElems, rElems ) {    /*dbug-log*///console.log("--buildSidePanel fConfg[%O] fElems[%O], rElems[%O]", fConfg, fElems, rElems);
    const cntnr = buildPanelContainer( fConfg.name, !!rElems.length );
    $( cntnr ).append( buildPanelHeader( fConfg.name ) );
    $( cntnr ).append( [...fElems, '<hr>', ...rElems] );
    return cntnr;
}
function buildPanelContainer( entity, rvwEls ) {
    const attr = { id: 'form-panel', class: _u.lcfirst( entity ) };
    if ( rvwEls ) { attr.class += ' rvw-panel'; }
    return _el.getElem( 'div', attr );
}
function buildPanelHeader( entity ) {
    const attr = { text:  `${ entity } Record` }
    return _el.getElem( 'h3', attr );
}
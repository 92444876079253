/**
 * Entry point for all local data-storage methods.
 *
 * TOC
 *     FETCH DATA
 *     TEMP DATA
 *     PENDING DATA
 */
export { getAllStoredData, getData, initDb, resetStoredData, setData } from './etc/idb-util';
export { initTempMemory, setNewDataInLocalStorage, getValue, getEntity, getEntities, removeData, storeData, initMemoryDataObj } from './etc/temp-data';
export { syncLocalDataAfterDataEntry, updateUserNamedList, syncLocalDbWithServer, processPending } from './sync/db-sync-main';
export { getAndSetData, getFilterListGroupString, initStoredData, isAvailableToCurrentUser, setAvailablePendingDataAndStats } from './init/db-init-main';

import { _u } from '~util';
import { getData } from './etc/idb-util';
import { clearMemory, setNewDataInLocalStorage } from './etc/temp-data';
import { clearFailedMemory, syncPendingData } from './sync/db-sync-main';
/* ============================ FETCH DATA ================================== */
export function fetchServerData ( url: string, options = {}, n = 9 ): Promise<any> {
    /*dbug-log*///console.log('       *-fetchServerData [%s] with params = %O', url, Object.keys(options).length ? options : null);
    return fetch( 'fetch/' + url, options ).then( response => {
        if ( response.ok ) { return response.json(); }
        if ( n === 1 ) { return alertFetchIssue( url, response.json() as any as string ); }
        return fetchServerData( url, options, n - 1 );
    } ).catch( e => _u.reportErr( e ) );
}
function alertFetchIssue ( url: string, responseText: string ) {
    _u.alertIssue( 'fetchIssue', { url: url, response: responseText } );
    return Promise.reject();
}
/**
 * Loops through the passed data object to parse the nested objects. This is
 * because the data comes back from the server having been double JSON-encoded,
 * due to the 'serialize' library and the JSONResponse object.
 */
export function parseData<T> ( data: T ): T {                       /*dbug-log*///console.log('*-parseData [%O]', data);
    for ( let k in data ) {
        try {
            data[k] = JSON.parse( data[k]! as string );
        } catch ( e ) { /* Fails on string values */
            //console.log('error');
        }
    }
    return data;
}
/** ========================= TEMP DATA ===================================== */
export function setUpdatedDataInLocalDbAndClearTempMemory () {
    return setNewDataInLocalStorage()
        .then( clearTempMemory );
}
export function clearTempMemory () {
    clearFailedMemory();
    clearMemory();
}
/* ========================= PENDING DATA =================================== */
/**
 * @return {obj}    .availablePending  Array of pendingData available to user
 *                  .pending           All pending-data records { id: entity, ... }
 */
export function syncAndReturnPendingData () {
    return syncPendingData()
        .then( () => getData( ['availablePending', 'pending'] ) );
}
/**
 * Mangages the Interaction Type and Interaction Tag fields in the interaction form.
 *
 * Export
 *     checkForRelatedTagChanges
 *     clearTypeTagData
 *     loadInteractionTypeTags
 *     onTagSelection
 *
 * TOC
 *     CLEAR INTERACTION-TYPE TAGS
 *     LOAD INTERACTION-TYPE TAGS
 *         REQUIRED TAG
 *         BUILD TAG-OPTS
 *         LOAD TAG-OPTS
 *     ON TAG SELECTION
 */
import { _cmbx, _opts } from '~util';
import { _state } from '~form';
import * as iForm from '../int-form-main.js';
/**
 * @param  {Array}  autoTag  Required tag IDs, automatically selected in field
 */
let md = { autoTag: [] };

/* =================== CLEAR INTERACTION-TYPE TAGS ========================== */
export function clearTypeTagData() {                                /*dbug-log*///console.log('     -- clearTypeTagData');
    md.autoTag = [];
    loadTagOpts();
    updateTagsState();
}
/* ==================== LOAD INTERACTION-TYPE TAGS ========================== */
/**
 * After an InteractionType is selected, the Tag field is updated with valid tags.
 * @param  {Array}  tags        Valid Tags for the selected InteractionType
 * @param  {Boolean} isRequired True if the InteractonType requires a tag.
 */
export function loadInteractionTypeTags ( typeTags, isRequired ) {     /*temp-log*///console.log('--loadInteractionTypeTags typeTags[%O] required?[%s]', typeTags, isRequired);
    handleRequiredTag( typeTags, isRequired );
    loadTagOpts( buildTagOpts( typeTags ) );
}
/* -------------------------- REQUIRED TAG ---------------------------------- */
function handleRequiredTag ( typeTags, isRequired ) {
    updateTagsState( typeTags, isRequired );
    md.autoTag = getRequiredTags( typeTags );                         /*dbug-log*///console.log('     --handleRequiredTag autoTag[%O]', md.autoTag);
}
function getRequiredTags ( typeTags = {} ) {
    return Object.keys( typeTags ).filter( t => typeTags[ t ].isRequired );
}
function updateTagsState ( typeTags = {}, isRequired = false ) {
    const tField = _state( 'getFieldState', [ 'top', 'InteractionTags', false ] );
    tField.required = isRequired;                                   /*dbug-log*///console.log('--updateTagsState typeTags[%O] field[%O] required?[%s]', typeTags, tField, isRequired);
    tField.misc.typeTags = typeTags;
    tField.value = tField.value ? getStillSelectedTags( tField.value, typeTags ) : null;
    _state( 'setFieldState', [ 'top', 'InteractionTags', tField, null ] );
}
function getStillSelectedTags ( selected, typeTags = {} ) {
    const valid = Object.keys( typeTags );
    const persist = selected.filter( i => valid.indexOf( i ) !== -1 );  /*dbug-log*///console.log('    --getStillSelectedTags selected[%O] valid[%O] persist[%O]', selected, valid, persist);
    return persist.length ? persist : null;
}
/* ------------------------ BUILD TAG-OPTS ---------------------------------- */
function buildTagOpts ( typeTags ) {
    return _opts.alphabetizeOpts( Object.values( typeTags ).map( getTagOpt ) );
}
function getTagOpt ( tag ) {
    return { text: tag.displayName, value: tag.id };
}
/* ------------------------- LOAD TAG-OPTS ---------------------------------- */
function loadTagOpts ( opts = [] ) {                                   /*dbug-log*///console.log('   --loadTagOpts opts?[%O]', opts);
    _cmbx.replaceSelOpts( 'InteractionTags', opts );
    afterTagOptsLoaded();
}
function afterTagOptsLoaded() {
    const tags = [ ...getInitVal(), ...md.autoTag ].filter( t => t );     /*dbug-log*///console.log('       --afterTagOptsLoaded tags[%O]', tags);
    if ( tags.length ) { _cmbx.setSelVal( 'InteractionTags', tags ); }
}
/**
 * Init-val is set when tag data is persistsed into a new interaction, and during
 * edit-form build to fill the field with record data.
 */
function getInitVal() {
    const initVal = $( '#sel-InteractionTags' ).data( 'init-val' );
    return initVal ? initVal : [];
}
/* ====================== ON TAG SELECTION ================================== */
export function onTagSelection ( tags ) {                              /*dbug-log*///console.log('onTagSelection [%O]', tags);
    ensureDefaultTagsStaysSelected( tags );
    iForm.focusPinAndEnableSubmitIfFormValid();
    if ( tags.length ) { return checkForRelatedTagUpdates( tags ); }

}
function ensureDefaultTagsStaysSelected ( tags ) {
    md.autoTag.every( t => ensureTagSelected( t, tags ) );
}
function ensureTagSelected ( id, tags ) {
    if ( tags.indexOf( id ) === -1 ) { addTag( id, tags ); }
}
function checkForRelatedTagUpdates ( tags ) {
    const tField = _state( 'getFieldState', [ 'top', 'InteractionTags', false ] );
    checkForRelatedTagChanges( tField.misc.typeTags, tags );
}
function checkForRelatedTagChanges ( typeTags, tags = [] ) {           /*dbug-log*///console.log('   -- checkForRelatedChanges typeTags[%O] tags?[%O]', typeTags, tags);
    tags.forEach( id => handleRelatedTags( id, typeTags, tags ) );
}
function handleRelatedTags ( id, typeTags, tags ) {
    const tag = typeTags[id].displayName;
    const map = {
        'Synchronous': checkIfCoroostNeeded
    };
    if ( map[ tag ] ) { map[ tag ]( typeTags, tags ); }
}
/** Bat->Bat Cohabitation - Synchronous: is always a Coroost interaction   */
function checkIfCoroostNeeded ( typeTags, tags ) {
    const coroostId = Object.keys( typeTags ).find( id => typeTags[ id ].displayName === 'Coroost' );
    if ( coroostId ) { return addTag( coroostId, tags ); }
}
function addTag ( id, tags ) {                                         /*dbug-log*///console.log('        --addTag id[%s] tags[%O]', id, tags);
    $( '#sel-InteractionTags' )[ 0 ].selectize.addItem( id, 'silent' );
    tags.push( String( id ) );
    _state( 'setFieldState', [ 'top', 'InteractionTags', tags ] );
}
/**
 * Form-state setters
 *
 * TOC
 *     SET CORE STATE
 *         CLEAR
 *     SET FORM STATE
 *         FIELDS
 *         PENDING
 *         TAXON
 */
import { _db, _u } from '~util';
import { _map } from '~db';
import { _data, _elems, _state } from '~form';
/* ======================= SET CORE STATE =================================== */
export function setStateProp( fS, prop, val ) {                       /*dbug-log*///console.log('   --setStateProp [%s][%s][%O]', prop, val, fS);
    fS[prop] = val;
}
export function updateFormRecordState( fS, entity ) {
    return _db.getData( _u.lcfirst( entity ) )
        .then( rcrds => setEntityRecords( fS, entity, rcrds ) );
}
function setEntityRecords( fS, entity, rcrds ) {                      /*dbug-log*///console.log('   --setEntityRecords entity[%s] rcrds[%O] fS[%O]', entity, rcrds, fS);
    fS.records[entity] = rcrds;
}
/* ------------------- CLEAR ------------------------------------------------ */
export function clearFormGroupState( fS, fLvl = 'top', clearAll ) {   /*dbug-log*///console.log('   --clearFormGroupState [%s][%O]', fLvl, fS);
    if ( fLvl === 'top' ) { return clearFormState( fS, clearAll ); }
    const fState = fS.forms[fLvl];
    delete fS.forms[fState.name];
    delete fS.forms[fLvl];
}
function clearFormState( fS, clearAll ) {
    _map( 'clearMemory' );
    handleReviewFormClear( fS );
    clearAll();
}
/** Handle unlocking open pending-data records, if the review was not submitted. */
export function handleReviewFormClear( fS ) {                         /*dbug-log*///console.log('   -- handleReviewFormClear submitted?[%s] fState[%O]', fS.forms.top.submitted, _u.snapshot(fS.forms.top));
    if ( !fS.forms ) { return; } //form closed
    const pData = fS.forms.top.pending;
    if ( fS.forms.top.submitted || !pData ) { return; }
    _data( 'pushPendingData', [pData.id, 'Skip', null, true] );
}
/* ====================== SET FORM STATE ==================================== */
export function setFormState( fState, prop, val ) {                   /*dbug-log*///console.log('   --setFormState [%s][%s][%O]', prop, val, fState);
    fState[prop] = val;
}
/** Sets form event-handler, overwritting any previously set. */
export function setFormHandler( fState, type, handler ) {             /*dbug-log*///console.log('   --setFormHandler type[%s] handler[%O] fState[%O]' , type, handler, fState);
    fState.handlers[type] = handler;
}
/** Adds handler to call after previously set handlers */
export function addFormHandler( fState, type, newHandler ) {          /*dbug-log*///console.log('   --addFormHandler type[%s] newHandler[%O] fState[%O]' , type, newHandler, fState);
    const handler = fState.handlers[type] ? fState.handlers[type] : () => {};
    fState.handlers[type] = callHandler.bind( null, type, handler, newHandler );
}
/**
 * Handlers are called in the order they were set.
 * @param  {function} leafHandler Current handler
 * @param  {function} branch      Previously set handlers of this type
 * @return {promise}
 */
function callHandler( type, leafHandler, nextHandler = Function.prototype ) {/*dbug-log*///console.log('   --callHandler type[%s] leafHandler[%O] nextHandler[%O]' , type, leafHandler, nextHandler);
    return Promise.resolve( leafHandler() ).then( nextHandler );
}
/* ----------------------- FIELDS ------------------------------------------- */
/**
 * Note: if prop is null, val is complete field-data and replaces all previous data.
 * TODO2: REFACTOR TO SAME ARG ORDER AS SET FORM STATE
 */
export function setFieldState( fState, fieldKey, val, prop = 'value' ) {/*dbug-log*///console.log('    --setFieldState fieldKey[%s] prop[%s] val?[%O] [%O]', fieldKey, prop, val, fState);//console.trace()
    let field = fState.fields[fieldKey];
    if ( prop === 'value' ) { setFieldValue( field, val ); }
    if ( !prop ) {
        field = val;
        toggleRequiredField( fState, fieldKey, val.required );
    } else {
        field[prop] = val;
    }                                                               /*dbug-log*///console.log('         -- new fieldState[%O]', _u.snapshot(field));
    onFieldStateChange( fState, fieldKey, val, prop );
}
/**
 * Sets field value. Handles setting within standard form-processes and during
 * data-review form initialization.
 */
export function setFieldValue( field, val, ord ) {                    /*dbug-log*///console.log('    -- setFieldValue field[%s][%O] val[%O] ord?[%s]', field.name, field, val, ord);
    if ( ord ) { return field.value[ord] = val; }
    field.value = val;
}
function onFieldStateChange( fState, fieldKey, val, prop ) {
    const map = {
        required: toggleRequiredField,
        value: ifPendingDataAddRecordToField
    };
    return map[prop]  ? map[prop]( ...arguments ) : null;
}
function toggleRequiredField( fState, fieldKey, val ) {                /*dbug-log*///console.log('    --toggleRequiredField fieldKey[%s] val?[%O] [%O]', fieldKey, val, fState);//console.trace()
    if ( val ) {
        $( `#${ fieldKey }_lbl` ).addClass( 'required' );
        _elems( 'checkReqFieldsAndToggleSubmitBttn', [fState.group] );
    } else {
        $( `#${ fieldKey }_lbl` ).removeClass( 'required' );
    }
}
/**
 * If the data added is an entity pending-review, the 'pending' property is added
 * to the field with the PendingData record. This triggers special handling elsewhere.
 */
function ifPendingDataAddRecordToField( fState, fieldKey, val, p ) {
    const field = fState.fields[fieldKey];
    if ( !_data( 'isPossiblyQuarantined', [field] ) ) { return; }       /*dbug-log*///console.log('    --ifPendingDataAddRecordToField field[%O]', _u.snapshot(field));
    if ( field.count ) { return Object.keys( val ).forEach( handleMultiFieldPending ); }
    const id = _u.isObj( val ) ? val.value : val;
    const rcrd = _state( 'getRcrd', [field.entity, id] );             /*dbug-log*///console.log('       -- Selected entity record [%s %O]', id, rcrd)
    setFieldPendingData( fState, fieldKey, rcrd );

    function handleMultiFieldPending( ord ) {
        if ( !val[ord] ) { return; } // Quarantined-data during manager review
        const rcrd = _state( 'getRcrd', ['source', val[ord]] );       /*dbug-log*///console.log('       --handleMultiFieldPending field[%s %O] ord[%s] val[%O] rcrd[%O]', field.name, _u.snapshot(field), ord, val, rcrd);
        setFieldPendingData( fState, fieldKey, rcrd, ord )
    }
}
/* __________________________ PENDING _______________________________________ */
/** When pending entity-data is selected, the pending data is added to the field. */
export function setFieldPendingData( fState, fieldKey, rcrd, ord ) {  /*dbug-log*///console.log('    --setFieldPendingData fieldKey[%s] rcrd[%O] ord?[%O] [%O]', fieldKey, rcrd, ord, fState);
    if ( ifPendingNotPossible( fState.action ) ) { return; }
    const field = fState.fields[fieldKey];                          /*dbug-log*///console.log('       --field [%O]', _u.snapshot(field));
    if ( ord ) { return setMultFieldPending( fState.pending, field, rcrd, ord ); }
    return handleSettingPendingData( rcrd, fState.pending, field );
}
function ifPendingNotPossible( action ) {
    return action !== 'review' && _u.getUserRole() !== 'contributor';
}
/* ---------------- FIELD --------------------- */
function handleSettingPendingData( rcrd, isReview, field ) {
    if ( isPendingCleared( field, rcrd ) ) { return delete field.pending; }
    if ( !rcrd || !rcrd.pending ) { return; } //Contributor reviewing final result
    setPendingRcrd( rcrd, field, isReview );
}
/* ---------------- MULTIFIELD --------------------- */
function setMultFieldPending( isReview, field, rcrd, ord ) {          /*dbug-log*///console.log('           --setMultiFieldPending field[%O] rcrd[%O] ord[%s]', _u.snapshot(field), rcrd, ord);
    if ( isPendingCleared( field, rcrd, ord ) ) { return clearFieldPending( field, ord ); }
    if ( !rcrd || !rcrd.pending ) { return; } //Contributor reviewing final result
    if ( !field.pending ) { field.pending = {}; }
    setPendingRcrd( rcrd, field, isReview, ord );
}
function isPendingCleared( field, rcrd, ord ) {                       /*dbug-log*///console.log('           --isPendingCleared field[%s %O] rcrd[%O] ord?[%s]', field.name, _u.snapshot(field), rcrd, ord);
    if ( rcrd && rcrd.pending ) { return false; }
    if ( !_u.isContributorUser() || !field.pending ) { return true; }
    if ( ord ) { return field.pending[ord] && field.pending[ord].completed; }
    return field.pending && field.pending.completed;
}
function clearFieldPending( field, ord ) {                            /*dbug-log*///console.log('    --clearFieldPending field[%s][%O] ord?[%s]', field.name, field, ord);
    if ( !field.pending ) { return; }
    delete field.pending[ord];
    if ( Object.keys( field.pending ).length ) { return; }
    delete field.pending;
}
/* ---------------- HELPERS --------------------- */
function setPendingRcrd( rcrd, field, isReview, ord ) {               /*dbug-log*///console.log('           -- setPendingRcrd field[%O] rcrd[%O] ord[%s]', _u.snapshot(field), _u.snapshot(rcrd), ord);
    if ( !isReview ) { return asyncSetPendingData( field, rcrd, ord ); }
    setFieldPending( field, _state( 'getRcrd', ['pending', rcrd.pending.id] ), ord );
}
/** Handles initial contributor-forms without pending data in memory. */
function asyncSetPendingData( field, rcrd, ord ) {                    /*dbug-log*///console.log('           -- asyncSetPendingData field[%O] rcrd[%O] ord[%s]', _u.snapshot(field), rcrd, ord);
    _db.getData( 'pending' ).then( setPendingData );

    function setPendingData( PendingData ) {
        const pRcrd = PendingData[rcrd.pending.id];
        setFieldPending( field, pRcrd, ord );
    }
}
/**
 * Sets field pending-data. Handles setting within standard form-processes and
 * during data-review form initialization.
 */
export function setFieldPending( field, pRcrd, ord ) {                /*dbug-log*///console.log('           -- setFieldPending field[%O] pRcrd[%O] ord[%s]', _u.snapshot(field), pRcrd, ord);  //console.trace()
    if ( ord ) { return field.pending[ord] = pRcrd; }
    field.pending = pRcrd;
}
/* ___________________________ TAXON ________________________________________ */
/** Note: Group init-value required. */
export function setTaxonGroupState( rcrds, f ) {                      /*dbug-log*///console.log('--setTaxonGroupState rcrds[%O] f[%O]', rcrds, f);
    const group = getGroupEntity( rcrds, f );
    const sGroupId = getSubGroupId( rcrds, f, group );                /*dbug-log*///console.log('   --setTaxonGroupState subGroupId[%s] group[%O] ', sGroupId, group);
    setGroupState( rcrds.taxon, f, group );
    setSubGroupState( rcrds.taxon, f.fields, group.subGroups, sGroupId );
}
function getGroupEntity( rcrds, f ) {
    return rcrds.group[f.fields.Group.value];
}
function getSubGroupId( rcrds, f, group ) {
    const subGroupId = f.fields['Sub-Group'].value;                 /*dbug-log*///console.log('--getSubGroupId group[%O] subGroupId[%s]', group, subGroupId);
    return subGroupId ? subGroupId : Object.keys( group.subGroups )[0];
}
/** [setGroupState description] */
function setGroupState( taxa, fState, group ) {                       /*dbug-log*///console.log('--setGroupState group[%O]', group);
    fState.fields.Group.value = group.id;
    fState.fields.Group.misc = {
        rcrd: group,
        subGroups: group.subGroups
    };                                                              /*dbug-log*///console.log('   --updated state[%O]', _u.snapshot(fState.fields.Group.misc));
}
/** [setSubGroupState description] */
function setSubGroupState( rcrds, fields, subGroups, sGroupId ) {     /*dbug-log*///console.log('--setSubGroupState rcrds[%O], fields[%O], subGroups[%O], sGroupId[%s]', rcrds, fields, subGroups, sGroupId);
    const subGroup = subGroups[sGroupId];
    fields['Sub-Group'].shown = Object.keys( subGroups ).length > 1;
    fields['Sub-Group'].misc = {
        rcrd: subGroup,
        subRanks: subGroup.subRanks,
        taxon: rcrds[subGroup.taxon]
    };
    fields['Sub-Group'].value = subGroup.id;                        /*dbug-log*///console.log('--setSubGroupState field[%O] subGroup[%O] subGroups[%O]', _u.snapshot(fields['Sub-Group']), subGroup, subGroups);
}
/**
 * When Source data is changed, all related citation fullText is regenerated.
 * Regenerates all citations for passed ids.
 *
 * Export
 *     ifSourceDataEditedUpdatedCitations
 *     updateCitations
 *
 * TOC
 *     SYNC RELATED CITATIONS
 *         CHECK FOR RELEVANT DATA-UPDATES
 *     UPDATE RELATED CITATIONS
 *         REGENERATE CITATIONS
 *         SERVER-DATA UPDATE
 *         LOCAL-DATA UPDATE
 */
import { _db, _u } from '~util';
import { _data, _state } from '~form';
import { handleLocalDataUpdate, hasEdits } from '../db-sync-main';
import { EntityRecords, SerializedEntity } from '~types';
import { DataEntryResults } from './data-entry-sync';

let rcrds: {
    author: EntityRecords,
    citation: EntityRecords,
    source: EntityRecords,
    publisher: EntityRecords,
};
/* ==================== SYNC RELATED CITATIONS ============================== */
// window.setTimeout(updateAllCitations, 1000);  //Used for data-cleanup
export default function ifSourceDataEditedUpdatedCitations ( data: DataEntryResults ): Promise<void> {
    if ( hasNoClearedChanges( data ) ) return Promise.resolve();    /*dbug-log*///console.log('ifSourceDataEditedUpdatedCitations data = %O', data);
    initEntityRecordData();
    return updateRelatedCitations( data );
}
function initEntityRecordData (): void {
    rcrds = {
        author: _db.getEntities( 'author' ),
        citation: _db.getEntities( 'citation' ),
        source: _db.getEntities( 'source' ),
        publisher: _db.getEntities( 'publisher' )
    };
}
/* -------------------- CHECK FOR RELEVANT DATA-UPDATES --------------------- */
function hasNoClearedChanges ( data: DataEntryResults ): boolean {
    return hasNoEditedSourceData( data ) || isPendingData( data );
}
function hasNoEditedSourceData ( data: DataEntryResults ): boolean {
    return data.core !== 'source' ||
        data.detail === 'citation' ||
        !( hasEdits( data.coreEdits ) || hasEdits( data.detailEdits ) );
}
function isPendingData ( data: DataEntryResults ): boolean {
    const stage = data.coreEntity.pending?.stage;
    return stage && stage !== 'Approved';
}
/* ==================== UPDATE RELATED CITATIONS ============================ */
/** Updates the citations for edited Authors, Publications or Publishers. */
function updateRelatedCitations ( data: DataEntryResults ): Promise<void> {
    const [srcType, citIds] = getCitationUpdateParams( data );      /*dbug-log*///console.log( 'updateRelatedCitations. srcType[%s] citType[%S] data[%O]', srcType, citIds, data );
    if ( !citIds.length ) return Promise.resolve();
    return updateCitations( citIds, srcType );
}
function getCitationUpdateParams ( data: DataEntryResults ): [string, number[]] {
    const srcType = data.coreEntity.sourceType.displayName;
    const citIds = getCitationIds( srcType, data );
    return [srcType, citIds];
}
function getCitationIds ( srcType: string, data: DataEntryResults ): number[] {
    switch ( srcType ) {
        case "Author":
            return getChildCites( data.coreEntity.contributions );
        case "Publication":
            return data.coreEntity.children;
        case "Publisher":
            return getChildCites( data.coreEntity.children );
        default:
            throw Error( `Unknown source-type ${ srcType }` );
    }
}
function getChildCites ( publications: number[] ): number[] {
    const citIds: number[] = [];
    publications.forEach( addSrcCitations );
    return citIds;

    function addSrcCitations ( id: number ): void {
        const src = _db.getEntity( rcrds.source, id, 'source' );
        if ( src?.citation ) {
            citIds.push( src.id );
        } else {
            src?.children.forEach( ( cId: number ) => citIds.push( cId ) );
        }
    }
}
/* -------------------- REGENERATE CITATIONS -------------------------------- */
function updateCitations (
    citIds: number[],
    srcType: string
): Promise<void> {                                                  /*dbug-log*///console.log('updateCitations. citIds = %O, rcrds = %O', citIds, rcrds);
    const updateCitations = citIds.map( id => updateCitText( id, srcType ) );
    return Promise.all( updateCitations )
        .then( onUpdateSuccess );
}
function updateCitText ( id: number, srcType: string ): JQuery.jqXHR<DataEntryResults> {
    const citSrc = _db.getEntity( rcrds.source, id, 'source' );
    const params = {
        cit: _db.getEntity( rcrds.citation, citSrc.citation, 'citation' ),
        citSrc: citSrc,
        pubSrc: _db.getEntity( rcrds.source, citSrc.parent, 'source' ),
        rcrds: rcrds
    };
    const citText: string = _u.generateCitationText( params );     /*dbug-log*///console.log('citText = %O', citText)
    return updateCitationData( citSrc, citText, srcType );
}
/** Regenerates all citation data in server data. Used for data cleanup. */
// function updateAllCitations() {                                     /*dbug-log*///console.log('updateAllCitations');
//     _db('getData', [['author', 'citSrcs', 'citation', 'publisher', 'source']])
//     .then(data => {
//         srcRcrds = data.source;
//         updateCitations(data.citSrcs, data)
//     });
// }
/* -------------------- SERVER-DATA UPDATE ---------------------------------- */
/** Sends ajax data to update citation and source entities. */
function updateCitationData (
    citSrc: SerializedEntity,
    text: string,
    srcType: string
): JQuery.jqXHR<DataEntryResults> {
    const data = { srcId: citSrc.id, text: text };
    return _u.sendAjaxQuery( data, 'crud/citation/edit', Function.prototype, citUpdateErr );

    function citUpdateErr ( _1: Error ): void {
        const fLvl = _state( 'getEntityFormLevel', [_u.lcfirst( srcType )] );
        _data( 'onSubmitError', [fLvl, ...arguments] );
    }
}
/* --------------------- LOCAL-DATA UPDATE ---------------------------------- */
function onUpdateSuccess ( ajaxData: DataEntryResults[] ): Promise<void> {
    return Promise.all( ajaxData.map( data => handleLocalDataUpdate( data ) ) )
        .then();
}
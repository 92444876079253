/**
 * Resets the interactions form leaving only the pinned values. Displays a
 * success message. Disables submit button until any field is changed.
 *
 * Export
 *     resetInteractionForm
 *
 * TOC
 *     HANDLE INTERACTION-FORM RESET
 *         HANDLE PERSISTED FIELDS
 *     CLEAR FIELD DATA
 *         CLEAR FIELD-TYPE
 *         FIELD-SPECIFIC CLEAR
 *     RESET FORM UI
 */
import { _cmbx, _u } from '~util';
import { _elems, _form, _state } from '~form';
import * as iForm from '../int-form-main.js';

let fields;
/* ==================== HANDLE INTERACTION-FORM RESET ============================= */
export function resetInteractionForm() {
    _elems( 'toggleFormStatusMsg', ['New Interaction successfully created.'] );
    resetIntFields();
    resetFormUi();
    _elems( 'resetReviewSidePanel' );
    _elems( 'toggleWaitOverlay', [false] );
}
/**
 * Resets the top-form in preparation for another entry. Pinned field values are
 * persisted. All other fields will be reset.
 */
function resetIntFields() {
    fields = _state( 'getFormState', ['top', 'fields'] );             /*dbug-log*///console.log('--resetInteractionForm fields[%O]', fields);
    Object.values( fields ).map( handleFieldDataReset );
}
function handleFieldDataReset( field ) {
    if ( !field.shown ) { return; }
    if ( !$( `#${ field.name }_pin` ).prop( 'checked' ) ) {
        clearField( field );
    } else {
        handePersistedField( field );
    }
}
/* --------------------- HANDLE PERSISTED FIELDS ---------------------------- */
function handePersistedField( field ) {                               /*dbug-log*///console.log('--handePersistedField field[%s][%O]', field.name, _u.snapshot(field));
    const map = {
        InteractionType: ensureTypeTagsSelected
    }
    if ( !map[field.name] ) { return; }
    map[field.name]( field );
}
function ensureTypeTagsSelected( field ) {
    iForm.onTypeSelection( _cmbx.getSelVal( field.name ) );
}
/* ==================== CLEAR FIELD DATA ==================================== */
function clearField( field ) {                                        /*dbug-log*///console.log('--clearField field[%s][%O]', field.name, _u.snapshot(field));
    clearFieldValue( field );
    clearFieldType( field );
    handleClearedField( field );
}
/* ------------------- CLEAR FIELD-TYPE ------------------------------------- */
function clearFieldType( field ) {
    const map = {
        page: clearTextareaField,
        select: clearComboField,
        tags: clearComboField,
        fullTextArea: clearTextareaField
    };
    if ( !map[field.type] ) { return; }
    map[field.type]( field );
}
function clearFieldValue( field ) {
    $( `#${ field.name }_pin` ).prop( 'checked', false );
    field.value = null;
}
function clearComboField( field ) {
    const persistOpts = [ 'Publication', 'Country-Region', 'Source', 'Season' ];
    _cmbx.resetCombobox( field.name, persistOpts.indexOf( field.name ) === -1 );
}
function clearTextareaField( field ) {
    $( `#${ field.name }_f .f-input` ).val( "" );
}
/* -------------------- FIELD-SPECIFIC CLEAR -------------------------------- */
function handleClearedField( field ) {
    const map = {
        CitationTitle: clearSidePanelCitationDetails,
        InteractionType: clearTypeAndTags,
        Location: syncWithCountryField,
        Object: clearTaxonField,
        Source: ensureQuoteIsNotRequiredAndPrimarySelected,
        Date: _form.bind( null, 'clearInteractionDate' ),
        Publication: iForm.clearCitationCombo,
        Subject: clearTaxonField,
    }
    if ( !map[field.name] ) { return; }
    map[field.name]( field );
}
function clearSidePanelCitationDetails( field ) {
    if ( !_cmbx.getSelVal( 'Publication' ) ) { return; } //already cleared
    _elems( 'clearSidePanelDetails', ['cit'] );
}
function clearTypeAndTags( field ) {
    iForm.onTypeSelection( null );
}
function clearTaxonField( field ) {
    if ( ['Subject', 'Object'].indexOf( field.name ) === -1 ) { return; }
    _cmbx.replaceSelOpts( field.name, [] );
    _cmbx.enableCombobox( 'InteractionType', false );
    $( '#sel-'+field.name ).data( 'selTaxon', false );
}
function syncWithCountryField( field ) {
    const cntryId = fields['Country-Region'].value;
    const cntry = cntryId ? _state( 'getRcrd', ['location', cntryId] ) : null;
    iForm.resetLocCombo( cntry );
    _elems( 'clearSidePanelDetails', ['loc'] );
}
function ensureQuoteIsNotRequiredAndPrimarySelected( field ) {
    _state( 'setFieldState', ['top', 'Quote', false, 'required'] );
    _cmbx.setSelVal( 'Source', 'Primary' );
    field.value = 'Primary';
}
/* ==================== RESET FORM UI ======================================= */
function resetFormUi() {
    $( '#top-cancel' ).val( ' Close ' );
    _elems( 'toggleSubmitBttn', ['top', false] );
    _state( 'setFormState', ['top', 'unchanged', true] );
}
/**
 * For data-managers, a combobox with the possible review-actions is added next to
 * the submit button. For data-contributors, the submit-button text is updated.
 *
 * Export
 *     handleReviewSubmitElems
 *
 * TOC
 *     BUILD CHAIN
 *         DATA-MANAGER
 *         DATA-CONTRIBUTOR
 *     COMBOBOX
 *         INIT
 *         ON CHANGE
 *     VALIDATE
 *         CHECK FOR SUB-PENDING-DATA
 *         ALERT SUB-PENDING-DATA
 */
import { _cmbx, _el, _opts, _u } from '~util';
import { _data, _elems, _state } from '~form';
/* ===================== BUILD CHAINS ======================================= */
export default function handleReviewSubmitElems( pRcrd ) {             /*dbug-log*///console.log("   --handleReviewSubmitElems pRcrd[%O]", pRcrd);
    const initSubmit = _u.isManagerUser() ? initManagerCombo : updateSubmitText;
    return Promise.resolve( initSubmit( pRcrd ) );
}
/* --------------------- DATA-MANAGER --------------------------------------- */
function initManagerCombo( pRcrd ) {
    return _opts.getOptsFromStoredData( 'activeStageNames' )
        .then( opts => buildElemAndInitCombo( opts, pRcrd.inst.group ) );
}
/* --------------------- DATA-CONTRIBUTOR ----------------------------------- */
/** Note: This is the only method called during contributor data-review. */
function updateSubmitText( pRcrd ) {
    const stage = pRcrd.data.stage.name === 'Return' ? 'Quarantine' : 'Complete';
    const text = stage === 'Quarantine' ? 'Submit' : 'Confirm';
    $( `#${ pRcrd.inst.group }-submit` )[0].value = text;
    _elems( 'checkReqFieldsAndToggleSubmitBttn', [pRcrd.inst.group] );
    pRcrd.inst.rvwAction = { name: stage };
}
/* ======================== COMBOBOX ======================================== */
/** Builds a combobox with the review-actions and appends next to the submit button. */
function buildElemAndInitCombo( opts, fLvl ) {                        /*dbug-log*///console.log("   --buildElemAndInitCombo opts[%O] fLvl[%s]", opts, fLvl);
    const el = buildActionsSelect( opts, fLvl );
    appendReviewActionsSelect( el, fLvl );
    initRvwActionsCombo( fLvl );
}
function buildActionsSelect( opts, fLvl ) {                           /*dbug-log*///console.log('--buildActionsSelect opts[%O] fLvl[%s]', opts, fLvl);
    const attr = { class: 'rvw-action-cmbx', id: `sel-${ fLvl }_rvw-actions` };
    return _el.getElem( 'select', attr, opts );
}
function appendReviewActionsSelect( el, fLvl ) {
    $( `#${ fLvl }-submit` ).before( el );
}
/* --------------------- INIT ----------------------------------------------- */
function initRvwActionsCombo( fLvl ) {                                /*dbug-log*///console.log(' initRvwActionsCombo [%s]', fLvl);
    const confg = {
        onChange: onReviewActionSelect.bind( null, fLvl ),
        id: `#sel-${ fLvl }_rvw-actions`,
        confgName: fLvl+'Action',
        name: 'Action'
    };
    _cmbx.initCombobox( confg );
}
/* --------------------- ON CHANGE ------------------------------------------ */
function onReviewActionSelect( fLvl, val ) {
    const action = getFormReviewAction( fLvl, val );                  /*dbug-log*///console.log(' -- onReviewActionSelect [%s] id[%s] action[%O]', fLvl, val, action);
    const isValidAction = action ? checkIfActionValid( fLvl, action ) : false;
    if ( isValidAction ) { setFormReviewAction( fLvl, action ); }
    _elems( 'toggleSubmitBttn', [fLvl, isValidAction] );
}
function getFormReviewAction( fLvl, val ) {
    return !val ? false : {
        id: val,
        name: _cmbx.getSelTxt( `${ fLvl }Action` )
    };
}
function setFormReviewAction( fLvl, action ) {
    const pRcrd = _state( 'getFormState', [fLvl, 'pending'] );        /*dbug-log*///console.log(' -- setFormReviewAction pRcrd[%O] action[%O]', pRcrd, action)
    pRcrd.inst.rvwAction = action;
}
/* ===================== VALIDATE =========================================== */
function checkIfActionValid( fLvl, action ) {
    const map = {
        Approve: checkIfAllFieldDataValid,
    };
    if ( map[action.name] ) { return map[action.name]( fLvl ); }
    toggleSubDataAlert( fLvl, false );
    return true;
}
/* ----------------- CHECK FOR SUB-PENDING-DATA ----------------------------- */
function checkIfAllFieldDataValid( fLvl ) {
    if ( !isAllFieldDataApproved( fLvl ) ) { return toggleSubDataAlert( fLvl ); }
    return _elems( 'areRequiredFieldsFilled', [fLvl] );
}
function isAllFieldDataApproved( fLvl ) {
    const fields = _state( 'getFormState', [fLvl, 'fields'] );        /*dbug-log*///console.log("   --isAllFieldDataApproved [%s][%O]", fLvl, fields);
    return Object.values( fields ).every( isFieldDataApproved );

    function isFieldDataApproved( field ) {                           /*dbug-log*///console.log("       --isFieldDataApproved field [%s][%O]", field.name, field);
        return !field.active || !_data( 'isFieldDataPending', [field] );
    }
}
/* ----------------- ALERT SUB-PENDING-DATA ----------------------------- */
function toggleSubDataAlert( fLvl, show = true ) {
    const alertId = 'temp-sub-data-alert';
    if ( show ) { return alertPendingSubDataInvalid( fLvl, alertId ) }
    $( `#${ alertId }` ).fadeOut( 'slow', () => $( `#${ alertId }` ).remove() );
}
function alertPendingSubDataInvalid( fLvl, alertId ) {
    const sClass = 'warn-msg warn';
    const txt = 'All sub-data must be fully approved.';
    const el = `<p id="${ alertId }" class="${ sClass }">${ txt }</p>`;
    $( `#sel-${ fLvl }_rvw-actions` ).before( el );
    return false;
}
/**
 * Displays the pending-data totals in the data-review panel.
 *
 * Export
 *     loadPendingDataStats
 *
 * TOC
 *     DISPLAY COUNTS
 *         DISPLAY NONE
 *         BUILD COUNT ELEMS
 */
import { _db, _el, _u } from '~util';

/* ======================== DISPLAY COUNTS ================================== */
export function loadPendingDataStats() {
    if ( !( _u.isContributorUser() || _u.isManagerUser() ) ) { return; }
    _db.getData( ['pendingCounts', 'availablePending'], true ).then( data => {
        displayPendingDataCounts( data.pendingCounts );
        toggleStartReviewBttn( data.pendingCounts, data.availablePending );
    } );
}
function displayPendingDataCounts( countObj ) {                       /*dbug-log*///console.log('-- displayPendingDataCounts counts[%O]', countObj);
    const elems = ifCountData( countObj ) ? getCounts( countObj ) : getNoPending();
    const countsId = '#rvw-pnl-col2-content';
    $( countsId ).empty();
    $( countsId ).append( elems );
}
function ifCountData( countObj ) {
    return countObj && Object.keys( countObj ).length;
}
function toggleStartReviewBttn( countObj, availablePending ) {
    const enable = ifDataReadyToReview( availablePending );
    const opac = enable ? 1 : .3;
    const cursor = enable ? 'pointer' : 'not-allowed';              /*dbug-log*///console.log('-- toggleStartReviewBttn opacity[%s] enable[%s]', opac, enable);
    $( '#rvw-cntrl-bttn' ).css( {opacity: opac, cursor: cursor} ).attr( {disabled: !enable} );
    setNoDataNotice( enable );
}
function ifDataReadyToReview( availablePending ) {                    /*dbug-log*///console.log('   -- ifDataReadyToReview availablePending[%O]', availablePending);
    if ( !availablePending ) { return false; }
    return Object.values( availablePending ).some( d => d.data.review.group === 'top' );
}
function setNoDataNotice( activate = true ) {
    const text = !activate ? 'No data ready for review.' : '';
    $( $( '#rvw-cntrl-bttn' )[0].nextElementSibling ).text( text );
}
/* ----------------------- DISPLAY NONE ------------------------------------- */
function getNoPending() {
    return '<span>No Pending Data</span>';
}
/* ---------------------- BUILD COUNT ELEMS --------------------------------- */
function getCounts( countObj ) {                                      /*dbug-log*///console.log('-- getCounts [%O]', countObj);
    const elems = [];
    Object.keys( countObj ).forEach( addCountElems );
    return elems;
    /** Creates count elems, with Interaction counts, if presenmt, displayed first. */
    function addCountElems( ent ) {
        const counts = getCountElems( ent, countObj[ent] );
        return ent === 'interaction' ? elems.unshift( counts ) : elems.push( counts );
    }
}
function getCountElems( entity, counts ) {
    const lbl = _el.getElem( 'label', { class: `pend-cnt-cntnr flex-row` } );
    const name = _el.getElem( 'span', { class: 'pend-name', text: _u.ucfirst( entity ) } );
    $( lbl ).append( [name, ...getPendingCountElems( counts )] );
    return lbl;
}
function getPendingCountElems( counts ) {
    const elems = [];
    Object.keys( counts ).forEach( getPendingCountEl );
    return elems;

    function getPendingCountEl( action ) {
        const attr = { class: `${ action }_pend-cnt`, text: counts[action] };
        elems.push( _el.getElem( 'span', attr ) );
    }
}
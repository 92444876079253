/**
 * Shows alerts related to entity-data validation.
 *
 * TOC
 *     INTERACTION
 *     SOURCE
 *     TAXON
 *         MUST STAY GENUS RANK
 *         INCORRECT BINOMIAL
 *         MUST HAVE GENUS PARENT
 *         PARENT MUST BE HIGHER RANK
 *         TAXON MUST BE HIGHER RANK
 *         NEEDS FAMILY
 *         NEEDS GENUS
 */
import { _cmbx } from '~util';
import { _elems, _form, _state } from '~form';
import * as alert from './alerts.js';
/* =========================== INTERACTION ================================== */
export function handleNoValidIntsAndReturnAlertMsg ( elem, fLvl ) {
    return '<span>There are no valid interaction types</span>';
}
export function clearAnyGroupAlerts() {                             /*dbug-log*///console.log('clearAnyGroupAlerts')
    alert.clearAlert( $( '#InteractionType_alert' )[0], 'top' );
}
export function handleNeedsTypeTagAndReturnAlertMsg ( elem, fLvl ) {
    const type = _cmbx.getSelTxt( 'InteractionType' );
    return `<span>Please select a tag for the type: ${ type }.</span>`;
}
/* ============================= SOURCE ===================================== */
export function handleAuthBlanksAndReturnAlertMsg ( elem, fLvl ) {
    alert.setOnCloseClearAlert( elem, fLvl );
    return '<p>Please fill the blank in the order.</p>';
}
/** Called when the blank is filled in the author|editor order. */
export function clrContribFieldAlert ( field, fLvl ) {                 /*dbug-log*///console.log('clrContribFieldAlert.')
    const elem = $( `#${ field }_alert` )[0];
    alert.clearAlert( elem, fLvl );
}
/* ============================= TAXON ====================================== */
/* ---------------- MUST STAY GENUS RANK ------------------------------------ */
export function handleIsGenusPrntAndReturnAlertMsg ( elem, fLvl ) {
    return "<span>Genus' with species children must remain at genus.</span>";
}
/* ------------- INCORRECT BINOMIAL ----------------------------------------- */
export function handleNeedsGenusNameAndReturnAlertMsg ( elem, fLvl ) {
    const genus = getGenusName( fLvl );
    alert.setOnChangeClearAlert( 'Species', elem, fLvl );
    return `<span>Species must begin with the Genus name "${ genus }".</span>`;
}
function getGenusName( fLvl ) {
    const parentId = _state( 'getFieldState', [fLvl, 'Parent'] );
    return _state( 'getRcrd', ['taxon', parentId] ).name;
}
/* ---------------- MUST HAVE GENUS PARENT ---------------------------------- */
/** Note: Alert generated in the sub-form and the msg is added to the 'top' form. */
export function handleNeedsGenusParentAndReturnAlertMsg ( elem, fLvl ) {
    return '<span>Please select a genus parent for the species taxon.</span>';
}
export function clrTaxonParentAlert ( elem, fLvl, e ) {                /*dbug-log*///console.log('clrTaxonParentAlert args[%O]', arguments);
    alert.clearAlert( $( '#Parent_alert' )[0], 'top' );
    const pId = _state( 'getFormState', [ 'top', 'rcrd' ] ).parent;     /*dbug-log*///console.log('--pId [%s]', pId);
    _elems( 'exitFormElemAndClearState', [ 'sub' ] );
    _form( 'buildOptAndUpdateCombo', [ 'Parent', pId, 'silent' ] );
}
/* -------------- PARENT MUST BE HIGHER RANK -------------------------------- */
export function handleNeedsHigherRankPrntAndReturnAlertMsg ( elem, fLvl ) {
    return '<span>The parent taxon must be at a higher taxonomic rank.</span>';
}
/* -------------- TAXON MUST BE DIFFERENT RANK ------------------------------ */
export function handleNeedsHigherRankAndReturnAlertMsg ( elem, fLvl ) {
    return '<div>Taxon rank must be higher than that of child taxa.</div>';
}
export function handleNeedsLowerRankAndReturnAlertMsg ( elem, fLvl ) {
    alert.setOnChangeClearAlert( 'Parent', elem, fLvl );
    return "<div>Taxon rank must be lower than the parent rank.</div>";
}
/** Resets the taxon's rank. */
export function clrTaxonRankAlert ( elem, fLvl, e ) {
    const txnRank = _state( 'getFieldState', [ 'top', 'Sub-Group', 'misc' ] ).taxon.rank.id;
    _elems( 'setSilentVal', [ 'top', 'Rank', txnRank ] );
    alert.clearAlert( elem, fLvl );
}
export function handleRankNotAvailableInNewGroupAndReturnAlertMsg ( elem, fLvl ) {
    const groupName = _state( 'getFieldState', [ fLvl, 'Group' ] ).text;
    const vRanks = _state( 'getFieldState', [ fLvl, 'Sub-Group', 'misc' ] ).subRanks;
    return `<span>Valid ${ groupName } ranks: \n${ vRanks }</span>`;
}
/* ------------------- NEEDS FAMILY ----------------------------------------- */
export function handleNoFamilyAndReturnAlertMsg ( elem, fLvl ) {
    alert.setOnChangeClearAlert( 'Family', elem, _state( 'getFormLevel', [ 'parent', fLvl ] ) );
    window.setTimeout( () => alert.clearAlert( elem, fLvl, false ), 2000 );
    _cmbx.resetCombobox( 'Genus' );
    return '<span>Please select a family before creating a genus.</span>';
}
/* ----------------------- NEEDS GENUS -------------------------------------- */
export function handleNoGenusAndReturnAlertMsg ( elem, fLvl ) {
    alert.setOnChangeClearAlert( 'Genus', elem, _state( 'getFormLevel', [ 'parent', fLvl ] ) );
    window.setTimeout( () => alert.clearAlert( elem, fLvl, false ), 2000 );
    _cmbx.resetCombobox( 'Species' );
    return '<span>Please select a genus before creating a species.</span>';
}
/**
 * Manages the table-state object.
 *
 * Exports:
 *     tableState
 *     resetCurTreeStorageProps
 *     resetTableParams
 *
 * TOC
 *     ACCESS
 *     GET
 *     SET
 *         RESET
 */
import { _db, _u } from '~util';
/**
 * Stores table state params needed throughout the page.
 *
 * {obj} api            Ag-grid API (available after table-init complete)
 * {obj} columnApi      Ag-grid Column API (available after table-init complete)(Not sure how to access via grid api above)
 * {str} curFocus       Focus of the data in table: taxa, srcs, locs
 * {str} curView        View combo-value, sub-sort of table data.
 * {obj} data           Misc data used throughout the data-table features
 * {obj}    group          Taxon-group records keyed by id.
 * {obj}    groupNames      All taxon-groups (k) and id (v)
 * {obj}    rankNames       All ranks (k) and id (v) (Species->...->Domain)
 * {obj}    rcrds          Focus-entity records keyed by ID
 * {obj} filters        Current filter state.
 * {obj} flags          allDataAvailable, tutorialActive
 * {ary} openRows       Array of entity ids whose table rows will be expanded on load.
 * {ary} rowData        Row data in table
 * {obj} selectedOpts   K: Combobox key V: value selected
 * {str} userRole       Stores the role of the user.
 *
 * In Taxon views:
 * {ary} allGroupRanks   Array of all ranks present in the current tree.
 * {ary} groupRoles      Array of the group's interaction-roles
 * {str} groupName       Stores Taxon Group name
 * {str} groupPluralName Stores Taxon Group's plural name
 * {obj} subGroups       Sub-group taxa: id (k) {id, name, subRanks[], taxon(id)} (v)
 * {obj} taxaByRank      Taxon records in curTree organized by rank and keyed under their display name.
 */
let tState = { flags: {}};
/* ========================== ACCESS ======================================== */
export function tableState() {
    return {
        get: getTableState,
        set: setTableState
    };
}
/* ========================== GET/SET ======================================= */
/* ----------------------------- GET ---------------------------------------- */
/** Returns table state to requesting module. */
//Todo2: remove the redundant second param
function getTableState( k, keys ) {                                               //console.log('getTableState. params? ', arguments);
    return k && Array.isArray( k ) ? getStateObj( k ) : k ? tState[k] :
        keys ? getStateObj( keys ) : tState;
}
function getStateObj( keys ) {
    const obj = {};
    keys.forEach( k => obj[k] = tState[k] || null );                              //console.log('stateObj = %O', obj)
    return obj;
}
/* ----------------------------- SET ---------------------------------------- */
function setTableState( stateObj ) {                                              //console.log('setTableState. stateObj = %O', stateObj);
    Object.keys( stateObj ).forEach( k => { tState[k] = stateObj[k]; } )
}
/* ========================== INIT ========================================== */
export function initTableData( props ) {
    const standard = ['group', 'groupNames', 'rankNames'];
    if ( !Array.isArray( props ) ) { props = [props]; }
    return _db.getData( props.concat( standard ) )
        .then( addDataToState );
}
function addDataToState( data ) {
    const d = {
        groupNames: data.groupNames,
        rankNames: data.rankNames,
        group: data.group,
        rcrds: Object.values( data )[0]
    };
    delete data[Object.keys( data )[0]];  //removes rcrds data
    Object.keys( data ).forEach( k => d[k] = data[k] );
    tState.data = d;
}
/* --------------------------- RESET ---------------------------------------- */
/** Resets on focus change. */
export function resetTableParams( f, dataAvailableFlag ) {
    if ( f ) { return Promise.resolve( resetTblParams( f ) ); }
    return Promise.resolve( _db.getData( 'curFocus' ).then( resetTblParams ) );

    function resetTblParams( focus ) {
        const prevApi = tState.api; //will be destroyed before new table loads. Visually jarring to remove before the new one is ready.
        const flags = tState.flags ? tState.flags : {};
        if ( dataAvailableFlag !== undefined ) { tState.flags.allDataAvailable = dataAvailableFlag }
        tState = {
            api: prevApi,
            curFocus: focus,
            flags: flags,
            openRows: [],
            selectedOpts: {},
            userRole: _u.getUserRole()
        };
    }
}
export function resetCurTreeStorageProps() {
    delete tState.curTree;
    tState.selectedOpts = {};
    tState.openRows = [];
}
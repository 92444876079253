/**
 * Handles HTML input-validation as needed.
 * TODO: DOCUMENT
 *
 * Export
 *     handleInputValidation
 *
 * TOC
 *     INPUT BUILDERS
 *         INPUT
 *         TEXTAREA
 *     FINISH INPUT BUILD
 *         CHANGE HANDLER
 *         REQUIRED FIELDS
 */
type Input = HTMLElement | HTMLElement[];
let input: Input;
export function handleInputValidation ( type: string, el: Input ): Input {
    input = el;
    setInputValidation( type );
    return input;
}
/* ======================== SET HANDLER ===================================== */
function setInputValidation ( type: string ): void {
    const map: { [key: string]: () => void; } = {
        doi: setDoiPattern,
        lat: setLatitudePattern,
        lng: setLongitudePattern,
        page: setPageRange,
        // numRange: setNumRange,
        url: setHttpPatternAndPlaceholder,
        year: setYearCharLength
    };
    if ( map[type] ) { map[type]!(); }
}
type ValidationAttributes = {
    pattern?: string;
    placeholder?: string;
    min?: number;
    max?: number;
};
function addAttrAndValidation ( attrs: ValidationAttributes, msg: string ): void {
    $( input ).attr( attrs ).on( 'change', validateInput.bind( null, msg ) );
}
/* ----------------------- COORDINATES -------------------------------------- */
function setLatitudePattern (): void {
    const pattern = '-?([0-8]?[0-9](\\.\\d+)?|90(.[0]+)?)\\s?';
    return handleCoordPattern( 'lat', pattern );
}
function setLongitudePattern (): void {
    const pattern = '-?(180((\\.[0]+)?)|([1]?[0-7]?|[8-9])[0-9](\\.\\d+)?)';
    handleCoordPattern( 'long', pattern );
}
function handleCoordPattern ( prefix: string, pattern: string ): void {
    const msg = `Please enter a valid ${ prefix }itude.`;
    addAttrAndValidation( { pattern: pattern }, msg );
}
/* ---------------------------- DOI ----------------------------------------- */
function setDoiPattern (): void {
    const msg = 'Please enter the full DOI URL. Ex: https://doi.org/10.1111/j.1439';
    const attrs = { pattern: 'https?://doi.org/\\S+', placeholder: 'https://doi.org/...' };
    addAttrAndValidation( attrs, msg );
}
/* ------------------------------ PAGE -------------------------------------- */
/** Test that range is valid. */
function setPageRange (): void {
    const attrs = { pattern: '^[\\d-]+$', placeholder: 'Ex: ##–###' };
    const msg = 'Please enter page range with no spaces: ##-###';
    addAttrAndValidation( attrs, msg );
}
/* ---------------------------- URL ----------------------------------------- */
function setHttpPatternAndPlaceholder (): void {
    const msg = 'Please enter a valid URL. Ex: https://...';
    const attrs = { pattern: '\\b(https?://\\S+.S*\\b/?)', placeholder: 'http(s)://...' };
    addAttrAndValidation( attrs, msg );
}
/* ------------------------------ YEAR -------------------------------------- */
function setYearCharLength (): void {
    const msg = 'Please input a valid year.';
    addAttrAndValidation( { min: 1000, max: 3000 }, msg );
}
/* ========================== VALIDATE ====================================== */
function validateInput ( msg: string, ev: Event ): void {
    const input = ev.currentTarget as HTMLInputElement;
    const valid = input.checkValidity();
    if ( valid ) { return; }
    if ( msg ) { setCustomInvalidMsg( input, msg ); }
    input.reportValidity();
}
function setCustomInvalidMsg ( elem: HTMLInputElement, msg: string ): void {
    elem.setCustomValidity( msg );
    elem.oninput = resetValidityMsg.bind( null );
}
/* HTML5 validation always fails if a custom validity message is set. */
function resetValidityMsg ( ev: Event ): void {
    const elem = ev.currentTarget as HTMLInputElement;
    elem.setCustomValidity( '' );
    elem.oninput = null;
}
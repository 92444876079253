/**
 * Validates that the new rank is:
 * - higher than the highest rank of its children
 * - lower than its parent-taxon's rank
 * - if originally genus with species children, must stay at the genus rank
 * - if species, it's direct parent is a genus with the same name
 *
 * Export
 *     onRankChangeValidate
 *
 * TOC
 *    BUILD RANK VALIDATION DATA
 *    VALIDATION TESTS
 *        NEEDS HIGHER RANK
 */
import { _cmbx, _u } from '~util';
import { _elems, _form, _state, _val } from '~form';
/**
 * Ensures that the new taxon-rank is higher than its children, and that a
 * species taxon being edited has a genus parent selected.
 */
export function onRankChangeValidate ( rId ) {                      /*dbug-log*///console.log( "--onRankChangeValidate rId[%s]", rId );
    const valData = buildRankValData();
    validateRank( valData );
}
/* ======================= BUILD RANK VALIDATION DATA ======================= */
function buildRankValData() {
    const data = _state( 'getEntityRcrds', [ [ 'taxon', 'orderedRanks' ] ] );/*dbug-log*///console.log('    --buildRankValData data[%O]', data);
    data.rcrd = _state( 'getFormState', [ 'top', 'rcrd' ] );
    data.newRank = _cmbx.getSelTxt( 'Rank' );
    data.childRank = getHighestChildRank( data.rcrd, data.taxon, data.orderedRanks );
    data.parentRank = getParentRank( data.rcrd );
    return data;

    function getParentRank ( rcrd ) {
        const newRank = _state( 'getFieldState', [ 'top', 'Parent', 'misc' ] );
        return newRank ? newRank : data.taxon[rcrd.parent].rank.displayName;
    }
}
function getHighestChildRank ( taxon, taxa, ranks ) {               /*dbug-log*///console.log('    --getHighestChildRank taxon[%O] taxa[%O] ranks[%O]', taxon, taxa, ranks);
    let high = ranks.indexOf( 'Species' );
    taxon.children.forEach( checkChildRank );
    return ranks[high];

    function checkChildRank ( id ) {
        const childIdx = ranks.indexOf( taxa[id].rank.displayName );
        if ( childIdx <= high ) { return; }
        high = childIdx;
    }
}
/* ======================= VALIDATION TESTS ================================= */
function validateRank ( data ) {                                    /*dbug-log*///console.log("--validateRank data[%O]", data);
    const issues = {
        isGenusPrnt: data.childRank === 'Species' && data.newRank !== 'Genus',
        needsHigherRank: ifRankTooLow( data.newRank, data.childRank, data.orderedRanks ),
        needsLowerRank: ifRankTooLow( data.parentRank, data.newRank, data.orderedRanks )
    };                                                              /*dbug-log*///console.log('   --issues[%O]', issues);
    for ( let tag in issues ) {
        if ( issues[tag] ) return _val( 'showFormValAlert', ['Rank', tag, 'top'] );
    }
    _val( 'clearActiveAlert', ['top', 'Rank'] );
}
/* -------------------- NEEDS HIGHER RANK ----------------------------------- */
export function ifRankTooLow ( highRank, lowRank, ranks ) {         /*dbug-log*///console.log('  --ifRankTooLow? high[%s] <= low[%s]', highRank, lowRank);
    return ranks.indexOf( highRank ) <= ranks.indexOf( lowRank );
}
/**
 * The Database Search page entry point. The data table is built to display the
 * eco-interaction records organized by a selected "focus": taxa (grouped further
 * by view: bat, plant, etc), locations, or sources (grouped by either
 * authors, publications, or publishers). The data map displays interactions
 * geographically. Filtered interactions can be viewed in either form.
 *
 * TOC
 *     MODULE-EXECUTOR
 *     PAGE INIT
 */
import { _cmbx, _db, _u, executeMethod } from '~util';
import * as forms from './data-entry/data-entry-main.js';
import * as map from './map/map-main.js';
import * as table from './table/table-main.js';
import * as tutorial from './tutorial/db-tutorial.js';
import * as ui from './ui/ui-main.js';
/*
 * NOTE: Not sure why this code is getting loaded on external pages, or why this
 * is ran before the core site-init begins. Probably something tangled with webpack.
 */
if ( window.location.pathname.includes( 'search' ) ) {
    window.setTimeout( initDbPage, 500 );
}
/** ===================== MODULE-EXECUTOR =================================== */
function moduleMethod( funcName, mod, modName, params ) {
    return executeMethod( funcName, mod, modName, 'db-main', params );
}
export function _filter( funcName, params = [] ) {
    return table._filter( funcName, [...params] );
}
export function _forms( funcName, params = [] ) {
    return moduleMethod( funcName, forms, 'forms', params );
}
export function _map( funcName, params = [] ) {
    return moduleMethod( funcName, map, 'map', params );
}
export function _table( funcName, params = [] ) {
    return moduleMethod( funcName, table, 'table', params );
}
export function _tutorial( funcName, params = [] ) {
    return moduleMethod( funcName, tutorial, 'tutorial', params );
}
export function _ui( funcName, params = [] ) {
    return moduleMethod( funcName, ui, 'ui', params );
}
/** ==================== PAGE INIT ========================================== */
/**
 * Initializes the UI unless on mobile device.
 * Note: The idb-util.initDb will call @initSearchStateAndTable once local database is ready.
 */
function initDbPage () {
    if ( $( '.mobile-opt-overlay' ).length ) { console.log( 'stopping DB load' ); return; } //Popup shown in oi.js
    requireScriptsAndStyles();
    ui.init();
    _db.initDb();
}
function requireScriptsAndStyles() {
    requireCss();
    requireJs();
}
/** Loads css files used on the search database page, using Encore webpack. */
function requireCss() {
    require( 'flatpickr/dist/flatpickr.min.css' )
    require( 'styles/css/lib/ag-grid.css' );
    require( 'styles/css/lib/theme-fresh.css' );
    require( 'styles/css/lib/selectize.default.css' );
    require( 'styles/pages/db/db.styl' );
}
function requireJs() {
    require( 'leaflet-control-geocoder' );
    require( '@libs/selectize/selectize.min.js' );
}
/**
 * The first time a browser visits the search page, or when local data is reset,
 * all data is downloaded and stored from the server. The intro-walkthrough is
 * shown on first visit.
 */
export function showIntroAndLoadingMsg( resettingData ) {             /*dbug-log*///console.log('showIntroAndLoadingMsg resetting? [%s]', resettingData);
    ui.updateUiForDatabaseInit();
    ui.selectInitialSearchFocus( 'taxa', resettingData );
    if ( resettingData ) { return _cmbx.resetCombobox( 'View' ); }
    tutorial.startWalkthrough( 'taxa' );
}
/** After new data is downlaoded, the search state is initialized and page loaded. */
export function initSearchStateAndTable( focus = 'taxa', isAllDataAvailable = true ) {/*perm-log*/console.log( '   *//initSearchStateAndTable. focus? [%s], allDataAvailable ? [%s]', focus, isAllDataAvailable );
    setTableInitState( focus, isAllDataAvailable );
    ui.selectInitialSearchFocus( focus );
    if ( $( 'body' ).data( 'env' ) === 'test' && isAllDataAvailable === false ) { return; }
    table.buildTable( focus )
    .then( () => _filter( 'initDefaultFilters' ) );
}
function setTableInitState( focus, isAllDataAvailable ) {
    ui.resetFilterPanelOnFocusChange( focus );
    table.resetTableParams( focus, isAllDataAvailable );
}
/** ==================== HELPERS ============================================ */
/**  Returns a copy of the record detached from the original. */
export function getDetachedRcrd ( rcrdKey, rcrds, entity ) {         /*dbug-log*///console.log("getDetachedRcrd. key = %s, rcrds = %O", rcrdKey, rcrds);
    if ( !rcrds[rcrdKey] ) return alertNoRecord( rcrdKey, entity, rcrds );
    if ( ifRecordSoftDeleted( rcrds[rcrdKey] ) ) return false;
    return _u.snapshot( rcrds[rcrdKey] );
}
function alertNoRecord ( rcrdKey, entity, rcrds ) {                  /*perm-log*/_u.logInDevEnv( "#########-ERROR- couldn't get record [%s] from %O", rcrdKey, rcrds );
    _u.alertIssue( 'noRcrdFound', {id: rcrdKey, entity: entity } );
    return false;
}
function ifRecordSoftDeleted( rcrd ) {
    const dName = rcrd.displayName && hasDeletedName( rcrd.displayName );
    const name = rcrd.name && hasDeletedName( rcrd.name );
    const deletedInt = rcrd.note && hasDeletedName( rcrd.note );    /*dbug-log*///console.log( 'ifRecordSoftDeleted?[%s] rcrd[%O]', ( dName || name || deletedInt ), rcrd )
    return dName || name || deletedInt;
}
function hasDeletedName( name ) {
    const deletes = ['DELETE', 'Delete', 'delete'];
    return deletes.find( d => name.includes( d ) );
}
/**
 * Strutural code and helpers for the search-options panels
 *
 * Export
 *     addPanelEvents
 *     closeOpenPanels
 *     togglePanel
 *     updateSubmitEvent
 *
 * TOC
 *     FACADE
 *         EXTERNAL
 *         INTERNAL
 *     EVENTS
 *     OPEN/CLOSE PANELS
 *         OPEN PANEL(S)
 *         CLOSE PANEL(S)
 *     SUBMIT/SUCCESS METHODS
 *     MISC
 */
import { _el } from '~util';
import * as filterPnl from './filter/filter-panel.js';
import * as listPnl from './list/int-list-panel.js';
import * as reviewPnl from './review/review-panel-main.js';
import * as util from './util/panel-util-main.js';
import { _tutorial } from '~db';

const confgs = util.getPanelConfigs();
/* ************************* FACADE ***************************************** */
export function newTableRowSelect () {
    return util.newTableRowSelect( ...arguments );
}
/* ======================== REVIEW PANEL ==================================== */
export function initDataInReviewPanel () {
    reviewPnl.initDataInReviewPanel( ...arguments );
}
export function loadPendingDataStats () {
    return reviewPnl.loadPendingDataStats();
}
/* ======================== FILTER PANEL ==================================== */
export function resetFilterPanelOnFocusChange () {
    filterPnl.resetFilterPanelOnFocusChange( ...arguments );
}
export function updateFilterPanelHeader ( focus ) {
    filterPnl.updateFilterPanelHeader( focus );
}
export function enableClearFiltersButton () {
    filterPnl.enableClearFiltersButton();
}
export function clearFilterUi () {
    filterPnl.clearFilterUi();
}
export function updateFilterStatusMsg () {
    filterPnl.updateFilterStatusMsg();
}
export function updateTaxonFilterViewMsg ( groupName ) {
    filterPnl.updateTaxonFilterViewMsg( groupName );
}
/* -------- INTERACTION LISTS ------------- */
export function enableListResetBttn () {
    return listPnl.enableListResetBttn();
}
/* ********************* MAIN CODE ****************************************** */
/* ======================= EVENTS =========================================== */
export function addPanelEventsAndStyles ( userRole ) {
    require( 'styles/pages/db/panels/panel.styl' );
    setInfoButtonClickEvents();
    filterPnl.initFilterPanel();
    listPnl.initListPanel();
    if ( ['visitor', 'user'].indexOf( userRole ) !== -1 ) { return; }
    initReviewPanel( userRole );
}
function setInfoButtonClickEvents () {
    $( '#svd-list-hlp' ).on( 'click', () => showTutorialSection( 'data-list' ) );
    $( '#svd-fltr-hlp' ).on( 'click', () => showTutorialSection( 'filter-set' ) );
    $( '#fltr-pnl-hlp' ).on( 'click', () => showTutorialSection( 'filter-panel' ) );
}
function showTutorialSection( key ) {
    _el.showInfoModal( getHelpSteps( key ) )
}
function getHelpSteps ( key ) {
    const getSteps = {
        'filter-panel': 'getFilterPanelSteps',
        'filter-set': 'getSavedFilterSteps',
        'data-list': 'getSavedListSteps'
    };
    return _tutorial( getSteps[key] );
}
function initReviewPanel ( userRole ) {
    reviewPnl.initReviewPanel( userRole );
    confgs['review'].onClose = reviewPnl.getOnCloseHandler();
}
export function updateSubmitEvent ( id, event ) {
    $( id ).off( 'click' ).on( 'click', event );
}
/* ==================== OPEN/CLOSE PANELS =================================== */
export function closeOpenPanels () {
    const opened = getOpenPanels();
    opened.forEach( key => togglePanel( key, 'close' ) );
}
export function togglePanel ( key, state ) {                                       //console.log('togglePanel [%s] [%s]', key, state);
    const panel = confgs[key];
    if ( state === 'open' ) { openPanel( panel ); }
    else { closePanel( panel ); }
}
/* -------------------------- Open Panel(s) --------------------------------- */
function openPanel ( panel ) {                                                     //console.log('openPanel = %O', panel);
    const opened = getOpenPanels();
    if ( !opened.length ) { return cssOpenPanel( panel ); }
    if ( isCompatiblePanelOpened( opened, panel ) ) {
        openVerticalPanels( panel );
    } else {
        closeOpenedPanelThenOpenNewPanel( opened, panel );
    }
}
function cssOpenPanel ( panel ) {
    $( panel.bttn ).addClass( 'panel-open-toggle' );
    $( panel.id ).removeClass( 'closed' );
    $( panel.tab ).addClass( 'shw-col-borders ' + panel.tabClass );
    window.setTimeout( () => $( panel.id ).css( 'overflow-y', 'visible' ), 1000 );
}
function isCompatiblePanelOpened ( opened, panel ) {
    return panel.key === 'review' ?
        false : opened.every( k => ['filter', 'lists'].indexOf( k ) >= 0 );
}
function openVerticalPanels ( panel ) {
    $( '#fltr-int-pnl-cntnr' ).attr( 'class', 'flex-row' );
    $( '#filter-pnl, #list-pnl' ).removeClass( 'flex-row' ).addClass( 'flex-col' );
    cssOpenPanel( panel );
    listPnl.toggleListPanelOrientation( 'vert' );
    filterPnl.toggleFilterPanelOrientation( 'vert' );
}
function closeOpenedPanelThenOpenNewPanel ( opened, panel ) {                      //console.log('closeOpenedPanelThenOpenNewPanel. toClose = %O, newPanel = %O', opened, panel)
    opened.forEach( key => closePanel( confgs[key] ) );
    window.setTimeout( () => cssOpenPanel( panel ), 500 );
}
/* ------------------------ Close Panel(s) ---------------------------------- */
function closePanel ( panel ) {
    const opened = getOpenPanels();
    if ( opened.length === 1 ) {
        cssClosePanel( panel );
    } else { closeVerticalPanel( panel ); }
    panel.onClose();
}
function cssClosePanel ( panel ) {
    $( panel.bttn ).removeClass( 'panel-open-toggle' );
    $( panel.id ).css( 'overflow-y', 'hidden' );
    $( panel.tab ).removeClass( 'shw-col-borders ' + panel.tabClass );
    $( panel.id ).addClass( 'closed' );
    window.setTimeout( () => $( panel.id ).css( 'overflow-y', 'hidden' ), 500 );  //Handles potential overlap with opening and closing rapidly due to rapid clicking.
}
function closeVerticalPanel ( panel ) {
    cssClosePanel( panel );
    window.setTimeout( () => {
        filterPnl.toggleFilterPanelOrientation( 'horz', panel.id.includes( 'filter' ) );
        listPnl.toggleListPanelOrientation( 'horz' );
        $( '#fltr-int-pnl-cntnr' ).attr( 'class', 'flex-col' );
        $( '#filter-pnl, #list-pnl' ).removeClass( 'flex-col' ).addClass( 'flex-row' );
    }, 500 );
}
/* ------------------------ UTIL ---------------------------------- */
function getOpenPanels () {
    return Object.keys( confgs ).filter( key => isPanelOpen( confgs[key] ) );
}
function isPanelOpen ( config ) {
    return $( config.id ).length && !$( config.id ).hasClass( 'closed' );
}
/**
 * Initiates and appends the main entity form.
 *
 * TOC
 *     INIT BUILD
 *         IF OPEN SUB-FORM ISSUE
 *         BUILD FORM
 *         SIDE-PANEL
 *     FORM PIECES
 *     EXIT FORM
 */
import { _elems } from '~form';
import * as form from './form/form-build-main.js';
import * as bttn from './buttons/buttons-main.js';
import * as panel from './side-panel/side-panel-main.js';
import * as footer from './footer/form-footer-main.js';
/* ============================= BUILD FORM ================================= */
export function initForm() {
    return form.initForm(...arguments);
}
export function initSubForm() {
    return form.initSubForm(...arguments);
}
export function finishFormBuild() {
    form.finishFormBuild(...arguments);
}
export function buildSubmitAndCancelBttns() {
    return bttn.buildSubmitAndCancelBttns(...arguments);
}
/* ----------------- IF OPEN SUB-FORM ISSUE --------------------------------- */
export function ifFormInUse() {
    return form.ifFormInUse(...arguments);
}
export function alertInUse() {
    return form.alertInUse(...arguments);
}
/* ---------------------------- SIDE-PANEL -------------------------------- */
/** ----------- INIT -------------- */
export function getSidePanelElems() {
    return panel.getSidePanelElems(...arguments);
}
export function initSubEntityDataReviewElems() {
    panel.initSubEntityDataReviewElems(...arguments);
}
/** ------------ SET -------------- */
export function setSubEntityDetails() {
    return panel.setSubEntityDetails(...arguments);
}
export function fillEditEntitySidePanel() {
    return panel.fillEditEntitySidePanel(...arguments);
}
/** ---------- CLEAR -------------- */
export function clearSidePanelDetails() {
    return panel.clearSidePanelDetails(...arguments);
}
export function resetReviewSidePanel() {
    panel.resetReviewSidePanel(...arguments);
}
/* ============================== EXIT FORM ================================= */
export function getExitButton() {
    return bttn.getExitButton(...arguments);
}
export function handleFormClose() {
    return form.handleFormClose(...arguments);
}
export function exitRootForm() {
    return form.exitRootForm(...arguments);
}
/* ========================= FORM PIECES ==================================== */
export function getFormPieces(fState) {
    const elems = {
        footer: footer.getFormFooter(fState.name, fState.group, fState.action),
        tutBttn: bttn.getFormHelpElems(fState.group, fState.infoSteps),
        exitBttn: getFormExitButton(fState.group)
    };
    return _elems('getFormRows', [fState.name, fState.group])
        .then(addRowsAndReturnPieces);

    function addRowsAndReturnPieces(rows) {
        elems.rows = rows;
        return elems;
    }
}
function getFormExitButton(fLvl) {
    if (fLvl !== 'top') { return; }
    const onExit = handleFormClose.bind(null, 'top', null, null);
    return getExitButton(onExit);
}
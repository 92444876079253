/**
 * Adds entity data to local storage.
 *
 * Exports
 *     addCoreEntityData
 *     addDetailEntityData
 *
 * TOC
 *     CORE ENTITY
 *         LOCAL PROPS
 *         RELATIONAL UPDATES
 *         EXECUTE UPDATES
 *     DETAIL ENTITY
 */
import { CoreEntity, DetailEntity, SerializedEntity, objectKeys } from '~types';
import { _u } from '~util';
import { updateData } from '../execute-update';
import * as a from './add-funcs';
/* ======================== CORE ENTITY ===================================== */
/** Updates stored-data props related to a core-entity record with new data. */
export function addCoreEntityData (
    entity: CoreEntity,
    rcrd: SerializedEntity
): void {                                                           /*dbug-log*///console.log("       --Updating Core entity. %s. %O", entity, rcrd);
    a.addToRcrdProp( entity, rcrd, entity );
    handleEntityLocalDataUpdates( entity, rcrd );
    updateCoreDataProps( entity, rcrd );
}
/* ------------------------ LOCAL PROPS ------------------------------------- */
/** Adds data to the record that is not set server-side. Must happen first. */
function handleEntityLocalDataUpdates (
    entity: CoreEntity,
    rcrd: SerializedEntity
): void {
    const update = {
        taxon: {
            group: a.addGroupDataToRcrd
        },
        interaction: {
            objGroupId: a.addGroupIdToRcrd.bind( null, 'object' ),
            subjGroupId: a.addGroupIdToRcrd.bind( null, 'subject' ),
        }
    };
    if ( !( entity in update ) ) { return; }
    updateDataProps( entity, rcrd, update[entity as keyof typeof update] );
}
function updateCoreDataProps (
    entity: CoreEntity,
    rcrd: SerializedEntity
): void {
    const updateFuncs = getRelDataHndlrs( entity, rcrd );           /*dbug-log*///console.log('updateCoreDataProps = %O', updateFuncs);
    updateDataProps( entity, rcrd, updateFuncs );
}
/* --------------------- RELATIONAL UPDATES --------------------------------- */
/** Returns an object of relational data properties and their update methods. */
function getRelDataHndlrs (
    entity: 'interaction' | 'location' | 'source' | 'taxon',
    rcrd: SerializedEntity
): AddLocalDataConfig {
    const type = getSourceType( rcrd );
    const update = {
        interaction: {
            location: a.addInteractionToEntity,
            source: a.addInteractionToEntity,
            subject: a.addInteractionRole,
            object: a.addInteractionRole,
        },
        location: {
            location: a.addToParentRcrd,
        },
        source: {
            author: {
                authSrcs: a.addToRcrdAryProp
            },
            citation: {
                authors: a.addContribData,
                source: a.addToParentRcrd,
            },
            publication: {
                pubSrcs: a.addToRcrdAryProp,
                authors: a.addContribData,
                source: a.addToParentRcrd,
                editors: a.addContribData
            },
            publisher: {
                publSrcs: a.addToRcrdAryProp
            },

        },
        taxon: {
            taxon: a.addToParentRcrd,
            taxonNames: a.addToTaxonNames
        }
    };
    const addDataConfig = update[entity];
    const config = !type ? addDataConfig : addDataConfig[type as keyof typeof addDataConfig];
    return config as AddLocalDataConfig;
}
function getSourceType ( rcrd: SerializedEntity ): string | false {
    return rcrd.sourceType ? _u.lcfirst( rcrd.sourceType.displayName ) : false;
}
/* ----------------------- EXECUTE UPDATES ---------------------------------- */
type AddLocalDataConfig = {
    [key: string]: ( ...args: any[] ) => void;
};
// export type AddMethod = <T extends string, U extends string>(
//     prop: T,
//     rcrd: SerializedEntity,
//     entity: U
// ) => void;
/** Sends entity-record data to each specialized storage-property handler. */
function updateDataProps (
    entity: string,
    rcrd: SerializedEntity,
    updateFuncs: AddLocalDataConfig
): void {                                                           /*dbug-log*///console.log( "           --updateDataProps [%s]. %O. updateFuncs = %O", entity, rcrd, updateFuncs );
    if ( !updateFuncs ) return;
    const params = { entity: entity, rcrd: rcrd, stage: 'addData' as const };
    objectKeys( updateFuncs ).forEach( prop => {
        updateData( updateFuncs[prop]!, prop, params );
    } );
}
/* ======================== DETAIL ENTITY =================================== */
/**
 * Updates stored-data props related to a detail-entity record with new data.
 * Note: Relational-data is handled while adding the core-entity record.
 */
export function addDetailEntityData (
    entity: DetailEntity,
    rcrd: SerializedEntity
): void {
    a.addToRcrdProp( entity, rcrd, entity );
}
/**
 * Mangages the Interaction Type and Interaction Tag fields in the interaction form.
 *
 * Exports:
 *     initTypeField
 *     onTypeSelection
 *     resetTypeData
 *
 * TOC
 *     FIELD STATE
 *     BUILD VALID OPTIONS
 *     LOAD OPTIONS
 *         FIELD INIT-VAL
 *     ON TYPE SELECTION
 */
import { _cmbx } from '~util';
import { _val, _state } from '~form';
import { focusPinAndEnableSubmitIfFormValid } from '../int-form-main.js';
import { clearTypeTagData, loadInteractionTypeTags } from './tag-int-field.js';

/* ========================= FIELD STATE ==================================== */
/**
 * Module-data.
 * object:          Object-taxon group-root (eg subGroup) id
 * subject:         Subject-taxon group-root (eg subGroup) id
 * validInts:       Valid interaction ids for the selected subject and object groups
 */
let md = getTypeDefaultState();

export function resetTypeData() {
    md = getTypeDefaultState();
}
function getTypeDefaultState() {
    return {
        object: null,
        subject: null,
        validInts: {}
    };
}
/**
 * Once both a subject and object taxon have been selected, all valid interaction
 * types are loaded in the field combobox,
 * @param  {int} subjGroup  Taxon sub-group id
 * @param  {int} objGroup   Taxon sub-group id
 */
export function initTypeField ( ids ) {                                /*temp-log*///console.log(        '+--initTypeField subjGroup[%s] -> objGroup[%s]', ids[0], ids[1]);
    md.subject = ids[0];
    md.object = ids[1];
    loadIntTypeOptions();
}
/* ====================== BUILD VALID OPTIONS =============================== */
function loadIntTypeOptions() {
    const vIntTypeOpts = buildValidInteractionTypeOptions();
    loadTypeOptions( vIntTypeOpts );
    if ( !vIntTypeOpts.length ) { return alertNoValidInteractions(); }
    _val( 'clearAnyGroupAlerts' );
    _cmbx.enableCombobox( 'InteractionType', true );
}
function buildValidInteractionTypeOptions() {
    const data = _state( 'getEntityRcrds', [ [ 'interactionType', 'validInteraction' ] ] );/*dbug-log*///console.log('buildInteractionTypeOptions for validInts = %O data = %O', md.validInts, data);
    return getAllValidInteractionTypes( data.validInteraction ).map( buildIntTypeOpt )

    function buildIntTypeOpt ( vData ) {
        const type = data.interactionType[vData.type];
        const txt = `${ type.displayName } (${ type.activeForm })`;
        return { text: txt, value: vData.valId };
    }
}
/**
 * Valid Interaction entties describe the valid combinations of subject & object
 * subGroups, interaction types, and tags. The ValidInteraction data is added to the
 * form's state.
 * @return {ary} Objects with each valid InteractionType id and it's ValidInteraction id
 */
function getAllValidInteractionTypes ( validInteractions ) {           /*dbug-log*///console.log('-getAllValidInteractionTypes = %O', validInteractions);
    const types = [];
    Object.keys( validInteractions ).forEach( ifValidAddData );
    return types;

    function ifValidAddData ( id ) {
        const vInt = validInteractions[id];                         /*dbug-log*///console.log('--ifValidAddData = %O', vInt);
        if ( !ifGroupsMatchState( vInt.subjectGroupRoot, vInt.objectGroupRoot ) ) { return; }
        md.validInts[id] = validInteractions[id];                   /*dbug-log*///console.log('---adding ValidInteraction[%O]', validInteractions[id]);
        types.push( { type: validInteractions[ id ].interactionType, valId: id } );
    }
}
function ifGroupsMatchState ( subjGroup, objGroup ) {                  /*dbug-log*///console.log('ifGroupsMatchState subj[%s][%s] obj[%s][%s]', subjGroup, md.subject, objGroup, md.object);
    return md.subject == subjGroup && md.object == objGroup;
}
/* ----------------- ALERT NO VALID INTERACTION-TYPES ----------------------- */
function alertNoValidInteractions() {
    onTypeClear();
    _val( 'showFormValAlert', [ 'InteractionType', 'noValidInts', 'top' ] );
    _cmbx.focusCombobox( 'InteractionTags', false );
}
/* ====================== LOAD OPTIONS ====================================== */
function loadTypeOptions ( opts ) {                                    /*dbug-log*///console.log('--loadTypeOptions[%O] md[%O]', opts, md);
    const prevType = _cmbx.getSelVal( `InteractionType` );
    _cmbx.replaceSelOpts( 'InteractionType', opts );
    selectTypeInitVal( prevType, opts );
}
/* -------------------- FIELD INIT-VAL -------------------------------------- */
/**
 * Init-val is set when type data is persistsed into a new interaction, and during
 * edit-form build to fill the field with record data.
 */
function selectTypeInitVal ( prevType, typeOpts ) {
    const preVal = $( '#sel-InteractionType' ).data( 'init-val' ) || prevType;
    const validType = Object.keys( md.validInts ).find( i => ifInitTypeValid( i, preVal ) );/*dbug-log*///console.log('selectInitValIfValidType initVal?[%s] validInts[%O] validType?[%s]', preVal, md.validInts, validType);
    const initVal = validType || ( typeOpts.length === 1 ? typeOpts[ 0 ].value : null );
    if ( initVal ) {
        _cmbx.setSelVal( 'InteractionType', initVal );
    } else {
        clearTypeTagData();
        _cmbx.focusCombobox( 'InteractionType' );
    }
}
function ifInitTypeValid ( i, typeId ) {
    const validInt = md.validInts[i];
    return validInt.interactionType == typeId;
}
/* ======================== ON TYPE SELECTION =============================== */
export function onTypeSelection ( val ) {
    if ( !val ) { return onTypeClear(); }
    const validInt = md.validInts[val];                             /*temp-log*///console.log('--onTypeSelection validInt[%O]', validInt)
    setInteractionTypeFieldData( validInt.interactionType );
    focusPinAndEnableSubmitIfFormValid( 'InteractionType' );
    if ( !Object.keys( validInt.tags ).length ) { return clearTypeTagData(); }
    loadInteractionTypeTags( validInt.tags, validInt.tagRequired );
}
function onTypeClear() {
    clearTypeTagData();
    setInteractionTypeFieldData( null );
    $( '#sel-InteractionType' ).data( 'init-val', null );
}
function setInteractionTypeFieldData ( val ) {
    _state( 'setFieldState', [ 'top', 'InteractionType', val ] );
}
/**
 * Manages form-data.
 *
 * TOC
 *     EDIT
 *     REVIEW
 *     SUBMIT
 */
import * as edit from './edit/edit-data.js';
import * as review from './review/review-data-main.js';
import * as submit from './submit/submit-main.js';
/** ---------------------------- EDIT --------------------------------------- */
export function setEditFieldValues() {
    return edit.setEditFieldValues(...arguments);
}
/** --------------------------- REVIEW -------------------------------------- */
export function addToReviewLog() {
    review.addToReviewLog(...arguments);
}
export function clearSkippedRecordsMemory() {
    review.clearSkippedRecordsMemory(...arguments);
}
export function getFirstPendingDataSet() {
    return review.getFirstPendingDataSet(...arguments);
}
export function getPendingAction() {
    return review.getPendingAction(...arguments)
}
export function getSubmittedStage() {
    return review.getSubmittedStage(...arguments);
}
export function afterPendingDataSubmitted() {
    return review.afterPendingDataSubmitted(...arguments);
}
export function isApprovedOrRejected() {
    return review.isApprovedOrRejected(...arguments);
}
export function isFieldDataPending() {
    return review.isFieldDataPending(...arguments);
}
export function isReadyToReview() {
    return review.isReadyToReview(...arguments);
}
export function isPendingAction() {
    return review.isPendingAction(...arguments);
}
export function isPossiblyQuarantined() {
    return review.isPossiblyQuarantined(...arguments);
}
export function ifPendingSubDataGetFirstGroup() {
    return review.ifPendingSubDataGetFirstGroup(...arguments);
}
export function ifValueQuarantined() {
    return review.ifValueQuarantined(...arguments);
}
export function prepareAndReturnReviewLog() {
    return review.prepareAndReturnReviewLog(...arguments);
}
export function pushPendingData() {
    return review.pushPendingData(...arguments);
}
export function pushUpdatedLogs() {
    review.pushUpdatedLogs(...arguments);
}
export function submitFormPendingData() {
    review.submitFormPendingData(...arguments);
}
export function updatePendingFields() {
    review.updatePendingFields(...arguments);
}
/** --------------------------- SUBMIT -------------------------------------- */
export function onSubmitError() {
    submit.onSubmitError(...arguments);
}
export function onSubmitSuccess() {
    submit.onSubmitSuccess(...arguments);
}
export function pushFormPendingData() {
    submit.pushFormPendingData(...arguments);
}
export function submitForm() {
    submit.submitForm(...arguments);
}
/**
 * Manages current filter-set state
 *
 * Export
 *     getSetState
 *     setSetState
 */
let filter = {};

export function setSetState(key, value) {                           /*dbug-log*///console.log('setSetState key[%s] value[%O]', key, value);  console.trace();
    if (key === 'clear') { return handleReset(value); }
    if (key === 'all') { return filter = value; }
    filter[key] = value;
}
function handleReset(value) {
    if (!filter || value == filter.id) { return; }
    filter = null;
}
export function getSetState(key) {                                  /*dbug-log*///console.log('getSetState key[%s] state[%O]', key, filter);
    if (!filter) { return false; }
    return key === 'all' ? filter : filter[key];
}
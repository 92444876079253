/**
 * Entry-point for form-build and -exit.
 *
 * TOC
 *     BUILD FORM
 *         TOP FORM
 *         SUB FORM
 *         FINISH BUILD
 *     EXIT FORM
 */
import * as root from './root-form.js';
import * as build from './build-form.js';
import * as exit from './exit-form.js';

/* ============================= BUILD FORM ================================= */
/* ------------------------ TOP FORM ---------------------------------------- */
export function initForm(p) {
    p.appendForm = root.finishAndAppendRootForm;
    return build.initForm(p);
}
/* ------------------------ SUB FORM ---------------------------------------- */
export function initSubForm() {
    return build.initSubForm(...arguments);
}
export function ifFormInUse() {
    return build.ifFormInUse(...arguments);
}
export function alertInUse() {
    return build.alertInUse(...arguments);
}
/* ------------------------ FINISH BUILD ------------------------------------ */
export function finishFormBuild() {
    build.finishFormBuild(...arguments);
}
/* ============================== EXIT FORM ================================= */
export function handleFormClose() {
    return exit.handleFormClose(...arguments);
}
export function exitRootForm() {
    return exit.exitRootForm(...arguments);
}
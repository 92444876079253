/**
 * Publisher-form code.
 * When a user enters a new publisher into the combobox, the create form is built
 * and appended to the field's row.
 *
 * Export
 *     initCreateForm
 *     onPublSelection
 *
 * TOC
 *     ON SELECTION
 *     INIT FORM
 *         CREATE
 *         EDIT
 *         SHARED
 *     FINISH BUILD
 */
import { _data, _elems, _state } from '~form';
import * as sForm from '../src-form-main.js';
/* ======================= ON SELECTION ===================================== */
export function onPublSelection(fLvl, val) {
    if (val === 'create') { return initCreateForm(val); }
    _state('setFieldState', [fLvl, 'Publisher', val]);
    _state('setFieldState', [fLvl, 'ParentSource', val]);
}
/* ======================= INIT FORM ======================================== */
/* --------------------------- CREATE --------------------------------------- */
export function initCreateForm(v) {                                 /*dbug-log*///console.log('           >--Publisher CREATE Form v[%s]', v);
    const p = getCreateParams(v);
    return _elems('initSubForm', [p])
        .then(status => finishFormInit(p, status));
}
function getCreateParams(v) {
    const cParams = {
        appendForm: form => $('#Publisher_f').append(form),
        combo: 'Publisher',
        style: 'sml-sub-form',
        vals: { DisplayName: v === 'create' ? '' : v }
    };
    return { ...cParams, ...getFormParams(_state('getSubFormLvl', ['sub2']), 'create') };
}
/* ---------------------------- EDIT ---------------------------------------- */
export function initEditForm(id) {                                  /*dbug-log*///console.log('           >--Publisher EDIT Form id[%s]', id);
   const p = getEditParams(id);                                     /*dbug-log*///console.log('   --params[%O]', p);
    return _elems('initForm', [p])
        .then(status => finishFormInit(p, status));
}
function getEditParams(id) {
    const eParams = {
        id: id,
        style: 'sml-form',
    };
    return { ...eParams, ...getFormParams('top', 'edit') };
}
/* --------------------------- CREATE --------------------------------------- */
export function initReviewForm(pRcrd) {                             /*dbug-log*///console.log('           >--Publisher REVIEW Form pRcrd[%O]', pRcrd);
    const p = _data('isPendingAction', [pRcrd, 'create']) ? getCreateParams() : getEditParams();
    p.pending = pRcrd;
    delete p.vals;
    delete p.submit; //submit modal is used for the review process
    return _elems('initSubForm', [p])
        .then(status => finishFormInit(p, status));
}
/* ---------------------------- SHARED -------------------------------------- */
function getFormParams(fLvl, action) {
    return {
        action: action,
        name: 'Publisher',
        group: fLvl,
        submit: sForm.showSrcSubmitModal.bind(null, fLvl),
    };
}
/* ======================== FINISH BUILD ==================================== */
function finishFormInit(p, status) {
    if (!status) { return; } //Error handled elsewhere
    $('#DisplayName_f input').focus();
}
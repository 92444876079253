/**
 * Pending-data is loaded in its originating form with additional elements that
 * facillitate the data-review process. This module manages review-specific
 * elements of the data-review process.

 * Data-Review Process:
 *
 * --Data entered by Contributors are quarantined, available only to that user,
 * pending review by a Data Manager.
 * --Managers review data in sets, each root form and its sub-form data.
 * ----During review, root PendingData is "locked" to prevent simultaneous review.
 * ----The root-form loads opened to the first leaf sub-form and filled with data.
 * ----Root-data can only be approved once sub-data are either approved or replaced.
 * ------The manager can edit, reject, or return invalid-data.
 * --------Notes can be added to fields and/or form for the Contributor|Manager.
 * --Once reviewed, results are sent to their contributor.
 *
 *
 * MANAGER ACTIONS
 * --APPROVE
 * ----Data is fully entered in the site database.
 * ------UI:
 * --------Root:
 * ----------Root-form closes.
 * ----------If available, a form loads with the next set of pending data.
 * --------Sub:
 * ----------Sub-form closes.
 * ----------The data is set in the parent-field's combobox
 *
 * --REJECT
 * ----Data is deleted once Contributor recieves the results and Manager notes.
 * ------UI:
 * --------Root:
 * ----------Root-form closes.
 * ----------If available, a form loads with the next set of pending data.
 * --------Sub:
 * ----------Sub-form closes.
 * ----------'Data REJECTED. Click to edit field...' set in the parent-field's combobox
 * ----------The action is logged in the parent'form's data-review notes
 *
 * --RETURN
 * ---Data is not valid or complete in its current state.
 * ---Manager should include review/field notes
 * ------UI:
 * --------Root:
 * ----------Root-form closes.
 * ----------If available, a form loads with the next set of pending data.
 * --------Sub:
 * ----------Sub-form closes.
 * ----------'Data RETURNED. Click to edit field...' set in its parent-field's combobox
 * ----------The action is logged in the parent'form's data-review notes
 *
 * --PAUSE
 * ---Data set is held until the manager continues sits review.
 * ---Functions the same as when the data are "locked" to the manager during review
 * ------UI:
 * --------Root/Sub:
 * ----------Root-form closes.
 * ----------If available, a form loads with the next set of pending data.
 *
 * --SKIP
 * ---Data set is closed and will not be reopened during the current data-review.
 * ---Data set is unlocked and remains pending for future review.
 * ------UI:
 * --------Root/Sub:
 * ----------Root-form closes.
 * ----------If available, a form loads with the next set of pending data.
 *
 *
 * Export
 *     continueReview
 *     initDataReviewWizard
 *     loadIfSubPendingData
 *     setPendingComboOpt
 *
 * TOC
 *     REVIEW ROOT-FORM
 *     REVIEW SUB-FORM
 *         LOAD NEXT SET
 *     MISC
 */
import { _el } from '~util';
import { _data, _elems, _state } from '~form';
import * as build from './build/build-data-review-main.js';
/* ===================== REVIEW ROOT-FORM =================================== */
/**
 * Opens the originating-form(s) for the pending-data and fills with data.
 * Sub-entities will be reviewed first.
 */
export function initDataReviewWizard( skipId ) {                      /*dbug-log*///console.log('   -- initDataReviewWizard');
    _el.showPagePopup();
    return _data( 'getFirstPendingDataSet', [skipId] )
        .then( ifDataToReviewLoadForm );
}
function ifDataToReviewLoadForm( pRcrd ) {
    return pRcrd ? review( pRcrd ) : endReview();
}
function review( pRcrd ) {
    _elems( 'toggleWaitOverlay', [true] );
    return build.initRvwForm( pRcrd )
        .then( () => _elems( 'toggleWaitOverlay', [false] ) );
}
function endReview() {                                              /*dbug-log*///console.log('       -- endReview');
    _el.hidePagePopup();
    _elems( 'toggleWaitOverlay', [false] );
    _state( 'clearFormGroupState' );
    _data( 'clearSkippedRecordsMemory' );
}
/* ===================== REVIEW SUB-FORM =================================== */
/**
 * After form-init, the next field's pending-sub-data is loaded for review.
 * @param  {string} fLvl Initialized form-level
 * @return {promise}
 */
export function loadIfSubPendingData( fLvl ) {                        /*dbug-log*///console.log('   -- loadIfSubPendingData fLvl[%s]', fLvl);
    const subPend = _data( 'ifPendingSubDataGetFirstGroup', [fLvl, null, true] );
    return build.loadRvwSubForm( subPend, fLvl );
}
/* ------------------------ LOAD NEXT SET ----------------------------------- */
/** After sub-form-submit, the parent-forms's next pending-sub-data group loads. */
export function continueReview( fLvl, skipId ) {                      /*temp-log*///console.log('   -- continueReview fLvl[%s] skipId[%s]', fLvl, skipId);
    if ( ifSubFormOpen( fLvl ) ) { return finishReviewInit( fLvl ); } // Form-level already opened
    const subPend = _data( 'ifPendingSubDataGetFirstGroup', [fLvl, skipId] );
    return handlePendingSubFormLoad( fLvl, subPend );
}
function ifSubFormOpen( fLvl ) {
    return fLvl !== 'top' && _elems( 'ifFormInUse', [fLvl] );
}
function handlePendingSubFormLoad( fLvl, subPend ) {
    if ( ifPendingDataReady( subPend ) ) { return build.loadRvwSubForm( subPend, fLvl ); }
    const pLvl = _state( 'getFormLevel', ['parent', fLvl] );
    return pLvl ? continueReview( pLvl ) : finishReviewInit( fLvl );
}
function ifPendingDataReady( pSub ) {
    return pSub && !_elems( 'ifFormInUse', [pSub.inst.group] );
}
function finishReviewInit( fLvl ) {                                   /*dbug-log*///console.log('   -- finishReviewInit fLvl[%s]', fLvl);
    build.loadRvwSubForm( false, fLvl );
    _elems( 'toggleWaitOverlay', [false] );
}
/* =========================== MISC ========================================= */
export function finishReviewFormInit() {
    return build.finishReviewFormInit( ...arguments );
}
export function reinitReviewFormatting() {
    build.reinitReviewFormatting( ...arguments );
}
export function setPendingComboOpt() {
    return build.setPendingComboOpt( ...arguments );
}
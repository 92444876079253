/*--------------------- Extend Prototypes/Libraries ----------------------*/
declare global {
    interface Date {
        convertToUTC ( date: Date ): Date;
        getDateTimeSentence (): string;
        getMonthDayYearString (): string;
        getStandardTimeString (): string;
        getTimezoneAlphaString (): string;
        timeNow (): string;
        today (): string;
    }
}
export default function extendPrototypes () {
    extendDate();
}
function extendDate () {
    /** Y-m-d  */
    Date.prototype.today = function () {
        return this.getFullYear() + "-" +
            ( ( ( this.getMonth() + 1 ) < 10 ) ? "0" : "" ) + ( this.getMonth() + 1 ) + "-" +
            ( ( this.getDate() < 10 ) ? "0" : "" ) + this.getDate();
    };
    /** H:i:s */
    Date.prototype.timeNow = function () {
        return ( ( this.getHours() < 10 ) ? "0" : "" ) + this.getHours() + ":" +
            ( ( this.getMinutes() < 10 ) ? "0" : "" ) + this.getMinutes() + ":" +
            ( ( this.getSeconds() < 10 ) ? "0" : "" ) + this.getSeconds();
    };
    /** Ex: PDT|PST */
    Date.prototype.getTimezoneAlphaString = function (): string {
        const localtime = this.toLocaleDateString( 'en-us', { timeZoneName: 'short' } );
        const results = /.*\s(.+)/.exec( localtime );
        if ( !results ) throw new Error( "No results - getTimezoneAlphaString" );
        return results[1] ?? '';

    };
    /** H:i(pm|am) PT */
    Date.prototype.getStandardTimeString = function ( this: Date ) {
        const [hours, period] = getHoursAndAmPm( this.getHours() );
        const minutes = getMinutes( this.getMinutes() );
        return `${ hours }:${ minutes }${ period } ${ this.getTimezoneAlphaString() }`;
    };
    function getHoursAndAmPm ( dateHours: number ): [hours: string, period: "am" | "pm"] {
        const period = dateHours >= 12 ? 'pm' : 'am';
        const hours = dateHours % 12 ? dateHours - 12 : dateHours; // the hour '0' should be '12'
        return [hours.toString(), period];
    }
    function getMinutes ( minutes: number ): string {
        return minutes > 9 ? minutes.toString() : `0${ minutes.toString() }`;
    }
    /** m/d/Y */
    Date.prototype.getMonthDayYearString = function () {
        return `${ this.getMonth() + 1 } /${ this.getDate() }/${ this.getFullYear() }`;
    };
    /** m/d/Y at H:i(pm|am) PT */
    Date.prototype.getDateTimeSentence = function () {
        return `${ this.getMonthDayYearString() } at ${ this.getStandardTimeString() }`;
    };
    /** Returns a Date set to the UTC timezone. */
    Date.prototype.convertToUTC = function ( date ) {
        return new Date( date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds() );
    };
}
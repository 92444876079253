/**
 * Custom data-entry alerts. (Forms utilize HTML5 validation where possible.)
 *
 * TOC
 *     ALERT FACADE
 *     SHOW DATA-ENTRY ALERT
 *     SHOW ALERT
 *     CLEAR ALERT
 *         DEFAULT
 */
import { _elems } from '~form';
import * as entity from './entity-alerts.js';
import * as form from './form-alerts.js';
/* ======================== ALERT FACADE ==================================== */
export function clrContribFieldAlert() {
    entity.clrContribFieldAlert( ...arguments );
}
export function clearAnyGroupAlerts() {
    entity.clearAnyGroupAlerts( ...arguments );
}
export function dataPrepFailure() {
    form.dataPrepFailure( ...arguments );
}
export function formSubmitError() {
    form.formSubmitError( ...arguments );
}
export function errUpdatingData() {
    form.errUpdatingData( ...arguments );
}
export function alertFormOpen() {
    form.alertFormOpen( ...arguments );
}
export function formInitAlert() {
    form.formInitAlert( ...arguments );
}
/* ====================== SHOW DATA-ENTRY ALERT ============================= */
/**
 * Handles the validation issue and displays an alert. The alert can be cleared
 * manually with a close button or automatically after a timeout, or on field change.
 */
export function showFormValAlert( fieldName, tag, fLvl ) {            /*perm-log*/console.log( "       --show[%s]FormValAlert [%s][%s]", fLvl, fieldName, tag );
    const el = getAlertElem( fieldName, fLvl );
    const handlers = getFieldValAlertHandler( tag )
    showAlert( fLvl, el, handlers.show( el, fLvl ), handlers.clear );
}
function getFieldValAlertHandler( tag ) {
    const map = {
        invalidRange: {
            show: form.handleInvalidRangeAndReturnAlertMsg
        },
        /* --- INTERACTION --- */
        noValidInts: {
            show: entity.handleNoValidIntsAndReturnAlertMsg
        },
        needsTypeTag: {
            show: entity.handleNeedsTypeTagAndReturnAlertMsg
        },
        /* --- SOURCE --- */
        fillAuthBlanks: {
            show: entity.handleAuthBlanksAndReturnAlertMsg
        },
        /* --- TAXON --- */
        isGenusPrnt: {
            show: entity.handleIsGenusPrntAndReturnAlertMsg,
            clear: entity.clrTaxonRankAlert
        },
        needsGenusName: {
            show: entity.handleNeedsGenusNameAndReturnAlertMsg
        },
        needsGenusPrnt: {
            show: entity.handleNeedsGenusParentAndReturnAlertMsg,
            clear: entity.clrTaxonParentAlert
        },
        needsHigherRankPrnt: {
            show: entity.handleNeedsHigherRankPrntAndReturnAlertMsg,
            clear: entity.clrTaxonParentAlert
        },
        needsHigherRank: {
            show: entity.handleNeedsHigherRankAndReturnAlertMsg,
            clear: entity.clrTaxonRankAlert
        },
        needsLowerRank: {
            show: entity.handleNeedsLowerRankAndReturnAlertMsg,
            clear: entity.clrTaxonRankAlert
        },
        needsName: {
            show: form.getRequiredFieldsEmptyAleryMsg
        },
        noFamily: {
            show: entity.handleNoFamilyAndReturnAlertMsg
        },
        noGenus: {
            show: entity.handleNoGenusAndReturnAlertMsg
        },
        rankNotAvailableInNewGroup: {
            show: entity.handleRankNotAvailableInNewGroupAndReturnAlertMsg,
            clear: entity.clrTaxonParentAlert
        }
    };
    return map[tag];
}
/* ===================== SHOW ALERT ========================================= */
/** Returns the validation alert container for the passed field|form. */
export function getAlertElem( fieldName, fLvl ) {                     /*dbug-log*///console.log("getAlertElem for %s", fieldName);
    const field = fieldName ? fieldName.split( ' ' ).join( '' ) : fLvl;  //[fLvl]_alert for form-validation alerts
    const elem = $( '#'+field+'_alert' )[0];
    $( elem ).addClass( fLvl+'-active-alert' );
    return elem;
}
export function showAlert( fLvl, elem, msg, onClear ) {               /*dbug-log*///console.log('-- showAlert. args[%O]', arguments)
    elem.innerHTML = msg;
    if ( onClear ) { $( elem ).append( getAlertExitBttn( onClear, elem, fLvl ) ); }
    _elems( 'toggleSubmitBttn', [fLvl, false] );
    toggleFieldAlertBackgroudColor( elem );
}
function getAlertExitBttn( onClear, elem, fLvl ) {
    const onExit = clearFieldAlert.bind( null, onClear, elem, fLvl );
    const bttn = _elems( 'getExitButton', [onExit] );
    bttn.className += ' alert-exit';
    return bttn;
}
function toggleFieldAlertBackgroudColor( alertElem, enable = true ) {
    if ( enable ) return $( alertElem.parentElement ).addClass( 'alert-bkgrnd' );
    $( alertElem.parentElement ).removeClass( 'alert-bkgrnd' );
}
/* ===================== CLEAR ALERT ======================================== */
function clearFieldAlert( fieldHandler, elem, fLvl ) {
    if ( fieldHandler ) { fieldHandler( elem, fLvl ); }
    clearAlert( elem, fLvl );
}
export function clrFormLvlAlert( elem, fLvl ) {
    $( '#'+fLvl+'_alert' ).empty();
    _elems( 'checkReqFieldsAndToggleSubmitBttn', [fLvl] );
}
export function setOnCloseClearAlert( elem, fLvl ) {
    $( `#${ fLvl }-form` ).bind( 'destroyed', clearAlert.bind( null, elem, fLvl ) );
}
export function setOnChangeClearAlert( field, elem, fLvl, hndlr ) {   /*dbug-log*///console.log(' --setOnChangeClearAlert field[%s][%s] elem[%O] hndlr[%O]', field, fLvl, elem, hndlr);
    const input = `#${ fLvl }-form #${ field }_f .f-input`;
    if ( !hndlr ) { hndlr = clearAlert.bind( null, elem, fLvl ); }
    $( input ).change( hndlr );
}
/* ------------------------ DEFAULT ----------------------------------------- */
export function clearAlert( elem, fLvl, enableSubmit = true ) {     /*dbug-log*///console.log( '   --clearAlert. elem[%O] enableSubmit?[%s]', elem, enableSubmit );  console.trace()
    $( elem ).fadeTo( 200, 0, clearAndEnableSubmit );

    function clearAndEnableSubmit() {
        clearAlertElem( elem, fLvl );
        enableSubmitIfFormReady( fLvl, enableSubmit );
    }
}
function clearAlertElem( elem, fLvl ) {                               /*dbug-log*///console.log('   --clearAlertElem. elem[%O] fLvl[%s]', elem, fLvl);
    $( elem ).removeClass( fLvl+'-active-alert' );
    if ( elem.innerHTML ) { elem.innerHTML = ''; }
    $( elem ).fadeTo( 0, 1 );
    toggleFieldAlertBackgroudColor( elem, false )
}
function enableSubmitIfFormReady( fLvl, enableSubmit ) {
    if ( !$( `#${ fLvl }-form` ).length || _elems( 'hasOpenSubForm', [fLvl] ) ) return;
    if ( !enableSubmit ) return;                                    /*dbug-log*///console.log( 'enableSubmitIfFormReady [%s]', fLvl )
    _elems( 'checkReqFieldsAndToggleSubmitBttn', [fLvl] );
}
/** External-access alert-clear method */
export function clearActiveAlert( fLvl, field = null, enableSubmit = true ) {
    const alerted = field ? field : fLvl;                           /*dbug-log*///console.log( '   --clearActiveAlert. fLvl[%s] field?[%s] alerted[%s] enableSubmit?[%s]', fLvl, field, alerted, enableSubmit );
    if ( !$( `.${ fLvl }-active-alert` ).length ) return;
    clearAlert( $( `#${ alerted }_alert` )[0], fLvl, enableSubmit );
}
/**
 * Inits the interaction form with all fields displayed and the first field,
 * publication, in focus. From within many of the fields the user can create
 * new entities of the field-type by selecting the 'add...' option from the
 * field's combobox and completing the appended sub-form.
 *
 * Export
 *     initCreateForm
 *     initEditForm
 *     finishInteractionFormBuild
 *
 * TOC
 *     INIT FORM
 *         CREATE
 *         EDIT
 *         REVIEW
 *         SHARED
 *     FINISH BUILD
 *         FORM COMBOBOXES
 *         LOCATION INIT
 */
import { _cmbx } from '~util';
import { _data, _elems, _state } from '~form';
import * as iForm from '../int-form-main.js';
import finishFieldFill from '../fields/int-field-fill.js';

/* ======================= INIT FORM ======================================== */
/* --------------------------- CREATE --------------------------------------- */
export function initCreateForm () {                                  /*temp-log*///console.log('   //Building New Interaction Form');
    if ( _state( 'getStateProp' ) ) { return; } //Form is already opened.
    return _elems( 'initForm', [ getCreateFormParams() ] )
        .then( finishInteractionFormBuild );
}
function getCreateFormParams () {
    const params = getIntFormParams( 'create' );
    params.afterSubmit = iForm.resetInteractionForm;
    return params;
}
/* ---------------------------- EDIT ---------------------------------------- */
export function initEditForm ( entity, id ) {                          /*temp-log*///console.log('   //Building EDIT Interaction Form id[%s]', id);
    return _elems( 'initForm', [ getEditFormParams( id ) ] )
        .then( finishInteractionFormBuild )
        .then( finishFieldFill );
}
function getEditFormParams ( id ) {
    const params = getIntFormParams( 'edit' );
    params.id = id;
    return params;
}
/* ---------------------------- REVIEW -------------------------------------- */
export function initReviewForm ( pRcrd ) {                             /*temp-log*///console.log('   //Building REVIEW Interaction Form pRcrd[%O]', pRcrd);
    const params = getReviewParams( pRcrd );
    return _elems( 'initForm', [ params ] )
        .then( finishInteractionFormBuild )
        .then( () => finishFieldFill( pRcrd ) );
}
function getReviewParams ( pRcrd ) {
    const params = getIntFormParams( _data( 'getPendingAction', [ pRcrd ] ) );
    params.pending = pRcrd;
    params.id = pRcrd.data.review.editId;
    return params;
}
/* --------------------------- SHARED --------------------------------------- */
function getIntFormParams ( action ) {                                 /*dbug-log*///console.log('   --getIntFormParams action[%s]', action);
    return {
        action: action,
        initCombos: iForm.initCombos,
        name: 'Interaction'
    };
}
/* ======================= FINISH BUILD ===================================== */
/**
 * Inits the selectize comboboxes, adds/modifies event listeners, and adds
 * required field elems to the form's config object.
 */
export function finishInteractionFormBuild () {                      /*dbug-log*///console.log('           --finishIntFormBuild');
    $( '#txt-Note' ).change( iForm.focusPinAndEnableSubmitIfFormValid.bind( null, 'Note' ) );
    modifyFormDisplay();
    finishLocationInit();
    finishComboboxInit();
    iForm.initMiscFields();
}
function modifyFormDisplay () {
    _elems( 'setDynamicFieldStyles', [ 'interaction' ] );
}
/* -------------------------- FORM COMBOBOXES ------------------------------- */
function finishComboboxInit () {
    _cmbx.enableCombobox( 'CitationTitle', false );
    iForm.addRoleTaxonFocusListeners();
    _cmbx.enableCombobox( 'InteractionType', false );
    focusPubFieldIfNewRecord();
}
function focusPubFieldIfNewRecord () {
    const action = _state( 'getFormState', [ 'top', 'action' ] );
    _cmbx.focusCombobox( 'Publication', action === 'create' );
}
/* ------------------------ LOCATION INIT ----------------------------------- */
function finishLocationInit () {
    iForm.addLocationSelectionMethodsNote();
    setDefaultLocationValue();
}
function setDefaultLocationValue () {
    const locField = _state( 'getFieldState', [ 'top', 'Location', null ] );/*dbug-log*///console.log('   -- setDefaultLocationValue locField[%O]', locField)
    if ( locField.value ) { return; }
    iForm.setUnspecifiedLocation( locField );
}
/**
 * Adds an element to the end of the field container to handle an action, either
 *  - a check box that allows the field data to be persisted through form-submit, or
 *  - an icon that inits, opens, and saves notes relevant for a data-review process
 */
import { _u } from '~util';
import { _state } from '~form';
import initPersistFieldPin from './field-persist-elem.js';
import * as notes from './field-notes-elem.js';
/* ============================ BUILD ======================================= */
export function addFieldUtilElem( f, field )  {                       /*dbug-log*///console.log('   -- addFieldUtilElem f[%O] field[%O]', f, field);
    const isReviewForm = _state( 'getFormState', [f.group, 'action'] ) === 'review';
    if ( !ifFieldHasUtilElem( isReviewForm, f.pinnable ) ) { return; }
    const el = getFieldUtilElem( isReviewForm, f );
    $( getFieldInputCntnr( field ) ).append( el );
}
/** True: fields during review by a data-manager, or interaction create-forms. */
function ifFieldHasUtilElem( isReviewForm, fieldsPinnable ) {
    return isReviewForm ? _u.isManagerUser() : fieldsPinnable;
}
function getFieldUtilElem( isReviewForm, f ) {
    return  isReviewForm ? notes.initFieldNotesElem( f ) : initPersistFieldPin( f.name );
}
function getFieldInputCntnr( field ) {
    const el = field.lastChild
    return $( el ).hasClass( 'cntnr' ) ? el.firstChild.lastChild : el;
}
/* =========================== FIELD-NOTES ================================== */
export function showFieldReviewNotes() {
    return notes.showFieldReviewNotes( ...arguments );
}
export function toggleFormReviewNotes() {
    return notes.toggleFormReviewNotes( ...arguments );
}
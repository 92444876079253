/**
 * Adds data relevant to data-review to be pushed with form-data.
 *
 * Export
 *     pushPendingData
 *     submitFormPendingData
 *
 * TOC
 *     REVIEW-DATA OBJECT
 *     SUBMIT PENDING DATA
 *         BUILD SUBMIT DATA
 */
import { _db, _u } from '~util';
import { _data, _state } from '~form';
import getFieldReviewData from './field-review-data.js';
/**
 * Builds and returns the data necessary for the data-review process.
 * @param  {obj} data   Form-data prepared to be submitted to the server
 * @param  {obj} fS     The submitted-form's state
 *
 * @return {obj}
 *           {int}  entityId     Used when rejecting pending as duplicate. (Will not stored with the rest of this data-object)
 *           {int}  id           PendingData ID (will not be stored)
 *           {obj}  *quarantined  Processed pending-data-entry to readd into contributor data-storage,
 *           {obj}  *review   Form-data relevant for the data-review process
 *             {int}  editId    Edit-form entity id
 *             {str}  *action   Data-entry action [Create, Edit, Delete]
 *             {str}  *group    Originating form-group: top, sub#
 *             {str}  *log      Notes between contributor and manager and activit
 *             {obj}  *fields   Form-data relevant for the data-review process
 *               {bol}  *.changed   If value changed during review
 *               {str}  *.notes    Notes attached to the field
 *               {obj}  *.pending  Pending sub-data: { id (PendingData), entity }
 *               {bol}  *.prop     Field entity-class properties
 *               {obj}  .replacedPending   If PendingData replaced { id (PendingData), entity, qId (quarantined ID) }
 *               {misc}  *.value    Field value
 *           {obj}  *stage    { name: [<SELECTED>||DEFAULT=QUARANTINE] }
 */
export function submitFormPendingData( data, fS ) {
    data.pend = getFormReviewPendingData( fS );                       /*temp-log*///console.log('   -- submitFormPendingData data[%O] fConfg[%O]', _u.snapshot(data), fS);
    return pushPendingData( null, null, data );
}
/** ====================== REVIEW-DATA OBJECT =============================== */
function getFormReviewPendingData( fS ) {                             /*dbug-log*///console.log('-- getFormReviewPendingData fS[%O]', fS);
    const stage = getStageData( fS.pending );
    return {
        entityId: getPendingEntityId( fS.pending ),
        id: getPendingDataId( fS.pending ),
        quarantined: getQuarantinedData( fS.pending ),
        review: getReviewData( fS, stage.name ),
        stage: stage
    };
}
function getPendingDataId( pRcrd ) {
    return pRcrd ? pRcrd.id : null;
}
/** Set when rejecting pending-data as duplicate of existing entity. */
function getPendingEntityId( pRcrd ) {
    return pRcrd ? pRcrd.entityId : null;
}
function getQuarantinedData( pRcrd ) {
    return pRcrd ? pRcrd.data.quarantined : null;
}
function getReviewData( fS, stage ) {
    return {
        action: _state( 'getFormAction', [fS.group] ),
        count: fS.count,
        editId: fS.editing ? fS.editing.core : null,
        fields: getFieldReviewData( fS ),
        group: fS.group,
        log: _data( 'prepareAndReturnReviewLog', [fS.group, stage] )
    };
}
function getStageData( pRcrd ) {
    return pRcrd ? pRcrd.inst.rvwAction : { name: 'Quarantine' }; //default
}
/* =================== SUBMIT PENDING-DATA ================================== */
/**
 * Pending data is 'locked', preventing simultaneous review. The root stage is set,
 * as all sub-entites are grouped and reviewed within.
 */
export function pushPendingData( id, stage, data, closed = false ) {
    data = getPushPendingData( ...arguments );                        /*temp-log*///console.log('   -- pushPendingData id[%s] stage[%s] formClosed?[%s] data[%O]', id, stage, closed, data);
    const fLvl = data.pend.review ? data.pend.review.group : null;
    _u.sendAjaxQuery( data, 'crud/pend/data', pushPendingSuccess, pushPendingError );

    function pushPendingSuccess ( results ) {                          /*temp-log*///console.log('       --[R] pushPendingSuccess fLvl?[%s] results[%O]', fLvl, _db.parseData(results));
        if ( stage === 'Lock' || closed ) { return; } //Data-review form opened or closed, not submitted
        _data( 'onSubmitSuccess', [fLvl, _db.parseData( results )] );
    }
    function pushPendingError ( results ) {                            /*temp-log*///console.log('       --[R] pushPendingError fLvl?[%s] results[%O]', fLvl, _db.parseData(results));
        _data( 'onSubmitError', [fLvl, results] );
    }
}
/* ---------------------- BUILD SUBMIT DATA --------------------------------- */
function getPushPendingData( id, stage, data ) {
    data = data ? data : {};
    data.setManager = _u.isManagerUser();
    return data.pend ? data : getBaseDataParams( id, stage, data );
}
function getBaseDataParams( id, stage, data ) {
    data.pend = {
        id: id,
        stage: {
            name: stage
        }
    };
    return data;
}
/**
 * All form state data for the core form and any sub-forms.
 * TODO: DOCUMENT
 *
 * Export
 *     getFormEntity
 *     initFormState
 *     addEntityFormState
 *
 * TOC
 *     INIT
 *         CLEAR
 *     EXECUTORS
 *     GETTERS
 *         CORE-STATE
 *             ENTITY RECORDS
 *         FORM-STATE
 *             FIELDS
 *             STATE PREDICATES`
 *         FIELD-STATE
 *     SETTERS
 *         CORE-STATE
 *         FORM-STATE
 *             TAXON
 *         FIELD-STATE
 */
// import { _u } from '~util';
import { _confg, _form } from '~form';
import * as get from './get-state.js';
import * as init from './init-state.js';
import * as set from './set-state.js';

let fS = {};
/* ============================ INIT ======================================== */
export function initFormState() {
    return init.initFormState( ...arguments )
        .then( storeAndReturnState );
}
function storeAndReturnState( coreState ) {
    fS = coreState;
    return coreState;
}
export function buildNewFormState() {
    return init.buildNewFormState( fS, ...arguments );
}
export function initTaxonState() {
    return init.initTaxonState( fS, ...arguments );
}
/* -------------------------- CLEAR------------------------------------------ */
export function clearFormGroupState( fLvl = 'top' ) {
    return callCoreState( set, 'clearFormGroupState', [fLvl, clearState] );
}
function clearState() {
    _form( 'clearEntityFormMemory', [getFormEntity( 'top' )] );
    fS = {};
}
/** Handle unlocking open pending-data records, if the review was not submitted. */
export function handleReviewFormClear() {                           /*dbug-log*///console.log('   -- handleReviewFormClear submitted?[%s] fState[%O]', fS.forms.top.submitted, _u.snapshot(fS.forms.top));
    return callCoreState( set, 'handleReviewFormClear' );
}
/* ============================= EXECUTORS ================================== */
/** State methods are passed the complete form-state. */
export function callCoreState( mod, funcName, args ) {                /*dbug-log*///console.log('--callCoreState [%s][%O]', funcName, args);
    if ( !fS.records ) { return false; } //Form closed
    return mod[funcName]( fS, ...args );
}
/** State methods are passed a specific form-group's state. */
export function callFormState( mod, funcName, args ) {                /*dbug-log*///console.log('--callFormState [%s] args[%O] fS[%O]', funcName, args, _u.snapshot(fS));
    if ( !ifStateActive( args[0] ) ) { return false; }
    return mod[funcName]( getFormObj( args.shift() ), ...args );
}
export function ifStateActive( fLvl ) {
    return fS.forms && getFormObj( fLvl );
}
/** Field state methods already have the field as the first argument */
function callFieldState( mod, funcName, args ) {
    return mod[funcName]( ...args );
}
/* +++++++++ GETTERS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
function getFormObj( fLvl ) {
    return fS.forms[fLvl];
}
/* =========================== CORE-STATE =================================== */
export function getStateProp( ...args ) {
    return callCoreState( get, 'getStateProp', args );
}
export function getFormLevel( ...args ) {
    return callCoreState( get, 'getFormLevel', args );
}
export function getSubFormLvl( ...args ) {
    return callCoreState( get, 'getSubFormLvl', args );
}
export function getEntityFormLevel( ...args ) {
    return callCoreState( get, 'getEntityFormLevel', args );
}
/* ----------------------- ENTITY RECORDS------------------------------------ */
export function getEntityRcrds( ...args ) {
    return callCoreState( get, 'getEntityRcrds', args );
}
export function getRcrd( ...args ) {
    return callCoreState( get, 'getRcrd', args );
}
export function getTaxonGroupRoot( ...args ) {
    return callCoreState( get, 'getTaxonGroupRoot', args );
}
export function getReviewStage( ...args ) {
    return callCoreState( get, 'getReviewStage', args );
}
export function getStagePassiveForm( ...args ) {
    return callCoreState( get, 'getStagePassiveForm', args );
}
/* ========================= FORM STATE ===================================== */
export function getFormState( ...args ) {
    return callFormState( get, 'getFormState', args );
}
export function getFormAction( ...args ) {
    return callFormState( get, 'getFormAction', args );
}
export function getFormEntity( ...args ) {
    return callFormState( get, 'getFormEntity', args );
}
export function getFormHandler( ...args ) {
    return callFormState( get, 'getFormHandler', args );
}
/* -------------------------- FIELDS ---------------------------------------- */
export function getComboFields( ...args ) {
    return callFormState( get, 'getComboFields', args );
}
export function getDisplayedFields( ...args ) {
    return callFormState( get, 'getDisplayedFields', args );
}
export function getFieldState( ...args ) {
    return callFormState( get, 'getFieldState', args );
}
export function getFieldStateById( ...args ) {
    return callFormState( get, 'getFieldStateById', args );
}
export function getFieldValues( ...args ) {
    return callFormState( get, 'getFieldValues', args );
}
/* --------------------- STATE PREDICATES ----------------------------------- */
export function isEditForm( ...args ) {
    return callFormState( get, 'isEditForm', args );
}
export function isFieldShown( ...args ) {
    return callFormState( get, 'isFieldShown', args );
}
export function isDisplayFormOnly( ...args ) {
    return callFormState( get, 'isDisplayFormOnly', args );
}
export function areFieldsShown( ...args ) {
    return callFormState( get, 'areFieldsShown', args );
}
/* ======================= FIELD STATE ====================================== */
export function getFieldClassData( ...args ) {
    return callFieldState( get, 'getFieldClassData', args );
}
/* +++++++++ SETTERS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* ======================= CORE STATE ======================================= */
export function setStateProp( ...args ) {
    return callCoreState( set, 'setStateProp', args );
}
export function updateFormRecordState( ...args ) {
    return callCoreState( set, 'updateFormRecordState', args );
}
/* ======================= FORM STATE ======================================= */
export function setFormState( ...args ) {
    return callFormState( set, 'setFormState', args );
}
export function addFormHandler( ...args ) {
    callFormState( set, 'addFormHandler', args );
}
export function setFormHandler( ...args ) {
    callFormState( set, 'setFormHandler', args );
}
export function setFieldState( ...args ) {
    return callFormState( set, 'setFieldState', args );
}
export function setFieldPendingData( ...args ) {
    return callFormState( set, 'setFieldPendingData', args );
}
/* ---------------------------- TAXON --------------------------------------- */
export function updateTaxonGroupState( fLvl ) {
    const fState = fS.forms[fLvl];
    set.setTaxonGroupState( fS.records, fState );
    _confg( 'updateCurrentFieldView', [fState] );
}
export function setTaxonGroupState( rcrds, fState ) {
    set.setTaxonGroupState( rcrds, fState );
}
/* ======================= SET FIELD-STATE ================================== */
export function setFieldPending( ...args ) {
    return callFieldState( set, 'setFieldPending', args );
}
export function setFieldValue( ...args ) {
    return callFieldState( set, 'setFieldValue', args );
}
/**
 * Returns the submit and cancel buttons. When clicked,  either a custom-handler
 * or the default handler is called.
 * Note: Handlers can be chanined on to, or completely overwrite, each other.
 *
 * Export
 *     buildSubmitAndCancelBttns
 *
 * TOC
 *     SUBMIT
 *     CANCEL
 *     HELPERS
 */
import { _el } from '~util';
import { _elems, _state, submitForm } from '~form';

export function buildSubmitAndCancelBttns ( fLvl, action ) {           /*dbug-log*///console.log("   -- buildSubmitAndCancelBttns fLvl[%s] action[%s]", fLvl, action);
    return [getSubmitBttn( fLvl, action ), getCancelBttn( fLvl )];
}
/* ========================== SUBMIT ======================================== */
function getSubmitBttn ( fLvl, action ) {
    const bttn = buildFormButton( 'submit', fLvl, getSubmitText( action ) );
    $( bttn ).attr( 'disabled', true ).css( 'opacity', '.6' );
    setInitClickHandler( fLvl, bttn, 'submit', submitForm );
    return bttn;
}
function getSubmitText ( action ) {
    const text = { create: 'Create', edit: `Update`, review: 'Submit' };
    return text[action];
}
/* ========================== CANCEL ======================================== */
function getCancelBttn ( fLvl ) {
    const bttn = buildFormButton( 'cancel', fLvl, 'Cancel' );
    $( bttn ).css( "cursor", "pointer" );
    setInitClickHandler( fLvl, bttn, 'cancel', cancelForm.bind( null, fLvl ) );
    return bttn;
}
function cancelForm ( fLvl ) {                                         /*dbug-log*///console.log("-- cancelForm fLvl[%s]", fLvl);
    return _elems( 'handleFormClose', [fLvl, true] );
}
/* ======================= HELPERS ========================================== */
/** Returns a (submit or cancel) button for the form fLvl. */
function buildFormButton ( actn, lvl, val ) {
    const attr = { id: lvl + '-' + actn, type: 'button', value: val };
    return _el.getElem( 'input', attr );
}
/**
 * Sets the form-button's onClick handler: Note: Handlers can be chanined on to,
 * or completely overwrite, the init function later.
 * @param {element}  bttn        Button element
 * @param {string}   fLvl        Form group
 * @param {string}   event       Button event-name
 * @param {function} defaultFunc Default handler
 */
function setInitClickHandler ( fLvl, bttn, event, defaultFunc ) {      /*dbug-log*///console.log("       -- setInitClickHandler fLvl[%s] bttn[%O] event[%s] defaultFunc[%O]", fLvl, bttn, event, defaultFunc);
    const onClick = callHandler.bind( null, fLvl, event, defaultFunc );
    $( bttn ).on( 'click', onClick );
}
function callHandler ( fLvl, event, defaultFunc ) {
    const custom = _state( 'getFormHandler', [fLvl, event] );         /*dbug-log*///console.log("           -- customHandler?[%O]", custom);
    return custom ? custom() : defaultFunc( fLvl );
}
/**
 * Processes the form-field data and adds to the entity's class property object.
 *
 * EXPORT
 *     prepareAndSetFieldData
 *
 * TOC
 *     DATA HANDLERS
 *         GENERAL
 *         AUTHOR
 *         CITATION
 *         INTERACTION
 *         LOCATION/GEOJSON
 *         PUBLICATION
 *         TAXON
 */
import { _cmbx, _u } from '~util';
import {_form, _state} from '~form';
import * as util from './prepare-data-util.js';

export function prepareDataAndSetEntityProperty ( v, fKey, fConfg ) {
    if ( fConfg.prep ) { return handleDataPreparation( fKey, fConfg, v );  }
    const prop = fKey === 'rel' ? fConfg.entity : fConfg.name;
    util.setEntityProp( fKey, prop, v );
}
/* ========================= DATA HANDLERS ================================== */
/** Loops through the field's prep methods to handle data-preparation. */
function handleDataPreparation( fKey, fConfg, v ) {
    Object.keys( fConfg.prep ).forEach( handleDataPrep );

    function handleDataPrep( h ) {
        if ( h === 'dataClasses' ) { return; } //Entity className obj
        getPrepMethod( h )( fKey, fConfg, v, ...fConfg.prep[h] );
    }
}
/**
 * @param  {string} h  Data-prep method-name, specified in the fields's confg.
 * @return {function}  Data-prep method
 */
function getPrepMethod( h ) {
    const map = {
        buildTaxonDisplayName: buildTaxonDisplayName,
        handleAuthorNames: handleAuthorNames,
        handleSeasonTags: handleSeasonTags,
        handleSecondaryTag: handleSecondaryTag,
        renameField: renameField,
        setCitationPages: setCitationPages,
        setCitationTitle: setCitationTitle,
        setContributors: setContributors,
        setCoreAndDetail: setCoreAndDetail,
        setCoreData: setCoreData,
        setCoreType: setCoreType,
        setDetailData: setDetailData,
        setDetailEntity: setDetailEntity,
        setElevationRange: setElevationRange,
        setGeoJsonData: setGeoJsonData,
        setParent: setParent,
        setPublicationTitle: setPublicationTitle,
        setSuffix: setSuffix,
        validateTags: validateTags
    };
    return map[h];
}
/* --------------------------- GENERAL -------------------------------------- */
function setCoreData( g, fConfg, v ) {                                /*dbug-log*///console.log('               --setCoreData [%s] fConfg[%O]', g, fConfg);
    util.setEntityProp( g, fConfg.name, v );
}
function setDetailData( g, fConfg, v ) {                              /*dbug-log*///console.log('               --setDetailData [%s] fConfg[%O]', g, fConfg);
    util.setEntityProp( g, fConfg.name, v, 'detail' );
}
function renameField( g, fConfg, v, name, dKey = 'core' ) {           /*dbug-log*///console.log('               --renameField [%s]entity[%s] fConfg[%O]', name, dKey, g, fConfg);
    util.setEntityProp( g, name, v, dKey );
}
function setCoreType( g, fConfg, v ) {                                /*dbug-log*///console.log('               --setCoreType [%s] fConfg[%O]', g, fConfg);
    if ( typeof v !== 'string' ) { return util.trackFailure( fConfg.name, v ); }
    util.setEntityProp( g, fConfg.entity, v );
}
function setParent( g, fConfg, v, entity ) {                          /*dbug-log*///console.log('               --setParent [%s]entity[%s] fConfg[%O]', g, entity, fConfg);
    const prop = 'Parent' + entity;
    if ( isNaN( v ) ) { return util.trackFailure( prop, v ); }
    util.setEntityProp( g, prop, v );
}
function setDetailEntity( g, fConfg, v ) {                            /*dbug-log*///console.log('               --setDetailEntity [%s] fConfg[%O]', g, fConfg);
    if ( isNaN( v ) ) { return util.trackFailure( fConfg.name, v ); }
    util.setEntityProp( g, fConfg.name, v, 'detail' );
}
function setCoreAndDetail( g, fConfg, v ) {
    ['core', 'detail'].forEach( e => util.setEntityProp( g, fConfg.name, v, e ) );
}
/* --------------------------- AUTHOR --------------------------------------- */
/** Handles Author names */
function handleAuthorNames( g, fConfg, v ) {
    const names = getAuthNameValues( _state( 'getFormState', [fConfg.group, 'fields'] ) );
    util.setEntityProp( 'flat', 'DisplayName', buildAuthDisplayName( names ) );
    util.setEntityProp( 'flat', 'DisplayName', buildAuthDisplayName( names ), 'detail' );
    util.setEntityProp( 'flat', 'FullName', buildAuthFullName( names ), 'detail' );
}
function getAuthNameValues( fields ) {                                /*dbug-log*///console.log('--getAuthNameValues fields[%O]', fields);
    const sufx = fields.Suffix.value;
    return {
        first: fields.FirstName.value,
        middle: fields.MiddleName.value,
        last: fields.LastName.value,
        suffix: sufx && sufx[sufx.length-1] !== '.' ? sufx+'.' : sufx
    };
}
function buildAuthDisplayName( names ) {                              /*dbug-log*///console.log('--buildAuthDisplayName names[%O]', names);
    if ( !names.first ) { return names.last; }
    const name = [names.last+',', names.first, names.middle, names.suffix];
    return name.filter( n => n ).join( ' ' );
}
function buildAuthFullName( names ) {                                 /*dbug-log*///console.log('--buildAuthFullName names[%O]', names);
    return Object.values( names ).filter( n => n ).join( ' ' );
}
/** Ensure value saved without punctuation */
function setSuffix( g, fConfg, v ) {
    const sufx = v && v[v.length-1] == '.' ? v.slice( 0, -1 ) : v;
    util.setEntityProp( 'flat', 'Suffix', sufx, 'detail' );
}
/** Creates an object with contributor server-data.  */
function setContributors( g, fConfg, v ) {                            /*dbug-log*///console.log('           --setContributors fConfg[%O] v[%O]', fConfg, v);
    if ( !Object.keys( v ).length ) return;
    const isEditor = fConfg.name === 'Editor';
    util.setEntityProp( g, 'Contributor', getContribs( v, isEditor ) );
}
function getContribs( vals, isEditor = false ) {                      /*dbug-log*///console.log('           --getContributorData editors?[%s] vals[%O]', isEditor, vals);
    const data = {};
    Object.keys( vals ).forEach( buildContributorData );
    return data;

    function buildContributorData( ord ) {
        const contrib = {
            authId: vals[ord],
            isEditor: isEditor,
            ord: ord,
        };                                                          /*dbug-log*///console.log('              --buildContributorData [%O]', contrib);
        data[vals[ord]] = contrib;
    }
}
/* --------------------------- CITATION --------------------------------------- */
function setCitationTitle( g, fConfg, v ) {                           /*dbug-log*///console.log('--setCitationTitle title[%s]', v)
    const title = _u.stripString( v, true );
    const unqName = getUniqueCitationName( fConfg.group, title );
    util.setEntityProp( 'flat', 'Title', title, 'detail' );
    util.setEntityProp( 'flat', 'DisplayName', unqName, 'detail' );
    util.setEntityProp( 'flat', 'DisplayName', unqName );
}
function getUniqueCitationName( fLvl, citationTitle ) {
    const prntData = _state( 'getFieldState', [fLvl, 'ParentSource', 'misc'] );
    let pub = prntData.pub.displayName;
    pub = pub[0] === '*' ? pub.slice( 1 ) : pub;                      /*dbug-log*///console.log('--getUniqueCitationName citationTitle[%s] pubTitle[%s]', citationTitle, pub)
    return pub == citationTitle ? citationTitle + '(citation)' : citationTitle;
}
/** Validates page range. */
function setCitationPages( g, fConfg, v ) {
    const pieces = v.split( '-' ).map( i => parseInt( i ) );
    if ( pieces.length > 2 || pieces[0] > pieces[1] ) { return util.trackFailure( 'Pages' ); }
    util.setEntityProp( 'flat', 'PublicationPages', v, 'detail' );
}
/* --------------------------- INTERACTION ---------------------------------- */
function getTagsValue() {
    const tags = util.getEntityProp( 'rel', 'Tags' );
    return !tags ? [] : tags;
}
function handleSecondaryTag( g, fConfg, v ) {                         /*dbug-log*///console.log('--handleSecondaryTag v[%s] add?[%s]', v, v === 'Secondary');
    const tags = getTagsValue();
    updateTags( getTagId( 'Secondary' ), tags, v === 'Secondary' );
    util.setEntityProp( 'rel', 'Tags', tags );
}
function updateTags( id, tags, adding = true ) {                      /*dbug-log*///console.log('--updateTags id[%s] tags[%O] adding?[%s]', id, tags, adding);
    if ( adding ) {
        tags.push( id );
    } else if ( tags.indexOf( id ) !== -1 ){
        tags.splice( tags.indexOf( id ), 1 );
    }
}
function getTagId( name ) {
    const tags = _state( 'getEntityRcrds', ['tag'] );
    return Object.values( tags ).find( t => t.displayName === name ).id;
}
function handleSeasonTags( g, fConfg, v ) {                           /*dbug-log*///console.log('  -- handleSeasonTags seasons[%O]', v);
    const seasons = v ? v : [];
    const tags = getTagsValue();
    tags.push( ...seasons );
    util.setEntityProp( 'rel', 'Tags', tags );
}
/** Interaction-type tags: Validates that required tags are selected. */
function validateTags( g, fConfg, v ) {                               /*dbug-log*///console.log('  -- validateTags fConfg[%O]', fConfg);
    const val = getTagsValue().concat( v ? v : [] );
    if ( fConfg.required ) { validateTypeTags( fConfg, val ); }
    util.setEntityProp( 'rel', 'Tags', val );
}
function validateTypeTags( fConfg, val ) {
    if ( !val.length ) { return failTags( val ); }
    ensureTypeTagSelected( fConfg.misc.typeTags, val );
}
function ensureTypeTagSelected( typeTags, selected ) {                /*dbug-log*///console.log('--ensureTypeTagSelected tags[%O] selected[%O]', typeTags, selected);
    const fails = Object.values( typeTags ).find( isTagMissing );
    if ( fails ) { failTags( selected ); }

    function isTagMissing( tag ) {                                    /*dbug-log*///console.log('   --isRequiredTagSelected tag[%O]', tag);
        const isSelected = selected.indexOf( String( tag.id ) ) !== -1;
        return tag.isRequired && !isSelected;
    }
}
function failTags( val ) {
    util.trackFailure( 'InteractionTags', val )
}
/* ----------------------- LOCATION/GEOJSON --------------------------------- */
/** Handles detail-entity data */
function setGeoJsonData( g, fConfg, v ) {                             /*dbug-log*///console.log('               --setGeoJsonData [%s] fConfg[%O]', g, fConfg);
    const displayPoint = getDisplayCoordinates( v, fConfg.group );
    util.setEntityProp( 'flat', 'DisplayPoint', displayPoint, 'detail' );
    util.setEntityProp( 'flat', 'Type', 'Point', 'detail' );
    util.setEntityProp( 'flat', 'Coordinates', getCoordValue( displayPoint ), 'detail' );
}
function getDisplayCoordinates( lat, fLvl ) {
    const lng = _state( 'getFieldState', [fLvl, 'Longitude'] )
    return JSON.stringify( [ lng, lat ] );
}
function getCoordValue( displayPoint ) {
    const geoJson = _state( 'getFormState', ['top', 'geoJson'] );
    return geoJson ? geoJson.coordinates : displayPoint;
}
/** Validates and sets elevation range. */
function setElevationRange( g, fConfg, v ) {                          /*dbug-log*///console.log('               -- setElevationRange [%s] fConfg[%O]', g, fConfg);
    const elevLow = _state( 'getFieldState', [fConfg.group, 'Elevation'] )
    if ( v < elevLow ) { return util.trackFailure( 'Elevation' ); }
    util.setEntityProp( 'flat', fConfg.name, v );
}
/* --------------------------- PUBLICATION ---------------------------------- */
function setPublicationTitle( g, fConfg, v ) {
    const title = _u.stripString( v );
    util.setEntityProp( 'flat', 'DisplayName', title, 'detail' );
    util.setEntityProp( 'flat', 'DisplayName', title );
}
/* --------------------------- TAXON ---------------------------------------- */
function buildTaxonDisplayName( g, fConfg, v ) {
    const rank = _form( 'getRankName', [fConfg.group] );
    const dName = rank === 'Species' ? v : rank +' '+ v;
    util.setEntityProp( 'flat', 'DisplayName', dName );               /*dbug-log*///console.log('--buildTaxonDisplayName rank[%s] name[%s]', rank, dName);
}
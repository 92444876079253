/**
 * Returns the next field with pending-data ready for review with the next pending
 * record attached.
 *
 * Export
 *     getFirstFieldWithPendingData
 *
 * TOC
 *    GET PENDING-FIELD DATA
 *    SEARCH PENDING-FIELD
 *        HANDLE SKIPPED
 *        AUTHOR|EDITOR FIELDS
 *        TAXON FIELDS
 *        CHECK FOR REPLACED
 */
import { _cmbx, _u } from '~util';
import { _data, _form, _review, _state } from '~form';
import { isSkipped } from './get-pending-data.js';
/* ===================== GET PENDING-FIELD DATA  =============================== */
/**
 * Returns the next field with pending-data ready for review with the next pending
 * record attached.
 */
export default function getFirstFieldWithPendingData( fLvl, rcrds ) {
    const fields = _state( 'getDisplayedFields', [fLvl] );            /*temp-log*///console.log('+-- getFirstFieldWithPendingData fLvl[%s] fields[%O]', fLvl, fields);
    return fields ? searchForPendingData( fields, rcrds ) : null;
}
function searchForPendingData( fields, rcrds ) {                      /*dbug-log*///console.log('  -- searchForPendingData fields[%O]', _u.snapshot(fields));
    return fields.find( f => checkIfFieldReady( f, rcrds ) );
}
function checkIfFieldReady( f, rcrds ) {                              /*dbug-log*///console.log('       -- checkIfFieldReady field[%s %O]', f.name, _u.snapshot(f));
    const map = {
        Contributor: ifPendingContributorNext,
        Taxon: ifPendingTaxonNext
    };
    if ( map[f.entity] ) { return map[f.entity]( f, rcrds ); }
    if ( !f.pending ) { return checkForReplacedPending( null, f, rcrds ); }
    return ifPendingDataReady( f.pending.id, f, rcrds );
}
/* ===================== SEARCH PENDING-FIELD =============================== */
/**
 * True if field data is ready for review by the current user. Adds the record to
 * the review-field data to simplify later stages of the data preparation. If the
 * data was skipped, the UI is updated as needed.
 */
function ifPendingDataReady( id, f, rcrds, ord = false ) {            /*dbug-log*///console.log('          -- ifPendingDataReady field[%O] ord?[%s] pRcrd[%s][%O]', f, ord, id, rcrds[id]);
    if ( isSkipped( id ) ) { return handleSkipped( id, f, rcrds, ord ); }
    const isReady = _data( 'isReadyToReview', [rcrds[id]] );
    if ( !isReady ) { return checkForReplacedPending( id, f, rcrds, ord ); }
    f.record = rcrds[id];
    if ( ord ) { f.ord = ord; }
    return true;
}
/* ------------------------- HANDLE SKIPPED --------------------------------- */
function handleSkipped( id, f, rcrds, ord ) {                         /*dbug-log*///console.log('               -- handleSkipped field[%O] ord?[%s] pRcrd[%s %O]', _u.snapshot(f), ord, id, rcrds[id]);
    const hasReplaced = checkForReplacedPending( id, f, rcrds, ord );
    return hasReplaced ? hasReplaced : addOptIfComboEmpty( f, rcrds[id] );
}
/** If not handled elsewhere, adds 'skipped' to the field's combo. */
function addOptIfComboEmpty( field, pRcrd ) {
    if ( _cmbx.getSelVal( field.combo ) || pRcrd.completed ) { return; }
    _review( 'setPendingComboOpt', [field.combo, 'skipped'] );
}
/* ----------------------- AUTHOR|EDITOR FIELDS ----------------------------- */
/**
 * Contributors are a multi-select field with a value object keyed by their
 * order in the form. The first is set as the pending-data to be returned.
 */
function ifPendingContributorNext( f, rcrds ) {                       /*dbug-log*///console.log('               -- ifPendingContributorNext field[%O]', _u.snapshot(f));
    let next = findNextPendingContrib( f.pending );
    if ( !next ) { next = findNextPendingContrib( f.replacedPending ); }
    return next;

    function findNextPendingContrib( contribs ) {
        if ( !contribs ) { return; }
        return Object.keys( contribs ).find( ord => ifMultiFieldReady( ord, contribs[ord].id ) )
    }
    function ifMultiFieldReady( ord, id ) {                           /*dbug-log*///console.log('                   -- ifMultiFieldReady id[%s] ord[%s] field[%O]', id, ord, f);
        return ifPendingDataReady( id, f, rcrds, ord );
    }
}
/* ------------------------- TAXON FIELDS ----------------------------------- */
/**
 * Contributor: True if any parent is ready for review.
 * Manager: True if taxonomy has no rejected or returned parent-taxa.
 */
function ifPendingTaxonNext( f, rcrds ) {
    const ifTaxonomyCompatible = _u.isContributorUser() ?
        ifTaxonReadyForContributorReview : ifTaxonReadyForManagerReview;
    return ifTaxonomyCompatible( f, rcrds );
}
/** Manager: True if taxonomy has no rejected or returned parent-taxa. */
function ifTaxonReadyForManagerReview( f, rcrds ) {
    if ( !f.pending || isNotCompatibleWithReview( f.pending ) ) { return; }
    return ifPendingDataReady( f.pending.id, f, rcrds );

    function isNotCompatibleWithReview( pRcrd ) {
        const stage = _state( 'getReviewStage', [null, pRcrd] );      /*dbug-log*///console.log('               -- isNotCompatibleWithReview stage[%s] pRcrd[%O]', stage, pRcrd);
        if ( pRcrd.entityId ) { return false;  } // Has approved data.
        if ( stage !== 'Pending' ) { return true; } // Rejected or returned.
        const pParent = getPendingParentTaxon( pRcrd, rcrds );
        if ( !pParent ) { return false; }
        return isNotCompatibleWithReview( pParent );
    }
}
/**
 * Contributor: True if any parent is ready for review. That parent record is
 * added to the field to be reviewed next.
 */
function ifTaxonReadyForContributorReview( f, rcrds ) {               /*dbug-log*///console.log('               -- ifTaxonReadyForContributorReview field[%s %O]', f.name, f);
    let next = false;
    checkTaxonomyForNextRecord();                                   /*dbug-log*///console.log('                   -- ifTaxonReadyForContributorReview field[%O] next?[%O]', f, next);
    return next || setTaxonFieldCombo( f );

    function checkTaxonomyForNextRecord() {
        if ( f.pending ) { findHighestTaxonReadyForReview( f.pending ); }
        if ( next || !f.replacedPending ) { return; }
        findHighestTaxonReadyForReview( rcrds[f.replacedPending.id] );
    }

    function findHighestTaxonReadyForReview( pRcrd ) {                /*dbug-log*///console.log('                   -- findHighestTaxonReadyForReview pRcrd[%O]', pRcrd);
        const pParent = getPendingParentTaxon( pRcrd, rcrds );
        if ( pParent ) { findHighestTaxonReadyForReview( pParent ); }   /*dbug-log*///console.log('                        -- found?[%O] pRcrd[%O]', next, pRcrd);
        if ( next ) { return; } //Pending parent found.
        next = ifPendingDataReady( pRcrd.id, f, rcrds );
    }
}
function getPendingParentTaxon( pRcrd, rcrds ) {
    const pParent = pRcrd.data.review.fields.Parent.pending;        /*dbug-log*///console.log('                       -- getPendingParentTaxon pRcrd[%O] pParent?[%O]', pRcrd, pParent);
    return pParent ? rcrds[pParent.id] : false;
}
/** Resets quarantined or approved value. */
function setTaxonFieldCombo( f ) {
    _form( 'buildOptAndUpdateCombo', [f.combo, f.value, f.combo === 'Parent'] ); // Edit-form field change-event is silenced to skip redundant parent validation.
}
/* ----------------------- CHECK FOR REPLACED ------------------------------- */
function checkForReplacedPending( id, f, rcrds, ord ) {               /*dbug-log*///console.log('          -- checkForReplacedPending field[%O] ord?[%s]', _u.snapshot(f), ord);
    if ( !f.replacedPending || !f.replacedPending[ord] ) { return; }
    const replacedId = f.replacedPending[ord].id;
    if ( replacedId == id ) { return; } // Already processed
    return ifPendingDataReady( replacedId, f, rcrds, ord );
}
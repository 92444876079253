/**
 * Author form-build.
 *
 * Export
 *     initCreateForm
 *     initEditForm
 *
 * TOC
 *     CREATE
 *         PARAMS
 *         FINISH
 *     EDIT
 *         PARAMS
 *     REVIEW
 *         PARAMS
 *         BUILD
 *     SHARED
 *         FINISH BUILD
 */
import { _cmbx, _u } from '~util';
import { _data, _elems, _form, _state } from '~form';
import * as sForm from '../src-form-main.js';
import * as aForm from './auth-form-main.js';
/* ============================ CREATE ====================================== */
/** Init form when a new author|editor name is entered into the combobox. */
export function initCreateForm ( cnt, aType, v ) {                     /*dbug-log*///console.log('           >--[%s][%s] CREATE Form - [%s]', aType, cnt, v);
    const p = getCreateParams( cnt, aType, v );                       /*dbug-log*///console.log('--params[%O]', p);
    return _elems( 'initSubForm', [ p ] )
        .then( status => finishCreateInit( status, p ) );
}
/* ---------------------------- PARAMS -------------------------------------- */
function getCreateParams ( cnt, aType, v ) {
    const fLvl = _state( 'getSubFormLvl', [ 'sub2' ] );
    return {
        action: 'create',
        appendForm: form => $( '#' + aType + cnt + '_f' )[ 0 ].append( form ),
        combo: aType+cnt,
        count: cnt,
        name: aType,
        group: fLvl,
        style: 'sml-sub-form',
        submit: getSubmitFunc( fLvl ),
        vals: { 'LastName': ( v === 'create' ? '' : v ) }
    };
}
/* ---------------------------- FINISH -------------------------------------- */
function finishCreateInit ( status, p ) {
    if ( !status ) { return; } //Error handled elsewhere
    addOnCancelHandler( p.count, p.name, p.group );
    disableOtherAuthorTypeField( p.name, p.group );
    finishFormInit( status, p );
}
function addOnCancelHandler ( cnt, aType, fLvl ) {
    const onCancel = resetOnCreateFormCancel.bind( null, fLvl, aType, cnt );
    _state( 'addFormHandler', [ fLvl, 'cancel', onCancel ] );
}
function resetOnCreateFormCancel ( fLvl, type, cnt ) {                 /*dbug-log*///console.log('--resetOnCreateFormCancel [%s][%s][%s]', fLvl, type, cnt);
    _elems( 'handleFormClose', [ fLvl, true ] );
    _elems( 'ifParentFormValidEnableSubmit', [ fLvl ] );
    aForm.ifNoneStillSelectedEnableOtherType( type, _state( 'getSubFormLvl', [ 'sub' ] ), cnt );
    _cmbx.resetCombobox( type + cnt );
}
function disableOtherAuthorTypeField ( aType, fLvl ) {
    const pLvl = _state( 'getFormLevel', [ 'parent', fLvl ] );
    aForm.toggleOtherAuthorType( aType, pLvl, false );
}
/* ============================= EDIT ======================================= */
export function initEditForm ( id ) {                                  /*dbug-log*///console.log('           >--Author EDIT Form id[%s]', id);
    const p = getEditParams( id );                                     /*dbug-log*///console.log('   --params[%O]', p);
    return _elems( 'initForm', [ p ] )
        .then( status => finishFormInit( status, p ) );
}
/* ---------------------------- PARAMS -------------------------------------- */
function getEditParams ( id ) {
    return {
        action: 'edit',
        group: 'top',
        id: id,
        name: 'Author',
        style: 'sml-form',
        submit: getSubmitFunc( 'top' )
    };
}
/* ============================ REVIEW ====================================== */
export function initReviewForm ( pRcrd ) {                             /*dbug-log*///console.log('           >--Author REVIEW Form pRcrd[%O]', pRcrd);
    const params = getReviewFormParams( pRcrd );
    return initReviewFormElems( pRcrd, params )
        .then( status => finishReviewFormInit( status, params ) );
}
/* ---------------------------- PARAMS -------------------------------------- */
function getReviewFormParams ( pRcrd ) {
    const p = _data( 'isPendingAction', [ pRcrd, 'create' ] ) ?
        getReviewCreateParams( pRcrd ) : getEditParams( pRcrd.data.review.editId );
    p.pending = pRcrd;
    delete p.vals;
    delete p.submit; //submit modal is used for the review process
    return p;
}
function getReviewCreateParams ( pRcrd ) {
    const cnt = pRcrd.data.review.count;
    const field = pRcrd.inst.field;
    const p = getCreateParams( cnt, field );
    if ( ifFieldShouldPersistAfterSubmit( pRcrd ) ) { return p; }
    p.afterFormClose = () => clearAuthorFieldAfterReviewComplete( cnt, field );
    return p;
}
/**
 * Field is cleared after rejected data is confirmed to allow the contributor
 * to replace. It is not cleared if the rejected data has already been replaced.
 */
function ifFieldShouldPersistAfterSubmit ( pRcrd ) {
    return pRcrd.stage.name !== 'Rejected' || pRcrd.entityId;
}
function clearAuthorFieldAfterReviewComplete ( cnt, field ) {          /*dbug-log*///console.log('           --clearAuthorFieldAfterReviewComplete cnt[%s] field[%O]', cnt, field);
    _form( 'onAuthAndEdSelection', [ cnt, field, '' ] );
}
/* ---------------------------- BUILD --------------------------------------- */
function initReviewFormElems ( pRcrd, params ) {
    const init = pRcrd.inst.group === 'top' ? 'initForm' : 'initSubForm';
    return _elems( init, [ params ] );
}
function finishReviewFormInit ( status, p ) {
    const finishInit = p.action === 'create' ? finishCreateInit : finishFormInit;
    return finishInit( status, p );
}
/* ======================== SHARED ========================================== */
function getSubmitFunc ( fLvl ) {
    return sForm.showSrcSubmitModal.bind( null, fLvl );
}
/* ----------------------- FINISH BUILD ------------------------------------- */
function finishFormInit ( status, p ) {                                /*dbug-log*///console.log('-finishFormInit status[%s] p[%O]', status, p);
    if ( !status ) { return; } //Error handled elsewhere
    _elems( 'toggleSubmitBttn', [ p.group ] );
    $( '#LastName_f .f-input' ).focus();
}
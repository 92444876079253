/**
 * Prepares the PendingData record for the data-review process, updating all
 * fields with pending sub-data and adding data for the current instance.
 *
 * Export
 *     prepareRecordForReview
 *     updatePendingFields
 *
 * TOC
 *     INSTANCE DATA
 *     UPDATE PENDING SUB-DATA
 *         UPDATE FIELD VALUE
 */
import { _el, _u } from '~util';
import { _data, _state } from '~form';
/**
 * Prepares the PendingData record for the data-review process, updating all
 * fields with pending sub-data and adding data for the current instance.
 * @param  {object} rcrds  All PendingData records
 * @param  {object} rcrd   The PendingData record to be reviewed
 * @param  {string} fLvl   Form level
 * @param  {string} fName  Field with pending sub-data being reviewed, or null
 * @return {object}        The PendingData record to be reviewed
 */
export default function prepareRecordForReview( rcrds, rcrd, fLvl, fName ) {
    const pRcrd = _u.snapshot( rcrd );
    setPendingInstanceProps( pRcrd, fName, fLvl );
    updatePendingSubData( pRcrd, rcrds );
    return pRcrd
}
/* ====================== INSTANCE DATA ===================================== */
/** Sets data specific to the current instance of the pending-data. */
function setPendingInstanceProps( pRcrd, fName, fLvl ) {
    pRcrd.inst = {}; // properties for the current instance
    pRcrd.inst.group = fLvl;  //sub-form level
    if ( fName ) { pRcrd.inst.field = fName; }//Parent-form field name
}
/* ================= UPDATE PENDING SUB-DATA ================================ */
/**
 * Flattens pending-data for the data-set fields. Pending-data is only filled for
 * the form being loaded and is filled with recently pulled server data.
 */
function updatePendingSubData( pRcrd, rcrds ) {
    updatePendingFields( pRcrd.data.review.fields, rcrds );
    return pRcrd;
}
/**
 * Updates the fields with pending-data with the most recent version of the data.
 * Note: referenced pending-data may loop through multiple times, eg parent and contrib fields.
 * @param  {object} fields  Form fields: review-data only (init) or field-config
 * @param  {object} rcrds   PendingData records, recently pulled from server
 */
export function updatePendingFields( fields, rcrds ) {                /*dbug-log*///console.log(' +--updatePendingFields fields[%O] rcrds[%O]', _u.snapshot(fields), rcrds);
    rcrds = rcrds ? rcrds : _state( 'getEntityRcrds', ['pending'] );
    Object.keys( fields ).forEach( k => updatePendingField( k, fields[k], rcrds ) );
}
/**
 * Before form-init: Fills field pending-data with the full PendingData entity.
 * After sub-form submit: resets field pending-data with the most recent versions
 * of the data.
 * Note: The pending property triggers the following processes:
 *     - initForm: Special review-field processing
 *     - onSubmit: Submits core pending-data with the review field-data
 * @param  {string} fName  Field-name (Necessary before form-field init for debugging)
 * @param  {object} field  After init: field confg, Before init: base data for review
 * @param  {object} rcrds  PendingData records, recently pulled from server
 */
function updatePendingField( fName, field, rcrds ) {                  /*dbug-log*///console.log('       --updatePendingField field[%s][%O]', fName, _u.snapshot(field));
    if ( !field.pending ) { return; }                                 /*dbug-log*///console.log('       --updatePendingField field[%s][%O]', fName, _u.snapshot(field));
    if ( _el.isMultiField( field ) ) { return processPendingMulti( field, rcrds ); }
    updatePendingFieldState( field.pending.id, field, rcrds );
}
function processPendingMulti( field, rcrds ) {                        /*dbug-log*///console.log('               --processPendingMulti field[%O] rcrds[%O]', _u.snapshot(field), rcrds);
    Object.keys( field.pending ).forEach( updateMultiField );

    function updateMultiField( ord ) {
        if ( !field.pending[ord] ) { return; }                        /*dbug-log*///console.log('               --updateMultiField ord[%s] pendingField[%O]', ord, field.pending[ord]);
        updatePendingFieldState( field.pending[ord].id, field, rcrds, ord );
    }
}
function updatePendingFieldState( id, field, rcrds, ord ) {
    const pRcrd = rcrds[id];                                        /*dbug-log*///console.log('                       -- updatePendingFieldState pRcrd[%s %O] field[%O] ord?[%s]', id, pRcrd, field, ord);
    updateFieldValue( pRcrd, field, ord );
    _state( 'setFieldPending', [field, pRcrd, ord] ); //MUST BE SET AFTER FIELD VALUE (DURING CONTRIBUTOR REVIEW, APPROVED PENDINGDATA WILL BE REMOVED DURING VALUE SET)
}
/** --------------------- UPDATE FIELD VALUE -------------------------------- */
/**
 * Contributors have access to their data during the review process. Entity data
 * are quarantined with temporary IDs. During manager review, these are removed.
 * Note: They are reset during submit if the quarantined ids remain relevant.
 * @param  {object} pRcrd  PendingData entity
 * @param  {object} field  After init: field confg, Before init: base data for review
 * @param  {string} ord    Multi-field ordinal
 */
function updateFieldValue( pRcrd, field, ord ) {
    if ( _data( 'ifValueQuarantined', [pRcrd] ) ) { return; } // Quarantined-data ID persists for contributor
    flagIfChanged( pRcrd, field, ord );
    const newValue = pRcrd.entityId ? pRcrd.entityId : null;        /*dbug-log*///console.log('               -- updateFieldValue pRcrd[%O] field[%O] ord[%s] newValue[%s]', pRcrd, field, ord, newValue);
    _state( 'setFieldValue', [field, newValue, ord] );
}
function flagIfChanged( pRcrd, field, ord ) {
    if ( !pRcrd.entityId ) { return; } // This property is set only when an entity has been approved
    const value = ord ? field.value[ord] : field.value;
    field.changed = value != pRcrd.entityId;
}
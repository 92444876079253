/**
 * The intro.js walkthrough contains the tutorial for the database search page.
 * It is loaded on the first visit to the page and prompts the user to go through
 * the entire tutorial. The tutorial can later be accessed by clicking on the
 * tutorial button and selecting the a smaller focus of the tutorial or the entire
 * set.
 * The search tips available by clicking on "Show Tips".
 *
 * Export
 *     startWalkthrough
 *     getFilterPanelSteps
 *     getSavedFilterSteps
 *     getSavedListSteps
 *
 * TOC
 *     INIT
 *         SET UP
 *         TEAR DOWN
 *     ON STEP CHANGE
 *         EVENT LISTENER
 *         SHORTCUT BUTTONS
 *         LOAD INTS ON MAP
 *         SET VIEW
 *         TOGGLE PANELS
 */
import { _cmbx, _db, _el } from '~util';
import { _table, _ui } from '~db';
import { getSteps } from './tutorial-steps.ts';
export { getFilterPanelSteps, getSavedFilterSteps, getSavedListSteps } from './tutorial-steps.ts';

let intro, focus, isMapDataAvailable = false;

addWalkthroughEventListener();
/* ============================== INIT ====================================== */
function addWalkthroughEventListener () {
    $( "#strt-tut" ).on( 'click', startWalkthrough.bind( null, null ) );
}
export function startWalkthrough ( curFocus ) {
    if ( intro ) { return; }
    if ( curFocus ) { focus = curFocus; }
    setTableState();
    _el.showIntroTutorial( getIntroOptions(), getIntroEvents() )
}
function getIntroEvents() {
    return {
        onafterchange: onAfterStepChange,
        onexit: resetTableState,
    };
}
function getIntroOptions () {
    return {
        doneLabel: "I'm done.",
        showBullets: true,
        steps: getSteps()
    };
}
/* --------------------------- SET UP --------------------------------------- */
function setTableState () {
    $( '#show-tips' ).off( "click" );
    $( '#db-view' ).css( "height", "444px" );
    _cmbx.enableCombobox( 'Focus', false );
    _cmbx.enableCombobox( 'View', false );
    setDbLoadDependentState();
}
function setDbLoadDependentState () {
    if ( !$( '#sel-Focus' ).val() ) {
        return window.setTimeout( setDbLoadDependentState, 200 );
    }
    $( '#sel-Focus' )[0].selectize.addItem( 'taxa' );
    $( '#sel-View' )[0].selectize.addItem( '1' );
}
/* --------------------------- TEAR DOWN ------------------------------------ */
function resetTableState () {
    resetUi();
    if ( !$( '#sel-View' ).val() ) { return; }
    updateUiIfAllTableDataAvailable();
}
function updateUiIfAllTableDataAvailable () {
    _db.getData( 'interaction', true ).then( interactions => {
        if ( !interactions ) { return; }
        $( '#sel-Focus' )[0].selectize.enable();
        _table( 'resetDataTable', [focus] );
    } );
}
function resetUi () {
    focus = focus || "taxa";
    intro = null;
    $( '#db-view' ).css( "height", "888px" );
    $( '#show-tips' ).on( 'click', _ui.bind( null, 'showTips' ) );
    $( '#sel-Focus' )[0].selectize.addItem( focus, 'silent' );
    $( '#sel-View' )[0].selectize.enable();
}
/* ====================== ON STEP CHANGE ==================================== */
/* ----------------- EVENT LISTENER ----------------------------------------- */
function onAfterStepChange ( instance ) {
    intro = instance;
    const stepConfg = intro._introItems[intro._currentStep];
    if ( !$( '#sel-View' ).val() && intro._currentStep > 1 ) { return waitForDbLoad( 'tbl', intro._currentStep ); }
    if ( intro._currentStep >= 14 ) { return showAlertIfGeoJsonNotAvailable( stepConfg ); }
    if ( stepConfg.setUpFunc ) { stepConfg.setUpFunc(); }
}
function waitForDbLoad ( tag, stepIdx ) {
    window.setTimeout( () => showDbStillLoadingAlert( tag ), 500 );
    if ( tag == 'map' || stepIdx >= 15 ) {
        intro._currentStep = 14;
    } else {
        window.setTimeout( () => goToStepAfterDbLoad( stepIdx ), 500 );
    }
}
function showDbStillLoadingAlert ( tag ) {
    const elem = tag == 'map' ? 'Database' : 'Table';
    $( '.introjs-tooltiptext' ).html( `
        <br><b>Please wait for the ${ elem } to load.<br><br>` );
}
function goToStepAfterDbLoad ( stepIdx ) {
    if ( !$( '#sel-View' ).val() ) { return window.setTimeout( () => goToStepAfterDbLoad( stepIdx ), 500 ); }
    if ( !intro ) { return; } //Tutorial closed
    intro.goToStep( ++stepIdx );
}
function showAlertIfGeoJsonNotAvailable ( stepConfg ) {
    isAllDataAvailablePromise().then( dataAvailable => {
        if ( !dataAvailable ) { return waitForDbLoad( 'map' ); }
        if ( stepConfg.setUpFunc ) { stepConfg.setUpFunc(); }
    } );
}
function isAllDataAvailablePromise () {
    return isMapDataAvailable ? Promise.resolve( isMapDataAvailable ) :
        _db.getData( 'geoJson', true ).then( updateFlagAndReturn );
}
function updateFlagAndReturn ( geoJson ) {
    isMapDataAvailable = !!geoJson;
    return Promise.resolve( !!geoJson );
}
/* --------------------------- SHORTCUT BUTTONS ----------------------------- */
export function addBttnEvents () {
    const map = {
        'Filters': 'filter',
        'Map View': 'map',
        'Table Data': 'tbl',
    };
    $( '.intro-bttn' ).each( ( i, elem ) => {
        const key = map[elem.innerText];
        if ( key === 'map' ) { return enableMapTutorialIfDataAvailable( elem, key ); }
        $( elem ).on( 'click', showTutorial.bind( null, key ) );
    } );
}
function enableMapTutorialIfDataAvailable ( elem, key ) {
    isAllDataAvailablePromise().then( dataAvailable => {
        if ( !dataAvailable ) { return; }
        $( elem ).attr( 'disabled', false ).css( { 'opacity': 1, 'cursor': 'pointer' } );
        $( elem ).on( 'click', showTutorial.bind( null, key ) );
    } );
}
function showTutorial ( tutKey ) {
    if ( tutKey === 'full' || tutKey === 'tbl' ) { intro.nextStep(); }
    if ( tutKey === 'filter' ) { intro.goToStep( 8 ); }
    if ( tutKey === 'map' ) { intro.goToStep( 16 ); }
}
/* --------------------------- LOAD INTS ON MAP ----------------------------- */
export function loadIntsOnMap () {
    if ( $( '#map' )[0].style.display === 'none' ) { $( '#shw-map' ).trigger( 'click' ); }
}
/* -------------------------------- SET VIEW -------------------------------- */
export function setTableView ( view ) {
    const val = _cmbx.getOptionValueForText( 'View', view );
    _cmbx.setSelVal( 'View', val );
    window.setTimeout( () => ( intro ? intro.refresh() : null ), 2000 );
}
export function loadLocView ( view ) {
    if ( $( '#sel-Focus' )[0].selectize.getValue() !== 'locs' ) {
        $( '#sel-Focus' )[0].selectize.addItem( 'locs' );
    }
    window.setTimeout( () => setLocView( view ), 400 );
}
function setLocView ( view ) {
    if ( $( '#sel-View' )[0].selectize.getValue() !== view ) {
        $( '#sel-View' )[0].selectize.addItem( view );
    }
}
/* --------------------------- TOGGLE PANELS -------------------------------- */
export function toggleFilterPanelInTutorial ( close ) {
    const closed = $( '#filter-pnl' ).hasClass( 'closed' );
    if ( ( close && closed ) || !close && !closed ) { return; }
    $( '#filter' ).trigger( 'click' );
}
export function clearFilters () {
    $( 'button[name="reset-tbl"]' ).trigger( 'click' );
    toggleFilterPanelInTutorial( true );
}
/**
 * TODO: DOCUMENT
 *
 * Export
 *
 * TOC
 *     GET CORE-STATE
 *         ENTITY RECORDS
 *         REVIEW FORM
 *    GET FORM-STATE
 *        FIELDS
 *        PREDICATES
 */
import { _u } from '~util';
import { alertFormIssue, _data } from '~form';

/* ============================ GET CORE-STATE ====================================================================== */
export function getStateProp( fS, prop ) {                            /*dbug-log*///console.log('   --getStateProp  prop[%s], fS[%O]', prop, fS);
    return prop ? fS[prop] : fS;
}
/** Returns the 'next' form level- either the parent or child. */
export function getFormLevel( fS, next, current ) {                   /*dbug-log*///console.log('   --getFormLevel next[%s] current[%s]', next, current);
    const curIdx = fS.levels.indexOf( current );
    return next === 'parent' ? fS.levels[curIdx-1] : fS.levels[curIdx+1];
}
/**
 * Returns the sub form's lvl. If the top form is not the interaction form,
 * the passed form lvl is reduced by one and returned.
 * TODO: REPLAVE WITH (GETFORMSTATE, ENTITY)
 */
export function getSubFormLvl( fS, lvl ) {
    if ( fS.forms.top.name === 'Interaction' ) { return lvl; }
    return fS.levels[fS.levels.indexOf( lvl )-1];
}
export function getEntityFormLevel( fS, entity ) {
    return fS.forms[entity];
}
/* ----------------------- ENTITY RECORDS------------------------------------ */
export function getEntityRcrds( fS, entity ) {                        /*dbug-log*///console.log('   --getEntityRcrds  entity[%O], fS[%O]', entity, fS);
    return typeof entity == 'string' ? fS.records[entity] : buildRcrdsObj( fS, entity );
}
function buildRcrdsObj( fS, entities ) {
    const rcrds = {};
    entities.forEach( ent => { rcrds[ent] = fS.records[ent]} );
    return rcrds;
}
/** Returns the record for the passed id and entity-type. */
export function getRcrd( fS, e, id, alertMissing = true ) {           /*dbug-log*///console.log('   --getRcrd  entity[%s][%s] fS[%O]', e, id, fS);
    const ent = _u.lcfirst( e );
    const r = fS.records[ent] ? fS.records[ent][id] : false;        /*dbug-log*///console.log('   --getRcrd  rcrd%O]', r);
    return r ? _u.snapshot( r ) : handleMissingAlert();
}
function handleMissingAlert( e, id, alertMissing ) {
    return alertMissing ? alertFormIssue( 'noRcrdFound', {id: id, entity: e } ) : false;
}
export function getTaxonGroupRoot( fS, id ) {
    return fS.records.taxon[id].group.subGroup;
}
/* ----------------------- REVIEW FORM -------------------------------------- */
/** Returns the review-stage (passiveForm) of the form or the PendingData record. */
export function getReviewStage( fS, fLvl, rcrd = false ) {            /*dbug-log*///console.log('   -- getReviewStage  fLvl[%s] fS[%O]', fLvl, fS);
    const pRcrd = rcrd ? rcrd : fS.forms[fLvl].pending;
    const stageName = pRcrd ? pRcrd.data.stage.name : null;         /*dbug-log*///console.log("           -- stageName?[%s]", stageName);
    return !stageName ? 'Pending' : getStagePassiveForm( fS, stageName );
}
export function getStagePassiveForm( fS, activeForm ) {
    const stages = Object.values( fS.records.pendingStages );
    const stage = stages.find( s => s.activeForm === activeForm );
    return stage.passiveForm;
}
/* ============================ GET FORM-STATE ====================================================================== */
export function getFormState( fState, prop = null ) {                 /*dbug-log*///console.log('   --getFormState prop?[%s] [%O]', prop, fState);//console.trace();
    return prop ? fState[prop] : fState;
}
export function getFormAction( fState ) {
    return fState.pending ? fState.pending.data.review.action : fState.action;
}
//todo: update throughout code
export function getFormEntity( fState, first = 'uc' ) {               /*dbug-log*///console.log('   --getFormEntity case[%s] [%O]', first, fState);//console.trace();
    return first === 'uc' ? fState.name : _u.lcfirst( fState.name );
}
/**
 * @param  {object} fState       Form-group state
 * @param  {string} type         Handler name
 * @param  {boolean} returnFunc  Flag to return stub-function if no handler available
 * @return {function|null}
 */
export function getFormHandler( fState, type, returnFunc = false ) {  /*dbug-log*///console.log('   --getFormHandler type[%s] returnFunc?[%s] fState[%O]', type, returnFunc, fState);//console.trace();
    const handler = fState.handlers[type];
    return handler ? handler : ( returnFunc ? () => {} : null );
}
/* -------------------------- FIELDS ---------------------------------------- */
export function getComboFields( fState ) {                            /*dbug-log*///console.log('getComboFields [%O]', fState.fields);//console.trace();
    return Object.values( fState.fields ).filter( f => f.combo && f.shown );
}
/** Returns an array of the form fields in the order they are displayed. */
export function getDisplayedFields( fState ) {                         /*dbug-log*///console.log('getDisplayedFields fState[%O]', fState.fields);//console.trace();
    const fields = [];
    fState.view.forEach( row => row.forEach( f => addField( f, fields ) ) );
    return fields;
}
function addField( field, fields ) {
    if ( field.confgs ) { return Object.values( field.confgs ).forEach( f => addField( f, fields ) ); }
    fields.push( field );
}
export function getFieldState( fState, field, prop = 'value' ) {       /*dbug-log*///console.log('   --getFieldState field[%s] prop[%s] fConfg[%O] fState[%O]', field, prop, fState.fields[field], fState);//console.trace();
    return prop ? fState.fields[field][prop] : fState.fields[field];
}
/** Used when the ID of the field is known, */
export function getFieldStateById( fState, id ) {                     /*dbug-log*///console.log('   --getFieldStateById id[%s] fState[%O]', id, fState);
    return Object.values( fState.fields ).find( f => f.id === id );
}
/** Returns an object with field names(k) and values(v) of all form fields */
export function getFieldValues( fState ) {
    const vals = {};
    for ( let name in fState.fields ) {
        if ( !fState.fields[name].value ) { continue; }
        vals[name] = fState.fields[name].value;
    }                                                               /*dbug-log*///console.log('   --getFieldValues fields[%O] vals[%O]', name, vals);
    return vals;
}
/**
 * Gets the data-classes of the current field.
 *
 * @param  {obj} fConfg         Form field configuration
 * @param  {obj} fConfg.entity  Server classname for the field data - default "core" name
 * @param  {obj} fConfg.name    Form-field display-name - default "detail" name
 * @param  {obj} fConfg.prep.dataClasses.core    Record-data core name, when different than fConfg.entity
 * @param  {obj} fConfg.prep.dataClasses.detail  Record-data detail name, when different than fConfg.name
 *
 * @return {ary}        ["<CORE>", "<DETAIL>" || false]
 */
export function getFieldClassData( field ) {
    if ( !field.entity ) { return [_u.lcfirst( field.name ), false]; }
    const cName = getDataClass( 'core', field );
    let dName = getDataClass( 'detail', field );                      /*dbug-log*///console.log('--getDataEntities core[%s] detail[%s]', _u.lcfirst(cName), dName);
    if ( cName === dName ) { dName = false; }
    return [cName, dName];
}
function getDataClass( type, fConfg ) {
    let name = getDataClassIfSpecified( type, fConfg.prep );
    if ( !name && name !== false ) { name = type === 'core' ? fConfg.entity : fConfg.name; }
    return !name ? false : _u.lcfirst( name );
}
function getDataClassIfSpecified( type, prepObj ) {
    return !prepObj || !prepObj.dataClasses ? null : prepObj.dataClasses[type];
}
/* ------------------------ PREDICATES -------------------------------------- */
export function isEditForm( fState ) {
    return getFormAction( fState ) === 'edit';
}
export function isDisplayFormOnly( fState ) {                         /*dbug-log*///console.log('   -- isDisplayFormOnly?[%s] fState[%O]', fState.pending && _data('isApprovedOrRejected', [fState.pending]), fState);
    return fState.pending && _data( 'isApprovedOrRejected', [fState.pending] );
}
export function isFieldShown( fState, field ) {                       /*dbug-log*///console.log('   --isFieldShown [%O][%O]', field, fState);
    if ( Array.isArray( field ) ) { return areFieldsShown( fState, field ); }
    return fState.fields[field].shown || false;
}
export function areFieldsShown( fState, fields ) {
    return fields.map( f => isFieldShown( fState, f ) ).every( b=>b );
}
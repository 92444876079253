/**
 * Modifies location-data for local storage:
 * - [entity]Names - an object with each entity's displayName(k) and id.
 * * location - resaved locations with an additional data point for countries.
 *
 * TOC
 *     REGION
 *     COUNTRY
 *     TYPES
 *     MODIFY RECORDS
 *
 * Export
 *     modifyLocDataForLocalDb
 */
import { _db } from '~util';
import { EntityRecords, IdsByName, SerializedEntity, objectValues } from '~types';
import { getRcrds, getNameObj, getTypeObj, getType } from '../init-helpers';

type LocationData = {
    location: EntityRecords;
    locationType: EntityRecords;
    habitatType: EntityRecords;
};
export function modifyLocDataForLocalDb ( data: LocationData ) {    /*dbug-log*///console.log("modifyLocDataForLocalDb called. data = %O", data);
    handleRegionData( data.location, data.locationType );
    handleCountryData( data.location, data.locationType );
    handleTypeData( data.habitatType, data.locationType );
    modifyLocationRecords( data.location );
}
/* =================== REGION =============================================== */
function handleRegionData ( locs: EntityRecords, locTypes: EntityRecords ) {
    const regions = getType( locTypes, 'region', 'locations' );     /*dbug-log*///console.log('--handleRegionData[%O]', regns);
    _db.storeData( 'regionNames', getNameObj( regions, locs ) );
    _db.storeData( 'topRegionNames', getTopRegionNameData( locs, regions ) );
}
function getTopRegionNameData ( locs: EntityRecords, regions: number[] ): IdsByName {
    const data: IdsByName = {};
    objectValues( getRcrds( regions, locs ) ).forEach( ifRegionAddData );
    return data;

    function ifRegionAddData ( loc: SerializedEntity ) {
        const name = loc?.displayName;
        if ( !name ) throw Error( 'Location name not found' );
        if ( !loc.parent ) data[name] = loc.id;
    }
}
/* ================== COUNTRY =============================================== */
function handleCountryData ( locs: EntityRecords, locTypes: EntityRecords ) {
    const cntrys = getType( locTypes, 'country', 'locations' );     /*dbug-log*///console.log('--handleCountryData[%O]', cntrys);
    _db.storeData( 'countryNames', getNameObj( cntrys, locs ) );
    _db.storeData( 'countryCodes', getCodeNameDataObj( cntrys, locs ) );
}
/** Return an obj with the 2-letter ISO-country-code (k) and the country id (v).*/
function getCodeNameDataObj ( ids: number[], rcrds: EntityRecords ): IdsByName {
    const data: IdsByName = {};
    ids.forEach( id => data[rcrds[id]?.isoCode] = id );
    return data;
}
/* ==================== TYPES =============================================== */
function handleTypeData ( habTypes: EntityRecords, locTypes: EntityRecords ) {
    _db.storeData( 'habTypeNames', getTypeObj( habTypes ) );
    _db.storeData( 'locTypeNames', getTypeObj( locTypes ) );
    ['locationType', 'habitatType'].forEach( k => _db.removeData( k ) );
}
/* ==================== MODIFY RECORDS ====================================== */
function modifyLocationRecords ( locations: EntityRecords ) {
    _db.storeData( 'location', addInteractionTotalsToLocs( locations ) );
}
/** Adds the total interaction count of the location and it's children. */
function addInteractionTotalsToLocs ( locs: EntityRecords ) {
    for ( let id in locs ) {
        const loc = locs[id];
        if ( !loc ) throw Error( 'Location not found' );
        loc.totalInts = getTotalInteractionCount( loc, locs );      /*dbug-log*///console.log('[%s] total = [%s]', locs[id].displayName, locs[id].totalInts);
    }
    return locs;
}
function getTotalInteractionCount ( loc: SerializedEntity, locs: EntityRecords ) {
    let ttl = loc.interactions.length;
    loc.children.forEach( ( id: number ) => {
        let child = locs[id];
        if ( !child ) throw Error( 'Location not found' );
        ttl += getTotalInteractionCount( child, locs );
    } );
    return ttl;
}
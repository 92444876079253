/**
 * Publication-form code.
 * When a user enters a new publication into the combobox, the create form is built
 * and appended to the field's row.
 *
 * Export
 *     finishPublicationEditForm
 *     initCreateForm
 *     initEditForm
 *     loadPubTypeFields
 *
 * TOC
 *     INIT FORM
 *         CREATE
 *         EDIT
 *         SHARED
 *     PUBLICATION-TYPE FIELDS
 *         SHOW NOTE
 *     FINISH BUILD
 */
import { _data, _elems, _form, _state } from '~form';
import * as sForm from '../src-form-main.js';
/* ========================= INIT FORM ====================================== */
/* --------------------------- CREATE --------------------------------------- */
export function initCreateForm(v) {                                 /*dbug-log*///console.log('           >--Publication CREATE Form v[%s]', v);
    _form('clearCitationCombo');
    return _elems('initSubForm', [getCreateParams(v)])
        .then(finishPubFormInit);
}
function getCreateParams(v) {
    const params = {
        appendForm: form => $('#CitationTitle_f')[0].parentNode.after(form),
        combo: 'Publication',
        style: 'med-sub-form',
        vals: { DisplayName: v === 'create' ? '' : v }
    };
    return { ...params, ...getFormParams('sub', 'create') };
}
/* ---------------------------- EDIT ---------------------------------------- */
export function initEditForm(id) {                                  /*dbug-log*///console.log('           >--Publisher EDIT Form id[%s]', id);
    return _elems('initForm', [getEditParams(id)])
        .then(finishPubFormInit);
}
function getEditParams(id) {
    const params = { id: id, style: 'lrg-form' };
    return { ...params, ...getFormParams('top', 'edit') };
}
/* ---------------------------- REVIEW ---------------------------------------- */
export function initReviewForm(pRcrd) {                             /*dbug-log*///console.log('           >--Publisher REVIEW Form pRcrd[%O]', pRcrd);
    const params = getReviewFormParams(pRcrd);
    const init = pRcrd.inst.group === 'top' ? 'initForm' : 'initSubForm';
    return _elems(init, [params])
        .then(finishPubFormInit);
}
function getReviewFormParams(pRcrd) {
    const action = _data('getPendingAction', [pRcrd]);
    const params = action === 'create' ? getCreateParams() : getRvwEditParams(pRcrd);
    params.type = pRcrd.data.quarantined.detailEntity.publicationType.displayName;
    params.pending = pRcrd;
    delete params.vals;
    delete params.submit; //submit modal is used for the review process
    return params;
}
function getRvwEditParams(pRcrd) {
    return getEditParams(pRcrd.data.quarantined.coreId);
}
/* --------------------------- SHARED --------------------------------------- */
function getFormParams(fLvl, action) {
    return {
        action: action,
        name: 'Publication',
        group: fLvl,
        submit: sForm.showSrcSubmitModal.bind(null, fLvl),
        initCombos: sForm.initCombos.bind(null, fLvl, 'Publication'),
    };
}
/* ======================= FINISH BUILD ===================================== */
function finishPubFormInit(status) {
    if (!status) { return; } //Error handled elsewhere
    $('#DisplayName_f .f-input').focus();
}
/* ===================== PUBLICATION-TYPE FIELDS ============================ */
/**
 * Loads the deafult fields for the selected Publication Type. Clears any
 * previous type-fields and initializes the selectized dropdowns.
 */
export function loadPubTypeFields(typeId) {                         /*dbug-log*///console.log('   @--loadPubTypeFields tId[%s]', typeId);
    return sForm.loadSrcTypeFields('Publication', typeId);
}
/* -------------------------- SHOW NOTE ------------------------------------- */
export function finishFieldLoad(fLvl) {
    showNoteIfBothEditorAndAuthorFieldsAvailable(fLvl);
}
/** Shows the user a note above the author and editor elems. */
function showNoteIfBothEditorAndAuthorFieldsAvailable(fLvl) {
    if (!isBothEditorAndAuthorFieldsAvailable(fLvl)) { return; }
    const note =`<div class="i g";>Note: This publication type can have either authors OR editors.</div>`;
    $(note).insertBefore($('#Author_f-cntnr')[0].parentNode);
}
function isBothEditorAndAuthorFieldsAvailable(fLvl) {
     return _state('areFieldsShown', [fLvl, ['Author', 'Editor']]);
}
/**
 * Facade for data-entry form-field utility methods.
 *
 * TOC
 *     BUILD/FILL
 *     PREDICATES
 *     FORM COMBOS
 */
import * as required from './is-required-filled.js';
import * as combo from './combo-field.js';
import * as fill from './complex-fields.js';
import * as rebuild from './rebuild-fields.js';
import * as style from './style-field.js';
import * as toggle from './toggle-fields.js';

/* --------------------- BUILD/FILL ----------------------------------------- */
export function ifMutlipleDisplaysGetToggle() {
    return toggle.ifMutlipleDisplaysGetToggle(...arguments);
}
export function fillComplexFormFields() {
    return fill.fillComplexFormFields(...arguments);
}
export function setDynamicFieldStyles() {
    style.setDynamicFieldStyles(...arguments);
}
export function rebuildFieldsOnFormConfgChanged() {
    return rebuild.rebuildFieldsOnFormConfgChanged(...arguments);
}
/* --------------------- PREDICATES ----------------------------------------- */
export function areRequiredFieldsFilled() {
    return required.areRequiredFieldsFilled(...arguments);
}
/* --------------------- FORM COMBOS ---------------------------------------- */
export function resetFormCombobox() {
    combo.resetFormCombobox(...arguments);
}
export function setSilentVal() {
    combo.setSilentVal(...arguments);
}
export function initFormCombos() {
    combo.initFormCombos(...arguments);
}
export function addAndSelectEntity() {
    return combo.addAndSelectEntity(...arguments);
}
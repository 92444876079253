/**
 * Misc Taxon helpers.
 */
import { OptionObject } from "~types";
import { _cmbx, _u } from "~util";
import { _state } from '~form';
import { FormGroup } from "@form/etc/form-types";

export function getRankName ( fLvl: FormGroup ) {
    const val: string | OptionObject = _state( 'getFieldState', [fLvl, 'Rank'] );
    if ( _u.isStringAndNaN( val ) ) return val;
    return _u.isOptionObject( val ) ? val.text : _cmbx.getSelTxt( 'Rank' );
}
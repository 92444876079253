/**
 * Sub-forms, form rows, field elements, etc.
 *
 * TOC
 *     STRUCTURE
 *         ROOT-FORM
 *         SIDE-PANEL
 *             INIT
 *             SET
 *             CLEAR
 *         SUB-FORM
 *         FOOTER
 *         SUBMIT|EXIT BUTTON
 *         FORM-STATUS MESSAGES
 *         FORM OVERLAY
 *     FIELDS
 *         COMPLETE FIELDS
 *         COMBOBOXES
 *         FILL FORM-DATA
 *         TOGGLE FORM-FIELDS
 *     PREDICATES
 */
import { _state, _val } from '~form';
import * as core from './core/elems-core-main.js';
import * as row from './row/form-row-main.js';
import * as eUtil from './util/form-elems-util-main.js';
import * as fields from './field/form-fields-main.js';
/* =================== STRUCTURE ============================================ */
export function getFormPieces() {
    return core.getFormPieces( ...arguments );
}
/* ---------------------------- ROOT-FORM ----------------------------------- */
export function initForm() {
    return core.initForm( ...arguments );
}
export function ifFormInUse() {
    return core.ifFormInUse( ...arguments );
}
/* ---------------------------- SIDE-PANEL -------------------------------- */
/** ----------- INIT -------------- */
export function getSidePanelElems() {
    return core.getSidePanelElems( ...arguments );
}
export function initSubEntityDataReviewElems() {
    core.initSubEntityDataReviewElems( ...arguments );
}
/** ------------ SET -------------- */
export function setSubEntityDetails() {
    return core.setSubEntityDetails( ...arguments );
}
export function fillEditEntitySidePanel() {
    return core.fillEditEntitySidePanel( ...arguments );
}
/** ---------- CLEAR -------------- */
export function clearSidePanelDetails() {
    return core.clearSidePanelDetails( ...arguments );
}
export function resetReviewSidePanel() {
    core.resetReviewSidePanel( ...arguments );
}
/* ---------------------------- SUB-FORM ------------------------------------ */
export function initSubForm() {
    return core.initSubForm( ...arguments );
}
export function handleFormClose() {
    return core.handleFormClose( ...arguments );
}
export function exitRootForm() {
    return core.exitRootForm( ...arguments );
}
/** Skips standard exit: removes form container and clears state. */
export function exitFormElemAndClearState( fLvl ) {
    if ( !$( `#${ fLvl }-form` ).length ) { return; } //FOrm-group not open
    $( `#${ fLvl }-form` ).remove();
    _state( 'clearFormGroupState', [fLvl] );
    ifParentFormValidEnableSubmit( fLvl );
    toggleCancelBttn( _state( 'getFormLevel', ['parent', fLvl] ), true );
}
/** Returns true if the next sub-form exists in the dom. */
export function hasOpenSubForm( fLvl ) {
    const childFormLvl = _state( 'getFormLevel', ['child', fLvl] );
    return $( '#'+childFormLvl+'-form' ).length > 0;
}
/* --------------------------- SUBMIT|EXIT BUTTON --------------------------- */
export function buildSubmitAndCancelBttns() {
    return core.buildSubmitAndCancelBttns( ...arguments );
}
export function getExitButton() {
    return core.getExitButton( ...arguments );
}
export function toggleSubmitBttn() {
    return eUtil.toggleSubmitBttn( ...arguments );
}
export function toggleCancelBttn() {
    return eUtil.toggleCancelBttn( ...arguments );
}
/** Enables the parent form's submit button if all required fields have values. */
export function ifParentFormValidEnableSubmit( fLvl ) {
    const parentLvl = _state( 'getFormLevel', ['parent', fLvl] );
    checkReqFieldsAndToggleSubmitBttn( parentLvl );
}
export function checkReqFieldsAndToggleSubmitBttn( fLvl, entity = false ) {/*dbug-log*///console.log('--checkReqFieldsAndToggleSubmitBttn fLvl?[%s] entity?[%s]', fLvl, entity);
    if ( !fLvl ) { fLvl = _state( 'getEntityFormLevel', [entity] ); }
    const reqFieldsFilled = ifNoOpenSubFormAndAllRequiredFieldsFilled( fLvl );
    toggleSubmitBttn( fLvl, reqFieldsFilled );
    _val( 'clearActiveAlert', [fLvl, null, false] );
}
/* --------------------- FORM-STATUS MESSAGES ------------------------------- */
export function toggleFormStatusMsg() {
    eUtil.toggleFormStatusMsg( ...arguments );
}
/* ------------------- FORM OVERLAY ----------------------------------------- */
export function toggleWaitOverlay() {
    eUtil.toggleWaitOverlay( ...arguments );
}
/* ============================== FIELDS ==================================== */
/* ------------------------- COMPLETE FIELDS -------------------------------- */
export function getFormRows( entity, fLvl ) {
    return row.getFormRows( entity, fLvl );
}
export function getFormFieldRows() {
    return row.getFormFieldRows( ...arguments );
}
export function finishFieldRebuild( fLvl, entity ) {
    const initCombos = _state( 'getFormHandler', [fLvl, 'initCombos'] );
    core.finishFormBuild( entity, fLvl, initCombos );
}
export function setDynamicFieldStyles() {
    fields.setDynamicFieldStyles( ...arguments );
}
export function buildFormField() {
    return fields.buildFormField( ...arguments );
}
export function onFormConfgChanged( fLvl, entity ) {
    toggleSubmitBttn( fLvl, false );
    return fields.rebuildFieldsOnFormConfgChanged( fLvl, entity );
}
export function storeMultiSelectValue() {
    return fields.storeMultiSelectValue( ...arguments );
}
/* -------------------------- COMBOBOXES ------------------------------------ */
export function initFormCombos() {
    return fields.initFormCombos( ...arguments );
}
export function resetFormCombobox() {
    return fields.resetFormCombobox( ...arguments );
}
export function buildDynamicFormField() {
    return fields.buildDynamicFormField( ...arguments );
}
export function setSilentVal() {
    fields.setSilentVal( ...arguments );
}
export function addAndSelectEntity() {
    return fields.addAndSelectEntity( ...arguments );
}
/*---------------------- FILL FORM-DATA --------------------------------------*/
export function fillComplexFormFields() {
    return fields.fillComplexFormFields( ...arguments );
}
export function ifFieldIsDisplayed( field, fLvl ) {
    return _state( 'getFieldState', [fLvl, field, 'shown'] );
}
/* -------------------- TOGGLE FORM-FIELDS ---------------------------------- */
export function ifMutlipleDisplaysGetToggle() {
    return fields.ifMutlipleDisplaysGetToggle( ...arguments );
}
export function showFieldReviewNotes() {
    return fields.showFieldReviewNotes( ...arguments );
}
export function toggleFormReviewNotes() {
    return fields.toggleFormReviewNotes( ...arguments );
}
/* ------------------------ PREDICATES -------------------------------------- */
export function areRequiredFieldsFilled() {
    return fields.areRequiredFieldsFilled( ...arguments );
}
/**  Note: Execution order can happen before field-alert is fully validated.  */
export function ifNoOpenSubFormAndAllRequiredFieldsFilled( fLvl ) {
    if ( $( `.${ fLvl }-active-alert` ).length ) return false;
    return fields.areRequiredFieldsFilled( fLvl ) && !hasOpenSubForm( fLvl );
}

/**
 * Contains code specific to the location form.
 *
 * Export
 *     addMapToLocForm
 *     autofillCoordinateFields
 *     focusParentAndShowChildLocs
 *     initCreateForm
 *     initEditForm
 *
 * TOC
 *     CREATE FORM
 *         FINISH BUILD
 *     EDIT FORM
 *         FINISH BUILD
 *     SHARED
 *         FINISH BUILD
 *     MAPS
 */
import { _cmbx } from '~util';
import { _data, _elems, _form } from '~form';
import * as map from './form-map.js';
import checkIfLocExists from './validate-new-loc.js';
/* ======================= CREATE FORM ====================================== */
export function initCreateForm ( entity, val ) {                       /*temp-log*///console.log("       --Location CREATE [%s]", val);
    if ( $( '#form-map' ).length !== 0 ) { $( '#form-map' ).remove(); }
    const p = getCreateParams( val );
    return _elems( 'initSubForm', [p] )
        .then( status => finishCreateInit( status, 'sub', 'create' ) );
}
function getCreateParams ( val ) {
    const createParams = {
        appendForm: form => $( '#Location_f' )[0].parentNode.after( form ),
        onFormClose: _form.bind( null, 'enableCountryRegionField' ),
        combo: 'Location',
        style: 'med-sub-form',
        submit: checkIfLocExists.bind( null, 'sub' ),
        vals: {
            DisplayName: val === 'create' ? '' : val, //clears form trigger value
            Country: $( '#sel-Country-Region' ).val()
        }
    };
    return { ...createParams, ...getFormParams( 'sub', 'create' ) };
}
/* ---------------------------- FINISH BUILD -------------------------------- */
function finishCreateInit ( status, fLvl, action ) {
    finishFormInit( status, fLvl, action );
    _cmbx.enableCombobox( 'Country-Region', false );
    disableTopFormLocNote();
    map.addListenerToGpsFields( 'sub' );
    scrollToLocFormWindow();
}
function disableTopFormLocNote () {
    $( '#loc-note' ).fadeTo( 400, .3 );
}
function scrollToLocFormWindow () {
    $( '#top-form' )[0].scrollTo( 0, 150 );
}
/* ========================= EDIT FORM ====================================== */
export function initEditForm ( entity, id ) {                          /*temp-log*///console.log('           >--Location EDIT entity[%s] id[%s]', entity, id);
    const p = getEditParams( id );
    return _elems( 'initForm', [p] )
        .then( status => finishEditInit( status, p, 'edit' ) );
}
function getEditParams ( id ) {
    const editParams = {
        id: id,
        style: 'lrg-form',
    };
    return { ...editParams, ...getFormParams( 'top', 'edit' ) };
}
/* ---------------------------- FINISH BUILD -------------------------------- */
function finishEditInit ( status, p, action ) {
    finishFormInit( status, p.group, action );
    map.addListenerToGpsFields( 'top', [p.id, 'edit', false] );
    map.afterMapLoads( finishEditForm, p.id );
}
function finishEditForm ( id ) {
    $( 'input.leaflet-control-create-icon' ).on( 'click', initCreateForm );
    map.ifCoordsAddMapPin( id );
}
/* ========================= REVIEW FORM ==================================== */
export function initReviewForm ( pRcrd ) {                             /*temp-log*///console.log('           >--Location REVIEW pending[%O]', pRcrd);
    const action = _data( 'getPendingAction', [pRcrd] );
    const params = getReviewFormParams( action, pRcrd );
    return initReviewFormElems( pRcrd, params )
        .then( status => finishReviewFormElems( status, params, action ) );
}
/* ---------------------------- PARAMS -------------------------------------- */
function getReviewFormParams ( action, pRcrd ) {
    const params = action === 'create' ? getCreateParams() : getEditParams( pRcrd.data.review.editId );
    delete params.vals;
    params.pending = pRcrd;
    return { ...params, ...getFormParams( pRcrd.inst.group, action ) };
}
/* ---------------------------- BUILD --------------------------------------- */
function initReviewFormElems ( pRcrd, params ) {
    const init = pRcrd.inst.group === 'top' ? 'initForm' : 'initSubForm';
    return _elems( init, [params] );
}
function finishReviewFormElems ( status, p, action ) {                 /*dbug-log*///console.log('  --finishReviewFormElems status[%s] p[%O]', status, p);
    const finish = p.group === 'top' ? finishEditInit : finishReviewCreate;
    return finish( status, p, action );
}
function finishReviewCreate ( status, p, action ) {
    finishCreateInit( status, p.group, action );
    map.afterMapLoads( map.ifCoordsAddMapPin, true );
}
/* ============================ SHARED ====================================== */
function getFormParams ( fLvl, action ) {
    return {
        action: action,
        name: 'Location',
        group: fLvl,
        initCombos: initCombos.bind( null, fLvl, action ),
    };
}
function initCombos ( fLvl, action ) {
    const events = {
        Country: { onChange: focusParentAndShowChildLocs.bind( null, action ) },
        HabitatType: { onChange: false },
    };
    _elems( 'initFormCombos', [fLvl, events] );
}
/* ---------------------------- FINISH BUILD -------------------------------- */
function finishFormInit ( status, fLvl, action ) {                                /*dbug-log*///console.log('           --finishFormInit status[%s] params[%O]', status, p);
    if ( !status ) { return; } //Error handled elsewhere
    _elems( 'checkReqFieldsAndToggleSubmitBttn', [fLvl] );
    $( '#DisplayName_f input' ).focus();
    addMapToLocForm( action );
    addNotesToForm();
}
function addNotesToForm () {
    addHowToCreateWithGpsNote();
    addSelectSimilarLocationNote();
}
function addHowToCreateWithGpsNote () {
    const note = `<div class="loc-gps-note">Enter
        decimal data (<a href="https://www.fcc.gov/media/radio/dms-decimal"
        target="_blank">convert</a>)<br>See the green pin’s popup for name suggestions.</div>`;
    $( $( '#Latitude_f' )[0].parentNode ).before( note );
}
function addSelectSimilarLocationNote () {
    const note = `<p class="loc-gps-note" style="margin: auto;">
        Select an existing location by clicking inside its pin's popup.</p>`;
    $( '#form-map' ).before( note );
}
/** ========================= MAPS ========================================== */
export function addMapToLocForm () {
    return map.addMapToLocForm( ...arguments );
}
export function focusParentAndShowChildLocs () {
    return map.focusParentAndShowChildLocs( ...arguments );
}
export function autofillCoordinateFields () {
    map.autofillCoordinateFields( ...arguments );
}
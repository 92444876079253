/**
 * If edited, the field is highlighted and the previous field-value displayed.
 *
 * Export
 *     highlightEditsForReview
 *
 * TOC
 *     CHECK FOR EDITS
 *         GET FIELD EDITS
 *     DISPLAY EDITS
 *         SHOW ORIGINAL VALUE
 *         HIGHLIGHT
 *     HANDLE TAGS
 *         GET TAG EDITS
 *     HANDLE FIELD PARENT-EDITS
 */
import { _cmbx, _u } from '~util';
import { _state } from '~form';

export default function highlightEditsForReview( field, quarantined ) {/*dbug-log*///console.log('-- highlightEditsForReview [%s] field[%O] quarantined[%O]', field.name, field, quarantined);
    if ( ifNoEditsPossible( ...arguments ) ) { return; }
    const eType = Object.keys( field.prop )[0];
    const edits = getEditedValue( field, eType, quarantined );        /*dbug-log*///console.log('     -- edits[%O]', edits);
    if ( !edits || divertIfEdgeCase( field, eType, edits ) ) { return; }
    showAndHighlightEdits( edits.old, field );
}
/* ======================= CHECK FOR EDITS ================================== */
function ifNoEditsPossible( field, quarantined ) {
    return !quarantined || !field.prop || !field.shown;
}
/* ------------------------ GET FIELD EDITS --------------------------------- */
/** Returns the edited value of the field. */
function getEditedValue( field, eType, qData ) {                      /*dbug-log*///console.log('-- getEditedValue eType[%s] fieldProps[%O], qData[%O]', eType, field.prop, qData);
    const prop = field.prop[eType];
    const key = Object.keys( qData[eType+'Edits'] ).find( ifFieldEdited );
    return key ? _u.snapshot( qData[eType+'Edits'][key] ) : false;

    function ifFieldEdited( editedProp ) {                            /*dbug-log*///console.log('   -- isFieldEdited prop[%s] editedProp[%s]', prop, editedProp);
        if ( editedProp.includes( 'Parent' ) && prop === 'parent' ) { return true; }
        if ( editedProp === 'Contributor' && field.entity === editedProp ) { return true; }
        return _u.lcfirst( editedProp ) === prop;
    }
}
/* ------------------------ EDGE CASES -------------------------------------- */
function divertIfEdgeCase( field, eType, edits ) {
    const prop = field.prop[eType];
    const map = {
        authors: handleContributorEdits,
        editors: handleContributorEdits,
        parent: handleParentFieldEdits,
        tags: handleTagEdits,
    };
    if ( !map[prop] ) { return false; }
    map[prop]( field, edits );
    return true;
}
/* ======================= DISPLAY EDITS ==================================== */
function showAndHighlightEdits( orgValue, field, fName = null ) {
    if ( !fName ) { fName = getFieldSelector( field ); }                /*dbug-log*///console.log('-- showAndHighlightEdits orgValue[%s] field[%s %O]', orgValue, fName, field);
    displayOriginalValue( orgValue, fName, field );
    hightlightEditedField( fName );
}
function getFieldSelector( field ) {
    return field.combo ? field.combo : ( field.id ? field.id : field.name );
}
/* ----------------------- SHOW ORIGINAL VALUE ------------------------------ */
/**
 * Display the original value of the edited field.
 * @param  {string|integer} orgVal  If integer, entity display-name is shown
 * @param  {string} fName  Field selector
 * @param  {[type]} field  Field confg: used if entity ID is original value
 */
function displayOriginalValue( orgVal, fName, field = {} ) {
    const value = getOriginalValue( orgVal, field );                  /*dbug-log*///console.log('-- displayOriginalValue value[%s] [%s] = [%O]', value, fName, field);
    const html = `<div class="prev-value"><i>Previous:</i> ${ value }</div>`;
    $( `#${ fName }_f` ).append( html );
}
function getOriginalValue( orgVal, field ) {                          /*dbug-log*///console.log('-- getOriginalValue orgVal[%s] field[%O]', orgVal, field);
    if ( orgVal === null ) { return "<i>[none]</i>" }
    return isNaN( orgVal ) || !field.entity ? orgVal : getOriginalEntityName( orgVal, field );
}
function getOriginalEntityName( orgVal, field ) {
    const name = _cmbx.getOptionTextForValue( field.combo, orgVal );
    return name ? name : getRecordName( _u.lcfirst( field.entity ), orgVal );
}
function getRecordName( eName, orgVal ) {
    const rcrd = _state( 'getRcrd', [eName, orgVal] );                /*dbug-log*///console.log('-- getRecordName record[%O]', rcrd);
    return rcrd.displayName;
}
/* ------------------------ HIGHLIGHT --------------------------------------- */
function hightlightEditedField( fName ) {                             /*dbug-log*///console.log('-- hightlightEditedField fName[%s]', fName);
    $( `#${ fName }_f` ).addClass( 'warn' );
}
/* ======================= INTERACTION TAGS ================================= */
/** Tag fields can be complex and are handled by their tag-type. */
function handleTagEdits( field, edits ) {                             /*dbug-log*///console.log('-- handleTagEdits edits[%O] field[%O]', edits, field);
    const type = field.misc.tagType;
    const oldTags = getPreviousFieldTags( type, edits, field.value ); /*dbug-log*///console.log('   -- oldTags?[%s]', oldTags);
    highlightTagEdits( oldTags, field );
    return false;
}
function highlightTagEdits( oldTags, field ) {
    if ( oldTags === 'NO EDITS' ) { return; }
    showAndHighlightEdits( oldTags, field, field.combo );
}
/* ------------------------ GET TAG EDITS ----------------------------------- */
/**
 * The field's tag-type is used to identify any edited tags for the field.
 * Returns a csv with the original tags, false to indicate there were none
 * originally, or flags that there are no edits to the field's tag-type.
 * @return {string}    CSV of original tags, no-edits-flag, or false
 */
function getPreviousFieldTags( type, edits, current ) {               /*dbug-log*///console.log('   -- getPreviousFieldTags type[%s] edits[%O] current[%O]', type, edits, current);
    const tags = _state( 'getEntityRcrds', ['tag'] );
    if ( type === 'source' ) { return getPreviousSourceTypeTag( edits, tags ); }
    const oldTags = getTagTypeEdits( 'old', type, edits, tags );
    const newTags = getTagTypeEdits( 'new', type, edits, tags );
    const prevTags = getPreEditTags( type, edits, current, tags );
    return prevTags.length ? getTagNames() : newTags.length ? null : 'NO EDITS';

    function getPreEditTags( type, edits, current, tags ) {
        const unchanged = current ? current.filter( ifNotAdded ) : [];/*dbug-log*///console.log('       getPreEditTags current[%O] new[%O] unchanged[%O]', current, newTags, unchanged)
        return oldTags.concat( getFieldTypeTags( unchanged, type, tags ) );

        function ifNotAdded( i ) {
            return !newTags || newTags.indexOf( parseInt( i ) ) === -1;
        }
    }
    function getTagNames() {
        return prevTags.map( i => tags[i].displayName ).join( ', ' );
    }
}
/** "Secondary" is a "source" tag, "Primary" is the default and is not a tag entity. */
function getPreviousSourceTypeTag( edits, tags ) {
    const id = Object.values( tags ).find( t => t.displayName === 'Secondary' ).id;
    if ( edits.new.indexOf( id ) !== -1 ) { return 'Primary'; }
    return edits.old.indexOf( id ) !== -1 ? 'Secondary' : 'NO EDITS';
}
function getTagTypeEdits( state, type, edits, tags ) {
    return edits[state] ? getFieldTypeTags( edits[state], type, tags ) : [];
}
function getFieldTypeTags( tagIds, type, tags ) {
    return tagIds.filter( i => tags[i].type === type );
}
/* ====================== HANDLE AUTHORS ==================================== */
function handleContributorEdits( field, edits ) {                     /*dbug-log*///console.log('-- handleContributorEdits edits[%O] field[%O]', edits, field);
    let removed = '';
    edits.forEach( edit => highlightChangedAuthor( field, edit ) );
    if ( removed !== '' ) { displayRemovedContribs( removed, field.name ); }

    function highlightChangedAuthor( field, edit ) {                  /*dbug-log*///console.log('  -- highlightChangedAuthor ord[%s] edit[%O]', edit.ord, edit);
        const oldId = edit.authId.old;
        const orgVal = oldId ? getRecordName( 'source', oldId ) : 'None';
        if ( !edit.authId.new ) { return addRemovedContrib( orgVal, edit.ord ); }
        showAndHighlightEdits( orgVal, null, field.name+edit.ord );
    }
    function addRemovedContrib( orgVal, ord ) {
        removed += ` [${ orgVal } (${ ord })]`;
    }
}
function displayRemovedContribs( removed, fName ) {
    if ( !removed ) { return; }
    const html = `<div class="prev-value warn"><i>Previous:</i>${ removed }</div>`;
    $( `#${ fName }_f-cntnr` ).append( html );
}
/* ================== HANDLE FIELD PARENT-EDITS ============================= */
function handleParentFieldEdits( field, edits ) {
    const parentFieldNames = {
        CitationTitle: 'Publication',
        Location: 'Country-Region'
    };
    if ( !parentFieldNames[field.name] ) { return; }
    const oldParent = getOldParentName( field.entity, edits );
    if ( !oldParent ) { return; }                                     /*dbug-log*///console.log('-- handleParentFieldEdits edits[%O] field[%O]', edits, field);
    showAndHighlightEdits( oldParent, null, parentFieldNames[field.name] );
}
/* -------------------------- UTIL ------------------------------------------ */
function getOldParentName( entity, edits ) {
    const rcrds = _state( 'getEntityRcrds', [_u.lcfirst( entity )] );
    const newParent = rcrds[rcrds[edits.new].parent];
    const oldParent = rcrds[rcrds[edits.old].parent];               /*dbug-log*///console.log('-- getOldParentName new[%O] old[%O]', newParent, oldParent);
    return newParent.id === oldParent.id ? false : oldParent.displayName;
}
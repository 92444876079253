/**
 * Updates local data after a user-named list is updated: interaction lists or
 * filter sets.
 *
 * Export
 *     updateUserNamedList
 */
import { EntityRecords, IdsByName, OptionObject, SerializedEntity } from '~types';
import { _db } from '~util';
import { EditObj } from './data-entry-sync';
import { EntityStateDates } from '../db-pg-load/db-pg-load-main';
/* ============================ TYPES ======================================= */
type UserNamedEntryResults = {
    name: string;
    entity: UserNamedEntity | string;
    edits: EditObj;
};
type UserNamedEntity = {
    id: number;
    displayName: string;
    type: 'filter' | 'interaction';
    details: string; //stringified FilterSet | InteractionList
};
type UserNamedList = FilterSet | InteractionList;
type FilterSet = {
    direct: object;
    focus: string;
    rebuild: object;
    table: object;
    view: OptionObject;
};
type InteractionList = number[];
type Action = 'create' | 'delete' | 'edit';

/* ======================== UPDATE STORED-LIST ============================== */
export function updateUserNamedList (
    data: UserNamedList | UserNamedEntryResults,
    action: Action
): Promise<void> {                                                  /*perm-log*/console.log( '   --Updating [%s] stored list data[%O]', action, data );
    const params = initUpdateParams( data, action );
    return getAndUpdateStoredList( params )
        .then( trackTimeUpdated.bind( null, 'UserNamed', params.list ) );
}
/* ------------------------ GET LIST PIECES --------------------------------- */
type ListUpdateParams = {
    action: Action;
    edits?: EditObj | null;
    list: UserNamedEntity;
    nameKey: 'savedFilterNames' | 'dataListNames';
    names: IdsByName | { [key: string]: { group: string, value: number; }; };
    rcrdKey: 'savedFilters' | 'dataLists';
    rcrds: EntityRecords;
};
type PartialParams = Omit<ListUpdateParams, 'rcrds' | 'names'>;
function initUpdateParams (
    data: UserNamedList | UserNamedEntryResults,
    action: Action
): PartialParams {
    const list = getListEntity( data, action );
    return {
        action: action,
        edits: ( 'edits' in data ) ? data.edits : null,
        list: list,
        rcrdKey: list.type == 'filter' ? 'savedFilters' : 'dataLists',
        nameKey: list.type == 'filter' ? 'savedFilterNames' : 'dataListNames',
    };
}
function getListEntity (
    data: UserNamedList | UserNamedEntryResults,
    action: Action
): UserNamedEntity {
    if ( action === 'delete' ) return data as unknown as UserNamedEntity;
    return JSON.parse( ( data as UserNamedEntryResults ).entity as string );
}
/* ---------------------- GET AND UPDATE LIST ------------------------------- */
//todo: use compose pattern to init params
function getAndUpdateStoredList ( params: PartialParams ): Promise<void> {
    return getStoredDataParams( params )
        .then( syncListData );
}
function getStoredDataParams ( params: PartialParams ): Promise<ListUpdateParams> {
    return _db.getData( [params.rcrdKey, params.nameKey] )
        .then( addStoredDataToParams );

    function addStoredDataToParams ( stored: any ): ListUpdateParams {
        const dataParams = {
            rcrds: stored[params.rcrdKey] as EntityRecords,
            names: stored[params.nameKey] as IdsByName,
        };
        return Object.assign( params, dataParams ) as ListUpdateParams;
    }
}
/* ---------------------- UPDATE AND STORE ---------------------------------- */
function syncListData ( p: ListUpdateParams ): void {               /*dbug-log*///console.log('syncListData params[%O]', p);
    const syncHander = p.action == 'delete' ? removeListData : updateListData;
    syncHander( p );

    _db.setData( p.rcrdKey, p.rcrds );
    _db.setData( p.nameKey, p.names );
}
function removeListData ( p: ListUpdateParams ): void {
    delete p.rcrds[p.list.id];
    delete p.names[p.list.displayName];
}
function updateListData ( p: ListUpdateParams ): void {
    p.rcrds[p.list.id] = p.list;
    p.names[p.list.displayName] = getUpdatedListData( p );          /*dbug-log*///console.log('--updateListData [%O]', listData);
    if ( p.edits?.displayName ) delete p.names[p.edits.displayName.old];
}
function getUpdatedListData ( p: ListUpdateParams ): number | { group: string, value: number; } {
    if ( p.list.type === 'interaction' ) return p.list.id;
    return { value: p.list.id, group: _db.getFilterListGroupString( p.list ) };
}
/* ----------------------- TRACK TIME-UPDATED ------------------------------- */
function trackTimeUpdated ( entity: string, rcrd: SerializedEntity ): void {
    _db.getData( 'lclDataUpdtdAt' )
        .then( stateObj => {
            ( stateObj as EntityStateDates )[entity] = rcrd.serverUpdatedAt;
            _db.setData( 'lclDataUpdtdAt', stateObj! );
        } );
}
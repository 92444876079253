/**
 * Builds a form to select a taxon with a combobox for the Group and Sub-group,
 * when selecting the taxon group and/or sub-group is available, and one for each
 * rank present in the taxon group, (eg: Bat - Family, Genus, and Species), filled
 * with the taxa at that rank in the group. When one is selected, the remaining
 * boxes are repopulated with related taxa and the 'select' button is enabled.
 * A 'Select Unspecified' button allows selection of a (sub)group's root taxon.
 * @since Refactored 11/2020
 *
 * TOC
 *     INIT
 *         GROUP IDS
 *             GROUP
 *             SUB-GROUP
 *         COMBOS
 *     SELECTED
 */
import { _elems, _form, _state } from '~form';
import * as build from './build-taxon-select.js';
import * as rank from './rank/txn-rank-main.js';
import * as group from './group-fields.js';

/* ========================= INIT =========================================== */
/**
 * //todo2
 * @param  {[type]} field    [description]
 * @param  {[type]} gId      [description]
 * @param  {[type]} sgId     [description]
 * @param  {[type]} onSubmit [description]
 * @param  {String} fLvl     When is this anything other than sub?
 * @return {[type]}          [description]
 */
export function initFieldTaxonSelect(field, gId, sgId, onSubmit, fLvl = 'sub') {/*dbug-log*///console.log('--initFieldTaxonSelect field[%s] gId?[%s sId?[%s] onSubmit?[%O]', field, gId, sgId, onSubmit);
    const ids = getInitGroupIds(field, gId, sgId);
    return build.initTaxonSelectForm(field, ...ids, onSubmit, fLvl)
        .then(() => group.ifParentSelectRemoveSpecies(field));
}
/* ----------------------- GROUP-IDS ---------------------------------------- */
function getInitGroupIds(field, gId, sgId) {
    const prevTxn = getPreviousTxn(field);                          /*dbug-log*///console.log('    -- getInitGroupIds prevTxn?[%O]', prevTxn)
    const group = gId ? gId : getInitGroupId(field, prevTxn);
    const subGroup = sgId ? sgId : getInitSubGroupId(prevTxn);
    return [group, subGroup];
}
function getPreviousTxn(field) {
    const id = $('#sel-'+field).data('selTaxon');
    return id ? _state('getRcrd', ['taxon', id]) : false;
}
/* _____________________ GROUP ______________________________________________ */
function getInitGroupId(field, prevTxn) {
    return prevTxn ? prevTxn.group.id : getGroupDefault(field);
}
function getGroupDefault(field) {
    return field === 'Subject' ? getGroupId('Bat') : getObjectDefault();
}
function getGroupId(type) {
    const groups = _state('getEntityRcrds', ['group']);             /*dbug-log*///console.log('    -- getGroupId type[%s] groups[%O]', type, groups);
    return Object.keys(groups).find(g => groups[g].displayName === type);
}
/**
 * Object defaults to plants when the subject is a bat, and to bats otherwise.
 * @return {int}    Default Object taxon-group ID
 */
function getObjectDefault() {
    const subjGroup = _state('getFieldState', ['top', 'Subject', 'misc']);
    const batGroup = getGroupId('Bat');                             /*dbug-log*///console.log('    -- getObjectDefault subjGroup?[%O] batGroup[%s]', subjGroup, batGroup);
    return !subjGroup || subjGroup.id == batGroup ? getGroupId('Plant') : batGroup;
}
/* _____________________ SUB-GROUP __________________________________________ */
function getInitSubGroupId(prevTxn) {
    return prevTxn ? prevTxn.group.subGroup.id : null;
}
/* ------------------------ COMBOS ------------------------------------------ */
export function initSelectFormCombos(fLvl = 'sub') {
    const events = getSelectComboEvents();
    _elems('initFormCombos', [fLvl, events]);
}
function getSelectComboEvents() {
    return {
        Class: { onChange: onRankChange('Class'), create: create.bind(null, 'Class') },
        Family: { onChange: onRankChange('Family'), create: create.bind(null, 'Family') },
        Genus: { onChange: onRankChange('Genus'), create: create.bind(null, 'Genus') },
        Order: { onChange: onRankChange('Order'), create: create.bind(null, 'Order') },
        Group: { onChange: group.onGroupSelection },
        'Sub-Group': { onChange: group.onSubGroupSelection },
        Species: { onChange: onRankChange('Species'), create: create.bind(null, 'Species') },
    };
}
function onRankChange(rank) {
    return onRankSelection.bind(null, rank);
}
function create(rank, val) {
    return _form('createEntity', [rank, val]);
}
function onRankSelection(rankName, val) {                           /*dbug-log*///console.log('--onRankSelection rank[%s] val[%s]', rankName, val);
    if (val === 'new') { return; } // New taxon being created.
    return rank.onRankSelection(rankName, val);
}
/* ======================== SELECTED ======================================== */
/** Finds the most specific rank with a selection and returns that taxon record. */
export function getSelectedTaxon(aboveRank) {
    let cmbxs = $('#sub-form .selectized').toArray();
    if (!cmbxs[0].id.includes('Species')) { cmbxs = cmbxs.reverse(); }/*dbug-log*///console.log("--getSelectedTaxon above [%s]. cmbxs[%O]", aboveRank, cmbxs);
    const selected = cmbxs.find(el => isSelectedTaxon(aboveRank, el));
    const id = !selected ? getRoot() : $(selected).val();           /*dbug-log*///console.log("     --selected[%s][%O]", !!selected, _state('getRcrd', ['taxon', id]));
    return _state('getRcrd', ['taxon', id]);
}
/** Note: On combo reset, the most specific taxon above the resetRank is selected. */
function isSelectedTaxon(resetRank, elem) {                         /*dbug-log*///console.log('--isSelectedTaxon above?[%s] [%s][%s]', resetRank, $(elem)[0].id, $(elem).val())
    if (!ifIsRankComboElem(elem)) { return false; }
    if (resetRank && isRankChildOfResetRank(resetRank, elem)) { return false; }
    return $(elem).val();
}
function isRankChildOfResetRank(resetRank, elem) {
    const rank = elem.id.split('sel-')[1];                          /*dbug-log*///console.log('--rank[%O]', rank);
    const fLvl = rank === 'Parent' ? 'sub' : _state('getSubFormLvl', ['sub']);
    const allRanks = _state('getFieldState', [fLvl, 'Sub-Group', 'misc']).subRanks;
    const isChild = allRanks.indexOf(rank) <= allRanks.indexOf(resetRank);/*dbug-log*///console.log('     is [%s] sub-rank to [%s]? [%s]', rank, resetRank, isChild);
    return isChild;
}
function ifIsRankComboElem(elem) {
    return elem.id.includes('sel') && !elem.id.includes('Group');
 }
function getRoot() {
    const rootRcrd = _state('getFieldState', ['sub', 'Sub-Group', 'misc']).rcrd;/*dbug-log*///console.log('     getRoot rootRcrd[%O]', rootRcrd);
    return rootRcrd.taxon;
}
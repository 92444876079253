/**
 * Builds a check box that allows the field data to be persisted through multiple
 * new interactions.
 *
 * Export
 *     initPersistFieldPin
 *
 * TOC
 *     INIT CHECKBOX
 *         BUILD CHECKBOX
 *     ON CLICK
 */
import { _el, _u } from '~util';
/* ======================= INIT CHECKBOX ==================================== */
/**
 * Returns checkbox that triggers field-data persitsance through form-submit.
 * @param  {str}    fName   Field name
 */
export default function initPersistFieldPin ( fName ) {                        /*dbug-log*///console.log('getFormFieldPin [%s]', fName);
    const pin = buildPinElem( fName );
    handledRelatedFieldPins( pin, fName );
    return pin;
}
/* ---------------------------- BUILD CHECKBOX ------------------------------ */
function buildPinElem ( fName ) {
    const attr = { type: 'checkbox', id: fName + '_pin', class: 'top-pin' };
    const pin = _el.getElem( 'input', attr );
    _u.addEnterKeypressClick( pin );
    return pin;
}
function handledRelatedFieldPins ( pin, fName ) {
    const relFields = ['CitationTitle', 'Country-Region', 'Location', 'Publication'];
    if ( relFields.indexOf( fName ) !== -1 ) { $( pin ).on( 'click', checkConnectedFieldPin ); }
}
/* ========================== ON CLICK ====================================== */
/**
 * When a dependent field is pinned, the connected field will also be pinned.
 * If the connected field is unpinned, the dependant field is as well.
 */
function checkConnectedFieldPin () { //move to interaction confg
    const fName = this.id.split( "_pin" )[0];
    const params = {
        'CitationTitle': { checked: true, relField: 'Publication' },
        'Country-Region': { checked: false, relField: 'Location' },
        'Location': { checked: true, relField: 'Country-Region' },
        'Publication': { checked: false, relField: 'CitationTitle' },
    };
    checkFieldPins( this, params[fName].checked, params[fName].relField );
}
function checkFieldPins ( curPin, checkState, relField ) {
    if ( curPin.checked === checkState ) {
        if ( $( '#' + relField + '_pin' )[0].checked === checkState ) { return; }
        $( '#' + relField + '_pin' )[0].checked = checkState;
    }
}
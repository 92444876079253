/**
 * Handles preparing pending-data for local-storage.
 *
 * Export
 *     processPending
 *     syncPendingData
 *
 * TOC
 *     STORED PENDING-DATA
 *         PENDING RECORDS
 *         SYNC PENDING-DATA
 *     HANDLE QUARANTINED DATA
 *         APPROVED
 *         LOCKED
 *         PENDING
 *         REJECTED
 */
import { _db, _u } from '~util';
import { _data } from '~form';
import { _ui } from '~db';

import { EntityRecords, SerializedEntity, objectValues } from '~types';
import { DataEntryResults, FormConfig } from '../data-entry/data-entry-sync';
import { updateLocalData } from '../db-sync-main';
import { initQuarantined } from './init-quarantined';
import { updateQuarantined } from './update-quarantined';

export type PendingDataProperty = PendingDataEntity | NormalizedPendingData | MultiFieldPendingData;
export type MultiFieldPendingData = { [key: number]: NormalizedPendingData; };
export type PendingDataEntity = SerializedEntity & { stage: { id: number; name: string; }; };
export type NormalizedPendingData = { id: number; stage: string; };

/** Handles processing pending-data for local-storage. */
export function processPending (
    data: DataEntryResults | PendingDataEntity,
    fConfig?: FormConfig
): Promise<void> {                                                  /*dbug-log*///console.log('       +--processPending data[%O] fConfig[%O]', data, fConfig);
    const pRcrd = getPendingData( data );
    return processPendingRecord( pRcrd, data, fConfig );
}
function getPendingData ( data: DataEntryResults | PendingDataEntity ): PendingDataEntity {
    const pRcrd = getPendingDataRecord( data );
    if ( typeof pRcrd.data === 'string' ) { pRcrd.data = JSON.parse( pRcrd.data ); }
    return pRcrd;
}
function getPendingDataRecord ( data: DataEntryResults | PendingDataEntity ) {
    const pRcrd = ( 'stage' in data ? data : data.pending ) as unknown as string;
    _db.parseData( pRcrd );
    return pRcrd as unknown as PendingDataEntity;
}
function processPendingRecord (
    pRcrd: PendingDataEntity,
    data: DataEntryResults | PendingDataEntity,
    fConfig: FormConfig | undefined,
): Promise<void> {
    const PendingData = _db.getEntities( 'pending' );
    const stage = pRcrd.stage.name;                                 /*dbug-log*///console.log('           --processPendingRecord pRcrd[%s][%O] qId?[%s] PendingData[%O] fConfig[%O]', stage, pRcrd, (pRcrd.data.quarantined ? pRcrd.data.quarantined.coreId : null), PendingData, fConfig);
    if ( stage === 'Completed' ) { handleCompletedRecord( pRcrd ); }
    updateStoredPendingData( stage, pRcrd, PendingData );
    return Promise.resolve( handlePendingDataEntry( stage, PendingData, data, fConfig ) );
}
/* --------------------- SYNC PENDING-DATA ---------------------------------- */
/** Gets updated PendingData and stores locally. */
export function syncPendingData () {
    return _db.initMemoryDataObj()
        .then( () => _db.getAndSetData( 'pending' ) )
        .then( () => _db.setUpdatedDataInLocalDbAndClearTempMemory() )
        .then( () => _ui( 'loadPendingDataStats' ) ); //async
}
/* ======================= HANDLE QUARANTINED DATA ========================== */
/**
 * Handles stage-specific processing.
 * TODO: During pending-data sync, only process data with updates
 */
function handlePendingDataEntry (
    stage: string,
    PendingData: EntityRecords,
    data: DataEntryResults | PendingDataEntity,
    fConfig: FormConfig | undefined,
): Promise<void> | void {                                           /*dbug-log*///console.log('           --handlePendingDataEntry stage[%s] data[%O] fConfig?[%O]', stage, data, fConfig);
    const handlers = {
        Approved: addToLocalStorage,
        Locked: onUpdateHandleLockedContributorData,
        Pending: handleQuarantine,
        Quarantine: handleQuarantine,
        Returned: ifReprocessAddToStorage,
        Rejected: onUpdateRemoveQuarantined
    } as const;
    stage = fConfig ? _data( 'getSubmittedStage', [stage, fConfig] ) : stage;
    const handler = handlers[stage as keyof typeof handlers];       /*dbug-log*///console.log( '          --submitted stage[%s]', stage );
    return handler ? handler( data, fConfig, PendingData ) : Promise.resolve();
}
/* ------------------------ APPROVED ---------------------------------------- */
function addToLocalStorage (
    data: DataEntryResults | PendingDataEntity,
    fConfig: FormConfig | undefined,
    _1: EntityRecords
): Promise<void> | void {
    if ( !fConfig ) return; // Added into local storage during standard page-sync
    data.coreEntity.pending = { id: data.pending.id, stage: data.pending.stage.name };
    return updateLocalData( data as DataEntryResults );
}
/* ------------------------ LOCKED ------------------------------------------ */
/** Handles readding quarantined data in "locked" stage on local-storage reset. */
function onUpdateHandleLockedContributorData (
    data: DataEntryResults | PendingDataEntity,
    fConfig: FormConfig | undefined,
    PendingData: EntityRecords
): Promise<void> | void {
    const stage = ( data as PendingDataEntity ).data.stage.name as string;
    return handlePendingDataEntry( stage, PendingData, data, fConfig );
}
/* ------------------------ PENDING ----------------------------------------- */
/**
 * Entity-data pending approval are quarantined locally.
 *     - Quarantined IDs are randomly altered to ensure unique values
 *     - Relationships with quarantined-entities are set in the serialized
 *         entity-data returned from the server.
 */
function handleQuarantine (
    data: DataEntryResults | PendingDataEntity,
    fConfig: FormConfig | undefined,
    PendingData: EntityRecords
): Promise<void> {                                                  /*dbug-log*///console.log('--handleQuarantine pRcrd[%O] fConfig[%O]', data, fConfig);
    return Promise.resolve( getQuarantinedDataEntry( data, fConfig, PendingData ) )
        .then( updateLocalData );
}
function getQuarantinedDataEntry (
    data: DataEntryResults | PendingDataEntity,
    fConfig: FormConfig | undefined,
    PendingData: EntityRecords
): Promise<DataEntryResults> | DataEntryResults {
    const results = fConfig ?
        initQuarantined( data as DataEntryResults, fConfig, PendingData ) :
        updateQuarantined( data as PendingDataEntity, PendingData );
    return results as Promise<DataEntryResults>;
}
/** Handles readding "returned" quarantined data during pending-data sync. */
function ifReprocessAddToStorage (
    data: DataEntryResults | PendingDataEntity,
    fConfig: FormConfig | undefined,
    PendingData: EntityRecords
): Promise<void> | void {
    if ( fConfig ) return;  // Added into local storage during standard page-sync
    return handleQuarantine( data, fConfig, PendingData );
}
/* ------------------------ REJECTED ---------------------------------------- */
function onUpdateRemoveQuarantined (
    _1: DataEntryResults | PendingDataEntity,
    _2: FormConfig | undefined,
    _3: EntityRecords
) {
    //TODO2 -> build delete functionality and utilize that
}
/* ======================= STORED PENDING-DATA ============================== */
/**
 * Sets flag tracking presence of quarantined-data in local storage. Updates the
 * pending-data counts for the current user.
 */
function updateStoredPendingData (
    stage: string,
    pRcrd: PendingDataEntity,
    PendingData: EntityRecords
): void {
    storePendingDataRecord( pRcrd, PendingData );
    updateQuarantinedDataFlag( stage );
}
/* ---------------------- HANDLE COMPLETED ---------------------------------- */
/**
 * Flags record completed and replaces record stage with the final review stage:
 * either Approved or Rejected.
 */
function handleCompletedRecord ( pRcrd: PendingDataEntity ): void {  /*dbug-log*///console.log('   --handleCompletedRecord pRcrd[%O]', pRcrd);
    const final = getFinalReviewResult( pRcrd );
    pRcrd.completed = true;
    pRcrd.stage.id = final.id;
    pRcrd.stage.name = final.passiveForm;
}
function getFinalReviewResult ( pRcrd: PendingDataEntity ): SerializedEntity {
    const stages = _db.getEntities( 'pendingStages' );
    const stage = objectValues( stages ).find( s => s.activeForm === pRcrd.data.stage.name );
    if ( !stage ) throw Error( "Final review stage not found" );
    return stage;
}
/* ------------------------ PENDING RECORDS --------------------------------- */
/** After data-entry, stored pending-data is updated. */
function storePendingDataRecord (
    pRcrd: PendingDataEntity,
    PendingData: EntityRecords
): void {
    PendingData[pRcrd.id] = pRcrd;                                  /*dbug-log*///console.log('--storePendingDataRecord pRcrd[%s][%O] PendingData[%O]', pRcrd.stage.name, pRcrd, PendingData);
    updateAllStoredPendingData( PendingData );
}
/** Adds updated PendingData records to local storage */
function updateAllStoredPendingData ( PendingData: EntityRecords ): void {
    _db.storeData( 'pending', PendingData ); // Must also get set in process-memory for when all process-data flushes
    _db.setData( 'pending', PendingData ); // must be flushed before the form tries to update it's stored records (todo2... refactor)
}
/**
 * Flag indicates user-specific quarantined-data has been added to local storage.
 * If the user changes, the database is redownloded to a fresh state.
 */
function updateQuarantinedDataFlag ( stage: string ): void {
    if ( stage !== 'Pending' ) return;
    _db.storeData( 'hasQuarantined', true );
}
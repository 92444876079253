/**
 * Entry point for entity-specific code.
 *
 * Note - required form methods: initCreateForm, initEditForm
 *
 * TOC
 *     INIT FORM
 *         IF OPEN SUB-FORM ISSUE
 *         FORM COMBOS
 *         EDIT FORMS
 *     ENTITY FACADE
 *         INTERACTION
 *         LOCATION
 *         TAXON
 *         SOURCE TYPES
 *             AUTHOR
 *             CITATION
 */
import { _u } from '~util';
import { _elems } from '~form';
import * as int from './interaction/int-form-main.js';
import * as loc from './location/location-form-main.js';
import * as src from './source/src-form-main.js';
import * as txn from './taxon/txn-form-main.js';

const forms = {
    int: { ent: ['interaction', 'object', 'subject'], mod: int },
    loc: { ent: ['location'], mod: loc },
    src: { ent: ['author', 'citation', 'editor', 'publication', 'publisher', 'source'], mod: src },
    txn: { ent: ['taxon', 'species', 'genus', 'family', 'order', 'class'], mod: txn }
};
function getEntityModule( entity ) {
    const lc = _u.lcfirst( entity );
    const key = Object.keys( forms ).find( m => forms[m].ent.indexOf( lc ) !== -1 );/*dbug-log*///console.log('--getEntityModule entity[%s] key[%s]', entity, key);
    return forms[key].mod;
}
export function clearEntityFormMemory( entity ) {
    const map = {
        interaction: int.clearFormMemory
    };
    if ( !map[entity] ) { return; }
    map[entity]();
}
/* =================== INIT FORM ============================================ */
export function createEntity( entity, val ) {                         /*dbug-log*///console.log('--createEntity  entity[%s] text?[%s]', entity, val);
    const createForm = getEntityModule( entity ).initCreateForm( ...arguments );
    if ( !createForm ) { return _elems( 'toggleWaitOverlay', [false] ); } //Alert shown to user
    return createForm.then( () => _elems( 'toggleWaitOverlay', [false] ) );
}
/* ------------------------- EDIT FORM -------------------------------------- */
export function editEntity( entity, id ) {
    const mod = getEntityModule( entity );
    return mod.initEditForm( ...arguments )
        .then( () => fillEntitySidePanel( entity, id ) )
        .then( () => _elems( 'fillComplexFormFields', ['top'] ) )
        .then( () => _elems( 'checkReqFieldsAndToggleSubmitBttn', ['top'] ) )
        .then( () => finishSrcFieldLoad( entity, 'top' ) )
        .then( () => _elems( 'toggleWaitOverlay', [false] ) );
}
function fillEntitySidePanel( entity, id ) {
    if ( entity === 'Interaction' ) { return; }
    _elems( 'fillEditEntitySidePanel', [id] );
}
/* ----------------------- REVIEW DATA -------------------------------------- */
export function reviewEntity( pRcrd ) {                               /*dbug-log*///console.log('--reviewEntity  entity[%s] data[%O]', pRcrd.entity, pRcrd);
    const mod = getEntityModule( pRcrd.entity );
    return mod.initReviewForm( pRcrd );
}
/* =================== ENTITY FACADE ======================================== */
/** ------------------------ INTERACTION ------------------------------------ */
export function fillCitationCombo() {
    int.fillCitationCombo( ...arguments );
}
export function selectIntLoc( id ) {
    int.selectLoc( id );
}
export function enableCountryRegionField() {
    int.enableCountryRegionField();
}
export function clearInteractionDate() {
    return int.clearInteractionDate( ...arguments );
}
/** --------------------------- LOCATION ------------------------------------ */
export function addMapToLocForm( elem, mapType ) {
    return loc.addMapToLocForm( elem, mapType );
}
export function focusParentAndShowChildLocs( mapType, val ) {
    return loc.focusParentAndShowChildLocs( mapType, val );
}
export function autofillCoordinateFields() {
    loc.autofillCoordinateFields( ...arguments );
}
/** ------------------------ TAXON ------------------------------------------ */
export function initFieldTaxonSelect() {
    return txn.initFieldTaxonSelect( ...arguments );
}
export function getSelectedTaxon() {
    return txn.getSelectedTaxon( ...arguments );
}
export function getRankName () {
    return txn.getRankName( ...arguments );
}
export function selectFieldTaxon() {
    return int.selectFieldTaxon( ...arguments );
}
export function onTaxonFieldSelection() {
    return int.onTaxonFieldSelection( ...arguments );
}
export function enableTaxonFieldCombos() {
    return int.enableTaxonFieldCombos( ...arguments );
}
export function buildOptAndUpdateCombo() {
    return int.buildOptAndUpdateCombo( ...arguments );
}
/** ------------------------ SOURCE TYPES ----------------------------------- */
export function finishSrcFieldLoad() {
    return src.finishSrcFieldLoad( ...arguments );
}
/** ---------------- AUTHOR ------------------- */
export function onAuthAndEdSelection() {
    return src.onAuthAndEdSelection( ...arguments );
}

export function selectExistingAuthsOrEds() {
    return src.selectExistingAuthsOrEds( ...arguments );
}
export function rmvExtraMultiFields() {
    return src.rmvExtraMultiFields( ...arguments );
}
/** ---------- CITATION ------------------------- */
export function clearCitationCombo() {
    int.clearCitationCombo( ...arguments );
}
export function handleCitText( fLvl ) {
    src.handleCitText( fLvl );
}
export function setFinalCitation() {
    src.setFinalCitation( ...arguments );
}
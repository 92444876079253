/**
 * Manages data relevant to the data-review processs.
 */
import * as log from './misc/log-review-notes.js';
import * as pred from './misc/pending-predicates.js';
import * as get from './prepare/get-pending-data.js';
import * as prep from './prepare/prepare-record.js';
import * as select from './submit/after-pending-submit.js';
import * as submit from './submit/submit-pending-data.js';
/* ========================== GET =========================================== */
export function clearSkippedRecordsMemory() {
    get.clearSkippedRecordsMemory(...arguments);
}
export function getFirstPendingDataSet() {
    return get.getFirstPendingDataSet(...arguments);
}
export function getPendingAction() {
    return get.getPendingAction(...arguments)
}
export function ifPendingSubDataGetFirstGroup() {
    return get.ifPendingSubDataGetFirstGroup(...arguments);
}
export function updatePendingFields() {
    prep.updatePendingFields(...arguments);
}
/** ----------------------- PREDICATES ------------------------------------- */
export function isApprovedOrRejected() {
    return pred.isApprovedOrRejected(...arguments);
}
export function isFieldDataPending() {
    return pred.isFieldDataPending(...arguments);
}
export function isPendingAction() {
    return pred.isPendingAction(...arguments);
}
export function isPossiblyQuarantined() {
    return pred.isPossiblyQuarantined(...arguments);
}
export function isReadyToReview() {
    return pred.isReadyToReview(...arguments);
}
export function ifValueQuarantined() {
    return pred.ifValueQuarantined(...arguments);
}
/* ========================== LOG =========================================== */
export function addToReviewLog() {
    log.addToReviewLog(...arguments);
}
export function prepareAndReturnReviewLog() {
    return log.prepareAndReturnReviewLog(...arguments);
}
export function pushUpdatedLogs() {
    log.pushUpdatedLogs(...arguments);
}
/* ========================== SUBMIT ======================================== */
export function getSubmittedStage() {
    return select.getSubmittedStage(...arguments);
}
export function afterPendingDataSubmitted() {
    return select.afterPendingDataSubmitted(...arguments);
}
export function pushPendingData() {
    return submit.pushPendingData(...arguments);
}
export function submitFormPendingData() {
    submit.submitFormPendingData(...arguments);
}
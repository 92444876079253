/**
 * Builds a standard form-field.
 *
 * Export
 *     getFieldElems
 *
 * TOC
 *     CONTAINER
 *     FIELD
 *         LABEL
 *     VALIDATION
 *         INPUT CHAR-COUNT
 */
import { _el, _u } from '~util';
/**
 * @typedef {Object} FieldConfig  - Field configuration and input element.
 * @prop  {String}   [class] - Field style-class
 * @prop  {String}   [count] - Present for fields with multiple inputs
 * @prop  {Object}   [flow] - Flex-direction class suffix.
 * @prop  {String}   [group] - Used for styling and intro-tutorials
 * @prop  {Str}      [id] - Set in config or to name in elem-build-main
 * @prop  {Str|Obj}  [info] - Text used for tooltip and|or intro-tutorial.
 * @prop  {Node|Ary} input - Field input element(s) [required]
 * @prop  {String}   label - Text to use for label. If false, no label is built.
 * @prop  {String}   name - Field name [required] Will be used for IDs
 * @prop  {Boolean}  [required] - True if field is required in a containing form.
 * @prop  {String}   [type] - Flags edge-case field types: 'multiSelect'
 * //TODO3: Move below into input-builder validation
 * @prop  {Object}   [val] - Contains field-validation params
 * @prop  {Object}   [val.charLimits] - If present, shows user mix/max char limitations.
 * @prop  {Object}   [val.charLimits.max] - Max-character count for an input field.
 * @prop  {Object}   [val.charLimits.min] - Min-character count for an input field.
 * @prop {string|object} [value] - Field value.
 */
export type FieldConfig = {
    class?: string;
    count?: number;
    flow?: string,
    group?: 'top' | 'sub' | 'sub2';
    id?: string;
    info?: { intro?: string, tooltip: string; };
    input: HTMLElement | HTMLElement[];
    label?: string | false;
    name: string;
    required?: boolean;
    type?: string;
    val?: {
        charLimits?: {
            min: number,
            max: number,
            onInvalid: () => void,
            onValid: () => void;
        };
    };
    value?: string | { [key: number]: number; } | { text: string, value: string; };
};

let f: FieldConfig;
/**
 * Builds a standard form-field.
 *
 * @export
 * @param {FieldConfig} config - Field configuration and input element.
 * @return {HTMLDivElement}   containerDiv->(alertDiv, fieldDiv->(label, input))
 */
export function getFieldElems ( fConfig: FieldConfig, fElems?: HTMLDivElement ): HTMLDivElement {
    f = fConfig;
    if ( !f.id ) f.id = f.name;
    return buildField( fElems );
}
function buildField ( fElems?: HTMLDivElement ): HTMLDivElement {
    const container = getFieldContainer();
    const alertDiv = _el.getElem( 'div', { id: f.id + '_alert' } );
    const fieldElems = fElems ? fElems : getFieldLabelAndInput();
    $( container ).append( [alertDiv, fieldElems] );
    return container;
}
/* ======================== CONTAINER ======================================= */
function getFieldContainer (): HTMLDivElement {
    const sfx = _el.isMultiField( f ) ? '_f-cntnr' : '_f';
    const attr = { class: getContainerClass( sfx ), id: f.id + sfx };
    return _el.getElem( 'div', attr ) as HTMLDivElement;
}
/** Returns the style classes for the field container. */
function getContainerClass ( sfx: string ): string {
    const classes: string[] = [];
    if ( f.group ) classes.push( f.group + sfx );
    if ( f.class && f.class.includes( 'invis' ) ) classes.push( 'invis' );
    return classes.join( ' ' );
}
function getFieldLabelAndInput (): HTMLDivElement {
    const container = buildFieldContainer();
    const elems = [buildFieldLabel(), ...getFormattedInputArray()].filter( el => el );
    setValidationEvents();
    $( container ).append( elems as HTMLElement[] );
    return container;
}
function getFormattedInputArray (): HTMLElement[] {
    if ( !Array.isArray( f.input ) ) return [f.input];
    const dash = _el.getElem( 'span', { text: ' – ', styles: { margin: '0 .5em' } } );
    f.input.splice( 1, 0, dash );
    return f.input;
}
/* ========================= FIELD ========================================== */
function buildFieldContainer () {
    const c = f.type && f.type.includes( 'multi' ) ?
        'cntnr flex-col' : `field-elems flex-${ f.flow ?? 'row' }`;
    const attr = { class: c, title: getInfoTxt() };
    const container = _el.getElem( 'div', attr ) as HTMLDivElement;
    if ( f.info ) addTutorialDataAttr( container );
    return container;
}
function addTutorialDataAttr ( container: HTMLDivElement ): void {
    $( container ).addClass( f.group + '-intro' )
        .attr( {
            'data-intro': getInfoTxt( 'intro' ),
            'data-title': f.name
        } );
}
function getInfoTxt ( key = 'tooltip' ): string {
    if ( !f.info ) return '';
    return key === 'intro' && f.info.intro ? f.info.intro : ( f.info.tooltip ?? 'Error' );
}
/* -------------------------- LABEL ----------------------------------------- */
function buildFieldLabel (): HTMLLabelElement | void {  //todo: add "for" attribute to connect with input elems
    if ( f.label === false ) return;
    f.label = getFieldName();
    const attr = {
        class: getLabelClass(),
        id: f.id + '_lbl',
        text: f.label,
    };
    return _el.getElem( 'label', attr ) as HTMLLabelElement;
}
function getLabelClass (): string {
    const group = f.group ? `${ f.group }_lbl` : '';
    return group + ( f.required ? ' required' : '' );
}
function getFieldName (): string {
    return f.label || _u.addSpaceBetweenCamelCaseUnlessHyphen( f.name );
}
/* =========================== VALIDATION =================================== */
// Data-entry form validation handled in form module. TODO3: MERGE
function setValidationEvents (): void {
    if ( !f.val ) return;
    const map = {
        charLimits: setCharLimitsAlertEvent
    };
    Object.keys( f.val ).forEach( type => map[type as keyof typeof map]() );
}
/* --------------------- INPUT CHAR-COUNT ----------------------------------- */
type CharValAlertParams = {
    field: string;
    max: number;
    min: number;
    onInvalid: () => void;
    onValid: () => void;
};
function setCharLimitsAlertEvent (): void {
    const val: CharValAlertParams = {
        field: f.name,
        min: f.val?.charLimits?.min ?? 0,
        max: f.val?.charLimits?.max ?? 250,
        onInvalid: f.val?.charLimits?.onInvalid ?? ( () => {} ),
        onValid: f.val?.charLimits?.onValid ?? ( () => {} )
    };
    addKeyUpEventListener( val, f.input as HTMLInputElement );
}
function addKeyUpEventListener ( val: CharValAlertParams, input: HTMLInputElement ) {
    $( input ).on( 'keyup', updateCharLimits.bind( null, val ) );
}
/**
 * Handles input char-count validation on keyup events.
 * @param {CharValAlertParams} val
 * @param {KeyboardEvent} e
 */
function updateCharLimits ( val: CharValAlertParams, e: Event ): void {
    const inputLength = ( e.target as HTMLInputElement ).value.length;
    $( `#${ val.field }_alert` ).text( getCharAlert( inputLength, val.min, val.max ) );
    callValidationHandlers( val, inputLength );
}
function getCharAlert ( inputLength: number, min: number, max: number ): string {
    if ( inputLength < min ) {
        return `${ inputLength } characters (${ min } min)`;
    } else {
        return `${ inputLength } characters (${ max } max)`;
    }
}
function callValidationHandlers ( val: CharValAlertParams, inputLength: number ): void {
    if ( inputLength < val.min ) {
        $( `#${ val.field }_alert` ).addClass( 'alert-active' ); //Flags the element as (in)valid
        val.onInvalid();
    } else {
        $( `#${ val.field }_alert` ).removeClass( 'alert-active' ); //Flags the element as valid
        val.onValid();
    }
}
/* =========================== PREDICATES =================================== */
/**
 * True if field has dynamic field-inputs. Can be called from multiple places
 * with various field states.
 * @param  {object}  field  Field-config
 * @return {Boolean}
 */
export function isMultiField ( field: FieldConfig ): boolean {
    if ( !field.value ) return isMultiFieldType( field );
    return _u.isObj( field.value ) && hasNumberKeys( field.value );
}
function isMultiFieldType ( field: FieldConfig ): boolean {
    return !!( field.type && field.type.includes( 'multi' ) );
}
/** Multi-field values are keyed by field order. */
function hasNumberKeys ( valObj: object ): boolean {
    const keys = Object.keys( valObj );
    return !keys.length || Object.keys( valObj ).every( v => !isNaN( parseInt( v ) ) );
}
/**
 * Returns the field's value to use during server handling. Replaces quarantined
 * data with temporary data as needed.
 *
 * EXPORT
 *     getFieldValue
 *
 * TOC
 *     HANDLE RELATIONAL DATA
 *         GET ENTITY ID
 *         REPLACE QUARANTINED
 */
import { _el, _u } from '~util';
import { _data, _state } from '~form';

export function getFieldValue( fConfg ) {                             /*dbug-log*///console.log('   +-- getFieldValue fConfg[%s][%O]', fConfg.name, fConfg);
    if ( _data( 'isPossiblyQuarantined', [fConfg] ) ) return getFieldIdValue( fConfg );
    if ( _u.isObj( fConfg.value ) ) return fConfg.value.value; //combos and multiFields
    return fConfg.value;
}
/** =================== HANDLE RELATIONAL DATA ============================== */
function getFieldIdValue( fConfg ) {
    if ( _el.isMultiField( fConfg ) ) { return getMultiSelectVal( fConfg ); }
    const val = _u.isObj( fConfg.value ) ? fConfg.value.value : fConfg.value;
    return getEntityId( fConfg, val );
}
/**
 * Returns false if empty to trigger validation, otherwise returns value object.
 * Quarantined ids are replaced with temporary ids for contributors.
 */
function getMultiSelectVal( fConfg ) {                                /*dbug-log*///console.log('           -- getMultiSelectVal fConfg[%s][%O]', fConfg.name, fConfg);
    const sanitized = {};
    const ids = Object.values( fConfg.value );
    $( ids ).each( ( i, v ) => {
        const pushId = getEntityId( fConfg, v, ids ).toString();      /*dbug-log*///console.log('               -- ord[%s] v[%s] pushId[%s]', i, v, pushId);
        sanitized[i+1] = pushId;
        ids.push( pushId );
    } );
    return !ids.length ? false : sanitized;
}
/* ------------------------- GET ENTITY ID ---------------------------------- */
/**
 * Ensures the field-data is valid for the submit process. When field-data is
 * quarantined, temporary references to existing data are necessary.
 * @param  {obj}  fConfg    Field configuration
 * @param  {int}  id        ID of referenced entity
 * @param  {ary}  blacklist Entities referenced in record already.
 * @return {int}            Validated field-value
 */
function getEntityId( fConfg, id, blacklist ) {                       /*dbug-log*///console.log('       -- getEntityId id[%s] fConfg[%O] blacklist?[%O]', id, fConfg, blacklist);
    if ( isNaN( id ) ) { return id; }
    if ( ifEntityIdSet( fConfg ) ) { return fConfg.pending.entityId; }
    const [core, detail] = _state( 'getFieldClassData', [fConfg] );   /*dbug-log*///console.log('         -- classData core[%s] detail?[%s]', core, detail);
    const rcrdId = id ? getValidIdFromRecord( core, id ) : null;
    return rcrdId ? rcrdId : getTempId( core, detail, blacklist );
}
/** If pending-data is connected to an existing entity. */
function ifEntityIdSet( fConfg ) {
    return fConfg.pending && fConfg.pending.entityId;
}
function getValidIdFromRecord( core, id ) {
    const rcrd = _state( 'getRcrd', [core, id] );                   /*dbug-log*///console.log( '             -- getValidIdFromRecord rcrd[%O]', rcrd );
    return !rcrd.pending ? id : ( core === 'taxon' ? rcrd.group.id : false );
}
/** --------------------- REPLACE QUARANTINED ------------------------------- */
/**
 * Temporary references to existing data are necessary during the submit process.
 * Quarantined data will be readded before it is stored in local storage.
 * @param  {string}  cEntity   Core Entity
 * @param  {string}  dEntity   Detail Entity
 * @param  {array}   blacklist Entities referenced in record already.
 * @return {integer}           Entity ID to push to server
 */
function getTempId( cEntity, dEntity, blacklist = [] ) {              /*dbug-log*///console.log('-- getTempId cEntity[%s] dEntity?[%s] blacklist?[%O]', cEntity, dEntity, blacklist);
    const entity = dEntity ? dEntity : cEntity;
    const rcrds = _state( 'getEntityRcrds', [entity] );
    const tEnt = Object.values( rcrds ).find( ifValidTempEntity )
    return !dEntity ? tEnt.id : tEnt[cEntity];

    function ifValidTempEntity( rcrd ) {
        const id = !dEntity ? rcrd.id : rcrd[cEntity];
        return !rcrd.pending && blacklist.indexOf( id.toString() ) === -1;
    }
}
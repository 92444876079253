/**
 * Returns row with a checkbox that will toggle optional form fields on the left
 * and, on the right, the submit/cancel buttons and, in review forms, an actions combo.
 *
 * Export
 *     getFormFooter
 *
 * TOC
 *     LEFT-ALIGNED ELEMS
 *         DELETE BUTTON
 *     RIGHT-ALIGNED ELEMS
 *         SUBMIT AND CANCEL BUTTONS
 */
import { _elems, _state, submitForm } from '~form';
import { _el } from '~util';

export function getFormFooter ( entity, fLvl, action ) {               /*dbug-log*///console.log("+--getFormFooter [%s][%s][%s]", entity, fLvl, action);
    const cntnr = getFooterRowContainer();
    $( cntnr ).append( ...buildFooterElems( entity, fLvl, action ) );
    return cntnr;
}
function getFooterRowContainer () {
    return _el.getElem( 'div', { class: 'flex-row bttn-cntnr' } );
}
function buildFooterElems ( entity, fLvl, action ) {
    const lElems = getLeftAlignedFooterElems( entity, fLvl, action );
    const spacer = $( '<div></div>' ).css( "flex-grow", 2 );
    const rElems = _elems( 'buildSubmitAndCancelBttns', [fLvl, action] );
    return [lElems, spacer, rElems];
}
/** Returns a (submit or cancel) button for the form fLvl. */
function buildFormButton ( actn, lvl, val ) {
    const attr = { id: lvl + '-' + actn, type: 'button', value: val };
    return _el.getElem( 'input', attr );
}
/* ================== LEFT-ALIGNED ELEMS ==================================== */
function getLeftAlignedFooterElems ( entity, fLvl, action ) {
    return action === 'edit' ? getDeleteBttn( fLvl ) :
        _elems( 'ifMutlipleDisplaysGetToggle', [entity, fLvl] );
}
/* --------------------- DELETE BUTTON -------------------------------------- */
function getDeleteBttn ( fLvl ) {
    if ( fLvl !== 'top' ) { return null; }
    const bttn = buildFormButton( 'delete', 'top', 'Delete' );
    $( bttn ).on( 'click', confirmDelete );
    return bttn;
}
function confirmDelete () {                                          /*dbug-log*///console.log('    -- confirmDelete');
    _el.showSaveModal( buildModalConfig(), getModalButton(), getModalEvents() );
    $( `#top-submit` ).css( { opacity: .5, cursor: 'not-allowed' } );
}
function buildModalConfig () {
    return {
        html: 'Are you sure the data are ready to be deleted?',
        position: 'right',
        selector: `#top-delete`,
        title: 'Delete Entity'
    };
}
function getModalButton() {
    return {
        onConfirm: () => {
            _state( 'setFormState', ['top', 'action', 'delete'] );
            submitForm( 'top' );
        }
    };
}
function getModalEvents() {
    return {
        onexit: () => _elems( 'checkReqFieldsAndToggleSubmitBttn', ['top'] ),
    };
}
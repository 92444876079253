/**
 * On form submit success, the new and edited data is updated in the local database.
 *
 * Export
 *     handleLocalDataUpdate
 *     syncLocalDataAfterDataEntry
 *     updateLocalData
 *
 * TOC
 *     UPDATE DATA AFTER FORM SUBMIT
 *         TRACK LOCAL-PROP EDITS
 *     UPDATE ENTITY DATA
 */
import { _db, _u } from '~util';
import * as sync from '../db-sync-main';
import ifSourceDataEditedUpdatedCitations from './citation-sync';
import { trackEditsToLocalProps } from './track-local-data-edits';
import { CoreEntity, SerializedEntity, DetailEntity } from '~types';
import { PendingDataProperty, NormalizedPendingData, MultiFieldPendingData } from '../pending/process-pending';
/* ============================ TYPES ======================================= */
/**
 * @param  core          Entity classname
 * @param  coreEntity    JSON Serialized record
 * @param  coreId        Record ID
 * @param  detail        Entity classname
 * @param  detailEntity  JSON Serialized record
 * @param  detailId      Record ID
 * @param  name          Entity display-name, if property exists
 * @param  pending       JSON Serialized PendingData
 */
export type DataEntryResults = {
    core: CoreEntity;
    coreEdits?: EditObj;
    coreEntity: SerializedEntity,
    coreId?: number;        //Added during quarantined-data init
    detail?: DetailEntity;
    detailEdits?: EditObj;
    detailEntity: SerializedEntity | false;
    detailId?: number;      //Added during quarantined-data init
    name?: string;
    pending?: PendingDataProperty;
};
export type EditObj = {
    [prop: string]: EditedIds;
};
export type EditedIds = {
    new: number,  //This describes the relational-data. Flat-data updated is also stored in this format.
    old: number;
};
export type FormConfig = {
    fields: {
        [name: string]: FormFieldConfig;
    };
};
export type FormFieldConfig = {
    changed?: boolean;  // true when field changeed during data-review./
    pending?: NormalizedPendingData | MultiFieldPendingData;
    replacedPending?: NormalizedPendingData | MultiFieldPendingData;
    entity?: string;
    entityId?: number; //Present when approved pending-data still in data-review process
    prop?: {
        core?: string;
        detail?: string;
    },
    value: any;

};
export type FormReturnData = DataEntryResults & { fails?: object; };
/* ===================== UPDATE DATA AFTER FORM SUBMIT ====================== */
/** After data-entry (create, delete, edit, quarantine), Local Storage is updated. */
export function syncLocalDataAfterDataEntry (
    data: DataEntryResults,
    fConfig: FormConfig | undefined
): Promise<FormReturnData> {
    /*perm-log*/console.log( "   /--syncLocalDataAfterDataEntry data recieved[%O] fConfig[%O]", _u.snapshot( data ), fConfig );
    return initLocalDataMemory()
        .then( () => handleLocalDataUpdate( data, fConfig ) )
        .then( _db.clearTempMemory )
        .then( () => sync.ifFailuresReportAndAddToReturnData( data ) );
}
function initLocalDataMemory (): Promise<void> {
    return _db.getAllStoredData()
        .then( _db.initTempMemory );
}
/* ----------------------- UPDATE LOCAL STORAGE ----------------------------- */
/** Parses data returned from server and updates local data-storage. */
export function handleLocalDataUpdate (
    data: DataEntryResults,
    fConfig?: FormConfig | undefined
): Promise<void> {
    _db.parseData( data );
    if ( data.pending ) return sync.processPending( data, fConfig );
    return updateLocalData( data );
}
export function updateLocalData ( data: DataEntryResults ): Promise<void> {/*temp-log*/console.log( '   -- updateLocalData data[%O]', _u.snapshot( data ) );
    trackEditsToLocalProps( data );
    updateEntityData( data );
    return ifSourceDataEditedUpdatedCitations( data )
        .then( _db.setNewDataInLocalStorage );
}
/* =================== UPDATE ENTITY DATA =================================== */
function updateEntityData ( data: DataEntryResults ): void {        /*dbug-log*///console.log('updateEntityData[%O]', data);
    // if ( ifDeletedInteraction( data ) ) return;
    sync.addCoreEntityData( data.core, data.coreEntity );
    updateDetailEntityData( data );
    sync.removeInvalidatedData( data );
    sync.retryFailedUpdates();
}
// function ifDeletedInteraction ( data: DataEntryResults ): boolean {
//     return data.coreEntity.note?.includes( '[INTERACTION DELETED]' );
// }
function updateDetailEntityData ( data: DataEntryResults ): void {
    if ( !data.detailEntity ) return;
    if ( !data.detail ) throw Error( 'Detail Entity name not found' );
    return sync.addDetailEntityData( data.detail, data.detailEntity );
}
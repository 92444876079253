/**
  * Checks if all required fields are filled.
  *
  * Export
  *     areRequiredFieldsFilled
 */
import { _state } from '~form';

/* ======================= REQUIRED FIELDS FILLED =========================== */
/**
 * True if all the required elements for the current form have a selected value.
 * @param  {string} fLvl Form-group to check
 * @return {boolean}
 */
export function areRequiredFieldsFilled( fLvl ) {
    const fields = _state( 'getFormState', [fLvl, 'fields'] );      /*dbug-log*///console.log( "+--areRequiredFieldsFilled... [%s][%O]", fLvl, fields );
    return Object.values( fields ).every( isRequiredFieldFilled );
}
function isRequiredFieldFilled( field ) {                           /*dbug-log*///console.log( '       --isRequiredFieldFilled[%O]', field );
    if ( isOptionalOrMetaDataField( field ) ) { return true; }
    if ( isInvalidData( field ) ) { return false; }                 /*dbug-log*///console.log( '           --[%s]?[%O]', field.name, field.value ? field.value : null );
    return getFieldValue( field );
}
/* -------------------------- CHECKS ---------------------------------------- */
function isOptionalOrMetaDataField( field ) {
    return !field.required || !field.type;
}
function isInvalidData( field ) {
    return field.value === 'invalid';
}
/* -------------------------- VALUE ----------------------------------------- */
function getFieldValue( field ) {
    if ( field.type === 'multiSelect' ) { return getMultiSelectValues( field.value ); }
    return field.value;
}
function getMultiSelectValues( values = {} ) {
    return Object.keys( values ).length && Object.values( values )[0] ? values : null;
}

/*
 * Handles the right section of the filter panel, saved filter set managment.
 *
 * Export
 *     setFilterSetEventListeners
 *     updateFilterSetCombo
 *     createNewFilterSet
 *     selectFilterSet
 *
 * TOC
 *      INIT UI
 *      COMBO
 *      CREATE
 *      DELETE
 *          AFTER DELETE
 *      SELECT/EDIT
 *      SUBMIT
 *          SUBMIT MODAL
 *          BUILD JSON-DATA
 *          AFTER SUBMIT
 *      UTILITY
 */
import { _filter, _table } from '~db';
import { _cmbx, _db, _el, _opts, _u } from '~util';
import * as sets from './main-filter-set.js';
const tState = _table.bind( null, 'tableState' );

/* ============================= INIT UI ==================================== */
export function setFilterSetEventListeners () {
    $( '#delete-filter' ).on( 'click', showCnfrmDeleteBttns );
    $( '#apply-filter' ).on( 'click', sets.applyFilterSet );
    $( '#confm-set-delete' ).on( 'click', confmDelete );
    $( '#cncl-set-delete' ).on( 'click', cancelDelete );
}
/* ============================== COMBO ===================================== */
export function updateFilterSetCombo () {
    return _opts.getOptsFromStoredData( 'savedFilterNames' )
        .then( updateFilterSetSel );
}
function updateFilterSetSel ( opts ) {                                 /*dbug-log*///console.log('updateFilterSetSel. opts[%O]', opts);
    addCreateOpt( opts );
    _cmbx.destroySelectizeInstance( 'FilterSet' );
    _cmbx.initCombobox( buildSavedFiltersComboConfg( opts ) );
}
function addCreateOpt ( opts ) {
    opts.unshift( { text: '... New Filter Set', value: 'create', group: 'Create' } );
}
function buildSavedFiltersComboConfg ( opts ) {
    return {
        name: 'Filter Set',
        create: createNewFilterSet,
        onChange: selectFilterSet,
        options: opts,
        optgroups: buildOptGroups( opts ),
        optgroupField: 'group',
        labelField: 'text',
        searchField: ['text'],
        sortField: [
            { field: 'group', direction: 'asc' },
            { field: 'text', direction: 'asc' },
            { field: '$score' }],
        render: {
            optgroup_header: function ( data, escape ) {
                return '<div class="optgroup-header">' + escape( data.text ) + '</div>';
            }
        }
    };
}
function buildOptGroups ( opts ) {
    const groups = new Set( opts.map( opt => opt.group ) );
    return [...groups].map( g => { return { text: g, value: g }; } );
}
/* ============================== CREATE ==================================== */
export function createNewFilterSet ( val ) {                           /*dbug-log*///console.log('--createNewFilterSet. val[%s]', val);
    sets.enableFilterSetInputs( 'create' );
    updateSubmitButton( createFilterSet );
    $( '#filter-set-name + input' ).val( val ).focus();
}
function createFilterSet () {
    const data = buildFilterData();
    _u.sendAjaxQuery( data, 'lists/create', onFilterSubmitComplete.bind( null, 'create' ) );
    _el.exitModal();
}
/* ============================== DELETE ==================================== */
function showCnfrmDeleteBttns () {                                   /*dbug-log*///console.log('deleteInteractionList')
    $( '#delete-filter' ).hide();
    $( '#set-confm-cntnr' ).show();
    sets.hideSavedMsg();
}
function confmDelete () {
    const id = sets.getSetState( 'id' );
    resetDeleteButton();
    _u.sendAjaxQuery( { id: id }, 'lists/remove', onFilterDeleteComplete );
}
function cancelDelete () {
    resetDeleteButton();
}
function resetDeleteButton () {
    $( '#set-confm-cntnr' ).hide();
    $( '#delete-filter' ).show();
}
/* ------------------------- AFTER DELETE ----------------------------------- */
function onFilterDeleteComplete ( results ) {                          /*dbug-log*///console.log('listDeleteComplete results = %O', results)
    _db.updateUserNamedList( results.list, 'delete' )
        .then( onDeleteSuccessUpdateFilterUi );
}
function onDeleteSuccessUpdateFilterUi () {
    sets.resetFilterUi();
    _opts.getOptsFromStoredData( 'savedFilterNames' ).then( updateFilterSetSel );
    $( '#sel-FilterSet' )[0].selectize.open();
}
/* ========================= SELECT/EDIT ==================================== */
export function selectFilterSet ( val ) {
    if ( val === 'new' ) { return; } // New list typed into combobox
    sets.resetFilterUi( val );
    if ( val === 'create' ) { return createNewFilterSet(); }
    if ( !val ) { return; }                                          /*dbug-log*///console.log('loading filter set. val = %s', val);
    sets.enableFilterSetInputs();
    updateSubmitButton( editFilterSet );
    _db.getData( 'savedFilters' ).then( filters => fillFilterData( val, filters ) );
}
function editFilterSet () {
    const data = buildFilterData();
    data.id = _cmbx.getSelVal( 'FilterSet' );
    _u.sendAjaxQuery( data, 'lists/edit', onFilterSubmitComplete.bind( null, 'edit' ) );
    _el.exitModal();
}
function fillFilterData ( id, filters ) {
    sets.setSetState( 'all', filters[id] );                           /*dbug-log*///console.log(' -- fillFilterData allFilters[%O] filter[%O]', filters, filters[id]);
    sets.fillFilterDetailFields( filters[id].displayName, filters[id].description );
}
/* =========================== SUBMIT ======================================= */
/* ----------------------- SUBMIT MODAL ------------------------------------- */
function updateSubmitButton ( func ) {
    $( '#save-filter' ).off( 'click' ).on( 'click', showSaveFilterModal.bind( null, func ) );
}
function showSaveFilterModal ( success ) {
    if ( ifEmptyNameInput() ) { return $( '.filter-set-details input' ).focus(); }
    const config = getModalOptions();
    const button = getButtonConfig( config, success );
    _el.showSaveModal( config, button );
}
function ifEmptyNameInput() {
    return !$( '.filter-set-details input' ).val();
}
function getModalOptions() {
    return {
        html: getActiveFilters( $( '#filter-status' ).html() ),
        position: 'left',
        selector: '#save-filter',
        title: 'Save Filter Set'
    };
}
function getActiveFilters ( statusMsg ) {
    ['List, ', ', List.', 'List.'].forEach( l => statusMsg = statusMsg.replace( l, '' ) );
    return statusMsg || 'No Active Filters.';
}
function getButtonConfig( config, success ) {
    if ( config.html.includes( 'No Active' ) ) { return null; }
    return {
        text: 'Submit',
        onConfirm: success
    };
}
/* ---------------------- BUILD JSON-DATA ----------------------------------- */
function buildFilterData () {
    const data = {
        displayName: _u.ucfirst( $( '#filter-set-name + input' ).val() ),
        type: 'filter',
        description: $( '#stored-filters textarea' ).val(),
        details: getFilterSetJson( tState().get( null, ['curFocus', 'curView', 'api'] ) ),
    };                                                               /*dbug-log*///console.log('-- buildFilterData [%O]', data);
    return data;
}
/**
 * Returns a json object with the current focus, view, and active filters in the
 * table column headers and the filter panel: rebuild (rebuilds table) and
 * direct (applied to row data directly).
 *
 * JSON: {
 *     direct: {}, //filter table-data directly
 *     focus: "", //top-level data-table sort: locs, srcs, taxa
 *     rebuild: {}, //filters reload data-table
 *     table: {}, //AG-GRID table column filters
 *     focus: "", //second-level table-data view
 *     view: { text: "", value: "" } //view-combo values
 * }
 */
function getFilterSetJson ( tState ) {
    const fState = _filter( 'getFilterState' );
    const filters = {
        direct: getDirectFiltersForSet( fState.direct ),
        focus: tState.curFocus,
        rebuild: fState.rebuild,
        table: fState.table,
        view: { text: _cmbx.getSelTxt( 'View' ), value: tState.curView },
    };
    return JSON.stringify( filters );
}
function getDirectFiltersForSet ( filters ) {
    delete filters.list; //Active interaction list not saved in set.
    return filters;
}
/* ---------------------- AFTER SUBMIT -------------------------------------- */
function onFilterSubmitComplete ( action, results ) {
    sets.setSetState( 'all', parseUserNamed( results.list.entity ) );   /*dbug-log*///console.log('onFilterSubmitComplete results[%O]', results);
    _db.updateUserNamedList( results.list, action )
        .then( onUpdateSuccessUpdateFilterUi.bind( null, sets.getSetState( 'id' ) ) );
}
function onUpdateSuccessUpdateFilterUi ( id ) {                        /*dbug-log*///console.log('onUpdateSuccessUpdateFilterUi id[%s]', id);
    updateFilterSetCombo()
        .then( () => updateFilterSetUi( id ) );
}
function updateFilterSetUi ( id ) {
    _cmbx.setSelVal( 'FilterSet', id );
    sets.addSetToFilterStatus();
    sets.showSavedMsg();
}
/* ====================== UTILITY =========================================== */
function parseUserNamed ( entity ) {
    return entity ? parseEntity( JSON.parse( entity ) ) : { details: [] };
}
function parseEntity ( entity ) {
    entity.details = typeof entity.details == 'string' ?
        JSON.parse( entity.details ) : entity.details;
    return entity;
}
/**
 * Executes the passed update function. If there is an error, the update is added
 * to the 'retryQueue'. After the initial updates complete, those in the retryQueue
 * are attempted again. Those that fail a second time are reported.
 *
 * Export
 *     clearFailedMemory
 *     ifFailuresSendReport
 *     retryFailedUpdates
 *     updateData
 *
 * TOC
 *     EXECUTE UPDATE
 *     FAILURES
 *         RETRY QUEUE
 *         REPORT FAILURES
 */
import { SerializedEntity, objectKeys } from '~types';
import { _u } from '~util';
import { DataEntryResults, EditObj, FormReturnData } from '../data-entry/data-entry-sync';

let failed: UpdateFailures = { data: [], retryQueue: {} };

export function clearFailedMemory () {
    failed = { data: [], retryQueue: {} };
}
/* =========================== TYPES ======================================== */
type UpdateParams = {
    edits?: EditObj;
    entity: string;
    rcrd: SerializedEntity;
    stage: 'addData' | 'rmvData';
};
/* --------- FAILURE OBJECT ----------- */
type UpdateFailures = {
    data: FailData[];
    retryQueue: {
        [entity: string]: {
            [prop: string]: RetryUpdateParams;
        };
    };
    final?: true;
};
type RetryUpdateParams = UpdateParams & { updateFunc: ( ...args: any[] ) => void; };
type FailData = {
    errMsg: string;
    msg: string;
    tag: string;
};
/* ======================== EXECUTE UPDATE ================================== */
/**
 * Executes the data update, tracks any failures and attempts them a second time
 * after all other updates have completed: this handles issues due to relational
 * dependencies.
 */
export function updateData (
    updateFunc: ( ...args: any[] ) => void,
    prop: string,
    params: UpdateParams
) {                                                                 /*dbug-log*///console.log('prop [%s] -> params [%O], updateFunc = %O', prop, params, updateFunc);
    try {
        updateFunc( prop, params.rcrd, params.entity, params.edits );
    } catch ( e: unknown ) {
        handleUpdateFailure( e as Error, updateFunc, prop, params );
    }
}
function handleUpdateFailure (
    e: Error,
    updateFunc: ( ...args: any[] ) => void,
    prop: string,
    params: UpdateParams | RetryUpdateParams
) {
    if ( !failed.final ) return addToRetryQueue( updateFunc, prop, params );
    trackDataSyncFailure( e, prop, params as RetryUpdateParams );
}
/* ========================= RETRY UPDATE =================================== */
/* -------------------------- RETRY QUEUE ----------------------------------- */
/**
 * If this is the first failure, it is added to other failed updates to be
 * retried at the end of the update process. If this is the second error,
 * the error is reported to the user. (<--todo for onPageLoad sync)
 */
function addToRetryQueue (
    updateFunc: ( ...args: any[] ) => void,         //AddMethod | RemoveMethod,
    prop: string,
    params: UpdateParams
) {                                                                 /*dbug-log*///console.log( 'addToRetryQueue. params[%O]', params );
    if ( !failed.retryQueue[params.entity] ) { failed.retryQueue[params.entity] = {}; }
    failed.retryQueue[params.entity]![prop] = { updateFunc: updateFunc, ...params };
}
export function retryIssuesAndReportFailures () {
    retryFailedUpdates();
    ifFailuresSendReport();
}
/** Retries any updates that failed in the first pass. */
export function retryFailedUpdates () {
    if ( !Object.keys( failed.retryQueue ).length ) return;         /*perm-log*/console.log( '           --retrying[%s]FailedUpdates = %O', Object.keys( failed.retryQueue ).length, _u.snapshot( failed ) );
    failed.final = true;
    Object.keys( failed.retryQueue ).forEach( retryEntityUpdates );
}
function retryEntityUpdates ( entity: string ) {
    const queue = failed.retryQueue[entity];
    if ( !queue ) throw Error( `[${ entity }]: Empty failure object should not exist.` );
    objectKeys( queue ).forEach( prop => {
        let params = queue[prop];
        if ( !params ) throw Error( `[${ prop }] Empty failure object should not exist` );
        updateData( params.updateFunc, prop, params );
    } );
}
/* ------------------------ TRACK FAILURE ----------------------------------- */
/** After a second retry, the data failures is tracked and later reported. */
function trackDataSyncFailure ( e: Error, prop: string, params: RetryUpdateParams ) {
    logSyncFailure( e, prop, params );
    failed.data.push( getFailDataObj( e, prop, params ) );
}
function logSyncFailure ( e: Error, prop: string, params: RetryUpdateParams ) {
    const funcName = params.updateFunc.name;
    console.log( '               !!Tracking failure: [%s][%s]->[%s] [func = %s] [params = %O] [e = %O], [rcrd = %s]', params.entity, params.rcrd.id, prop, funcName, params, e, JSON.stringify( params.rcrd ) );
}
function getFailDataObj ( e: Error, prop: string, params: RetryUpdateParams ): FailData {
    return {
        errMsg: e.name + ': ' + e.message,
        msg: getDataSyncFailureMsg( params.entity, params.stage ),
        tag: params.entity + ':' + prop + ':' + params.rcrd.id
    };
}
function getDataSyncFailureMsg ( entity: string, stage: 'addData' | 'rmvData' ): string {
    const trans = { addData: 'adding to', rmvData: 'removing from' };
    return `There was an error while ${ trans[stage] } the ${ entity }'s stored data.`;
}
/* ======================= REPORT FAILURES ================================== */
export function ifFailuresSendReport () {
    if ( !failed.data.length ) return;
    _u.alertIssue( 'dataSyncFailure', { fails: getFailureReport() } );
}
function getFailureReport () {
    const data = failed.data.map( f => { return { err: f.errMsg, tag: f.tag }; } );
    return JSON.stringify( data );
}
/**
 * After a data-sync process is complete, any unresolved update failures are
 * reported to the issue tracking system. Failures syncing after data-entry are
 * added and returned to the form to trigger special handling and user alerts.
 */
export function ifFailuresReportAndAddToReturnData ( data: DataEntryResults ): FormReturnData {
    if ( failed.data.length ) {
        ifFailuresSendReport();
        ( data as FormReturnData ).fails = failed.data;
    }
    return data;
}
/**
 * Handles loading the citation-type fields.
 *
 * Export
 *     loadCitTypeFields
 *     selectDefaultCitType
 *     handleCitationTypeData
 *
 * TOC
 *     SELECT DEFAULT-TYPE
 *     LOAD TYPE-FIELDS
 *     UPDATE UI FOR CITATION-TYPE
 *     ADD PUB DATA
 */
import { _cmbx, _db, _u } from '~util';
import {  _state } from '~form';
import * as sForm from '../src-form-main.js';
/* ----------------------- SELECT DEFAULT-TYPE ------------------------------ */
export function selectDefaultCitType() {                            /*dbug-log*///console.log('           /--selectDefaultCitType');
    return _db.getData( 'citTypeNames' )
        .then( types => setCitType( types ) );
}
function setCitType( cTypes ) {
    const fLvl = _state( 'getSubFormLvl', ['sub'] );
    const pData = _state( 'getFieldState', [fLvl, 'ParentSource', 'misc'] );
    const pubType = pData.pubType.displayName;
    const defaultType = getDefaultCitType( pubType, pData );
    _cmbx.setSelVal( 'CitationType', cTypes[defaultType], 'silent' );
    handleCitationTypeData( defaultType, fLvl );
    return loadCitTypeFields( cTypes[defaultType], defaultType );
}
function getDefaultCitType( pubType, pData ) {
    return {
        Book: getBookDefault( pubType, pData ),
        Journal: 'Article',
        Other: 'Other',
        'Thesis/Dissertation': 'Ph.D. Dissertation'
    }[pubType];
}
function getBookDefault( pubType, pData ) {
    const pubAuths = pData.src.authors;
    return pubAuths ? 'Book' : 'Chapter';
}
/* -------------------------- LOAD TYPE-FIELDS ------------------------------ */
/**
 * Adds relevant data from the parent publication into formVals before
 * loading the default fields for the selected Citation Type. If this is an
 * edit form, skip loading pub data...
 */
export function loadCitTypeFields( typeId, typeName ) {               /*dbug-log*///console.log('           /--loadCitTypeFields id[%s] type[%s]', typeId, typeName);
    const fLvl = _state( 'getSubFormLvl', ['sub'] );
    const type = typeName ? typeName : _cmbx.getSelTxt( 'CitationType' );
    return sForm.loadSrcTypeFields( 'Citation', typeId, type )
        .then( () => sForm.handleCitText( fLvl ) );
}
/* ------------------- UPDATE UI FOR CITATION-TYPE -------------------------- */
/* ------------------------- ADD PUB DATA ----------------------------------- */
export function handleCitationTypeData( type, fLvl ) {                /*dbug-log*///console.log('handleCitationTy9889peData [%s][%s]', fLvl, type)
    const copy = ['Book', "Master's Thesis", 'Museum Record', 'Other',
        'Ph.D. Dissertation', 'Report', 'Chapter' ];
    const addSameData = copy.indexOf( type ) !== -1;
    addPubValues( fLvl, addSameData, type );
}
/**
 * Adds or removes publication data from the form's values and elems, disabling
 * fields with data from the publication entity.
 */
function addPubValues( fLvl, addValues, type ) {
    if ( _state( 'getFormState', ['top', 'action'] ) !== 'create' ) { return; }
    const fData = _state( 'getFormState', [fLvl, 'fields'] );         /*dbug-log*///console.log('--addPubValues fData[%O]', _u.snapshot(fData))
    const pSrc = fData.ParentSource.misc.src;
    addPubTitle();
    addPubYear();
    addAuthorsToCitation();
    _state( 'setFormState', [fLvl, 'fields', fData] );
    /**
     * Adds the pub title to the citations when the default type is for the entire
     * publication. TODO (may not actually be needed)
     */
    function addPubTitle() {                                        /*dbug-log*///console.log('--addPubTitle pSrc[%O] displayNameField[%O]', pSrc.displayName, fData.DisplayName);
        const skip = ['Chapter', 'Article'];
        const enable = skip.indexOf( type ) !== -1;
        $( '#DisplayName_f input' ).prop( 'disabled', !enable );
        _state( 'setFieldState', [fLvl, 'DisplayName', getPubTitle( skip )] );
    }
    function getPubTitle( skip ) {
        const title = ifFullPubCited( skip ) ? pSrc.displayName : fData.DisplayName.value;/*dbug-log*///console.log('   -- getPubTitle title[%s] pSrc[%O] displayNameField[%O]', title, pSrc, _u.snapshot(fData.DisplayName));
        return title && title[0] === '*' ? title.slice( 1 ) : title; // Removed symbol added to quarantined-data titles
    }
    function ifFullPubCited( skip ) {
        return addValues && skip.indexOf( type ) === -1;
    }
    function addPubYear() {
        $( '#Year_f input' ).prop( 'disabled', addValues );
        const year = addValues ? pSrc.year : '';
        _state( 'setFieldState', [fLvl, 'Year', year] );
    }
    function addAuthorsToCitation() {                               /*dbug-log*///console.log('--addAuthorsToCitation');
        if ( !addValues || !pSrc.authors ) { return; }                /*dbug-log*///console.log('    --pAuths[%O]', pSrc.authors);
        _state( 'setFieldState', [fLvl, 'Author', pSrc.authors] );
        disableAuthorField( fLvl );
    }
}
function disableAuthorField( fLvl ) {
    if ( !$( '#sel-Author1' ).length ) { return; } // Citation-type does not have authors
    _cmbx.enableComboboxes( $( `#Author_f-cntnr select` ), false );
    const count = _state( 'getFieldState', [fLvl, 'Author', 'count'] );
    sForm.rmvExtraMultiFields( 'Author', count, fLvl, false );
}
/**
 * On database page load, all server-data updated since the last local-data update
 * is downloaded.
 *
 * Export
 *     syncLocalDbWithServer
 *
 * TOC
 *     INIT SYNC
 *     SYNC LOCAL STORAGE
 *         SYNC USER DATA
 *         SYNC ENTITY DATA
 *     ON SYNC COMPLETE
 */
import { _db, _u } from '~util';
import { isTruthy, objectKeys, User } from '~types';
import { initSearchStateAndTable, _ui } from '~db';
import { ifFailuresSendReport } from '../db-sync-main';
import { downloadAndStoreUpdatedData } from './sync-updated-data';
/* ======================== INIT SYNC ======================================= */
export type EntityStateDates = { [entity: string]: string; };

export function syncLocalDbWithServer ( lclState: EntityStateDates ): void {
    /*perm-log*/_u.logInDevEnv( "   /--syncLocalDbWithServer. lclState[%O]", lclState );
    _db.getData( ['hasQuarantined', 'user'], true )
        .then( d => syncLocalDbForCurrentUser( d.hasQuarantined as boolean, d.user as User ) );

    function syncLocalDbForCurrentUser ( hasQ: boolean, dbUser: User ): Promise<void> {
        /*dbug-log*///console.log(' -- syncLocalDbForCurrentUser dbUser[%O] hasQuarantined?[%s]', dbUser, qData);
        return _db.initMemoryDataObj()
            .then( () => updateLocalDataStorage( lclState, hasQ, dbUser ) );
    }
}
/* ======================== SYNC LOCAL STORAGE ============================== */
/**
 * If the user changes, user data is updated, and if the database had quarantined
 * data, local storage is completely reset. Otherwise, local-storage is synced
 * to the server data.
 */
function updateLocalDataStorage (
    lclState: EntityStateDates,
    hasQ: boolean,
    dbUser: User
): Promise<void> | void {
    const userChanged = !isExpectedUser( dbUser.username );
    if ( userChanged && hasQ ) return _db.resetStoredData();
    return syncWithServer( lclState, dbUser, userChanged );
}
function syncWithServer (
    lclState: EntityStateDates,
    dbUser: User,
    userChanged: boolean
): Promise<void> {
    return updateLocalUserData( userChanged )
        .then( () => syncLocalData( lclState ) )
        .then( () => _ui( 'initDataInReviewPanel', [dbUser.role] ) );
}
/* -------------------------- SYNC USER DATA -------------------------------- */
function isExpectedUser ( dbUsername: string | null ): boolean {
    const curName = $( 'body' ).data( 'user-name' );
    const isExpected = curName ? curName === dbUsername : !dbUsername;
    return isExpected;
}
function updateLocalUserData ( userChanged: boolean ): Promise<void> {
    if ( !userChanged ) { return Promise.resolve(); }
    return fetchAndStoreUserData()
        .then( _db.setNewDataInLocalStorage );
}
function fetchAndStoreUserData () {
    return ['user', 'pending'].reduce( ( p, url ) => {
        return p.then( p => _db.getAndSetData( url ) );
    }, Promise.resolve() );
}
function syncLocalData ( lclState: EntityStateDates ) {             /*dbug-log*///console.log(' -- syncLocalData  lclState[%O]', lclState);
    return _db.fetchServerData( 'data-state' )
        .then( handleSync );

    function handleSync ( srvrStateResults: { state: EntityStateDates; } ) {
        return syncLocalDatabase( srvrStateResults.state, lclState );
    }
}
function syncLocalDatabase (
    srvrState: EntityStateDates,
    lclState: EntityStateDates
): Promise<void> | void {                                           /*dbug-log*///console.log('syncLocalDatabase. srvrState = %O, lcl = %O', srvrState, lclState);
    if ( ifTesting( srvrState ) ) return _db.resetStoredData();
    const entities = getEntitiesWithUpdates( srvrState, lclState );
    return entities.length ? syncDb( entities, srvrState ) : initSearchPage();
    /** Db is reset unless testing suite did not reload database. */
}
function ifTesting ( systemUpdateAt: EntityStateDates ): boolean {
    if ( !systemUpdateAt.System ) throw Error( 'System update time is not found.' );
    return systemUpdateAt.System == "2020-05-20 11:11:11";
}
/* ------------------------ SYNC ENTITY DATA -------------------------------- */
export type EntitySyncData = { name: string, updated: string; };
function getEntitiesWithUpdates (
    srvrState: EntityStateDates,
    lclState: EntityStateDates
): EntitySyncData[] {                                               /*dbug-log*///console.log('getEntitiesWithUpdates. srvrState = %O, lcl = %O', srvrState, lclState);
    return objectKeys( srvrState )
        .map( entity => ifUpdatesBuildSyncData( entity, srvrState[entity], lclState[entity] ) )
        .filter( isTruthy );
    // return [{ name: 'PendingData', updated: "2020-05-20 11:11:11" }];  //FOR TESTING PENDINGDATA CONTRIBUTOR SYNC
}
function ifUpdatesBuildSyncData (
    entity: string,
    srvrDatetime: string | undefined,
    lclDatetime: string | undefined
): false | EntitySyncData {                                         /*dbug-log*///console.log('   --[%s] updates ? ', entity, entityHasUpdates(srvrState[entity], lclState[entity]));
    return hasUpdates( entity, srvrDatetime, lclDatetime ) ?
        { name: entity, updated: lclDatetime! }
        : false;
}
function hasUpdates (
    entity: string,
    srvrDatetime: string | undefined,
    lclDatetime: string | undefined
): boolean {
    if ( !srvrDatetime || !lclDatetime ) throw Error( 'Entity update time not found' );
    return entity !== 'System' && entityHasUpdates( srvrDatetime, lclDatetime );
}
/**
 * Returns true if the first datetime is more recent than the second.
 * Note: for cross-browser date comparison, dashes are be replaced with slashes.
 */
function entityHasUpdates (
    timeOne: string,
    timeTwo: string
): boolean {
    const time1 = timeOne.replace( /-/g, '/' );
    const time2 = timeTwo.replace( /-/g, '/' );                     /*dbug-log*///console.log("firstTimeMoreRecent? ", Date.parse(time1) > Date.parse(time2))
    return Date.parse( time1 ) > Date.parse( time2 );
}
/** Note: Sub-entity data is downloaded first, then interactions, then pending-data. */
function syncDb (
    entities: EntitySyncData[],
    dataUpdatedAt: EntityStateDates
): Promise<void> {
    return downloadAndStoreUpdatedData( entities )
        .then( ifFailuresSendReport )
        .then( initSearchPage )
        .then( () => _db.setData( 'lclDataUpdtdAt', dataUpdatedAt ) );
}
/* ========================= ON SYNC COMPLETE =============================== */
function initSearchPage () {                                         /*dbug-log*///console.log(" -- initSearchPage ")
    _db.clearTempMemory();
    _db.getData( 'curFocus', true )
        .then( f => initSearchStateAndTable( ( f ? f.toString() : undefined ) ) );
}
/**
 * Checks all required citation fields, generates the citation, and sets the
 * disabled textarea field. Default text displays Until required fields are filled.
 *
 * Export
 *     buildCitTextAndUpdateField
 *     setFinalCitation
 *
 * TOC
 *     GET CITATION FIELD TEXT
 *         GET FINAL CITATION
 *     GET ALL DATA FOR CITATION
 *     UPDATE FIELD
 */
import { _cmbx, _u } from '~util';
import { _elems, _state } from '~form';

export function buildCitTextAndUpdateField( fLvl ) {                  /*dbug-log*///console.log('--buildCitTextAndUpdateField [%s]', fLvl);
    const $elem = $( `#Description_f textarea.${ fLvl }-field` );
    if ( !$elem.val() ) { initializeCitField( $elem ); }

    return getCitationFieldText( $elem, fLvl )
        .then( citText => updateCitField( fLvl, citText, $elem ) )
}
function initializeCitField( $elem ) {
    $elem.prop( 'disabled', true ).unbind( 'change' ).css( {height: '6.6em'} );
}
/* ------------------------ GET CITATION TEXT ------------------------------- */
/** Returns the citation field text or false if there are no updates. */
function getCitationFieldText( $elem, fLvl ) {
    const dfault = 'The citation will display here once all required fields are filled.';
    return Promise.resolve( getCitationText() );

    function getCitationText() {
        return _elems( 'ifNoOpenSubFormAndAllRequiredFieldsFilled', [fLvl] ) ?
            buildCitationText( fLvl ) :
           ( $elem.val() === dfault ? false : dfault );
    }
}
function buildCitationText( fLvl ) {                                  /*dbug-log*///console.log('--buildCitationText [%s]', fLvl);
    return _u.generateCitationText( getDataForCitation( fLvl ) );
}
/* ------------------------ GET CITATION TEXT ------------------------------- */
export function setFinalCitation( fLvl ) {
    const text = _u.generateCitationText( getDataForCitation( fLvl, false ) );
    _state( 'setFieldState', [fLvl, 'Description', text] );
}
/* -------------------- GET ALL DATA FOR CITATION --------------------------- */
function getDataForCitation( fLvl, warn = true ) {                    /*dbug-log*///console.log('--getDataForCitation [%s]', fLvl);
    const fields = _state( 'getFormState', [fLvl, 'fields'] );        /*dbug-log*///console.log('      --fields[%O]', fields);
    const data = {
        pubSrc: fields.ParentSource.misc.src,
        citSrc: { authors: fields.Author.value, year: fields.Year.value },
        cit: buildCitData( fields ),
        showWarnings: warn
    };                                                              /*dbug-log*///console.log('       --data[%O]', data);
    return { ...data, ...addEntityRecords() };
}
function buildCitData( fields ) {                                     /*dbug-log*///console.log('--buildCitData [%O]', _u.snapshot(fields));
    return {
        citationType: { displayName: getCitationType( fields.CitationType.value ) },
        title: fields.DisplayName.value,
        publicationPages: fields.Pages.shown ? fields.Pages.value : null,
        publicationIssue: fields.Issue.value,
        publicationVolume: getVolumeOrEdition( fields.Volume, fields.Edition ),
    };
}
function getCitationType( val ) {
    return val.text ? val.text : _cmbx.getSelTxt( 'CitationType' );
}
function getVolumeOrEdition( vol, ed ) {
    return vol.value ? vol.value : ed.value;
}
function addEntityRecords() {
    const entities = ['author', 'citation', 'publisher', 'source'];
    return { rcrds: _state( 'getEntityRcrds', [entities] )};
}
/* --------------------------- UPDATE FIELD --------------------------------- */
function updateCitField( fLvl, citText, $elem ) {
    if ( !citText ) { return; }                                       /*dbug-log*///console.log('-- updateCitField fLvl[%s] citText[%s] $elem[%O]', fLvl, citText, $elem);
    $elem.val( citText );
    _state( 'setFieldState', [fLvl, 'Description', citText] );
}
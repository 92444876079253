/**
 * Database filter util methods.
 */
import {  _el } from '~util';
/* ---------------------------- BUILD --------------------------------------- */
export function newSel( opts, c, i, field ) {                         /*dbug-log*///console.log('--newSel opts[%O], c[%s], i[%s], field[%s]', opts, c, i, field);
    const elem = _el.getElem( 'select', { class: c, id: i }, opts );
    // Shouldn't be needed with Combobox classes
    // if ( field ) { $( elem ).data( 'field', field+' Filter' ); }
    return elem;
}
export function getFilterField( lblTxt, input ) {
    const classes = lblTxt ? 'flex-row field-cntnr' : 'row-field';
    const lbl = _el.getElem( 'label', { class: classes } );
    const span = lblTxt ? _el.getElem( 'span', { text: lblTxt + ': ' } ) : null;
    $( lbl ).append( [span, input].filter( e=>e ) );
    return lbl;
}
/* ------------------------------- APPEND ----------------------------------- */
export function appendDynamicFilter( filterEl ) {
    const $el = ifRowIsFull() ?
        $( '#focus-filters' ) : $( $( '#focus-filters' )[0].lastChild );
    $el.append( filterEl );
}
function ifRowIsFull() {
    return $( '#focus-filters' )[0].lastChild.children.length % 2 === 0;
}
/**
 * Shows a sub-form to 'Select <Field>' of the interaction with a combobox for
 * the taxon group, sub-groups (if group has multiple taxon roots), and one for
 * each rank present in the group, (eg: Bat - Family, Genus, and Species), filled
 * with the taxa at that rank. When one is selected, the remaining boxes
 * are repopulated with related taxa and the 'select' button is enabled. A 'Select
 * Unspecified' button allows selection of a (sub)group's root taxon.
 *
 * Export
 *     initTaxonSelectForm
 *
 * TOC
 *     INIT SELECT-FORM
 *     UPDATE FORM ELEMS
 *         CUSTOMIZE FORM-TEXT
 *         ADD SELECT-UNSPECIFIED BUTTON
 *     HANDLE INIT SELECTION
 *         FOCUS FIRST RANK-COMBO
 *         SET CURRENT TAXON-FIELD SELECTION
 *     EXIT SELECT-FORM
 *         RESET FIELD-COMBO
 *         RESET SELECT-FORM
 */
import { _cmbx, _el } from '~util';
import { _form, _elems, _state, _val } from '~form';
import * as selectForm from './txn-select-main.js';
/* ======================= INIT SELECT-FORM ================================= */
/**
 * Taxon select-form init.
 * @param  {string} field     Form-field name to select a taxon for (Object, Parent, Subject)
 * @param  {string} gId       Taxon group ID
 * @param  {string} sgId      Taxon sub-group ID
 * @param  {function} onSubmit  Alternative submit method: validate when editing Parent taxon (except during review)
 * @return {promise}
 */
export function initTaxonSelectForm ( field, gId, sgId, onSubmit ) {   /*temp-log*///console.log('       +--init[%s]Select (selected ? [%s])', field, $(`#sel-${field}`).val());
    $( '#sel-' + field ).data( 'loading', true );
    return _elems( 'initSubForm', [getTxnSelectParams( field, gId, sgId, onSubmit )] )
        .then( status => finishTaxonSelectBuild( status, field ) );
}
function getTxnSelectParams ( field, gId, sgId, onSubmit ) {           /*dbug-log*///console.log('--getTxnSelectParams field[%s] gId?[%s sId?[%s] onSubmit?[%O]', field, gId, sgId, onSubmit);
    return {
        action: 'select',
        appendForm: form => $( `#${ field }_f` ).append( form ),
        onFormClose: exitSelectForm.bind( null, field ),
        cancel: resetTaxonSelectForm.bind( null, field, gId, sgId ),
        combo: field,
        group: 'sub',
        initCombos: selectForm.initSelectFormCombos,
        name: field,
        style: 'sml-sub-form',
        submit: onSubmit ? onSubmit : _form.bind( null, 'selectFieldTaxon' ),
        vals: { Group: gId, 'Sub-Group': sgId }
    };
}
/**
 * Customizes the taxon-select form ui. Either re-sets the existing taxon selection
 * or brings the first rank-combo into focus. Clears [field]'s combobox.
 */
function finishTaxonSelectBuild ( status, field ) {                    /*dbug-log*///console.log('+--finishTaxonSelectBuild field[%s]', field);
    if ( !status ) { return $( '#sel-' + field ).data( 'loading', false ); } //Error handled elsewhere
    updateFormElems( field );
    selectInitTaxonOrFocusFirstCombo( field );
}
/* ===================== UPDATE FORM ELEMS ================================== */
function updateFormElems ( field ) {
    clearFieldCombobox( field );
    updateSelectFormText( field );
    addSelectRootTaxonBttn( field );
    addSelectFormExitButton( field );
}
function clearFieldCombobox ( field ) {                                /*dbug-log*///console.log('--clearFieldCombobox [%O]', field)
    _cmbx.replaceSelOpts( field, [] );
    $( '#sel-' + field ).data( 'loading', false );
}
/* --------------------- CUSTOMIZE FORM-TEXT -------------------------------- */
/** Adds a close button. Updates the Header and the submit/cancel buttons. */
function updateSelectFormText ( field ) {
    $( '#sub-hdr span' )[0].innerHTML = `Select ${ field } Taxon`;
    $( '#sub-submit' )[0].value = "Select Taxon";
    $( '#sub-cancel' )[0].value = "Reset";
}
/* -------------------- ADD SELECT-UNSPECIFIED BUTTON ----------------------- */
/** Adds a button that selects the group's root-taxon. */
function addSelectRootTaxonBttn ( field ) {
    const bttn = buildSelectUnspecifedBttn( field );
    $( '#sub-form .bttn-cntnr' ).prepend( bttn );
}
function buildSelectUnspecifedBttn ( field ) {
    const bttn = _el.getElem( 'input', getUnspecifiedBttnAttrs() );
    $( bttn ).on( 'click', _form.bind( null, 'selectFieldTaxon', [null, 'root'] ) );
    $( bttn ).data( 'field', field );
    return bttn;
}
function getUnspecifiedBttnAttrs () {
    return {
        id: 'select-group',
        type: 'button',
        value: 'Select Unspecified'
    };
}
/* =================== HANDLE INIT SELECTION ================================ */
/**
 * Restores a previously selected taxon on initial load, or when reseting the select
 * form. When the select form loads without a previous selection or when the group
 * is changed by the user, the first combobox of the group is brought into focus.
 */
function selectInitTaxonOrFocusFirstCombo ( field ) {
    const selId = getPrevSelId( field );                              /*dbug-log*///console.log("--selectInitTaxonOrFocusFirstCombo field[%s] id?[%s]", field, selId);
    if ( selId ) {
        resetPrevTaxonSelection( selId, field );
    } else { focusFirstRankCombo(); }
}
function getPrevSelId ( field ) {
    return $( '#sel-' + field ).val() || $( '#sel-' + field ).data( 'reset' ) ?
        $( '#sel-' + field ).data( 'selTaxon' ) : null;
}
/* ----------------- FOCUS FIRST RANK-COMBO --------------------------------- */
function focusFirstRankCombo () {
    const ranks = _state( 'getFieldState', ['sub', 'Sub-Group', 'misc'] ).subRanks;
    const rank = ranks.slice().pop();
    _cmbx.focusCombobox( rank );
}
/* ----------------- SET CURRENT TAXON-FIELD SELECTION ---------------------- */
/** Resets the taxon to the one previously selected in the interaction form.  */
function resetPrevTaxonSelection ( id, field ) {
    const taxon = _state( 'getRcrd', ['taxon', id] );
    if ( taxon.isRoot ) { return; }                                   /*dbug-log*///console.log('           --resetPrevTaxonSelection [%s] [%s] = %O', field, id, taxon);
    selectPrevTaxon( taxon, field );
}
function selectPrevTaxon ( taxon, field ) {                            /*dbug-log*///console.log('  -- selectPrevTaxon field[%s] txn[%O]', field, taxon);
    if ( ifDifferent( 'Group', taxon.group.id ) ) { return setGroup( taxon.group.id ); }
    _cmbx.setSelVal( taxon.rank.displayName, taxon.id );
    window.setTimeout( () => { deleteResetFlag( field ); }, 1000 );
}
function ifDifferent ( field, id ) {
    return $( '#sel-' + field ).val() != id;
}
function setGroup ( id ) {
    _cmbx.setSelVal( 'Group', id );
}
function deleteResetFlag ( field ) {
    $( '#sel-' + field ).removeData( 'reset' );
}
/* =================== EXIT SELECT-FORM ===================================== */
/**
 * Exits select-form completely. The cancel button, typically used for sub-form
 * exits is repurposed elsewhere to allow select-form reset.
 * @param  {string} field Taxon-field the select-form is for.
 */
function addSelectFormExitButton ( field ) {
    const bttn = _elems( 'getExitButton', [exitTaxonSelectForm.bind( null, field )] );
    $( '#sub-hdr span+div' ).append( bttn );
}
/** Exits sub form and restores any previous taxon selection. */
function exitTaxonSelectForm ( field ) {
    _elems( 'handleFormClose', ['sub', false] );
    _val( 'clearAlert', [$( `#${ field }_alert` )[0], 'top'] );
    handleComboReset( field, $( '#sel-' + field ).data( 'selTaxon' ) );
}
/* -------------------- RESET FIELD-COMBO ----------------------------------- */
/** Resets previous field value in combobox. */
function handleComboReset ( field, prevTaxonId ) {
    if ( !prevTaxonId ) { return; }
    resetTaxonCombobox( field, prevTaxonId );
}
function exitSelectForm ( field ) {
    return _form( 'enableTaxonFieldCombos', [field] );
}
function resetTaxonCombobox ( field, prevTaxonId ) {
    const opt = { text: getTaxonym( prevTaxonId ), value: prevTaxonId };
    _cmbx.replaceSelOpts( field, opt );
    _cmbx.setSelVal( field, prevTaxonId, 'silent' );
}
function getTaxonym ( id ) {
    return _state( 'getRcrd', ['taxon', id] ).displayName;
}
/* -------------------- RESET SELECT-FORM ----------------------------------- */
/**
 * Reinitializes the taxon select-form to the field-taxon previously selected or
 * to the default taxon-group for the field. Repurposes the sub-form cancel button.
 */
function resetTaxonSelectForm ( field, gId, sgId ) {                   /*dbug-log*///console.log('resetTaxonSelectForm field[%s] gId[%s] sgId[%s]', field, gId, sgId);
    $( '#sel-' + field ).data( 'reset', true );
    _elems( 'exitFormElemAndClearState', ['sub'] );
    selectForm.initFieldTaxonSelect( field, gId, sgId );
}
/**
 * Data review panel will allow editors to view the data they've edited, for
 * admin-editors to view all edited data and to send edits back to the editors
 * when needed.
 *
 * Export
 *     initReviewPanel
 *     initDataInReviewPanel
 *
 * TOC
 *     INIT
 *     TOGGLE
 */
import * as util from './util/review-util-main.js';
import * as init from './init/panel-init-main.js';
/* ============================ INIT ======================================== */
export function initReviewPanel ( userRole ) {                      /*dbug-log*///console.log( 'initReviewPanel role[%s]', userRole );
    if ( ['visitor', 'user'].indexOf( userRole ) !== -1 ) return;
    init.handleReviewPanelInit( userRole );
    util.initReviewPanelUtil();
    $( '#rvw-data' ).on( 'click', updateDataAndTogglePanel );
}
function updateDataAndTogglePanel () {
    util.loadPendingDataStats(); //async
    init.initNamesCombobox(); //async
    util.toggleReviewPanel();
}
export function getOnCloseHandler () {
    return util.toggleDataOptsBarButtons;
}
/* --------------------------- COMBOBOXES ----------------------------------- */
export function initDataInReviewPanel ( userRole ) {
    if ( ['visitor', 'user'].indexOf( userRole ) !== -1 ) { return; }
    // initReviewDataForRole(userRole);
    util.loadPendingDataStats();
}
// function initReviewDataForRole(userRole) {
//     const map = {
//         contributor: contributor.initDataContributorReviewPanel,
//         // editor: editor.initEditorReviewPanel,
//         // manager: manager.loadManagerPendingData,
//         // super: manager.initAdminDataReviewPanel,
//     };
//     return map[userRole] ? map[userRole]() : null;
// }

export function loadPendingDataStats () {
    return util.loadPendingDataStats();
}
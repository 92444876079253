/**
 * Handles sata submit.
 *
 * Export
 *     onSubmitError
 *     pushFormPendingData
 *     submitForm
 *
 * TOC
 *     SUBMIT FORM
 *         DATA PREPARATION
 *     PUSH DATA
 *         QUARANTINED
 *         EVENTS
 */
import { _u } from '~util';
import { _data, _elems, _form, _state, _val } from '~form';
import * as after from './after-submit.js';
import { prepareFormData } from './prepare/prepare-data-main.js';
/** ======================= SUBMIT FORM ================================ */
export function submitForm( fLvl ) {                                  /*dbug-log*///console.log("   --submitForm fLvl[%s]", fLvl);
    _elems( 'toggleWaitOverlay', [true] );
    _elems( 'toggleSubmitBttn', [fLvl, false] );
    handleSubmitOnceFormDataReady( _state( 'getFormState', [fLvl] ) );
}
function handleSubmitOnceFormDataReady( confg ) {
    if ( confg.name === 'Citation' ) { //Ensure citation-text has been generated
        return window.setTimeout( () => prepCitationDataAndSubmit( confg ), 1000 );
    }
    prepDataAndHandleSubmit( confg );
}
function prepCitationDataAndSubmit( confg ) {
    _form( 'setFinalCitation', [confg.group] );
    prepDataAndHandleSubmit( confg );
}
/** ----------------------- DATA PREPARATION -------------------------------- */
function prepDataAndHandleSubmit( confg ) {
    prepareFormData( confg )
    .then( data => handleFormSubmit( data, confg ) );
}
function handleFormSubmit( fData, confg ) {
    if ( fData.fails ) { return handleDataPrepFailure( fData.fails ); } /*dbug-log*///console.log("   --submitFormData fData[%O] confg[%O]", fData, confg);
    const pushData =  ifDataQuarantined( confg ) ? pushFormPendingData : pushDataEntry;
    pushData( confg, fData );
}
function handleDataPrepFailure( fails ) {
    if ( fails === 'handled' ) { return _elems( 'toggleWaitOverlay', [false] ); }
    _val( 'errUpdatingData', ['dataPrepFail', fails] );
}
/**
 * All data created by contributors is quarantined until reviewed and is handled
 * via the PendingData process.
 * @param  {object} confg Form configuration
 * @return {boolean}
 */
function ifDataQuarantined( confg ) {
    return confg.pending || _u.isContributorUser();
}
/** ========================== PUSH DATA ==================================== */
function pushDataEntry( confg, data ) {
    const url = `crud/entity/${ confg.action }`;
    pushData( data, url, confg.group );
}
function pushData( data, url, fLvl ) {                                /*dbug-log*///console.log("   --pushData data[%O]", data);
    _u.sendAjaxQuery( data, url, entrySuccess, entryError );                 //_u.logInProdEnv('data = ', JSON.stringify(data)]);
   // Shows alerts from server errors
    function entryError() { //Send more accurate error response codes...
        after.onSubmitError( fLvl, ...arguments );
    }
    function entrySuccess( data ) {
        after.onSubmitSuccess( fLvl, data );
    }
}
/** ------------------------ QUARANTINED ------------------------------------ */
/**
 * Adds data necessary for the quarantine and review process and pushed to server.
 * @param  {obj} confg  Form-configuration
 * @param  {obj} data   Form-data preared for data-entry.. Not set when just pushing PendingData.
 * @param  {obj} fLvl   When just pushing PendingData.
 */
export function pushFormPendingData( confg, data = {}, fLvl ) {       /*dbug-log*///console.log("   --pushFormPendingData data[%O] confg[%O]", data, confg);
    confg = confg ? confg : _state( 'getFormState', [fLvl] );
    confg.submitted = true;
    _data( 'submitFormPendingData', [data, confg] );
}
/** --------------------------- EVENTS -------------------------------------- */
export function onSubmitError() {
    after.onSubmitError( ...arguments );
}
export function onSubmitSuccess() {
    after.onSubmitSuccess( ...arguments );
}
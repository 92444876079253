/**
 * Taxon todo
 *
 * Export
 *     initCreateForm
 *
 * TOC
 *     CREATE
 *         PARAMS
 *         FINISH BUILD
 *     EDIT
 *         PARAMS
 *         PARENT-TAXON SELECT
 *     REVIEW
 */
import {  _u } from '~util';
import { _elems, _form, _state } from '~form';
import * as rvw from './review/txn-review-main.js';
import * as validate from './validate/validate-main.ts';
/* ========================= CREATE ========================================= */
export function initCreateForm ( rank, v ) {                        /*temp-log*/console.log( '           /--Taxon CREATE [%s]Form [%s]', rank, v );
    const val = v === 'create' ? '' : v;
    if ( validate.ifMissingParentTaxon( rank, 'sub2' ) ) return;
    return _elems( 'initSubForm', [getCreateFormParams( rank, val, 'create' )] )
        .then( status => finishTxnCreateFormInit( status, rank ) );
}
/* -------------------------- PARAMS ---------------------------------------- */
function getCreateFormParams( rank, v, action ) {
    const params = {
        action: 'create',
        appendForm: form => $( `#${ rank }_f` ).append( form ),
        combo: rank,
        group: 'sub2',
        name: 'Taxon',
        style: 'sml-sub-form',
    };
    if ( action === 'create' ) params.vals = getTaxonCreateStateVals( v, rank );
    return params;
}
function getTaxonCreateStateVals( val, rank ) {
    return {
        DisplayName: val,
        Group: _state( 'getFieldState', ['sub', 'Group'] ),
        Rank: rank,
        Parent: _form( 'getSelectedTaxon', [rank] ).id,
        'Sub-Group': _state( 'getFieldState', ['sub', 'Sub-Group'] ),
    };
}
/* ----------------------- FINISH BUILD ------------------------------------- */
function finishTxnCreateFormInit ( status, _rank ) {
    if ( !status ) return  //Error handled elsewhere
    initDisplayNameField( 'sub2' );
}
function initDisplayNameField ( fLvl ) {
    $( '#DisplayName_f input' ).change( validate.validateAndVerifyTaxonym.bind( null, fLvl ) );
    $( '#DisplayName_f input' ).focus();
    triggerOnChangeIfNameEntered();
}
/**
 * Triggers the change event on the display name field if the field has a value.
 * This triggers the validation and verification of the taxon name before
 * enabling the submit button.
 */
function triggerOnChangeIfNameEntered () {
    if ( $( '#DisplayName_f input' ).val() ) $( '#DisplayName_f input' ).change();
}
/* =========================== EDIT ========================================= */
export function initEditForm ( entity, id ) {                       /*temp-log*/console.log( '           >--Taxon EDIT [%s][%s]', entity, id );
    const p = getEditFormParams( id );                              /*dbug-log*///console.log( '   --params[%O]', p );
    return _elems( 'initForm', [p] )
        .then( finishEditFormBuild );
}
/* -------------------------- PARAMS ---------------------------------------- */
function getEditFormParams( id ) {                                  /*dbug-log*///console.log('--getEditFormParams id[%s]', id);
    return {
        action: 'edit',
        group: 'top',
        id: id,
        initCombos: initCombos,
        name: 'Taxon',
        style: 'sml-form',
    };
}
function initCombos() {
    const events = {
        Rank: { onChange: validate.onRankChangeValidate },
        Parent: { onChange: validate.onParentChange },
    };
    _elems( 'initFormCombos', ['top', events] );
}
/* -------------------- PARENT-TAXON SELECT --------------------------------- */
function finishEditFormBuild ( status ) {                           /*dbug-log*///console.log( '--finishEditFormBuild status[%s]', status );
    if ( !status ) return; //Error handled elsewhere
    initDisplayNameField( 'top' );
    setSelectFormListener();
    handleParentTaxonInit();
}
function setSelectFormListener() {
    $( `#sel-Parent` )[0].selectize.on( 'focus', loadParentSelectForm );
}
function handleParentTaxonInit() {
    const parentId = _state( 'getFieldState', ['top', 'Parent'] );  /*dbug-log*///console.log( '--handleParentTaxonInit parentId[%O]', parentId );
    if ( !parentId ) return;     //todo: when does the parent field not have a value?
    $( '#sel-Parent' ).data( 'selTaxon', parentId );
    _form( 'buildOptAndUpdateCombo', ['Parent', parentId, 'silent'] );
}
function loadParentSelectForm() {
    const gId = _state( 'getFieldState', ['top', 'Group'] );
    const sgId = _state( 'getFieldState', ['top', 'Sub-Group'] );   /*dbug-log*///console.log('--loadParentTaonxSelectForm g[%s] sg[%s]', gId, sgId);
    _form( 'initFieldTaxonSelect', ['Parent', gId, sgId, validate.onParentChange] );
}
/* ========================= REVIEW ========================================= */
export function initReviewForm( pRcrd ) {
    const isCreate = pRcrd.data.review.action === 'create';         /*temp-log*///console.log('           >--Taxon REVIEW [%s] pRcrd[%O]', (isCreate ? 'CREATE' : 'EDIT'), _u.snapshot(pRcrd));
    const formHandlers = getTxnReviewFormHandlers( isCreate );
    const initTxnReview = isCreate ? rvw.reviewCreate : rvw.reviewEdit;
    return initTxnReview( pRcrd, formHandlers );
}
function getTxnReviewFormHandlers( isCreate ) {
    return {
        getParams: isCreate ? getCreateFormParams : getEditFormParams,
        finishBuild: isCreate ? finishTxnCreateFormInit : finishEditFormBuild
    };
}
/**
 * Returns a container with the form's walkthrough button, if walkthrough set.
 *
 * Export
 *     getFormHelpElems
 */
import { _el } from '~util';
/* ===================== FORM WALKTHROUGH =================================== */
export function getFormHelpElems ( fLvl, infoSteps ) {                 /*dbug-log*///console.log('+--getFormHelpElems fLvl[%s] infoSteps[%O]', fLvl, infoSteps);
    const cntnr = _el.getElem( 'div', { id: fLvl + '-help', class: 'flex-row' } );
    $( cntnr ).append( getFormWalkthroughBttn( fLvl, infoSteps ) );
    return cntnr;
}
function getFormWalkthroughBttn ( fLvl, infoSteps ) {
    if ( !infoSteps ) { return $( '<div>' )[0]; }
    const titleInfo = "Hover your mouse over any field and it's help popup will show, if it has one.";
    const bttn = buildWalkthroughButton( fLvl, titleInfo );
    $( bttn ).on( 'click', _el.showFormTutorialModal.bind( null, fLvl ) );
    setIntroWalkthroughAttrs( bttn, titleInfo, ++infoSteps, fLvl );
    return bttn;
}
function buildWalkthroughButton ( fLvl, titleInfo ) {
    const attr = {
        id: fLvl + '-walkthrough',
        class: 'hlp-bttn',
        title: titleInfo,
        type: 'button',
        value: '?',
    };
    return _el.getElem( 'input', attr );
}
function setIntroWalkthroughAttrs ( bttn, titleInfo, infoSteps, fLvl ) {
    $( bttn ).attr( {
        'data-intro': titleInfo,
        'data-step': infoSteps
    } );
}
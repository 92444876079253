/**
 * Citation-form facade.
 */
import { _cmbx } from '~util';
import * as cForm from './citation-form.js';
import * as types from './cit-type-fields.js';
/* ========================= INIT FORM ====================================== */
/* --------------------------- CREATE --------------------------------------- */
/** Init form when a new citation title is entered into the combobox. */
export function initCreateForm() {
    return cForm.initCreateForm( ...arguments );
}
/* ---------------------------- EDIT ---------------------------------------- */
/** Shows the Citation  sub-form and disables the publication combobox. */
export function initEditForm() {
    return cForm.initEditForm( ...arguments );
}
/* ---------------------------- REVIEW -------------------------------------- */
export function initReviewForm() {
    return cForm.initReviewForm( ...arguments );
}
/* --------------------- FINISH REBUILD ------------------------------------- */
export function finishFieldLoad ( fLvl ) {
    types.handleCitationTypeData( _cmbx.getSelTxt( 'CitationType' ), fLvl );
    cForm.handleCitText( fLvl );
}
export function loadCitTypeFields() {
    return types.loadCitTypeFields( ...arguments );
}
/* ======================= AUTO-GENERATE CITATION =========================== */
/** Note: to prevent multiple rebuilds, a timeout is used. */
export function handleCitText() {
    cForm.handleCitText( ...arguments );
}
export function setFinalCitation() {
    cForm.setFinalCitation( ...arguments );
}
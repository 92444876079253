/**
 * Interaction form configuration.
 */
export default function(action, entity) {
	return {
        fields: getInteractionFieldConfg(),
        data: {
            create: ['author', 'citation', 'group', 'interactionType', 'location', 'publication',
                'publisher', 'rankNames', 'source', 'tag', 'taxon', 'validInteraction'],
            edit: ['author', 'citation', 'group', 'interaction', 'interactionType', 'location',
                'publication', 'publisher', 'rankNames', 'source', 'tag', 'taxon', 'validInteraction'],
        },
        name: entity,
        pinnable: action === 'create', // phrasing?
        style: 'lrg-form',
        views: {
            all: [
                ['Publication', 'CitationTitle'],
                ['Country-Region', 'Location'],
                ['Subject', 'Object'],
                ['InteractionType','InteractionTags' ],
                [
                    { fields: [['Source','Pages'], 'Date', 'Season' ] },
                    { fields: ['Quote', 'Notes'], 'class': 'row-field' }
                ]
            ],
        },
    };
}
function getInteractionFieldConfg() {
    return {
        CitationTitle: {
            entity: 'Source',
            name: 'CitationTitle',
            prep: {
                dataClasses: {
                    detail: 'citation' },
                renameField: ['Source'],  //TODO2: maybe not necessary because reference values might be set with the entity prop
            },
            prop: {
                core: 'source'
            },
            required: true,
            type: 'select'
        },
        'Country-Region': {
            id: 'Country-Region', //label is used for id, but '/' is an invalid selector character
            label: 'Country/Region',
            name: 'Country-Region',
            prep: {},
            type: 'select'
        },
        Date: {
            class: 'date-range-input w-9',
            id: 'int-date', //Inputs will have ID's appended with '-start' and '-end'
            name: 'Date',
            prop: {
                core: 'date'
            },
            type: 'dateRange'
        },
        InteractionTags: {
            entity: 'Tag',
            info: {
                tooltip: 'Tags indicate the part of the object organism in the interaction'
            },
            label: 'Tags',
            misc: {
                defaultTags: [], //Always valid and available when tags load.
                tagType: 'interaction type'
            },
            name: 'InteractionTags',
            prep: {
                validateTags: []
            },
            prop: {
                core: 'tags'
            },
            type: 'tags'
        },
        InteractionType: {
            entity: 'InteractionType',
            info: {
                tooltip: 'Read about Interaction Types on the About->Definitions page.',
                intro: `Read about Interaction Types on the <a href="/definitions"
                    target="_blank">Definitions page</a>.`
            },
            label: 'Type',
            misc: {
                customValueStore: true
            },
            name: 'InteractionType',
            prop: {
                core: 'interactionType'
            },
            required: true,
            type: 'select'
        },
        Location: {
            entity: 'Location',
            name: 'Location',
            prop: {
                core: 'location'
            },
            required: true,
            type: 'select',
        },
        Note: {
            clss: 'flex-grow',
            label: 'Notes',
            name: 'Note',
            prop: {
                core: 'note'
            },
            type: 'fullTextArea'
        },
        Object: {
            entity: 'Taxon',
            info: {
                tooltip: 'Check bat taxonomy and names at www.batnames.org. ' +
                'If the name in the publication is no longer accepted, use the currently' +
                'accepted name and note the name used in the publication in the Notes Field.',
                intro: 'Check bat taxonomy and names at <a href="www.batnames.org" target="_blank">www.batnames.org</a>. ' +
                'If the name in the publication is no longer accepted, use the currently' +
                'accepted name and note the name used in the publication in the Notes Field.',
            },
            name: 'Object',
            prep: {
                dataClasses: {
                    detail: false }, //necessary because the name and entity fields are different, signaling a detail class 
                renameField: ['Object']
            },
            prop: {
                core: 'object'
            },
            required: true,
            type: 'select'
        },
        Pages: {
            class: 'w-6',
            name: 'Pages',
            prop: {
                core: 'pages'
            },
            type: 'page'
        },
        Publication: {
            entity: 'Source',
            name: 'Publication',
            prep: {},
            type: 'select'
        },
        Quote: {
            class: 'flex-grow',
            info: {
                tooltip: 'Source text citing this specific interaction.'
            },
            name: 'Quote',
            prop: {
                core: 'quote'
            },
            type: 'fullTextArea'
        },
        Season: {
            name: 'Season',
            misc: {
                tagType: 'season'
            },
            prep: {
                handleSeasonTags: []
            },
            prop: {
                core: 'tags'
            },
            type: 'select'
        },
        Source: {
            class: 'w-7',
            name: 'Source',
            misc: {
                opts: ['Primary', 'Secondary'],
                tagType: 'source'
            },
            prep: {
                handleSecondaryTag: []
            },
            prop: {
                core: 'tags' //If secondary tag...
            },
            required: true,
            type: 'select'
        },
        Subject: {
            entity: 'Taxon',
            info: {
                tooltip: 'The active taxon in the interaction. Check bat taxonomy and names ' +
                'at www.batnames.org. ' +
                'If the name in the publication is no longer accepted, use the currently' +
                'accepted name and note the name used in the publication in the Notes Field.',
                intro: 'The active taxon in the interaction. Check bat taxonomy and names ' +
                'at <a href="www.batnames.org" target="_blank">www.batnames.org</a>. ' +
                'If the name in the publication is no longer accepted, use the currently' +
                'accepted name and note the name used in the publication in the Notes Field.',
            },
            name: 'Subject',
            prep: {
                dataClasses: {
                    detail: false }, //necessary because the name and entity fields are different, signaling a detail class
                renameField: ['Subject']
            },
            prop: {
                core: 'subject'
            },
            required: true,
            type: 'select'
        },
    };
}
/**
 * Modifies taxon-data for local storage:
 * - rankNames - an object with each rank name (k) and it's id and ordinal (v).
 * - groupNames - an object with each group name (k) and it's id.
 * - [group][subGroup][rank]Names - object with all taxa in subGroup at the rank: name (k) id (v)
 * - [group]SubGroupNames - object with subGroup taxa root displayNames(k) and id(v)
 *
 * Export
 *     modifyTxnDataForLocalDb
 *
 * TOC
 *     RANKS
 *     TAXA BY GROUP AND RANK
 *         SUB-GROUPS
 *     MODIFY GROUP DATA
 */
import { _db } from '~util';
import { EntityRecords, IdsByName, objectKeys, objectValues, SerializedEntity } from '~types';
import { getNameObj } from '../init-helpers';

type TaxonData = {
    group: EntityRecords;
    groupRoot: EntityRecords;
    rank: EntityRecords;
    taxon: EntityRecords;
};
export function modifyTxnDataForLocalDb ( data: TaxonData ): void {                     /*dbug-log*///console.log("modifyTxnDataForLocalDb called. data = %O", data);
    storeGroupNames( data.group );
    modifyGroupData( data.group, data.rank );
    storeTaxaByRankAndGroup( data.taxon, data.group );
    storeRankData( data.rank );
    _db.removeData( 'groupRoot' );
}
/* ========================= GROUP DATA ===================================== */
function storeGroupNames ( groups: EntityRecords ): void {
    const groupNames = getNameObj( objectKeys( groups ), groups );     /*dbug-log*///console.log('    -- storeGroupNames groupNames[%O]', groupNames);
    _db.storeData( 'groupNames', groupNames );
}
/* --------------------------- MODIFY --------------------------------------- */
function modifyGroupData ( groups: EntityRecords, ranks: EntityRecords ): void {
    objectValues( groups ).forEach( g => modifyGroup( g, ranks ) );
    _db.storeData( 'group', groups );
}
function modifyGroup ( group: SerializedEntity, ranks: EntityRecords ): void {
    buildSubGroupObject( group );
    flattenGroupSubRanks( group, ranks );
}
function buildSubGroupObject ( group: SerializedEntity ): void {
    const subGroups: EntityRecords = {};
    group.subGroups.forEach( ( g: SerializedEntity ) => subGroups[g.id] = g );
    group.subGroups = subGroups;                                /*dbug-log*///console.log('buildSubGroupObject = %O', subGroups);
}
function flattenGroupSubRanks ( group: SerializedEntity, ranks: EntityRecords ): void {                          /*dbug-log*///console.log('flattenGroupSubRanks [%O]', group)
    objectValues( group.subGroups ).forEach( sg => flattenSubGroupRanks( sg, ranks ) );
}
function flattenSubGroupRanks ( subGroup: SerializedEntity, ranks: EntityRecords ): void {
    subGroup.subRanks = fillRankNames( JSON.parse( subGroup.subRanks ), ranks );
}
function fillRankNames ( rankAry: number[], ranks: EntityRecords ): string[] {
    return rankAry.map( id => {
        const name = ranks[id]?.displayName;
        if ( !name ) throw Error( 'Rank display name not found' );
        return name;
    } );
}
/* ================= TAXA BY GROUP AND RANK ================================= */
function storeTaxaByRankAndGroup ( taxa: EntityRecords, groups: EntityRecords ): void {
    for ( let groupId in groups ) {
        const group = groups[groupId];
        if ( !group ) throw Error( 'Group not found ' );
        sortTaxaBySubGroupRoot( group, group.subGroups, taxa );
        storeGroupSubRootNames( group, taxa );
    }
    _db.storeData( 'group', groups );
    _db.storeData( 'taxon', taxa );
}
function sortTaxaBySubGroupRoot (
    group: SerializedEntity,
    gRoots: EntityRecords,
    taxa: EntityRecords
): void {                /*dbug-log*///console.log('-sortTaxaBySubGroupRoot group %O', group);
    for ( let id in gRoots ) {
        const gRoot = gRoots[id];
        const gTaxon = taxa[gRoot?.taxon];
        if ( !gRoot || !gTaxon ) throw Error( 'Group taxon not found' );
        separateAndStoreGroupTaxa( gTaxon, gRoot, group, taxa );
    }
}
function separateAndStoreGroupTaxa (
    taxon: SerializedEntity,
    subGroup: SerializedEntity,
    group: SerializedEntity,
    taxa: EntityRecords
): void {                                           /*dbug-log*///console.log('--separateAndStoreGroupTaxa group = %O subGroup = %O taxon = %O', group, subGroup, taxon);
    addGroupDataToTaxon( taxon, subGroup, group );
    const data = separateGroupTaxaByRank( taxon.children, subGroup, group, taxa );
    storeTaxaByGroupAndRank( data, subGroup, group );
}
type TaxaIdsByRankAndName = { [name: string]: IdsByName; };
function separateGroupTaxaByRank (
    cTaxa: number[],
    subGroup: SerializedEntity,
    group: SerializedEntity,
    taxa: EntityRecords
): TaxaIdsByRankAndName {                                                 /*dbug-log*///console.log('---separateAndStoreGroupTaxa group = %O subGroup = %O rank[%s]', group, subGroup, rank);
    const data: TaxaIdsByRankAndName = {};
    cTaxa.forEach( separateTaxonAndChildren );
    return data;

    function separateTaxonAndChildren ( id: number ): void {
        const taxon = taxa[id];
        if ( !taxon ) throw Error( 'Taxon not found' );
        addToGroupRank( taxon, taxon.rank.displayName );
        addGroupDataToTaxon( taxon, subGroup, group );
        taxon.children.forEach( separateTaxonAndChildren );
    }
    function addToGroupRank ( taxon: SerializedEntity, rank: string ): void {
        if ( !data[rank] ) data[rank] = {};
        data[rank]![taxon.name] = taxon.id;
    }
}
function addGroupDataToTaxon (
    taxon: SerializedEntity,
    subGroup: SerializedEntity,
    group: SerializedEntity
): void {
    taxon.group = {
        id: group.id,
        displayName: group.displayName,
        pluralName: group.pluralName,
        subGroup: { id: subGroup.id, name: subGroup.name }
    };
}
function storeTaxaByGroupAndRank (
    taxonObj: TaxaIdsByRankAndName,
    subGroup: SerializedEntity,
    group: SerializedEntity
): void {
    for ( let rank in taxonObj ) {
        const prop = group.displayName + subGroup.name + rank + 'Names';/*dbug-log*///console.log("storeTaxaByGroupAndRank [%s] = %O", prop, taxonObj[rank]);
        _db.storeData( prop, taxonObj[rank]! );
    }
}
/* -------------------------- SUB-GROUPS ------------------------------------ */
function storeGroupSubRootNames ( group: SerializedEntity, taxa: EntityRecords ): void {                      /*dbug-log*///console.log("--storeGroupSubRootNames group[%O] taxa[%O]", group, taxa);
    const prop = group.displayName + 'SubGroupNames';
    const data = buildSubGroupOpts( objectValues( group.subGroups ), taxa );/*dbug-log*///console.log('-- [%s] data[%O]', prop, data);
    _db.storeData( prop, data );
}
function buildSubGroupOpts ( subGroups: SerializedEntity[], taxa: EntityRecords ): IdsByName {
    const data: IdsByName = {};
    subGroups.forEach( addSubGroup );
    return data;

    function addSubGroup ( sGroup: SerializedEntity ): void {
        const subGroupName = taxa[sGroup.taxon]?.displayName;
        if ( !subGroupName ) throw Error( 'SubGroup name not found' );
        data[subGroupName] = sGroup.id;
    }
}
/* ========================= RANKS ========================================== */
function storeRankData ( rankData: EntityRecords ): void {
    const ranks: { [name: string]: { id: number, ord: number; }; } = {};
    let order = objectValues( rankData ).sort( orderRanks );             /*dbug-log*///console.log('--storeRankData order[%O]', order);
    $.each( order, addRankData );
    _db.storeData( 'rankNames', ranks );
    _db.storeData( 'orderedRanks', order.map( rank => rank.displayName ) );
    function addRankData ( i: number, rank: SerializedEntity ): void {
        const rankName = rank?.displayName;
        if ( !rankName ) throw Error( 'Rank name not found' );
        ranks[rankName] = { id: rank.id, ord: i + 1 };
    }
}
function orderRanks ( a: SerializedEntity, b: SerializedEntity ): -1 | 0 | 1 {
    const x = a?.ordinal;
    const y = b?.ordinal;
    if ( !x || !y ) throw Error( 'Unable to sort rank data' );
    return x < y ? 1 : x > y ? -1 : 0;
}
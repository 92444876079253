/**
 * Utility types.
 *
 * TOC
 *     HTML
 *     ENTITY
 *     UTILITY ALIASES
 */
export type StringObject = {
    [key: string]: string;
};
/** ============================= HTML ====================================== */
export type OptionObject = {
    text: string;
    value: string;
    group?: string;  /* For comboboxes with grouped, ie categorized, dropdown menus. */
};
/** =========================== ENTITY ====================================== */
export type CoreEntity = 'interaction' | 'location' | 'source' | 'taxon';
export type DetailEntity = 'author' | 'citation' | 'publication' | 'publisher' | 'geoJson';


export type ServerEntityData = {
    [name: string]: EntityRecords | SerializedEntity[];
};
export type SerializedEntity = {
    id: number;
    displayName?: string;
    //// @ts-expect-error "Will be fixed once each entity is explicitly typed"
    [key: string | number]: any;
};
export type EntityRecords = {
    [id: number]: SerializedEntity;
};
export type IdsByName = {
    [name: string]: number | string;
};
export type User = {
    id?: number;
    fullname?: string;
    role: 'super' | 'admin' | 'manager' | 'editor' | 'contributor' | 'user' | 'visitor';
    username: string | null;
};
/** ======================== UTILITY ALIASES ================================ */
/* ------------------------------ ARRAY ------------------------------------- */
/** Properly types .filter() */
export function isNonNullable<TValue> ( value: TValue | undefined | null ): value is TValue {
    return value !== null && value !== undefined;
}
export function isTruthy<TValue> ( value: TValue | undefined | null | false ): value is TValue {
    return !!value;
}
/* ----------------------------- OBJECT ------------------------------------- */
export function objectKeys<T extends object> ( obj: T ) {
    return Object.keys( obj ) as ( keyof T & string )[];
}
export function keyOf<T extends { [key: string]: Function; }, U extends Extract<keyof T, string>> (
    object: T,
    key: string
): keyof T {
    return key as U;
}
export function objectValues<T extends object, U extends keyof T> ( obj: T ): T[U][] {
    return Object.values( obj ) as T[U][];
}
export type KeyedDataObject<T extends readonly string[], U> = {
    [Key in T[number]]: U;
};
/* ------------------------- MODIFY TYPES ----------------------------------- */
// type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

/**
 * Returns the column-confg object for the agGrid table.
 *
 * Export
 *     getInteractionRowIcons
 *     getShowIcon
 *
 * TOC
 *     TAXON-GROUP ICONS
 *         GROUP NAME
 *         GROUP STYLE
 *         INTERACTINO ARROW
 *     SHOW INTERACTION-PAGE ICON
 *     BUILD ICON
 */
import { _u } from '~util';

let tblState;
const icons = {};

export function getInteractionRowTreeIcons( data, tState ) {          /*dbug-log*///console.log('--getInteractionRowIcons row[%O] tState[%O]', data, tState);
    if ( !data.subjGroupId ) { return ''; }  // local database is not fully downloaded
    tblState = tState;
    const treeIcons = [
        getTaxonGroupIcon( 'subject', data ),
        getInteractionTypeArrow( data ),
        getTaxonGroupIcon( 'object', data ),
        getShowIcon( 'interaction', data.id, data.isQuarantined )
    ]; //elems are added in reverse into the parent, agGrid's row-span
    const html = treeIcons.reverse().join( ' ' );                     /*dbug-log*///console.log('--icon html [%s]', html);
    return html;
}
/* ===================== TAXON-GROUP ICONS ================================== */
function getTaxonGroupIcon( role, data ) {
    const title = getTxnGroupName( role, data );
    const group = getGroupIconName( title );                          /*dbug-log*///console.log('-- getTxnGroupIconSrc role[%s] group[%s]', role, group);
    const style = getGroupIconStyle( group );
    const src = getIconSrc( group );
    return buildIcon( src, style, title, `tree-icon ${ role }-icon` );
}
/* --------------------- GROUP NAME ----------------------------------------- */
function getGroupIconName( gName ) {
    const map = {
        Arthropod: 'bug',
    };
    return map[gName] ? map[gName] : _u.lcfirst( gName );
}
function getTxnGroupName( role, data ) {
    const prop = ( role === 'object' ? 'o' : 'su' ) +'bjGroupId';
    const id = data[prop];
    const group = tblState.data.group[id];
    return group.displayName;
}
/* -------------------- GROUP STYLE ----------------------------------------- */
function getGroupIconStyle( group ) {
    const map = {
        bat: '1.5',
        reptile: '1.5'
    };
    const width = ( map[group] ? map[group] : '1' ) + 'em';
    return `width:${ width };`;
}
/* ----------------- INTERACTION ARROW -------------------------------------- */
function getInteractionTypeArrow( data ) {
    const type = data.interactionType === 'Cohabitation' ? 'arrows-alt-h': 'arrow-right';
    return buildIcon( getIconSrc( type ), '', data.interactionType, 'tree-arrow-icon' );
}
/* ================= SHOW INTERACTION-PAGE ICON ============================= */
export function getShowIcon ( entity, id, isQuarantined, tState = tblState ) {       /*dbug-log*///console.log('--getShowIcon [%O]', arguments);
    if ( isQuarantined ) { return getQuarantinedAsterik() }
    tblState = tState;
    const icon = getShowIconHtml( _u.ucfirst( entity ) );
    return `<a href="${ getShowLink( entity, id ) }" target="_blank" rel="noopener noreferrer">${ icon }</a>`;
}
function getQuarantinedAsterik() {
    const style = "font-size:1.5em;font-weight:600;";
    const title = 'Data is pending review.';
    return `<span class="tree-icon" style="${ style }" title="${ title }">*</span>`;
}
function getShowIconHtml () {
    const title = "Show Interaction Details";
    const style = 'opacity:'+ ( tblState.flags.allDataAvailable ? 1 : 0 );
    const src = getIconSrc( 'search' );
    return buildIcon( src, style, title );
}
function getShowLink( entity, id ) {
    const link =  `${ $( 'body' ).data( 'base-url' ) }show/${ entity }/${ id }`;   /*dbug-log*///console.log('--getShowLink [%s]', link);
    return link;
}
/* ====================== BUILD ICON ======================================== */
function getIconSrc( name ) {                                         /*dbug-log*///console.log('-- getIconSrc [%s]', name);
    return icons[name] ? icons[name] : initIconSrc( name );
}
function initIconSrc( name ) {
    const src = require( `images/icons/${ name }.svg` );
    icons[name] = src;
    return src;
}
function buildIcon( src, style = '', title = '', clss = 'tree-show' ) {/*dbug-log*///console.log('-- builIcon src[%s] style[%s] title[%s]', src, style, title);
    const source = src ? `src="${ src }"` : '';
    return`<img ${ source } class="${ clss }" title="${ title }" alt="${ title }" style="${ style }">`;
}

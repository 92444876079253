/**
 * Returns the form-config for the passed entity and current field-display (all|simple).
 * TODO: REFACTOR AND DOCUMENT
 * { *: default confg-properties returned
 *    core: EntityName, ucfirst
 *    *data: { *edit: [dataProp, ..., create: [dataProp, ..., ] }
 *    *display: view, //Defaults to 'simple' display, if defined.
 *    *fields: {
 *         //CORE.FIELDS AND TYPE.FIELDS WILL BE MERGED IN.
 *        FieldName: { //DisplayName
 *            class: "",
 *            entity: "" //Server classname for the field data
 *            combo: "", //Confg-key used for the selectized combo ("true" before combo init)
 *            id: '' //set using 'name' otherwise
 *            info: {
 *                intro: "",
 *                *tooltip: ""(req) },
 *            label: Field label text (Name-prop used if absent)
 *            *name: FieldName,  [REQUIRED]
 *            prep: { //prep data for server
 *                dataClasses: {
 *                    core: "" (When not the entity/classname)
 *                    detail: "" (When not the field-name) }
 *                funcNameString: [arguments]
 *            prop: { core||detail: serverPropString } //used to set edit data
 *            required: true, //Set if true
 *            *type: "", null if field-data auto-derived [REQUIRED]
 *        }, ...
 *    },
 *    *group: top|sub|sub2, //SET DURING CONFG BUILD
 *    infoSteps: ##, //Count of fields with steps for the form tutorial, intro.js
 *    misc: {
 *        entityProp: value
 *    },
 *    *name: formName (entity or su|object) ucfirst
 *    onInvalidInput: Fired when an input fails HTML validation  //TODO
 *    onValidInput: Fired after invalid input validates (perhaps merge with all checkReqFieldsAndToggleSubmitBttn calls?)  //TODO
 *    prep: [], //server-data handled before form-submit
 *    *style: '', //CSS style classes: [lrg|med|sml]-form
 *    type: Type name, once selected. Only for entities with subTypes
 *    types: { //ENTITY SUB-TYPES
 *         Type name: {
 *              name: (req)
 *              [confg prop with type-data]
 *         }
 *    },
 *    view: [] //RETURNED VALUE IS views[display] MAPPED WITH EACH FIELD'S CONFG.
 * }
 *
 * Export
 *     getFormConfg
 *     updateCurrentFieldView
 *     onEntityTypeChangeUpdateConfg
 *     onFieldViewChangeUpdateConfg
 *
 * TOC
 *     INIT FORM-CONFG
 *     REBUILD FORM-CONFG
 *         FORM-TYPE CHANGED
 *         FIELD-VIEW CHANGED
 *     CONFG BUILDERS
 *         BASE CONFG
 *         FIELD CONFG
 *     GET CONFG DATA
 */
// import { _u } from '~util';
import { _state } from '~form';
import * as cUtil from './util/confg-util-main.js';
import { getGroupFieldViewOrder } from './entity/group-confg.js';
/* ====================== INIT FORM-CONFG =================================== */
/**
 * Form-confg is built twice during initialization. For forms where entity data
 * influences form field displays: the confg is loaded, necessary data is downloaded,
 * and this data informs the final configuration. This causes some redunant builds
 * for forms that are unchanged depending on the "type" of data being loaded.
 */
export function finishFormStateInit( c ) {                            /*dbug-log*///console.log('+--finishFormStateInit confg[%O]', _u.snapshot(c));
    c.display = c.action === 'create' && c.views.simple ? 'simple' : 'all';
    updateConfg( c );
}
/* ====================== ON FORM-CONFG CHANGE ============================== */
/* ------------------------ FORM-TYPE CHANGED ------------------------------- */
/** [onEntityTypeChangeUpdateConfg description] */
export function onEntityTypeChangeUpdateConfg( fLvl ) {
    const confg = _state( 'getFormState', [fLvl] );                   /*dbug-log*///console.log('--onEntityTypeChangeUpdateConfg confg[%O]', confg)
    resetConfgDefaults( confg );
    updateConfg( confg );
}
/* ----------------------- FIELD-VIEW CHANGED ------------------------------- */
/** Toggles between showing a simple set of fields and all available. */
export function onFieldViewChangeUpdateConfg( fLvl ) {
    const confg = _state( 'getFormState', [fLvl] );
    confg.display = confg.display === 'all' ? 'simple' : 'all';
    resetConfgDefaults( confg );
    updateConfg( confg );
}
/* ====================== REBUILD FORM-CONFG ================================ */
function updateConfg( c ) {                                           /*dbug-log*///console.log('   --updateConfg[%s][%O]', c.name, _u.snapshot(c));
    const mConfg = getBaseConfg( c.action, c.name, c.type, c.pending );
    cUtil.mergeFieldConfg( c.fields, mConfg.fields, 'finalMerge' );
    updateCurrentFieldView( c, mConfg.views );
}
export function updateCurrentFieldView( c, mViews ) {
    const views = c.action !== 'select' ? mViews : getGroupView( c ); /*dbug-log*///console.log('--updateCurrentFieldView views[%O]', views);
    cUtil.buildViewConfg( c, views );
    updateActiveFieldFlags( c.fields );
}
function getGroupView( c ) {                                          /*dbug-log*///console.log('--getGroupView c[%O]', c)
    return {
        all: getGroupFieldViewOrder( c.fields['Sub-Group'] )
    };
}
/* ---------------------- RESET VOLATILE CONFG ------------------------------ */
function resetConfgDefaults( c ) {
    resetFieldConfgDefaults( c.fields );
    delete c.view
}
function resetFieldConfgDefaults( fields ) {                          /*dbug-log*///console.log('  --resetFieldConfgDefaults [%O]',fields);
    Object.values( fields ).forEach( resetFieldDefaults );
}
/**
 * Note: Resetting 'shown' is necessary due to this being the reset when the
 * source-type changes, but it messes with the sub-group-init for taxon forms.
 */
function resetFieldDefaults( fConfg ) {                               /*dbug-log*///console.log('     --resetFieldDefaults [%O]', fConfg);
    const props = [ 'active', 'combo', 'input', 'shown' ];
    if ( fConfg.name === 'Sub-Group' ) { props.pop(); }
    props.forEach( p => delete fConfg[p] );                           /*dbug-log*///console.log('     --after reset [%O]', _u.snapshot(fConfg));
    if ( fConfg.count ) { fConfg.count = 1 }
}
function updateActiveFieldFlags( fields ) {                           /*dbug-log*///console.log('  --updateActiveFieldFlags fields[%O]', fields);
    Object.values( fields ).forEach( setActiveFlag );
}
function setActiveFlag( field ) {                                     /*dbug-log*///console.log('  --setActiveFlag field[%s][%O]', field.name, _u.snapshot(field));
    field.active = field.current ? field.current : false;
    delete field.current;
}
/* ====================== CONFG BUILDERS ==================================== */
export function mergeIntoFormConfg( confg, mConfg ) {
    cUtil.mergeIntoFormConfg( confg, mConfg );
}
export function buildViewConfg( c ) {
    return cUtil.buildViewConfg( c, c.views );
}
/* ----------------------- BASE CONFG --------------------------------------- */
export function getBaseConfg( action, entity, type, pRcrd ) {         /*dbug-log*///console.log('   --getBaseConfg action[%s] entity[%s] type?[%O]', action, entity, type);
    const cAction = getActionForConfg( action, pRcrd );
    const confg = cUtil.getBaseFormConfg( cAction, entity );
    if ( confg.core ) { mergeCoreEntityConfg( confg, cAction ); }
    handleEntityTypeConfg( confg, type, action, pRcrd );              /*dbug-log*///console.log('   --[%s] = [%O]', confg.name, _u.snapshot(confg));
    return confg;
}
/** [mergeCoreEntityConfg description] */
function mergeCoreEntityConfg( c, cAction ) {
    const coreConfg = cUtil.getBaseFormConfg( cAction, c.core );      /*dbug-log*///console.log('   --mergeCoreEntityConfg confg[%O], coreConfg[%O]', c, coreConfg);
    mergeIntoFormConfg( c, coreConfg );
}
function handleEntityTypeConfg( confg, type, action, pRcrd ) {        /*dbug-log*///console.log('   -- handleEntityTypeConfg confg[%O] type[%s] action[%s] pRcrd[%O]', _u.snapshot(confg), type, action, pRcrd);
    if ( type ) {
        mergeIntoFormConfg( confg, confg.types[type] );
        confg.type = type;
    } else {
        handleFormTypeFields( confg, action, pRcrd );
    }
    delete confg.types;
}
/** Handles forms with field-configuration based upon form-action. */
function handleFormTypeFields( confg, action, pRcrd ) {               /*dbug-log*///console.log('   --handleFormTypeFields confg[%O] pRcrd[%O]', _u.snapshot(confg), pRcrd);
    if ( confg.views.all.length ) { return; }
    mergeIntoFormConfg( confg, confg.types[getActionForConfg( action, pRcrd )] );
}
function getActionForConfg( action, pRcrd ) {
    action = pRcrd ? pRcrd.data.review.action : action;  // Edit forms are used for delete actions
    const confgAction = action === 'delete' ? 'edit' : action;      /*dbug-log*///console.log('       -- getActionForConfg formAction[%s] confgAction[%s] pRcrd[%O]', action, confgAction, pRcrd);
    return confgAction;
}
/* ====================== GET CONFG DATA ==================================== */
export function getConfgData( entity, prop ) {
    const cfg = getBaseConfg( null, entity );
    return cfg[prop];
}
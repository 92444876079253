/**
 * Taxon review-form.
 *
 * Export
 *     reviewCreate
 *     reviewEdit
 *
 * TOC
 *     CREATE
 *     EDIT
 */
/* ========================= CREATE ========================================= */
export { reviewCreate } from './create-txn-review.js';

/* ========================== EDIT ========================================== */
import { _elems, _state } from '~form';

export function reviewEdit( pRcrd, hndlrs ) {                         /*dbug-log*///console.log('           >--Taxon review EDIT [%O]', pRcrd);
   const params = getReviewEditParams( pRcrd, hndlrs.getParams );     /*dbug-log*///console.log('   --params[%O]', params);
    return _elems( 'initForm', [params] )
        .then( hndlrs.finishBuild )
        .then( () => storeTxnRcrd( pRcrd ) );
}
function getReviewEditParams( pRcrd, getParams ) {
    const p = getParams( pRcrd.data.review.editId );
    p.pending = pRcrd;
    p.style = 'med-form';
    delete p.vals;
    return p;
}
function storeTxnRcrd( pRcrd ) {
    const txn = _state( 'getRcrd', ['taxon', pRcrd.data.review.editId] );/*dbug-log*///console.log('   -- storeTxnRcrd[%O]', txn);
    _state( 'setFormState', ['top', 'rcrd', txn] );
}
/**
 * Handles building Options objects for comboboxes throughout the site.
 *
 * Export
 *
 * TOC
 *    GET OPTIONS
 *        SIMPLE OPTIONS
 *        GROUP OPTIONS
 *    BUILD OPTIONS
 *        STORED DATA
 *        FIELD DATA
 *        BASIC ENTITY-OPTIONS
 *        SOURCE
 *        TAXON
 *        LOCATION
 *        INTERACTION
 *    HELPERS
 */
import { _db, _u } from '~util';
import { _state } from '~form';
import { EntityRecords, objectKeys, OptionObject, objectValues, SerializedEntity } from '~types';
/* =========================== GET OPTIONS ================================== */
type EntityIdsByName = {
    [name: string]: number | string;
};
/** Options for comboboxes with grouped, ie categorized, dropdown menus. */
type GroupedOptionData = {
    [name: string]: OptionObject;
};
type EntityOptionData = GroupedOptionData | EntityIdsByName;
export function getOptions (
    entityObj: EntityOptionData,
    sortedKeys: string[]
): OptionObject[] | [] {
    return isSimpleOpts( entityObj ) ?
        getSimpleOpts( entityObj, sortedKeys ) : getOptGroups( entityObj );
}
function isSimpleOpts ( obj: EntityOptionData ): obj is EntityIdsByName {
    const entityValueType = typeof objectValues( obj )[0];
    return ['number', 'string'].indexOf( entityValueType ) >= 0;
}
/** --------------------- SIMPLE OPTIONS ------------------------------------ */
function getSimpleOpts ( entityObj: EntityIdsByName, sortedKeys: string[] ): OptionObject[] {
    return sortedKeys.map( name => getEntityOpt( name, entityObj[name] ) );
}
function getEntityOpt ( name: string, id: number | string | undefined ): OptionObject {
    if ( !id ) throw Error( 'ID is undefined' );
    return { text: _u.ucfirst( name ), value: id.toString() };
}
/** --------------------- GROUP OPTIONS ------------------------------------- */
function getOptGroups ( entityObj: GroupedOptionData ): OptionObject[] {
    return objectKeys( entityObj ).map( k => getGroupOpt( k, entityObj ) );
}
function getGroupOpt ( name: string, entityObj: GroupedOptionData ): OptionObject {
    const opt = entityObj[name];
    if ( !opt ) throw Error( `Option not found for ${ name }` );
    opt.text = name;
    return opt;
}
/* ========================== BUILD OPTIONS ================================= */
/** --------------------- STORED DATA --------------------------------------- */
/** Builds options out of a stored entity-na me object. */
export function getOptsFromStoredData (
    prop: string | null,
    emptyOk = false
): Promise<OptionObject[] | []> {
    if ( !prop ) throw Error( 'Undefined option property' );
    return _db.getData( prop, true )
        .then( data => buildOpts( data, prop, emptyOk ) );
}
function buildOpts<T> (
    data: T,
    prop: string,
    emptyOk = false,
): OptionObject[] | [] {
    if ( !data ) return handleNullData( prop, emptyOk );
    return getOptions( data, Object.keys( data ).sort() );
}
function handleNullData ( prop: string, emptyOk: boolean ): [] {
    if ( !emptyOk ) console.log( 'NO STORED DATA for [%s]', prop );
    return [];
}
function getStoredOpts ( _: string | null, key: string | null ): Promise<OptionObject[]> {
    return getOptsFromStoredData( key );
}
/** --------------------- FIELD DATA ---------------------------------------- */
type OptReturn = OptionObject[] | [] | Promise<OptionObject[]>;
type OptBuilder = ( name: string, prop: string | null ) => OptReturn;
/** Returns and array of options for the passed field type. */
export function getFieldOptions ( field: string ): OptReturn {
    const optMap: {
        [field: string]: [OptBuilder, string | null];
    } = {
        Author: [getSrcOpts, 'authSrcs'],
        CitationType: [getCitTypeOpts, 'citTypeNames'],
        Class: [getTaxonOpts, 'Class'],
        Country: [getStoredOpts, 'countryNames'],
        'Country-Region': [getCntryRegOpts, null],
        Editor: [getSrcOpts, 'authSrcs'],
        Family: [getTaxonOpts, 'Family'],
        Genus: [getTaxonOpts, 'Genus'],
        Group: [getRoleTaxonGroups, 'groupNames'],
        HabitatType: [getStoredOpts, 'habTypeNames'],
        Location: [getRcrdOpts, null],
        Order: [getTaxonOpts, 'Order'],
        Publication: [getSrcOpts, 'pubSrcs'],
        PublicationType: [getStoredOpts, 'pubTypeNames'],
        Publisher: [getSrcOpts, 'publSrcs'],
        Rank: [getRankOpts, null],
        Region: [getStoredOpts, 'regionNames'],
        Season: [getTagTypeOpts, 'season'],
        'Sub-Group': [getSubGroupOpts, null],
        Species: [getTaxonOpts, 'Species'],
    };
    return buildFieldOptions( field, optMap[field] );
}
function buildFieldOptions (
    field: string,
    buildConfig: [OptBuilder, string | null] | undefined
): Promise<OptionObject[]> {
    if ( !buildConfig ) return Promise.resolve( [] );
    const getOpts = buildConfig[0];
    const fieldKey = buildConfig[1];
    return Promise.resolve( getOpts( field, fieldKey ) );
}
/* ----------------------- BASIC ENTITY-OPTIONS ----------------------------- */
export function initOptsWithCreate ( entity: string ): OptionObject[] {
    return [{ text: `Add a new ${ _u.ucfirst( entity ) }...`, value: 'create' }];
}
export function getRcrdOpts ( entity: string, ids: string | number | null ): OptionObject[];
export function getRcrdOpts ( entity: string, ids?: number[], rcrds?: EntityRecords ): OptionObject[];
export function getRcrdOpts (
    entity: string,
    ids?: null | string | number | number[],
    rcrds?: EntityRecords
): OptionObject[] {
    rcrds = rcrds ?? _state( 'getEntityRcrds', [_u.lcfirst( entity )] ) as EntityRecords;
    ids = Array.isArray( ids ) ? ids : objectKeys( rcrds );
    const opts = initOptsWithCreate( entity );
    opts.push( ...alphabetizeOpts( buildEntityOptions( ids, rcrds ) ) );
    return opts;
}
function buildEntityOptions ( ids: number[], rcrds: EntityRecords ): OptionObject[] {
    return ids.map( id => {
        return { text: getComboEntityDisplayName( rcrds[id], id ), value: `${ id }` };
    } );
}
/* -------------------------- SOURCE ---------------------------------------- */
/* Removes text used to distinguish the names of citations for an entire publication. */
export function getComboEntityDisplayName ( rcrd: SerializedEntity | undefined, id: number | string ): string {
    if ( !rcrd ) throw Error( `No record found for [${ id }]` );
    const name = rcrd.displayName?.split( '(citation)' )[0];
    if ( !name ) throw Error( `No name found for [${ id }]` );
    return name;
}
/** Returns an array of source-type (prop) options objects. */
function getSrcOpts ( field: string, prop: string | null ): OptReturn {
    if ( !prop ) throw Error( `No source prop found for [${ field }]` );
    const entity = getFieldName( field, prop );
    return _db.getData( prop )
        .then( ids => buildSrcOpts( entity, ids ) );
}
type SourceType = 'Author' | 'Citation' | 'Editor' | 'Publication' | 'Publisher';
function getFieldName ( field: string, prop: string ): Exclude<SourceType, 'Citation'> {
    const names = {
        authSrcs: getAuthorType( field ),
        pubSrcs: 'Publication',
        publSrcs: 'Publisher'
    } as const;
    return names[prop as keyof typeof names];
}
function getAuthorType ( field: string ): 'Author' | 'Editor' {
    return ( field ? field.slice( 0, -1 ) : 'Author' ) as 'Editor' | 'Author';
}
export function buildSrcOpts<T> ( srcType: SourceType, ids: T ): OptReturn;
export function buildSrcOpts ( srcType: SourceType, ids: number[] | null ): OptReturn {
    const opts = initOptsWithCreate( srcType );
    if ( ids ) opts.push( ...getRcrdOpts( 'source', ids ) );
    return opts;
}
/** Return the citation type options available for the parent-publication's type. */
function getCitTypeOpts ( _: string, prop: string | null ): OptReturn {
    if ( !prop ) throw Error( 'Citation type property cannot be null' );
    return _db.getData( prop )
        .then( names => buildCitTypeOpts( names ) );
}
function buildCitTypeOpts<T> ( types: T ): OptReturn;
function buildCitTypeOpts ( types: EntityIdsByName ): OptReturn {
    return getOptions( types, getCitTypeNames().sort() );
}
function getCitTypeNames (): string[] {
    const opts = {
        Book: ['Book', 'Chapter'],
        Journal: ['Article'],
        Other: ['Museum Record', 'Other', 'Report'],
        'Thesis/Dissertation': ["Master's Thesis", 'Ph.D. Dissertation']
    };
    return opts[getFormPublicationType()];
}
function getFormPublicationType (): 'Book' | 'Journal' | 'Other' | 'Thesis/Dissertation' {
    const fLvl = _state( 'getSubFormLvl', ['sub'] );
    const formData = _state( 'getFieldState', [fLvl, 'ParentSource', 'misc'] );
    return formData.pubType.displayName;
}
/* -------------------------- TAXON ----------------------------------------- */
function getRankOpts ( _: string, _2: string | null ): OptReturn {
    return _db.getData( ['orderedRanks', 'rankNames'] )
        .then( data => buildRankOpts( data.orderedRanks as string[], data.rankNames as RankDataByName ) );
}
//todo: static types for all local data-storage properties
type RankDataByName = {
    [name: string]: RankData;
};
type RankData = { id: string; ord: number; };
function buildRankOpts ( order: string[], ranks: RankDataByName ): OptionObject[] {
    order.splice( order.indexOf( 'Phylum' ) ); //Removes levels unused in UI
    return order.map( r => buildRankOpt( r, ranks[r] ) );
}
function buildRankOpt ( name: string, rank: RankData | undefined ): OptionObject {
    if ( !rank ) throw Error( `No rank data found for ${ name }` );
    return { text: name, value: rank.id };
}
/** Returns the options for the taxa in the group/subGroup and rank (never null). */
export function getTaxonOpts ( field: string, rank: string | null ): OptReturn;
export function getTaxonOpts ( field: string, rank: string | null, g?: string, sg?: string ): OptReturn {
    if ( !rank ) throw Error( 'Rank cannot be null' );
    const optKey = getTxnOptDataKey( rank, g, sg );
    return getStoredOpts( null, optKey )
        .then( o => buildTaxonOpts( rank, o ) );
}
function buildTaxonOpts ( rank: string, optData: OptionObject[] ): OptionObject[] {
    const opts = initOptsWithCreate( rank );
    opts.push( ...alphabetizeOpts( optData ) );
    return opts;
}
function getTxnOptDataKey ( rank: string, g?: string, sg?: string ): string {
    if ( g && sg ) return buildTxnOptDataKey( rank, g, sg );
    const group: string = getSubGroupTxn().group.displayName;
    const subGroup: string = getSubGroupTxn().name;
    return buildTxnOptDataKey( rank, group, subGroup );
}
function buildTxnOptDataKey ( rank: string, group: string, subGroup: string ): string {
    return group + subGroup + rank + 'Names';
}
function getSubGroupTxn (): SerializedEntity {
    return _state( 'getFieldState', ['sub', 'Sub-Group', 'misc'] ).taxon;
}
/** Builds opts for the SubGroup combobox. */
function getSubGroupOpts ( _: string, _2: string | null ): OptReturn {
    const root: string = getSubGroupTxn().group.displayName;
    return getStoredOpts( null, `${ root }SubGroupNames` );
}
function getRoleTaxonGroups ( field: string, prop: string | null ): OptReturn {
    const role = _state( 'getFormState', ['sub', 'name'] );
    prop = role === 'Subject' ? 'subjectNames' : prop;
    return getStoredOpts( null, prop );
}
/* -------------------------- LOCATION -------------------------------------- */
/** Returns options for each country and region. */
function getCntryRegOpts ( _: string, _2: string | null ): OptReturn {
    const proms = ['Country', 'Region'].map( getFieldOptions ) as OptionObject[][];
    return Promise.all( proms ).then( concatOpts );
}
function concatOpts ( data: OptionObject[][] ): OptionObject[] {
    const [countries, regions] = data;
    if ( !countries || !regions ) throw Error( 'Missing country or region options' );
    return countries.concat( regions );
}
/* ------------------------ INTERACTION ------------------------------------- */
/** Builds opts for the specified tag-type: ie, seasons. */
function getTagTypeOpts ( _: string, tagType: string | null ): OptReturn {
    return _db.getData( 'tag' )
        .then( tags => buildTagTypeOpts( tags as EntityRecords, tagType ) );
}
function buildTagTypeOpts ( tags: EntityRecords, tagType: string | null | undefined ): OptionObject[] {
    if ( !tagType ) throw Error( 'Tag type is undefined' );
    const opts: OptionObject[] = [];
    objectValues( tags ).forEach( ifTypeTagBuildOpt );
    return alphabetizeOpts( opts );

    function ifTypeTagBuildOpt ( tag: SerializedEntity ): void {
        if ( tag.type !== tagType ) return;
        opts.push( { text: tag.displayName!, value: tag.id.toString() } );
    }
}
/** ==================== HELPERS ============================================ */
export function getOptsFromStringArray ( strings: string[] ): OptionObject[] {
    return strings.map( s => { return { text: s, value: s }; } );
}
export function alphabetizeOpts ( opts: OptionObject[] ): OptionObject[] {
    return opts.sort( alphaOptionObjs );
}
function alphaOptionObjs ( a: OptionObject, b: OptionObject ): -1 | 0 | 1 {
    const x = a.text.toLowerCase();
    const y = b.text.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
}
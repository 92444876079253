/**
 * Initialize data-entry form.
 *
 * Export
 *     alertInUse
 *     finishFormBuild
 *     ifFormInUse
 *     initForm
 *     initSubForm
 *
 * TOC
 *     TOP FORM
 *     SUB FORM
 *         IF OPEN SUB-FORM ISSUE
 *         UPDATE TOP-FORM
 *     BUILD CHAIN
 *         BUILD FORM-ELEMS
 *         PANEL ELEMS
 *         FINISH BUILD
 *     ASSEMBLE FORM
 *         CONTAINER
 *         HEADER
 *         STATUS/ALERTS
 */
import { _cmbx, _el, _u } from '~util';
import { _elems, _state, _val } from '~form';
let fState;
/* ======================== TOP FORM ======================================== */
export function initForm( p ) {                                       /*dbug-log*///console.log('+--initForm params[%O]', p);
    p.group = 'top';
    return _state( 'initFormState', [p] )
        .then( fS => initFormElems( fS.forms.top, p.appendForm ) )
        .then( fState => finishFormBuild( p.name, p.group, p.initCombos ) )
        .then( () => _el.setElemExpandableListeners( $( '#form-panel' ) ) )
        .then( () => 'success' );
}
/* ======================== SUB FORM ======================================== */
export function initSubForm( p ) {                                    /*dbug-log*///console.log('+--initSubForm params[%O]', p);
    if ( ifFormInUse( p.group ) ) { return alertInUse( p.combo, p.group ); }
    return _state( 'buildNewFormState', [p] )
        .then( fState => initFormElems( fState, p.appendForm ) )
        .then( fState => _elems( 'initSubEntityDataReviewElems', [fState] ) )
        .then( () => finishFormBuild( p.name, p.group, p.initCombos ) )
        .then( () => updateParentForm( p ) )
        .then( () => 'success' );
}
/* ----------------- IF OPEN SUB-FORM ISSUE --------------------------------- */
export function ifFormInUse( fLvl ) {                                 /*dbug-log*///console.log('--ifFormInUse fLvl[%s]', fLvl);
    return fLvl ? $( `#${ fLvl }-form` ).length !== 0 : false;
}
export function alertInUse( name, fLvl ) {                            /*dbug-log*///console.log('--alertInUse name[%s] fLvl[%O]', name, fLvl);
    _val( 'alertFormOpen', [fLvl] );
    _cmbx.resetCombobox( name );
    return Promise.resolve();
}
/* -------------------- UPDATE TOP-FORM ------------------------------------- */
function updateParentForm( p ) {                                      /*dbug-log*///console.log('--updateParentForm');
    const pLvl = _state( 'getFormLevel', ['parent', p.group] );
    _elems( 'toggleSubmitBttn', [pLvl, false] );
    _elems( 'toggleCancelBttn', [pLvl, false] );
    _cmbx.enableCombobox( p.combo, false );
    boldLeafFormHeader( pLvl, false );
}
/* ======================== BUILD CHAIN ===================================== */
function initFormElems( fState, appendFunc ) {                        /*dbug-log*///console.log('--initFormElems fState[%O] appendFunc[%O]', fState, appendFunc);
    return _elems( 'getFormPieces', [fState] )
        .then( elems => buildAndAppendForm( elems, fState, appendFunc ) )
        .then( () => fState );
}
/* ----------------------- BUILD FORM-ELEMS --------------------------------- */
function buildAndAppendForm( elems, fState, appendFunc ) {             /*dbug-log*///console.log('--assembleAndAppend elems[%O] fState[%O] appendFunc[%O]', elems, fState, appendFunc);
    const form = assembleFormElems( elems, fState );
    if ( fState.group === 'top' ) {
        $( '#b-overlay-popup' ).html( '' ); //Clears "Loading..." text
        appendFunc( form, fState, elems );
    } else {
        appendFunc( form );
    }
    delete fState.handlers.appendForm;
}
/* -------------------- FINISH BUILD ---------------------------------------- */
export function finishFormBuild( entity, fLvl, initCombos ) {         /*dbug-log*///console.log('--finishFormBuild initCombos?[%O]', initCombos);
    _elems( 'setDynamicFieldStyles', [entity] );
    boldLeafFormHeader( fLvl );
    if ( initCombos ) { return initCombos(); }
}
/* ======================== ASSEMBLE FORM =================================== */
function assembleFormElems( el, formState ) {                         /*dbug-log*///console.log('+--assembleFormElems elems[%O] fState[%O]', el, _u.snapshot(formState));
    fState = formState;
    const cntnr = buildFormCntnr();
    const hdr = buildFormHdr( el.tutBttn );
    const valMsg = getValMsgCntnr();
    $( cntnr ).append( [hdr, valMsg, el.rows, el.footer].filter( e=>e ) );
    $( cntnr ).submit( e => e.preventDefault() );
    return cntnr;
}
/* --------------------------- CONTAINER ------------------------------------ */
function buildFormCntnr() {
    const attr = {id: fState.group+'-form', class: fState.style };
    return _el.getElem( 'form', attr );
}
/* ------------------------------ HEADER ------------------------------------ */
function buildFormHdr( tutBttn ) {
    const cntnr = buildHeaderCntnr();
    const hdr = _el.getElem( 'span', { text: getHeaderTitle() } );
    $( cntnr ).append( [tutBttn, hdr, $( '<div>' )[0]] );
    return cntnr;
}
function buildHeaderCntnr() {
    return _el.getElem( 'div', { id: fState.group+'-hdr', class: 'flex-row'} );
}
function getHeaderTitle() {
    let hdr = fState.name + ' ';
    hdr += fState.action === 'review' ? getRvwHdr() : _u.ucfirst( fState.action );
    return hdr;
}
function getRvwHdr() {
    const action = _u.ucfirst( fState.pending.data.review.action );
    const stage = _state( 'getReviewStage', [fState.group] );
    const hdr = `${ action } - ${ stage }`;
    if ( stage !== 'Rejected' || !fState.pending.entityId ) { return hdr; }
    return `${ hdr } (Non-Unique)`;
}
/** Note: On sub-form exit, parent-form header is rebolded. */
export function boldLeafFormHeader( fLvl, enable = true ) {
    const weight = enable ? 600 : 400;
    $( `#${ fLvl }-hdr span` ).css( {'font-weight': weight} );
}
/* ------------------------- STATUS/ALERTS ---------------------------------- */
/** Container for custom form-validation messages. */
function getValMsgCntnr() {
    return _el.getElem( 'div', { id: fState.group+'_alert' } );
}
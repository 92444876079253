/**
 * Helper methods for the entity-data init process.
 */
import { EntityRecords, IdsByName, SerializedEntity } from "~types";
/** Returns an object with a record (value) for each id (key) in passed array.*/
export function getRcrds ( ids: number[], rcrds: EntityRecords ): EntityRecords {
    const data: EntityRecords = {};
    ids.forEach( id => {
        const rcrd = rcrds[id];
        if ( !rcrd ) throw Error( `Record not found for ${ id }` );
        data[id] = rcrd;
    } );
    return data;
}
/** Returns an object with each entity record's displayName (key) and id. */
export function getNameObj ( ids: number[], rcrds: EntityRecords ): IdsByName {
    const data: IdsByName = {};
    ids.forEach( id => {
        data[getDisplayName( rcrds[id] )] = id;
    } );
    return data;
}
/** Returns an object with each entity types's displayName (key) and id. */
export function getTypeObj ( entityTypes: EntityRecords ): IdsByName {
    const data: IdsByName = {};
    for ( let id in entityTypes ) {
        data[getDisplayName( entityTypes[id] )] = id;
    }
    return data;
}
function getDisplayName ( entity: SerializedEntity | undefined ): string {
    const name = entity?.displayName;
    if ( !name ) throw Error( 'No name found in record' );
    return name;
}
/** Returns the ids for an entity sub-type: eg, citation sources or country locations. */
export function getType ( types: EntityRecords, type: string, collection: string ): number[] {
    for ( let t in types ) {
        const record = types[t];
        if ( !record ) throw Error( 'Invalid entity type' );
        if ( record.slug === type ) { return record[collection]; }
    }
    throw Error( 'Entity type not found' );
}
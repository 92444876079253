/*
 * Loads table-data with the selected filter-set applied.
 *
 * Export
 *      applyFilterSet
 *      onTableReloadCompleteApplyFilters
 *
 * TOC
 *      TABLE REBUILD
 *          LOAD TABLE IN SET-FOCUS AND -VIEW
 *          FILTERS THAT REBUILD TABLE
 *      ROW-DATA FILTERS
 *          TABLE COLUMNS
 *          UPDATE FILTER MEMORY
 *      UPDATE UI
 */
import { _cmbx, _db } from '~util';
import { _filter, _table } from '~db';
import * as sets from './main-filter-set.js';

/* Holds selected filter data and table state. */
const tState = _table.bind( null, 'tableState' );

export function applyFilterSet() {
    const filters = sets.getSetState( 'details' );                    /*perm-log*/console.log( '//Applying Filter Set = %O', filters );
    reloadTableThenApplyFilters( filters, sets.getSetState( 'details' ) );
}
/* ================== TABLE REBUILD ========================================= */
/* ------------ LOAD TABLE IN SET-FOCUS AND -VIEW --------------------------- */
function reloadTableThenApplyFilters ( filters, id ) {
    if ( id ) { setSavedFilterFocusAndView( filters ); } //If no id, reapplying filters after form closed.
    _table( 'buildTable', [ filters.focus, filters.view.value ] )
        .then( onTableReloadCompleteApplyFilters.bind( null, filters, id ) );
}
function setSavedFilterFocusAndView ( filters ) {
    _cmbx.setSelVal( 'Focus', filters.focus, 'silent' );
    setView( filters );
}
function setView ( filters ) {
    if ( filters.view.value == tState().get( 'curView' ) ) { return; }
    const view =  filters.view ? filters.view.value : 'tree'; //Location filters are only saved in tree view
    _db.setData( 'curView', view );
    _cmbx.setSelVal( 'View', view, 'silent' );
}
export function onTableReloadCompleteApplyFilters ( filters, id ) {    /*dbug-log*///console.log('   --onTableReloadComplete. filters = %O', filters);
    setFiltersThatResetTable( filters.rebuild )
        .then( () => applyDirectFilters( filters ) )
        .then( () => applyColumnFilters( filters.table ) )
        .then( onAllFiltersApplied )
        .then( () => ifActiveSetResetVal( id ) );
}
function onAllFiltersApplied() {
    const id = sets.getSetState( 'id' );
    if ( !id ) { return; } /* reapplying filters after form closed. *//*dbug-log*///console.log('onAllFiltersApplied id[%s]', id);
    _cmbx.setSelVal( 'FilterSet', id, 'silent' );
}
function ifActiveSetResetVal ( id ) {
    if ( !id ) { return; }  //If no id, reapplying filters after form closed.
    const filter = sets.getSetState( 'all' );
    sets.fillFilterDetailFields( filter.displayName, filter.description );
    _cmbx.setSelVal( 'Filter Set', id, 'silent' );
    sets.addSetToFilterStatus();
}
/* ------------------- FILTERS THAT REBUILD TABLE --------------------------- */
function setFiltersThatResetTable ( filters ) {
    if ( !Object.keys( filters ).length ) { return Promise.resolve(); }
    return setComboboxFilter( filters.combo );
}
function setComboboxFilter ( filter ) {
    if ( !filter ) { return Promise.resolve(); }
    const name = Object.keys( filter )[0];
    const val = filter[name].value;
    return _cmbx.triggerComboChangeReturnPromise( getFilterName( name ), val );
}
/* ================== ROW-DATA FILTERS ====================================== */
function applyDirectFilters ( filters ) {                              /*dbug-log*///console.log('applyDirectFilters. filters[%O]', filters)
    if ( !Object.keys( filters.direct ).length ) { return; }
    addFiltersToMemoryAndUi( filters );
    _filter( 'onFilterChangeUpdateRowData' );
}
/* ---------------------- TABLE COLUMNS ------------------------------------- */
function applyColumnFilters ( filters ) {                              /*dbug-log*///console.log('applyColumnFilters filters[%O]', filters);
    const tblApi = tState().get( 'api' );
    for ( let name in filters ) {
        if ( filters[name] === null ) {continue;}
        const colName = Object.keys( filters[name] )[0];              /*dbug-log*///console.log('col[%s] Model[%O]', colName, filters[name][colName]);
        tblApi.getFilterApi( colName ).setModel( filters[name][colName] );
    }
}
/* ------------------- UPDATE FILTER MEMORY --------------------------------- */
function addFiltersToMemoryAndUi ( filters ) {
    [ 'direct', 'rebuild' ].forEach( handleFilters );

    function handleFilters ( group ) {
        if ( !filters[group] ) {return;}
        Object.keys( filters[group] ).forEach( handleFilterState );

        function handleFilterState ( type ) {                          /*dbug-log*///console.log('handleFilterState type[%s] group[%s] filters[%O]', type, group, filters);
            handleUiUpdate( type, filters[group][type] );
            _filter( 'setFilterState', [type, filters[group][type], group] );
        }
    }
}
/* ======================== UPDATE UI ======================================= */
function handleUiUpdate ( type, val ) {                                /*dbug-log*///console.log('handleUiUpdate type[%s] val[%s]', type, val)
    const map = {
        name: setNameTextInput,
        combo: setComboElems,
        date: setDateElems
    };
    if ( !map[type] ) {return;}
    map[type]( type, val );
}
function setNameTextInput ( type, val ) {
    $( '#focus-filters input[type="text"]' ).val( val.replace( /"/g, "" ) );
}
function setComboElems ( type, fields ) {                              /*dbug-log*///console.log('setComboElems fields[%O]', fields);
    Object.keys( fields ).forEach( f => setComboElem( f, fields[f] ) );
}
function setComboElem ( field, val ) {
    _cmbx.setSelVal( getFilterName( field ), val, 'silent' );
}
function setDateElems ( type, val ) {                                  /*dbug-log*///console.log('setDateElems time[%O]', val)
    _cmbx.setSelVal( 'Date Filter Type', type, 'silent' );
    _filter( 'toggleDateFilter', [ true, val.time, 'skipSync' ] );
}
function getFilterName ( name ) {
    return name.includes( 'Filter' ) ? name : ( name + 'Filter' );
}
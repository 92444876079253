/**
 * Builds standard input elements.
 *
 * Export
 *     getFieldInput
 *     buildMultiSelectField
 *
 * TOC
 *     INPUT BUILDERS
 *         INPUT
 *         TEXTAREA
 *         SINGLE SELECT/COMBOS
 *         DATE-RANGE SELECT
 *         MULTI-SELECT/COMBOS
 *         SET VALUE
 *     FINISH BUILD
 */
import { OptionObject } from '~types';
import { _el, _opts, _u } from '~util';
import { handleInputValidation } from './val-input';
/* ======================= INPUT BUILDERS =================================== */
/**
 * @typedef {Object} InputConfig  - Field configuration and input element.
 * @prop  {String}   [class] - Field style-class
 * @prop  {String}   [count] - Present for fields with multiple inputs
 * @prop  {Object}   [flow] - Flex-direction class suffix.
 * @prop  {String}   [group] - Used for styling and intro-tutorials
 * @prop  {Str}      [id] - Set in config or to name in elem-build-main
 * @prop  {Node|Ary} [input] - Field input element(s) [required]
 * @prop  {String}   [label] - Text to use for label. If false, no label is built.
 * @prop  {String}   name - Field name [required] Will be used for IDs
 * @prop  {Boolean}  [required] - True if field is required in a containing form.
 * @prop  {String}   [type] - Flags edge-case field types: 'multiSelect'
 * @prop {string|object} [value] - Field value.
 */
interface InputConfig {
    class?: string;
    combo?: boolean;
    group?: 'top' | 'sub' | 'sub2';
    id?: string;
    input?: HTMLElement | HTMLElement[];
    label?: string;
    misc?: { opts: string[]; };
    name: string;
    type: string;
    required?: boolean;
    value?: string | OptionObject;
}
interface MultiInputConfig extends InputConfig {
    count: number;
}
/**
 * Builds the input element, handles setting html validation, and returns the
 * field configuration with the new input added.
 * @param fConfig:InputConfig
 * @returns InputConfig
 */
export function getFieldInput ( fConfig: InputConfig ): Promise<InputConfig> {
    initConfigSelectors( fConfig );
    return Promise.resolve( getInput( fConfig ) )
        .then( input => setFieldValue( fConfig, input ) )
        .then( input => handleInputValidation( fConfig.type, input ) )
        .then( input => finishInputBuild( fConfig, input ) );  //
}
function initConfigSelectors ( f: InputConfig ): void {
    f.id = f.id ? f.id : f.name;
    f.class = 'f-input ' + ( f.class ? f.class : '' );
}
type InputReturnType = HTMLElement | HTMLElement[] | Promise<HTMLElement>;
function getInput ( f: InputConfig ): InputReturnType | never {
    const inputMap: { [key: string]: ( f: InputConfig ) => InputReturnType; } = {
        checkbox: buildCheckbox,
        dateRange: buildDateRangeInputs,
        doi: buildInput,
        fullTextArea: buildLongTextArea,
        lat: buildInput,
        lng: buildInput,
        multiSelect: buildMultiSelectFieldContainer,
        num: buildNumberInput,
        numRange: buildNumberRangeInputs,
        page: buildInput,
        select: buildSelect,
        tags: buildSelect,
        text: buildInput,
        textArea: buildTextArea,
        url: buildUrlInput,
        year: buildNumberInput
    } as const;
    const builder = inputMap[f.type];
    return builder ? builder( f ) : throwErr( f.type );
}
function throwErr ( inputType: string ): never {
    throw Error( `Input type not supported: [${ inputType }]` );
}
/* ------------------------------- INPUT ------------------------------------ */
function buildInput ( f: InputConfig, type = 'text' ): HTMLElement {
    const attr = { type: type, class: f.class };
    const input = _el.getElem( 'input', attr );
    return input;
}
function buildNumberInput ( f: InputConfig ): HTMLElement {
    return buildInput( f, 'number' );
}
/** Note: Inputs will have ID's appended with '-start' and '-end' */
function buildNumberRangeInputs ( f: InputConfig ): [HTMLElement, HTMLElement] {
    const start = buildInput( f, 'number' );
    start.id = f.id + '-start';
    const end = buildInput( f, 'number' );
    end.id = f.id + '-end';
    return [start, end];
}
function buildUrlInput ( f: InputConfig ): HTMLElement {
    return buildInput( f, 'url' );
}
function buildCheckbox ( f: InputConfig ): HTMLElement {
    return buildInput( f, 'checkbox' );
}
/* ----------------------------- TEXTAREA ----------------------------------- */
function buildTextArea ( f: InputConfig ): HTMLElement {
    return _el.getElem( 'textarea', { class: f.class } );
}
function buildLongTextArea ( f: InputConfig ): HTMLElement {
    const attr = { class: f.class, id: 'txt-' + f.id };
    return _el.getElem( 'textarea', attr );
}
/* --------------------- SINGLE SELECT/COMBOS ------------------------------- */
/**
 * Creates and returns a select dropdown for the passed field. If it is one of
 * a larger set of select elems, the current count is appended to the id. Adds
 * the select's fieldName to the subForm config's 'selElem' array to later
 * init the 'selectize' combobox.
 */
function buildSelect ( f: InputConfig ): Promise<HTMLElement> {
    return buildOptions( f )
        .then( finishSelectBuild.bind( null, f ) );
}
function buildOptions ( f: InputConfig ): Promise<OptionObject[]> {
    const optStrings = f.misc ? f.misc.opts : null;
    return optStrings ?
        Promise.resolve( _opts.getOptsFromStringArray( optStrings ) ) :
        _opts.getFieldOptions( f.name ) as Promise<OptionObject[]>;
}
function finishSelectBuild ( f: InputConfig, opts: OptionObject[] ): HTMLSelectElement {
    const attr = { class: f.class, id: 'sel-' + f.id, value: f.value };
    f.combo = true; //Flag for the combobox selectize-library
    const select = _el.getElem( 'select', attr, opts );
    return select as HTMLSelectElement;
}
/* ---------------------- DATE-RANGE SELECT --------------------------------- */
/** Note: Inputs will have ID's appended with '-start' and '-end' */
function buildDateRangeInputs ( f: InputConfig ): [HTMLElement, HTMLElement] {
    const start = buildInput( f );
    start.id = f.id + '-start';
    const end = buildInput( f );
    end.id = f.id + '-end';
    if ( f.value ) $( start ).data( 'init-val', f.value );
    return [start, end];
}
/* ---------------------- MULTI-SELECT/COMBOS ------------------------------- */
/**
 * Creates a select dropdown field wrapped in a div container that will
 * be replaced inline upon selection. Either with an existing Author's name,
 * or the Author create form when the user enters a new Author's name.
 */
function buildMultiSelectFieldContainer ( f: InputConfig ): Promise<HTMLElement> {
    return buildMultiSelectField( f as MultiInputConfig )
        .then( buildMultiSelectContainer.bind( null, f ) );
}
/** Builds an input inside of a container, allowing subsequent inputs to be added. */
export function buildMultiSelectField ( f: MultiInputConfig ): Promise<HTMLElement> {
    return buildSelect( f )
        .then( finishFieldInput.bind( null, f ) );
}
function finishFieldInput ( f: MultiInputConfig, input: HTMLElement ): HTMLElement {
    const config = getMultiFieldInputConfig( f, input );
    input.id += f.count;
    return _el.getFieldElems( config as _el.FieldConfig );
}
function getMultiFieldInputConfig ( f: MultiInputConfig, input: HTMLElement ): InputConfig {
    return {
        'class': f.class,
        group: f.group,
        id: f.name + f.count,
        input: input,
        label: `${ getCntLabel( f.count ) } ${ f.name }`,
        name: f.name + f.count,
        required: f.required || false,
        type: 'select'
    };
}
function getCntLabel ( cnt: number ): string {
    const map = { 1: '1st', 2: '2nd', 3: '3rd' } as const;
    return cnt in map ? map[cnt as keyof typeof map] : cnt + 'th';
}
function buildMultiSelectContainer ( f: InputConfig, field: HTMLElement ): HTMLElement {
    f.input = field;
    return _el.getFieldElems( f as _el.FieldConfig );
}
/* --------------------------- SET VALUE ------------------------------------ */
function setFieldValue ( f: InputConfig, input: HTMLElement | HTMLElement[] ): InputReturnType {
    if ( f.type === 'multiSelect' ) {
        handleMultiFieldInitVal( f );
    } else if ( f.value ) {
        setInputVal( f.value, input );
    }
    return input;
}
function setInputVal ( value: string | OptionObject, input: InputReturnType ): void {
    const val = _u.isObj( value ) ? value.value : value;
    $( input ).val( val );
}
function handleMultiFieldInitVal ( f: InputConfig ): void {
    if ( f.value && _u.isObj( f.value ) ) return;
    //@ts-expect-error "Only used to instantiate for future use... todo: move this elsewhere"
    f.value = {};
}
/* ========================== FINISH BUILD ================================== */
function finishInputBuild ( f: InputConfig, input: HTMLElement | HTMLElement[] ): InputConfig {
    f.input = input;
    return f;
}
/**
 * Create taxon review-form.
 *
 * Export
 *     reviewCreate
 *
 * TOC
 *     CREATE FORM
 *         INIT
 *         CLOSE
 *     SELECT FORM
 *         INIT
 *         SELECT PARENT
 *         CLOSE
 */
import { _cmbx } from '~util';
import { _elems, _form, _review, _state } from '~form';
import { getFirstPendingRcrdToReview } from './get-pending-txn.js';

let handlers;

export function reviewCreate ( pRcrd, hndlrs ) {
    handlers = hndlrs;
    pRcrd.inst.group = 'sub2'; // the submit-form is an extra form level
    return reviewTxnCreate( pRcrd );
}
/* ========================= CREATE FORM ==================================== */
function reviewTxnCreate ( pRcrd ) {
    const nextRcrd = getFirstPendingRcrdToReview( pRcrd );            /*dbug-log*///console.log(' +-- reviewTxnCreate nextRcrd[%O]', nextRcrd);
    if ( nextRcrd.skip ) { return Promise.resolve( 'skipReview' ); } //SKIPPING TAXON REVIEW
    return initTaxonSelect( nextRcrd )
        .then( () => preparePendingFieldRcrd( pRcrd ) )
        .then( () => selectParentAndInitCreate( nextRcrd ) );
}
/**
 * Stores the PendingData record associated with the field for select forms.
 * This keeps track of the selected-taxon pending review, while pending parent-taxa
 * are handled first. Ie, when a new species was created under a new genus, the
 * genus is reviewed first, and the pending family before that... etc.
 */
function preparePendingFieldRcrd ( pRcrd ) {                           /*dbug-log*///console.log('       -- preparePendingFieldRcrd pRcrd[%O]', pRcrd);
    _state( 'setFormState', ['sub', 'pending', pRcrd] );
}
/* ----------------------------- INIT --------------------------------------- */
function initReviewCreateForm ( pRcrd ) {                              /*dbug-log*///console.log('       -- initReviewCreateForm pRcrd[%O]', pRcrd);
    const rank = getPendingRank( pRcrd );
    const params = getReviewCreateFormParams( pRcrd, rank );
    return _elems( 'initSubForm', [params] )
        .then( status => handlers.finishBuild( status, rank ) );
}
function getPendingRank ( pRcrd ) {
    return pRcrd.data.quarantined.coreEntity.rank.displayName;
}
function getReviewCreateFormParams ( pRcrd, rank ) {
    const params = handlers.getParams( rank, null );
    params.beforeFormClose = handleCreateFormClose.bind( null, pRcrd.inst.group );
    params.pending = pRcrd;
    delete params.vals;
    return params;
}
/* ---------------------------- CLOSE --------------------------------------- */
/**
 * After create-form review submitted, the select-form handler is set relative to
 * the submitted stage (set after successful submit and called after create-form closed)
 * @param  {object} pRcrd    Reviewed and closing PendingData instance props.
 * @param  {string}     .group   The closing form's group-key
 * @param  {object}     .pChild  The next set of PendingData to load, child taxon
 */
function handleCreateFormClose ( fLvl ) {
    const pRcrd = _state( 'getFormState', [fLvl, 'pending'] );
    const afterCreateClosed = getCloseSelectHandler( pRcrd );         /*dbug-log*///console.log('       -- handleCreateFormClose fLvl[%s] pRcrd[%s][%O] afterCreateClosed[%O]', fLvl, pRcrd.inst.rvwAction.name, pRcrd, afterCreateClosed);
    _state( 'addFormHandler', ['sub2', 'onFormClose', afterCreateClosed] );
}
/**
 * Create-form close is based on the PendingData stage of the closing record,
 * the parent form's stage, and the user role.
 *
 * Contributor:
 *     Approved Complete:
 *         (all parent stages): Autoruns the standard select-form close method,
 *     Rejected Complete:
 *         Approved parent: Autoruns the standard select-form close method,
 *         Returned parent: Select-form elem is removed. (Combo later filled if approved value, ie duplicate identified.)
 *         Rejected parent: Select-form elem is removed. ('Rejected...' later added to the field's combo)
 *     Returned:
 *         Approved parent: Autoruns the standard select-form close method,
 *         Returned parent: Select-form elem is removed. (Combo later filled with the quarantined value.)
 *         Rejected parent: Select-form elem is removed. (Combo later filled with the quarantined value.)
 *
 * Manager: (parent-form stage is always 'Pending')
 *     Approved: Autoruns the standard select-form close method, if taxonomy reviewed.
 *     Rejected: Select-form elem is removed. 'Rejected...' is added to the field's combo
 *     Returned: Select-form elem is removed. 'Returned...' is added to the field's combo
 */
function getCloseSelectHandler ( pRcrd ) {                             /*dbug-log*///console.log('       -- getCloseSelectHandler pRcrd[%O]', pRcrd);
    if ( isApprovedWithPendingChild( pRcrd ) ) { return continueCreateReview; }
    const map = {
        Approve: submitSelectForm,
        Complete: handleBasedOnRootForm.bind( null, pRcrd ),
        Quarantine: submitSelectForm,
        Reject: handleBasedOnRootForm.bind( null, pRcrd ),
        Return: closeFormAndSetPendingOpt.bind( null, pRcrd.inst.field, 'Returned' )
    };
    if ( pRcrd.entityId && !pRcrd.completed ) { return map.Approve; } // Necessary when data is rejected and replaced
    return map[pRcrd.inst.rvwAction.name];
}
function isApprovedWithPendingChild ( pRcrd ) {
    return pRcrd.inst.pChild && pRcrd.entityId;
}
/**
 * After pending taxon is approved and there are pending child-taxa to review,
 * the review process is continued from here.
 * @param  {object} rcrd  The reviewed PendingData record
 */
function continueCreateReview () {
    const pRcrd = _state( 'getFormState', ['sub', 'pending'] );
    const nextRcrd = getFirstPendingRcrdToReview( pRcrd );            /*dbug-log*///console.log(' +-- continueCreateReview nextRcrd[%O]', nextRcrd);
    return initReviewCreateForm( nextRcrd )
        .then( () => _review( 'finishReviewFormInit', [nextRcrd] ) );
}
/* ========================= SELECT FORM ==================================== */
/* ----------------------------- INIT --------------------------------------- */
function initTaxonSelect ( pRcrd ) {
    const params = [
        pRcrd.inst.field,
        pRcrd.data.review.fields.Group.value,
        pRcrd.data.review.fields['Sub-Group'].value,
        null, //submit
        pRcrd.inst.group
    ];
    return _form( 'initFieldTaxonSelect', [...params] );
}
function selectParentAndInitCreate ( pRcrd ) {
    return selectParentTaxa( pRcrd )
        .then( () => initReviewCreateForm( pRcrd ) );
}
/* ------------------------- SELECT PARENT ---------------------------------- */
function selectParentTaxa ( pRcrd ) {
    const pTxn = getParentTaxon( pRcrd );                             /*dbug-log*///console.log(' -- selectParentTaxa pRcrd[%O] pTxn[%O]', pRcrd, pTxn);
    return selectGroupTaxon( pTxn )
        .then( () => selectSubGroupTaxon( pTxn ) )
        .then( () => selectParentTaxon( pTxn ) );
}
function getParentTaxon ( pRcrd ) {
    const prntField = pRcrd.data.review.fields.Parent;              /*dbug-log*///console.log(' -- getParentTaxon prntField[%O]', prntField);
    const id = prntField.pending ? getApprovedPrntId( prntField ) : prntField.value;
    return _state( 'getRcrd', ['taxon', id] );
}
function getApprovedPrntId ( prntField ) {
    return _state( 'getRcrd', ['pending', prntField.pending.id] ).entityId;
}
function selectGroupTaxon ( txn ) {
    const curGroup = _cmbx.getSelVal( 'Group' );
    if ( curGroup == txn.group.id ) { return Promise.resolve(); }
    return _cmbx.triggerComboChangeReturnPromise( 'Group', txn.group.id );
}
function selectSubGroupTaxon ( txn ) {                                 /*dbug-log*///console.log('       -- selectSubGroupTaxon txn[%O]', txn);
    if ( !$( '#sel-Sub-Group' ).length ) { return; }
    const curSubGroup = _cmbx.getSelVal( 'Sub-Group' );
    if ( curSubGroup == txn.group.subGroup.id ) { return; }
    return _cmbx.triggerComboChangeReturnPromise( 'Sub-Group', txn.group.subGroup.id );
}
function selectParentTaxon ( txn ) {                                   /*dbug-log*///console.log('       -- selectParentTaxon txn[%O]', txn);
    if ( txn.isRoot ) { return; }
    return _cmbx.triggerComboChangeReturnPromise( txn.rank.displayName, txn.id );
}
/* ---------------------------- CLOSE --------------------------------------- */
/** A taxon is selected in the form for these stages, so standard submit works. */
function submitSelectForm () {                                       /*dbug-log*///console.log('           -- submitSelectForm');
    $( `#sub-submit` ).trigger( 'click' );
}
/**
 * Closes the select form and adds the pending-stage to the field's combo
 * @param  {string} field The name of the field with the select form
 */
function closeFormAndSetPendingOpt ( field, stage ) {
    closeSelectForm();                                              /*dbug-log*///console.log('       -- closeFormAndSetPendingOpt field[%s] stage[%s]', field, stage);
    if ( _cmbx.getSelVal( field ) ) { return; } //set elsewhere. ie, duplicate entity identified an replaced
    _review( 'setPendingComboOpt', [field, stage] );
}
function closeSelectForm () {
    _elems( 'exitFormElemAndClearState', ['sub'] );
}
function handleBasedOnRootForm ( pRcrd ) {
    const rootStage = _state( 'getReviewStage', ['top'] );            /*dbug-log*///console.log('       -- handleBasedOnRootForm closingStage[%s] rootStage[%s] field[%O]', pRcrd.stage.name, rootStage, pRcrd.inst.field);
    if ( rootStage === 'Approved' ) { return submitSelectForm(); }
    if ( pRcrd.completed ) { return closeSelectForm(); } // Rejected record. Continue review handles combo
    closeFormAndSetPendingOpt( pRcrd.inst.field, pRcrd.stage.name );
}

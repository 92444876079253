/**
 * Manages form review-log. Messages can be added forr form intercommunication.
 * (Notes can also be attached to each field, but the originating user is not saved.)
 *
 * Export
 *     addToReviewLog
 *     prepareAndReturnReviewLog
 */
import { _u } from '~util';
import { _state } from '~form';
/** Adds text to the form's review-log. */
export function addToReviewLog( fLvl, txt ) {                         /*dbug-log*///console.log('   -- addToReviewLog fLvl[%s] txt[%s]', fLvl, txt);
    const curLogs = $( `#${ fLvl }_rvw-msg-hstry` )[0].lastChild;
    $( curLogs ).append( txt );
}
/** Adds new user messages to the log and returns the log-history html. */
export function prepareAndReturnReviewLog( fLvl, stage ) {
    addNewMessagesToLog( fLvl, stage );
    return $( `#${ fLvl }_rvw-msg-hstry` ).html();
}
function addNewMessagesToLog( fLvl, stage ) {
    const $msg = $( `#${ fLvl }_rvw-msg` ).val()
    const newMsgs = $msg ? $msg.trim() : null;                      /*dbug-log*///console.log('   -- addNewMessagesToLog [%s] newMsgs?[%s]', fLvl, newMsgs);
    if ( newMsgs ) { addToReviewLog( fLvl, `<p>>>> ${ newMsgs } </p>` ); }
    addToReviewLog( fLvl, `<p class="g i">--- Data: ${ stage }.</p>` );
}
export function pushUpdatedLogs( fLvl ) {
    const data = {
        id: _state( 'getFormState', [fLvl, 'pending'] ).id,
        logs: $( `#${ fLvl }_rvw-msg-hstry` )[0].innerHTML
    }
    _u.sendAjaxQuery( data, 'crud/pend/log' );
}
/**
 * Tests simple assertions about PendingData for the data-review process.
 */
import { _db, _el, _u } from '~util';
import { _data, _state } from '~form';
/** Current user set during data-review init. */
let user;

export function isPendingAction( pRcrd, action ) {
    return _data( 'getPendingAction', [pRcrd] ) === action;
}
/**
 * Contributors have access to their data during the review process. Entity data
 * are quarantined with temporary IDs. During manager review, these are removed.
 * @param  {object}  pRcrd PendingData entity
 * @return {boolean}       True: User is the contributor and the data is quarantined
 */
export function ifValueQuarantined( pRcrd ) {                         /*dbug-log*///console.log('-- ifValueQuarantined isContribtor?[%s] isQuarantined?[%s]', _u.isContributorUser(), isQuarantined(pRcrd));
    return _u.isContributorUser() && isQuarantined( pRcrd );
}
/** True if data is still pending final review. */
function isQuarantined( pRcrd ) {                                     /*dbug-log*///console.log('-- isQuarantined pRcrd[%O]', pRcrd);
    return pRcrd && !pRcrd.completed && !isApprovedOrRejected( pRcrd );
}
/** Tests whether the field-data is quarantined and pending review. */
export function isFieldDataPending( fConfg ) {                        /*dbug-log*///console.log('-- isFieldDataPending fConfg[%O] pRcrd?[%O]', fConfg, fConfg.pending);
    if ( !_el.isMultiField( fConfg ) ) { return hasQuarantinedData( fConfg.pending ); }
    return fConfg.pending && Object.values( fConfg.pending ).some( hasQuarantinedData );
}
function hasQuarantinedData( pRcrd ) {
    return pRcrd && !pRcrd.entityId;
}
export function isReadyToReview( pRcrd, userData ) {                  /*dbug-log*///console.log('-- isReadyToReview pRcrd[%O] userData?[%O] user?[%O]', pRcrd, userData, user);
    if ( userData ) { user = userData; }
    return pRcrd ? _db.isAvailableToCurrentUser( pRcrd, user ) : false;
}
/**
 * True if data has been approved or rejected by a data-manager.
 * @param  {object}  pRcrd  PendingData entity
 * @param  {string}  fLvl   Form group to check the PendingData record
 * @return {boolean}
 */
export function isApprovedOrRejected( pRcrd, fLvl ) {
    if ( fLvl ) { pRcrd = _state( 'getFormState', [fLvl, 'pending'] ); }
    return pRcrd && ( isStage( pRcrd, 'Approve' ) || isStage( pRcrd, 'Reject' ) );
}
function isStage( pRcrd, stageName ) {
    const stages = [pRcrd.stage.name, pRcrd.data.stage.name]; //Handles 'Locked' data
    return stages.some( s => stageName.includes( s ) );
}
/** ------------------ IS QUARNTINE NOT POSSIBLE ---------------------------- */
/**
 * All entity fields must be processed for potential quarantined data, except for
 * certain fields which can not possibly refer to quarantined data.
 */
export function isPossiblyQuarantined( fConfg ) {                     /*dbug-log*///console.log('-- isPossiblyQuarantined fConfg[%O]', fConfg);
    if ( !fConfg.value || !fConfg.entity ) { return false; }
    return !['Type', 'Rank'].find( s => fConfg.entity.includes( s ) );
}
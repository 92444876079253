/**
 * The form side-panel is used to display additional details about the entity
 * record - showing all related entities, or the contained data within an
 * interaction (source and location details).
 *
 * TOC
 *     INIT SIDE-PANEL
 *     INTERACTION-FORM DETAILS
 *     EDIT-FORM RELATIONAL DETAILS
 *     SUB-FORM DATA-REVIEW
 */
import buildSidePanel from './assemble-panel.js';
import * as eElems from './entity/entity-details-main.js';
import * as dReview from './review/review-panel-main.js';
/* ===================== INIT SIDE-PANEL ==================================== */
export function getSidePanelElems(fConfg) {                         /*dbug-log*///console.log("--getSidePanelElems fConfg[%O]", fConfg);
    const fElems = eElems.getEntityDetailElems(fConfg);
    const rElems = dReview.getDataReviewElems(fConfg);
    return buildSidePanel(fConfg, fElems, rElems);
}
export function resetReviewSidePanel() {
    dReview.resetReviewSidePanel(...arguments);
}
/* ================== INTERACTION-FORM DETAILS ============================== */
export function setSubEntityDetails() {
    return eElems.setSubEntityDetails(...arguments);
}
export function clearSidePanelDetails() {
    return eElems.clearSidePanelDetails(...arguments);
}
/* ================== EDIT-FORM RELATIONAL DETAILS ========================== */
export function fillEditEntitySidePanel() {
    eElems.fillEditEntitySidePanel(...arguments);
}
/* ======================= SUB-FORM DATA-REVIEW ============================= */
export function initSubEntityDataReviewElems() {
    dReview.initSubEntityDataReviewElems(...arguments);
}
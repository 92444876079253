/**
 * If another existing location captures all entered data, prompts editor to select.
 *
 * Export
 *     checkIfLocExists
 *
 * TOC
 *     CHECK FOR UNIQUE DATA
 *     SELECT EXISTING LOCATION
 */
import { _cmbx } from '~util';
import { _state, _val, submitForm } from '~form';

export default function checkIfLocExists ( fLvl ) {
    const fields = _state( 'getFormState', [ 'sub', 'fields' ] );       /*dbug-log*///console.log('-- checkIfLocExists [%s]fields[%O]', fLvl, fields);
    if ( ifUniqueLocData( fields ) ) { return submitForm( 'sub' ); }
    setExistingEntityData( fLvl, fields );
    _val( 'formSubmitError', [ 'sub', 'existingEntity' ] );
}
/* ================= CHECK FOR UNIQUE DATA ================================== */
/**
 * Checks to ensure there is specific data for the new location. Otherwise, the
 * existing location is found and the editor is alerted to select it instead.
 */
function ifUniqueLocData ( fields ) {
    return Object.values( fields ).find( isSpecificData );
}
function isSpecificData ( field ) {
    if ( !field.shown ) { return false; }
    const habData = ['DisplayName', 'HabitatType', 'Country'];      /*dbug-log*///console.log('-- isSpecificData? field[%s]?[%s]', field.name, (habData.indexOf(field.name) === -1 ? !!field.value : false));
    return habData.indexOf( field.name ) === -1 ? !!field.value : false;
}
/* ================= SELECT EXISTING LOCATION =============================== */
function setExistingEntityData ( fLvl, fields ) {
    const name = buildExistingLocName();
    const loc = getExistngLocRcrd( name, fields.Country.value );
    updateLocState( fLvl, loc );
}
function buildExistingLocName() {
    const name = _cmbx.getSelTxt( 'Country' );
    const habName = _cmbx.getSelTxt( 'HabitatType' );            /*dbug-log*///console.log('-- buildExistingLocName parent[%s] habitat?[%s]', name, habName);
    return habName ? ( name + '-' + habName ) : name;
}
function getExistngLocRcrd ( name, id ) {
    const locs = _state( 'getEntityRcrds', [ 'location' ] );
    const pLoc = locs[id];                                          /*dbug-log*///console.log('-- getExistngLocRcrd name[%s] pLoc[%O]', name, pLoc);
    return pLoc.displayName === name ? pLoc : getLocHabRcrd( name, pLoc, locs );
}
function getLocHabRcrd ( name, pLoc, locs ) {
    const id = pLoc.children.find( c => locs[ c ].displayName == name );
    return locs[id];
}
function updateLocState ( fLvl, loc ) {
    const misc = _state( 'getFormState', [ fLvl, 'misc' ] );            /*dbug-log*///console.log('-- updateLocState loc[%O] state[%O]', loc, misc);
    misc.existingEntity = loc;
}
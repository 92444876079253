/**
 * Data-entry form bundle's main file.
 *
 * TOC
 *     FORM STATE / MEMORY
 *     FORM DATA
 *     ENTITY FORMS
 *     FORM UI
 *     VALIDATION ALERTS
 *     DATA REVIEW
 */
import { _el, _u, executeMethod } from '~util';
import * as alert from './alerts/alerts.js';
import * as form from './entity/entity-form-main.js';
import * as state from './etc/form-state-main.js';
import * as data from './data/data-main.js';
import * as rvw from './review/data-entry-review-main.js';
import * as elems from './elems/elems-main.js';

/** ===================== MODULE-EXECUTOR =================================== */
function moduleMethod( funcName, mod, modName, params ) {
    return executeMethod( funcName, mod, modName, 'forms-main', params );
}
export function _confg( funcName, params = [] ) {
    return form._confg( funcName, params );
}
/** ------------------- FORM STATE / MEMORY --------------------------------- */
export function _state( funcName, params = [] ) {
    return moduleMethod( funcName, state, 'state', params );
}
/* ----------------------- FORM DATA ---------------------------------------- */
export function _data( funcName, params = [] ) {                      /*dbug-log*///console.log('entity func = %O', arguments);//entity form interface
    return moduleMethod( funcName, data, 'form', params );
}
export function submitForm() {
    return data.submitForm( ...arguments );
}
/* ----------------- ENTITY FORMS ------------------------------------------- */
export function _form( funcName, params = [] ) {                      /*dbug-log*///console.log('entity func = %O', arguments);//entity form interface
    return moduleMethod( funcName, form, 'form', params );
}
export function create( entity, name ) {
    return form.createEntity( entity, name );
}
export function edit( entity, id ) {
    return initDataEntryForm( entity, id, form.editEntity );
}
export function initNewDataForm() {
    return initDataEntryForm( 'Interaction', null, create );
}
function initDataEntryForm( entity, val, initFunc ) {                 /*dbug-log*///console.log('--initDataEntryForm entity[%s] val[%s] initFunc[%O]', entity, val, initFunc);
    _el.showPagePopup();
    _elems( 'toggleWaitOverlay', [true] );
    return initFunc( entity, val );
}
export function selectIntLoc( id ) {
    form.selectIntLoc( id );
}
export function autofillCoordinateFields() {
    form.autofillCoordinateFields( ...arguments );
}
/** --------------------------- FORM UI ------------------------------------- */
export function _elems( funcName, params = [] ) {
    return moduleMethod( funcName, elems, 'form-elems', params );
}
/* ------------------- VALIDATION ALERTS ------------------------------------ */
export function _val( funcName, params = [] ) {
    return alert[funcName]( ...params );
}
export function alertFormIssue( ...args ) {
    if ( !state.getStateProp() ) { return; } //form closed
    return _u.alertIssue( ...args );
}
/** ------------------------- DATA REVIEW ----------------------------------- */
export function _review( funcName, params = [] ) {
    return moduleMethod( funcName, rvw, 'review', params );
}
export function review() {
    rvw.initDataReviewWizard();
}
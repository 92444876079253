/**
 * When an author is selected, a new author combobox is initialized underneath
 * the last author combobox, unless the last is empty. The total count of
 * authors is added to the new id.
 *
 * Export
 *     alertBlank
 *     toggleOtherAuthorType
 *     onAuthAndEdSelection
 *
 * TOC
 *     ON AUTHOR|EDITOR SELECTION
 *     ON FIELD CLEARED
 *     SYNC AUTH-TYPE FIELDS
 *         UPDATE OTHER AUTH-TYPE UI
 *             FIELD WIDTH
 *             FIELD LABEL
 */
import { _cmbx } from '~util';
import { _elems, _form, _state, _val } from '~form';
import * as sForm from '../src-form-main.js';
import * as aForm from './auth-form-main.js';
/* ======================= ON AUTHOR|EDITOR SELECTION ======================= */
/**
 * Note: If create form selected from dropdown, the count of that combo is used.
 * @param  {num} cnt      Order count Bound to input on init
 * @param  {str} aType    Author||Editor
 * @param  {num} v        ID of selected entity
 */
export function onAuthAndEdSelection( cnt, aType, v ) {               /*temp-log*///console.log('+--onAuthAndEdSelection [%s][%s] = [%s]', cnt, aType, v);
    const fLvl = _state( 'getSubFormLvl', ['sub'] );
    const ttl = _state( 'getFieldState', [fLvl, aType, 'count'] );    /*dbug-log*///console.log('       --ttl[%s]', ttl);
    if ( v === 'create' ) { return aForm.initCreateForm( cnt, aType, v ); }
    if ( v === '' || isNaN( v ) ) { return onAuthFieldClear( aType, fLvl, ttl, cnt ); }
    if ( ttl === 1 ) { toggleOtherAuthorType( aType, fLvl, false );  }
    return handleAuthorSelected( cnt, aType, v, fLvl, ttl );
}
function handleAuthorSelected( cnt, aType, v, fLvl, ttl ) {           /*dbug-log*///console.log('       --handleAuthorSelected [%s][%s] = [%s] (fLvl[%s] ttl[%s])', cnt, aType, v, fLvl, ttl);
    _elems( 'storeMultiSelectValue', [fLvl, cnt, aType, v] );
    sForm.setSourceFieldPendingData( fLvl, aType, v, cnt );
    handleBlanks( aType, fLvl );
    if ( aForm.isDynamicFieldEmpty( aType, ttl ) ) { return; }
    if ( isDisplayForm( fLvl ) ) { return; }
    return aForm.buildNewAuthorSelect( fLvl, aType, ttl+1 );
}
function isDisplayForm( fLvl ) {
    const pLvl = _state( 'getFormLevel', ['parent', fLvl] );
    return _state( 'isDisplayFormOnly', [pLvl] );
}
/* ======================= ON FIELD CLEARED ================================= */
/** [onAuthFieldClear description] */
function onAuthFieldClear( aType, fLvl, ttl, cnt ) {                  /*dbug-log*///console.log('--onAuthFieldClear [%s] cleared[%s] ttl[%s]', aType, cnt, ttl);
    _elems( 'storeMultiSelectValue', [fLvl, cnt, aType, null] );
    sForm.handleCitText( fLvl );
    if ( !aForm.isDynamicFieldEmpty( aType, ttl ) ) { return handleBlanks( aType, fLvl, cnt ); }
    aForm.removeAuthField( aType, ttl-- );
    rmvExtraMultiFields( aType, ttl, fLvl )
    ifNoneStillSelectedEnableOtherType( aType, fLvl, cnt );
    sForm.setSourceFieldPendingData( fLvl, aType, null, cnt )
}
/**
 * Ensure that only one empty combo is at the end of the field by removing extras.
 * @param  {string}  aType  Author field: Authors or Editors
 * @param  {string}  ttl    Current count of combos in the field
 * @param  {string}  fLvl   Form group level
 * @param  {boolean} leaveOne  True: one empty combo is left at the end of field
 */
export function rmvExtraMultiFields( aType, ttl, fLvl, leaveOne = true ) {/*dbug-log*///console.log('--rmvExtraMultiFields aType[%s] ttl[%s] leaveOne?[%s]', aType, ttl, leaveOne);
    const newCnt = removeExtraCombos( aType, ttl, fLvl, leaveOne );   /*dbug-log*///console.log('       --newCnt?[%s]', newCnt);
    _state( 'setFieldState', [fLvl, aType, newCnt, 'count'] );
    handleBlanks( aType, fLvl );
}
function removeExtraCombos( aType, ttl, fLvl, leaveOne ) {
    while ( ifMoreThanOneComboAndLastIsEmpty( aType, ttl ) ) {
        if ( ifNextComboFilled( aType, ttl ) && leaveOne ) { return ttl; }
        aForm.removeAuthField( aType, ttl );                          /*dbug-log*///console.log('  --Removing [%s]', ttl);
        _elems( 'storeMultiSelectValue', [fLvl, ttl, aType, null] );
        --ttl;
    }
    return ttl;
}
function ifMoreThanOneComboAndLastIsEmpty( aType, ttl ) {
    return ttl > 1 && aForm.isDynamicFieldEmpty( aType, ttl );
}
function ifNextComboFilled( aType, ttl ) {
    return !aForm.isDynamicFieldEmpty( aType, --ttl );
}
/* ------------------ HANDLE BLANKS IN ORDER -------------------------------- */
/**
 * Author/editor fields must have all fields filled continuously. There can
 * be no blanks in the selected order. If found, an alert is shown to the user.
 */
function handleBlanks( aType, fLvl, cnt ) {
    const vals = _state( 'getFieldState', [fLvl, aType] );            /*dbug-log*///console.log('--handleBlanks [%s][%O] cleared?[%s]', aType, vals, cnt);
    let blank = checkForBlanks( vals, cnt );
    if ( blank !== 'found' ) { return ifPreviousAlertClearIt( aType, fLvl ); }
    alertBlank( aType, fLvl );
    return true;
}
function checkForBlanks( vals, cleared ) {
    let blanks = false;
    checkValsForBlanks();
    return blanks;

    function checkValsForBlanks() {
        for ( let ord in vals ) {                                     /*dbug-log*///console.log('    --ord[%s] val[%s] blanks[%s]?', ord, vals[ord], blanks)
            if ( !vals[ord] || ord == cleared ) {
                blanks = ifBlankFound( ord ) ? 'found' : 'maybe';
            } else if ( blanks ) {
                blanks = 'found';
                return;
            }
        }
    }
    function ifBlankFound( ord ) {
        return ord > 1 ? blanks : Object.keys( vals ).length > 1;
    }
}
export function alertBlank( aType, fLvl ) {
     _val( 'showFormValAlert', [aType, 'fillAuthBlanks', fLvl] );
}
function ifPreviousAlertClearIt( aType, fLvl ) {
    if ( !$( `#${ aType }_alert.${ fLvl }-active-alert` ) ) { return; }
    _val( 'clrContribFieldAlert', [aType, fLvl] );
    _form( 'handleCitText', [fLvl] );
}
/* ====================== SYNC AUTH-TYPE FIELDS ============================= */
/** [ifNoneStillSelectedEnableOtherType description] */
export function ifNoneStillSelectedEnableOtherType( aType, fLvl, clearedCnt ) {/*dbug-log*///console.log('--ifNoneStillSelectedEnableOtherType lvl[%s] type[%s] cleared[%s]', fLvl, aType, clearedCnt);
    if ( ifTypeStillSelected( aType, fLvl, clearedCnt ) ) { return; }
    toggleOtherAuthorType( aType, fLvl, true );
}
function ifTypeStillSelected( aType, fLvl, clearedCnt ) {
    const fVals = _state( 'getFieldState', [fLvl, aType] );           /*dbug-log*///console.log('--ifTypeStillSelected lvl[%s] type[%s] vals[%O]', fLvl, aType, _u.snapshot(fVals));
    if ( fVals[clearedCnt] ) { fVals[clearedCnt] = null; } //val store change event could happen after this check
    return Object.values( fVals ).find( v => v );
}
/* -------------------- UPDATE OTHER AUTH-TYPE UI --------------------------- */
export function toggleOtherAuthorType( type, fLvl, enable ) {         /*dbug-log*///console.log('--toggleOtherAuthorType [%s][%s][%s]', type, fLvl, enable);
    const other = type === 'Author' ? 'Editor' : 'Author';
    if ( !_state( 'isFieldShown', [fLvl, other] ) ) { return; }
    _cmbx.enableFirstCombobox( other, enable );
    updateOtherTypeUi( other, enable );
    _state( 'setFieldState', [fLvl, other, enable, 'required'] );
}
function updateOtherTypeUi( oType, enable ) {                         /*dbug-log*///console.log('--updateOtherTypeUi [%s][%s]', oType, enable);
    setOtherFieldWidth( oType, enable );
    showOtherLabelElem( oType, enable );
}
/* --------------------------------------------------- FIELD WIDTH ---------- */
/** [setOtherFieldWidth description] */
function setOtherFieldWidth( oType, enable ) {
    const orgW = $( `#${ oType }_f-cntnr` ).css( 'flex-basis' ).slice( 0, -1 );
    const newW = enable ? orgW * 3 : orgW / 3;                      /*dbug-log*///console.log('--showOtherLabel [%s]enable?[%s] orgW[%s] newW[%s]', oType, enable, orgW, newW);
    $( `#${ oType }_f-cntnr` ).css( {'flex-basis': newW+'%'} );
}
/* --------------------------------------------------- FIELD LABEL ---------- */
/** [showOtherLabelElem description] */
function showOtherLabelElem( oType, enable ) {
    const val = enable ? 'block' : 'none';
    $( `#${ oType }_f-cntnr label` ).css( {display: val} );
}
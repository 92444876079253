/**
 * Some data properties only exist in local storage. Edits that affect this data
 * are tracked here and will be updated properly during the record entry process.
 */
import { _db, _u } from '~util';
import { DataEntryResults, EditObj, EditedIds } from './data-entry-sync';
import { objectKeys } from '~types';
/* ------------------ TRACK LOCAL-PROP EDITS -------------------------------- */
export function trackEditsToLocalProps ( data: DataEntryResults ): void { /*dbug-log*///console.log('   -- trackEditsToLocalProps data[%O]', data);
    if ( !data.coreEdits ) return;
    trackLocalEdits( data, data.coreEdits );
    // trackLocalEdits(data, data.detailEdits); //Not used yet.
}
function trackLocalEdits ( data: DataEntryResults, edits: EditObj ): void {
    const map = {
        ParentTaxon: ifTaxonGroupChanged
    } as const;
    objectKeys( map ).forEach( trackEditsToRelatedData );

    function trackEditsToRelatedData ( prop: keyof typeof map ): void {
        if ( !( prop in edits ) ) return;
        map[prop]( data, edits, edits[prop]! );
    }
}
function ifTaxonGroupChanged ( data: DataEntryResults, edits: EditObj, pTxnId: EditedIds ): void {
    const taxa = _db.getEntities( 'taxon' );
    const newPrnt = taxa[pTxnId.new];
    const oldPrnt = taxa[pTxnId.old];                               /*dbug-log*///console.log('   -- ifTaxonGroupChanged newPrnt[%O] old[%O]', newPrnt, oldPrnt);
    if ( !newPrnt || !oldPrnt ) throw Error( 'Taxon parent not found' );
    trackEditsToData( 'Group', newPrnt.group.displayName, oldPrnt.group.displayName, edits );
    trackEditsToData( 'SubGroup', newPrnt.group.subGroup.name, oldPrnt.group.subGroup.name, edits );
}
function trackEditsToData ( field: string, newVal: any, oldVal: any, edits: EditObj ): void {
    if ( newVal === oldVal ) { return; }
    edits[field] = { new: newVal, old: oldVal };
}
/**
 * Form-button-builder facade (those that have been sensible to place here).
 */
import * as simple from './simple-buttons.js';
import * as help from './help-buttons.js';
import * as submit from './submit-cancel-buttons.js';

export function getFormHelpElems() {
    return help.getFormHelpElems(...arguments);
}
export function getExitButton() {
    return simple.getExitButton(...arguments);
}
export function buildSubmitAndCancelBttns() {
    return submit.buildSubmitAndCancelBttns(...arguments);
}
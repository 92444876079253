/**
 * Initializes the Data-Review Panel.
 *
 * Export
 *     handleReviewPanelInit
 *     initNamesCombobox
 *
 * TOC
 *     INIT
 *     COMBOBOXES
 *         EDITOR NAME
 *         PENDING STATUS
 *         DATE-RANGE
 *     ROLE SPECIFIC
 */
import { _cmbx, _el, _opts } from '~util';
import { review } from '~form';

const app = { cal: false };
/* ============================ INIT ======================================== */
export function handleReviewPanelInit ( userRole ) {                   /*dbug-log*///console.log('-- handleReviewPanelInit userRole[%s]', userRole)
    require( 'styles/pages/db/panels/rvw-data.styl' );
    initComboboxes();
    $( '#rvw-cntrl-bttn' ).off( 'click' ).on( 'click', review );
    disableFilterButton();
}
function disableFilterButton () {
    $( '#rvw-fltr-bttn' ).attr( { disabled: 'disabled' } ).fadeTo( 'fast', '.3' );
}
function initComboboxes () {
    /** TODO: UPDATE TO WORK WITH CONTRIBUTORS AS WELL. */
    initNamesCombobox();
    initDateRangeCalendar();
    initStatusCombobox();
}
/* ============================ COMBOBOXES ================================== */
/* ---------------------------- EDITOR NAME --------------------------------- */
export function initNamesCombobox () {
    _opts.getOptsFromStoredData( 'rvwContribNames', true )
        .then( loadNamesCombobox );
}
function loadNamesCombobox ( opts ) {                                  /*dbug-log*///console.log('--loadNamesCombobox opts[%O]', opts)
    const confg = {
        id: '#sel-rvw-contrib',
        name: 'Data Contributor',
        onChange: Function.prototype,
        placeholder: 'Select Editor (ALL BY DEFAULT)'
    };
    if ( opts.length ) {
        confg.maxItems = null;
        confg.options = opts;
    }                                                               /*dbug-log*///console.log('   -- confg[%O]', confg)
    _cmbx.initCombobox( confg );
    _cmbx.enableCombobox( confg.name, false );
}
/* ------------------------ PENDING STATUS ---------------------------------- */
function initStatusCombobox () {
    _opts.getOptsFromStoredData( 'rvwStages' )
        .then( loadStatusCombobox );
}
function loadStatusCombobox ( opts ) {
    const confg = {
        id: '#sel-rvw-status',
        maxItems: null,
        name: 'Status',
        onChange: Function.prototype,
        options: opts,
        placeholder: 'Select Status (PENDING BY DEFAULT)'
    };                                                              /*dbug-log*///console.log('initStatusCombobox confg[%O]', confg);
    _cmbx.initCombobox( confg );
    _cmbx.enableCombobox( confg.name, false );
}
/* --------------------------- DATE-RANGE ----------------------------------- */
function initDateRangeCalendar () {                                  /*dbug-log*///console.log('--initDateRangeCalendar');
    app.cal = _el.getNewCalendar( getDateRangeCalConfg() );
    app.cal._input.disabled = true;
    $( '#rvw-date-end' ).attr( { disabled: 'disabled' } ).fadeTo( 'fast', '.6' );
}
/** TODO: ONLY ALLOW AVAILABLE DATE-RANGE */
function getDateRangeCalConfg () {
    return {
        elemId: '#rvw-date-start',
        enableTime: false,
        plugins: { range: { input: '#rvw-date-end' } },
    };
}
/* ========================== ROLE SPECIFIC ================================= */
// function initPanelForRole(userRole) {
//     const map = {
//         // contributor: contributor.initDataContributorReviewPanel,
//         // editor: editor.initEditorReviewPanel,
//         // manager: initDataManagerReviewPanel,
//         // super: initDataManagerReviewPanel,
//     };
//     return map[userRole] ? map[userRole]() : null;
// }

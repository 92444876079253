/**
 * Manages the TableRowSelect instance for the review panel.
 *
 * Export
 *     initTableRowSelect
 *
 * TOC
 *     INIT
 */
import * as pM from '../../panels-main.js';
/**
 * tableRowSelect - Feature class handling table-row selection
 */
let app = {};
/**
 * Inits the new TableRowSelect instance for the review panel. It is disabled
 * until needed.
 */
export function initTableRowSelect() {                              /*dbug-log*///console.log('--initTableRowSelect [%O]', app);
    app.tableRowSelect = pM.newTableRowSelect('review');
    app.tableRowSelect.disable();
}
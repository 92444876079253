/**
 * Builds and manages the form fields.
 *
 * TOC
 *     INIT FORM-FIELDS
 *         STORE FIELD-VALUE
 *     FORM-FIELD UTILITY
 *         BUILD/FILL
 *         PREDICATES
 *         FORM COMBOS
 *         REVIEW NOTES
 */
import * as build from './build/field-build-main.js';
import * as util from './util/field-util-main.js';
/* ==================== INIT FORM-FIELDS ================================== */
export function buildFormField() {
    return build.buildFormField(...arguments);
}
export function buildDynamicFormField() {
    return build.buildDynamicFormField(...arguments);
}
/* ------------------- STORE FIELD-VALUE ------------------------------------ */
export function storeMultiSelectValue() {
    return build.storeMultiSelectValue(...arguments);
}
/* =================== FORM-FIELD UTILITY =================================== */
/* --------------------- BUILD/FILL ----------------------------------------- */
export function setDynamicFieldStyles() {
    util.setDynamicFieldStyles(...arguments);
}
export function ifMutlipleDisplaysGetToggle() {
    return util.ifMutlipleDisplaysGetToggle(...arguments);
}
export function rebuildFieldsOnFormConfgChanged() {
    return util.rebuildFieldsOnFormConfgChanged(...arguments);
}
export function fillComplexFormFields() {
    return util.fillComplexFormFields(...arguments);
}
/* ------------------------ PREDICATES -------------------------------------- */
export function areRequiredFieldsFilled() {
    return util.areRequiredFieldsFilled(...arguments);
}
/* ------------------------ FORM COMBOS ------------------------------------- */
export function resetFormCombobox() {
    util.resetFormCombobox(...arguments);
}
export function setSilentVal() {
    util.setSilentVal(...arguments);
}
export function initFormCombos() {
    util.initFormCombos(...arguments);
}
export function addAndSelectEntity() {
    return util.addAndSelectEntity(...arguments);
}
/* --------------------- REVIEW NOTES -------------------------------------- */
export function showFieldReviewNotes() {
    return build.showFieldReviewNotes(...arguments);
}
export function toggleFormReviewNotes() {
    return build.toggleFormReviewNotes(...arguments);
}
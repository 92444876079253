/**
 * Modifies pending-data for local storage:
 * + DATA-MANAGER
 *     - rvwContribNames - an object with contributor's (with pending-data) displayName(k) and id(v).
 *     - pending - all PendingData records
 * + CONTRIBUTOR
 *     - pending - data from the contributor is readded to local storage
 * + BOTH
 *     - passiveStageNames - PendingData stage passive forms(k)
 *     - activeStageNames
 *     - availablePending - PendingData available for current user to review
 *     - rvwStages - PendingData stages:
 *     - pendCounts - Pending create, edit, and delete counts for each entity.
 *
 * Export
 *     modifyRvwDataForLocalDb
 *     setAvailablePendingDataAndStats
 *     isAvailableToCurrentUser
 *
 * TOC
 *     SORT AVAILABLE PENDING-DATA
 *     USER-ROLE SPECIFIC
 *         CONTRIBUTOR
 *         MANAGER
 *         NON-EDITOR
 *         HELPERS
 */
import { EntityRecords, IdsByName, SerializedEntity, User, objectKeys, objectValues } from '~types';
import { _db, _u } from '~util';
import { PendingDataEntity } from '../../sync/pending/process-pending';

type ReviewData = {
    contributions: EntityRecords;
    pending: EntityRecords;
    pendingStages: EntityRecords;
};
export function modifyRvwDataForLocalDb ( data: ReviewData ): void | Promise<void> {                                           /*dbug-log*///console.log( "--modifyRvwDataForLocalDb [%O]", data );
    if ( ['visitor', 'user'].indexOf( _u.getUserRole() ) !== -1 ) return deleteRvwData();
    parseAndUpdatePendingData( data.pending );
    sortAndStoreStageNames( data.pendingStages );
    setAvailablePendingDataAndStats( data.pending, _db.getValue( 'user' ) as User );
    return handlePendingContributorData( data.contributions );
}
/** Parses nested json data */
function parseAndUpdatePendingData ( pendingData: EntityRecords ): void {
    objectValues( pendingData ).forEach( parseAndUpdateRcrd );
    _db.storeData( 'pending', pendingData );
}
function parseAndUpdateRcrd ( pRcrd: SerializedEntity ): void {
    pRcrd.data = JSON.parse( pRcrd.data );
    delete pRcrd.data.submitted; //Used server-side during data-approval
}
/* ======================= SORT PENDING-DATA ================================ */
/* ---------------------- SORT AVAILABLE DATA ------------------------------- */
/**
 * Pending create, edit, and delete counts for pending data available to review
 * for the current user.
 * @return {promise}      Unused
 */
export function setAvailablePendingDataAndStats (
    pending: EntityRecords | null,
    user: User | null
): void | Promise<void> {                                           /*dbug-log*///console.log( " --setAvailablePendingDataAndStats pending?[%O] user?[%O]", pending, user );
    return user && pending ? sortAllPendingData( pending, user ) : getDataThenSort();
}
function getDataThenSort (): Promise<void> {
    return _db.getData( ['pending', 'user'] )
        .then( data => sortAllPendingData( data.pending as EntityRecords, data.user as User ) );
}
type PendingStats = {
    counts: {
        [name: string]: {
            create: number;
            edit: number;
            delete: number;
        };
    };
    editors: {
        [name: string]: number;
    };
    stages: {
        [name: string]: number;
    };
};
function sortAllPendingData ( pending: EntityRecords, user: User ): void {/*dbug-log*///console.log( " --sortAllPendingData pending[%O] user[%O]", pending, user );
    const t: PendingStats = { counts: {}, stages: {}, editors: {} };
    sortAndStoreRelevantPendingData();
    storeTrackedData( t );

    function sortAndStoreRelevantPendingData (): void {
        const rcrds: SerializedEntity[] = [];
        Object.values( pending ).forEach( sortPendingData );
        _db.storeData( 'availablePending', rcrds );                /*dbug-log*///console.log( " -- sortAllPendingData tracked[%O] rcrds[%O]", t, rcrds );

        function sortPendingData ( pRcrd: SerializedEntity ): void {/*dbug-log*///console.log( " --sortPendingData pRcrd[%O] user[%O]", pRcrd, user );
            if ( !isAvailableToCurrentUser( pRcrd, user ) ) return;
            trackPendingData( pRcrd );
            rcrds.push( pRcrd );
        }
    }
    function trackPendingData ( pRcrd: SerializedEntity ): void {   /*dbug-log*///console.log( " --trackPendingData pRcrd[%O]", pRcrd );
        if ( !t.counts[pRcrd.entity] ) initEntityCountObj( pRcrd.entity );
        ++t.counts[pRcrd.entity]![pRcrd.data.review.action as ( 'create' | 'edit' | 'delete' )];
        t.stages[pRcrd.stage.name] = pRcrd.stage.id;
        t.editors[pRcrd.createdBy.displayName] = pRcrd.createdBy.id;
    }
    function initEntityCountObj ( entity: string ): void {
        t.counts[entity] = { create: 0, edit: 0, delete: 0 };
    }
}
/**
 * Stores the counts and contributor names of available pending data.
 * @param  {object} tracked Object with sorted pending-data stats
 */
function storeTrackedData ( tracked: PendingStats ): void {
    _db.storeData( 'rvwContribNames', tracked.editors );
    _db.storeData( 'rvwStages', tracked.stages );
    _db.storeData( 'pendingCounts', tracked.counts );               /*dbug-log*///console.log( " -- storeTrackedData tracked[%O]", tracked );
}
/* ------------------------ SORT STAGE DATA --------------------------------- */
function sortAndStoreStageNames ( stages: EntityRecords ): void {
    const active: IdsByName = {};
    // const passive: IdsByName = {};
    objectKeys( stages ).forEach( storeStageName );                 /*dbug-log*///console.log(" -- sortAndStoreStageNames active[%O] passive[%O]", active, passive);
    active.Skip = 'release';
    _db.storeData( 'activeStageNames', active );
    // _db('storeData', ['passiveStageNames', passive]); //not currently used

    function storeStageName ( id: number ): void {
        // passive[stages[id]?.passiveForm] = id;
        if ( ifStageWithoutActiveIntentionalAction( id ) ) return;
        active[stages[id]?.activeForm] = id;
    }
    function ifStageWithoutActiveIntentionalAction ( id: number ): boolean {
        const skip = ['Complete', 'Lock', 'Quarantine'];
        return skip.indexOf( stages[id]?.activeForm ) !== -1;
    }
}
/* =================== USER-ROLE SPECIFIC =================================== */
export function isAvailableToCurrentUser (
    pRcrd: SerializedEntity,
    user: User
): boolean {
    const isAvailable = {
        super: isReadyForManagerReview,
        admin: isReadyForManagerReview,
        manager: isReadyForManagerReview,
        editor: Function.prototype,
        contributor: isReadyForContributorReview,
        user: Function.prototype,
        visitor: Function.prototype,
    } as const;                                                     /*dbug-log*///console.log( " --isAvailableToCurrentUser? pRcrd[%O] user[%O] isAvailable?[%s]", pRcrd, user, isAvailable[user.role]( pRcrd, user ) );
    return isAvailable[user.role]( pRcrd, user );
}
/* ------------------------ CONTRIBUTOR ------------------------------------- */
function isReadyForContributorReview ( pRcrd: SerializedEntity, user: User ): boolean {/*dbug-log*///console.log(" --isReadyForContributorReview? pRcrd[%O] user[%O]", pRcrd, user);
    const stages = ['Approve', 'Approved', 'Return', 'Returned', 'Reject', 'Rejected'];
    if ( !ifUserMatchAndDataReady( pRcrd.createdBy, user ) ) return false; /*dbug-log*///console.log(' -- stages[%O]', stages);
    return isReviewComplete( pRcrd ) ? false : isValidStageForUser( pRcrd, stages );
}
function isReviewComplete ( pRcrd: SerializedEntity ): boolean {
    return pRcrd.stage.name === 'Completed' || pRcrd.completed; //Before local processing || after
}
/** Created by current contributor and not in active review with a data-manager. */
function ifUserMatchAndDataReady ( pRcrd: SerializedEntity, user: User ): boolean {
    return !pRcrd.managedBy && ifUserMatches( pRcrd.managedBy, user );
}
/** Readds pending-data from the contributor to their local database. */
function handlePendingContributorData ( cData: EntityRecords ): Promise<void> {/*dbug-log*///console.log("handlePendingContributorData cData[%O]", cData);
    return storePendingContributorData( cData )
        .then( () => _db.removeData( 'contributions' ) );
}
function storePendingContributorData ( cData: EntityRecords ): Promise<void> {
    return Object.values( cData ).reduce( ( p, pRcrd ) => { //p(romise), pendingData
        return p.then( () => _db.processPending( pRcrd as PendingDataEntity ) );
    }, Promise.resolve() );
}
/* -------------------------- MANAGER --------------------------------------- */
function isReadyForManagerReview ( pRcrd: SerializedEntity, user: User ): boolean {/*dbug-log*///console.log( " --isReadyForManagerReview? pRcrd[%O] user[%O]", pRcrd, user );
    const stages = ['Pause', 'Held', 'Quarantine', 'Pending'];      /*dbug-log*///console.log( ' -- isReadyForManagerReview pRcrd[%O] mngrId[%s]', pRcrd, user.id );
    if ( !ifUserMatches( pRcrd.managedBy, user ) ) return false;
    return isValidStageForUser( pRcrd, stages );
}
/* ------------------------ NON-EDITOR -------------------------------------- */
/** For user roles without the data-entry features   */
function deleteRvwData (): void {
    ['contributions', 'pending', 'stages'].forEach( k => _db.removeData( k ) );
}
/* --------------------------- HELPERS -------------------------------------- */
function ifUserMatches ( dataUser: User, curUser: User ): boolean { /*dbug-log*///console.log( "ifUserMatches dataUser?[%O] curUser[%O] matches?[%s]", dataUser, curUser, dataUser?.id === curUser.id );
    return dataUser ? dataUser.id === curUser.id : true;
}
function isValidStageForUser ( pRcrd: SerializedEntity, validStages: string[] ): boolean {
    const stage = [pRcrd.stage.name, pRcrd.data.stage.name]; //Handles 'Locked' data
    const isValid = stage.some( s => validStages.indexOf( s ) !== -1 ); /*dbug-log*///console.log( "isValidStageForUser stage[%O] validStages[%O] isValid?[%s]", stage, validStages, isValid );
    return isValid;
}

/**
 * After pending-sub-data has been submitted, form-state is updated accordingly.
 *
 * Export
 *     getSubmittedStage
 *     afterPendingDataSubmitted
 *
 * TOC
 *     PREPARE FORM-STATE
 *     UPDATE PARENT FORM
 *         UPDATE PARENT FIELDS
 *         SET PARENT FIELD-COMBO
 *         LOG SUB-STAGE
 *     STAGE-SPECIFIC UPDATES
 */
import { _cmbx, _db, _u } from '~util';
import { _data, _elems, _review, _state } from '~form';
/**
 * After pending-sub-data created, the parent-form's data is updated. If a
 * data-review process is active, it is continued.
 * @param  {object} fState  Submitted form-state
 * @param  {object} eRcrd   Entity-data after submit
 * @return {string}         'success' will continue the form's close-chain
 */
export function afterPendingDataSubmitted( fState, eRcrd ) {
    addPendingRcrdToEntityRcrd( eRcrd );                              /*temp-log*///console.log('+-- afterPendingDataSubmitted. fState[%O] eRcrd[%O]', _u.snapshot(fState), _u.snapshot(eRcrd));
    _db.setAvailablePendingDataAndStats(); // Updates displayed PendingData counts
    return updatePendingDataFormState( fState, eRcrd );
}
function addPendingRcrdToEntityRcrd( eRcrd ) {
    eRcrd.pending = _state( 'getRcrd', ['pending', eRcrd.pending.id] );
}
/* ====================== UPDATE FORM-STATE ================================= */
function updatePendingDataFormState( fState, eRcrd ) {
    updateFormPendingData( fState, eRcrd );
    const stage = getSubmittedStage( eRcrd.pending.stage.name, fState );
    updatePendingParentForm( fState, eRcrd, stage );
    return handleStageSpecificUpdates( fState, stage );
}
/**
 * Skipped data-review sets are reset to their stage before the data-review began.
 * This is detected here in order to properly process the pending-data.
 */
export function getSubmittedStage( stage, fConfg ) {
    if ( !fConfg.pending || !fConfg.pending.inst ) { return 'Pending'; }
    const submitStage = fConfg.pending.inst.rvwAction.name;
    return ['Pause', 'Skip'].indexOf( submitStage ) !== -1 ? submitStage : stage;
}
/** Adds current PendingData record to the form state to be used during form close. */
function updateFormPendingData( fState, eRcrd ) {
    const inst = fState.pending ? fState.pending.inst : null;
    fState.pending = { ...eRcrd.pending };
    if ( !inst ) { return; }
    fState.pending.inst = { ...inst };
}
/* ===================== UPDATE PARENT-FORM ================================= */
function updatePendingParentForm( fState, eRcrd, stage ) {
    const pState = getPendingParentFormState( fState.group );
    updateParentPendingFields( pState, fState.combo, eRcrd );
    logToParentFormNotes( stage, fState.combo, pState.group, eRcrd.pending );
    if ( !pState.pending ) { return; } //Initial Contributor entry
    setParentFieldCombo( stage, fState.combo, eRcrd.pending.completed );
}
function getNextFormState( fLvl ) {
    const pLvl = _state( 'getFormLevel', ['parent', fLvl] );
    return _state( 'getFormState', [pLvl] );
}
/** Note: Taxa are created within select forms, so their field is 2 levels up. */
function getPendingParentFormState( fLvl ) {
    const pState = getNextFormState( fLvl );                          /*dbug-log*///console.log('--getPendingParentFormState fLvl[%s] pState[%O]', fLvl, pState);
    return pState.action === 'select' ? getNextFormState( pState.group ) : pState;
}
/** ---------------- UPDATE PARENT FIELDS --------------------------------- */
/**
 * Adds pending-data to the parent-form's field config and then updates all
 * parent-form-field pending data. Sets approved data in its combobox.
 * @param  {object} pState     Parent-form state
 * @param  {string} comboField Submitted-form's entity combo-field
 * @param  {object} eRcrd      Entity-data after submit
 */
function updateParentPendingFields( pState, comboField, eRcrd ) {
    const pendingRcrds = _state( 'getEntityRcrds', ['pending'] );     /*dbug-log*///console.log('--updateParentPendingFields pState[%O] PendingData[%O]', pState, pendingRcrds);
    _data( 'updatePendingFields', [pState.fields, pendingRcrds] );
    _cmbx.setSelVal( comboField, eRcrd.pending.entityId, 'silent' );
}
/** ---------------- SET PARENT FIELD-COMBO --------------------------------- */
function setParentFieldCombo ( stage, comboField, reviewComplete ) {   /*dbug-log*///console.log('setParentFieldCombo stage[%s] comboField[%s] reviewComplete?[%s] currentValue[%s]', stage, comboField, reviewComplete, _cmbx.getSelVal(comboField))
    if ( _cmbx.getSelVal( comboField ) || reviewComplete ) { return; } //existing entity set elsewhere
    _review( 'setPendingComboOpt', [comboField, stage] );
}
/** ------------------- LOG SUB-STAGE --------------------------------------- */
function logToParentFormNotes( stage, comboField, fLvl, pRcrd ) {     /*dbug-log*///console.log('--logToParentFormNotes fLvl[%s] comboField[%s] stage[%s] pRcrd[%O]', fLvl, comboField, stage, pRcrd);
    _data( 'addToReviewLog', [fLvl, getLogTxt( stage, comboField, pRcrd )] );
    if ( stage === 'Pending' || fLvl === 'top' ) { return; }
    _data( 'pushUpdatedLogs', [fLvl] );
}
function getLogTxt( stage, comboField, pRcrd ) {
    stage = getLogStageText( stage, pRcrd );
    return `<p class="g i">--- ${ stage } ${ comboField } (ID: ${ pRcrd.id })</p>`;
}
function getLogStageText( stage, pRcrd ) {
    if ( pRcrd.completed ) { return `${ stage } Completed`; }
    return stage === 'Pending' ? 'Created' : stage;
}
/** ================== STAGE-SPECIFIC UPDATES =============================== */
function handleStageSpecificUpdates( fState, stage ) {                /*dbug-log*///console.log('--handleStageSpecificUpdates stage[%s] fState[%O]', stage, fState);
    const hndlr = {
        Held: () => closeSetAndContinueReview( stage, fState ),
        Pause: () => closeSetAndContinueReview( stage, fState ), // Stage: Pause (active), Held (passive)
        Skip: () => closeSetAndContinueReview( stage, fState ),
        Pending: () => reenableParentForm( fState.group )
    };
    return hndlr[stage] ? hndlr[stage]() : null;
}
/**
 * When sub-data is paused, skipped, or held, the data-set is set to the same stage.
 * In these cases, the sub-form's close-chain must be prevented to allow for the
 * data-review process to continue using the root-form's close-chain.
 */
function closeSetAndContinueReview( stage, fState ) {                 /*dbug-log*///console.log('--closeSetAndContinueReview stage[%s] fState[%O]', stage, fState);
    if ( fState.group === 'top' ) { return; }
    const rootForm = _state( 'getFormState', ['top'] );
    rootForm.pending.inst.rvwAction = { name: stage };
    _data( 'pushFormPendingData', [rootForm] );
    return 'skipClose';  // Closing data-set from nested sub-form. Sub-chain will disrupt root-form close
}
function reenableParentForm( fLvl ) {
    const pLvl = getPendingParentFormState( fLvl ).group;
    _elems( 'checkReqFieldsAndToggleSubmitBttn', [pLvl] )
}
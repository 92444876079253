/**
 * Modifies source-data for local storage.
 * - [entity]Names - an object with each entity's displayName(k) and id.
 * - [entity]Sources - an array with of all source records for the entity type.
 *
 * Export
 *     modifySrcDataForLocalDb
 */
import { _db } from '~util';
import { EntityRecords } from '~types';
import { getTypeObj, getType } from '../init-helpers';

type SourceServerData = {
    sourceType: EntityRecords;
    citationType: EntityRecords;
    publicationType: EntityRecords;
    author: EntityRecords;
    citation: EntityRecords;
    publication: EntityRecords;
    publisher: EntityRecords;
};
export function modifySrcDataForLocalDb ( data: SourceServerData ) {                     /*dbug-log*///console.log("modifySrcDataForLocalDb called. data = %O", data);
    const authSrcs = getType( data.sourceType, 'author', 'sources' );
    const citSrcs = getType( data.sourceType, 'citation', 'sources' );
    const pubSrcs = getType( data.sourceType, 'publication', 'sources' );
    const publSrcs = getType( data.sourceType, 'publisher', 'sources' );
    _db.storeData( 'authSrcs', authSrcs );
    _db.storeData( 'citSrcs', citSrcs );
    _db.storeData( 'pubSrcs', pubSrcs );
    _db.storeData( 'publSrcs', publSrcs );
    _db.storeData( 'citTypeNames', getTypeObj( data.citationType ) );
    _db.storeData( 'pubTypeNames', getTypeObj( data.publicationType ) );
    ['citationType', 'publicationType', 'sourceType'].forEach( k => _db.removeData( k ) );
}
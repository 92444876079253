/**
 * Modifies interaction-data for local storage:
 * - [entity]Names - an object with each entity's displayName(k) and id.
 * - subjectNames - Array of valid subject taxon-group ids.
 * * interaction - Adds the Taxon Group's ids to each record to filter by groups.
 *
 * Export
 *     modifyIntDataForLocalDb
 */
import { _db } from '~util';
import { getNameObj } from '../init-helpers';
import { EntityRecords, IdsByName, SerializedEntity, objectKeys, objectValues } from '~types';

type InteractionData = {
    interaction: EntityRecords;
    validInteraction: EntityRecords;
    tag: EntityRecords;
};
export function modifyIntDataForLocalDb ( data: InteractionData ): void {  /*dbug-log*///console.log("modifyIntDataForLocalDb called. data = %O", data);
    _db.storeData( 'tagNames', getNameObj( objectKeys( data.tag ), data.tag ) );
    addGroupIdProps( data.interaction );
    addValidSubjectGroups( data.validInteraction );
    fixValidIntTagFormat( data.validInteraction );
}
/* =================== FIX TAG KEYS ========================================= */
/**
 * Due to a bug with the serializer (that seems to only affect this entity and
 * this property), tags must be restored as an object keyed by tag ID,
 */
function fixValidIntTagFormat ( validInts: EntityRecords ): void {
    objectValues( validInts ).forEach( fixTagIdKeys );
    _db.storeData( 'validInteaction', validInts );
}
function fixTagIdKeys ( validInt: SerializedEntity ): void {
    const fixed: EntityRecords = {};
    validInt.tags.forEach( ( t: SerializedEntity ) => fixed[t.id] = t );
    validInt.tags = fixed;
}
/* =================== GROUP ID ============================================= */
function addGroupIdProps ( ints: EntityRecords ): void {
    const taxa = _db.getValue( 'taxon' ) as EntityRecords;
    objectValues( ints ).forEach( addGroupIds );
    _db.storeData( 'interaction', ints );                           /*dbug-log*///console.log('taxa[%O]', taxa)

    function addGroupIds ( int: SerializedEntity ): void {                 /*dbug-log*///console.log('addGroupIds intRcrd[%O]', int)
        int.objGroupId = getGroupId( taxa, int.object );
        int.subjGroupId = getGroupId( taxa, int.subject );
    }
}
function getGroupId ( taxa: EntityRecords, id: number ): string {
    const txn = taxa[id];                                           /*dbug-log*///console.log('getGroupId txn[%O]', txn)
    return txn?.group.id.toString();
}
/* =================== SUBJECT GROUPS ======================================= */
function addValidSubjectGroups ( vInts: EntityRecords ): void {
    _db.storeData( 'subjectNames', getSubjectGroupNames( vInts ) );
}
function getSubjectGroupNames ( vInts: EntityRecords ): IdsByName {
    const names: IdsByName = {};
    const groups = _db.getValue( 'group' ) as EntityRecords;
    const subjects = objectValues( vInts ).map( v => v.subjectGroupRoot );
    objectValues( groups ).forEach( addIfValidInteractionSubject ); /*dbug-log*///console.log('--getSubjectGroupNames data[%O]', names);
    return names;

    function addIfValidInteractionSubject ( group: SerializedEntity ): void {/*dbug-log*///console.log('   --addIfValidInteractionSubject group[%O]', group);
        const isValid = objectKeys( group.subGroups ).find( ifValidSubject );
        if ( !isValid ) return;
        names[group.displayName!] = group.id;
    }
    function ifValidSubject ( id: string ): boolean {
        return subjects.indexOf( parseInt( id ) ) !== -1;
    }
}
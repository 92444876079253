/*
 * Manages the filter-set features.
 *
 * TOC
 *     STATE
 *     INIT UI
 *     ACTIONS
 *     UTILITY
 */
import * as utility from './utility-filter-set.js';
import * as actions from './actions-filter-set.js';
import * as apply from './apply-filter-set.js';
import * as state from './state-filter-set.js';
/* ============================= STATE ====================================== */
export function setSetState() {
    state.setSetState(...arguments);
}
export function getSetState() {
    return state.getSetState(...arguments);
}
/* ============================= INIT UI ==================================== */
export function initFilterSetsFeature() {
    actions.setFilterSetEventListeners();
    actions.updateFilterSetCombo();
    utility.disableFilterSetInputs();
}
/* ============================= ACTIONS ==================================== */
export function applyFilterSet() {
    apply.applyFilterSet()
}
export function onTableReloadCompleteApplyFilters() {
    apply.onTableReloadCompleteApplyFilters(...arguments);
}
/* ============================= UTILITY ==================================== */
export function showSavedMsg() {
    utility.showSavedMsg();
}
export function hideSavedMsg() {
    utility.hideSavedMsg();
}
export function enableFilterSetInputs() {
    utility.enableFilterSetInputs(...arguments);
}
export function fillFilterDetailFields() {
    utility.fillFilterDetailFields(...arguments);
}
export function addSetToFilterStatus() {
    utility.addSetToFilterStatus(getSetState('all'));
}
export function resetFilterUi(newId) {
    setSetState('clear', newId);
    utility.resetFilterUi();
}
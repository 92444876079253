/**
 * Handles all notifications and reporting related to issues that occur throughout
 * the database search page. Submits new events to the issue-tracker Sentry.
 *
 * Export
 *     alertIssue
 *     reportErr
 *
 * TOC
 *     ALERT DATA-TYPES
 *     CREATE SENTRY EVENT
 *         EDITOR ISSUE REPORT
 *         SENTRY ERROR OBJECT
 *     ALERT USER
 */
import { _u } from '~util';
import { _filter, _table } from '~db';
import * as Sentry from "@sentry/browser";
/* ==================== ALERT DATA-TYPES ==================================== */
/** Tag: dataSyncFailure, dataPrepFail */
type FailedAlertData = {
    fails: string | string[];
};
/** Tag: feedback */
type FeedbackAlertData = {
    route: string;
    topic: string;
    feedback: string;
};
/** Tag: fetchIssue */
type FetchAlertData = {
    url: string;
    responseText: string;
};
/** Tag: expectedDataNotFound, undefinedDataKey */
type GenericAlertData = {
    [key: string]: string | null | undefined;
};
/** Tag: invalidDataKeyType */
type InvalidAlertData = {
    key: string;
    type: string;
};
/** Tag: facadeErr */
type ModuleAlertData = {
    module: string;
    caller: string;
    called: string;
    error: string; //Error.toString
    errMsg: string; //Error.message
};
/** Tag: noRcrdFound */
type NoRecordAlertData = {
    id: string;
    entity: string;
};
type AlertData = (
    FailedAlertData |
    FeedbackAlertData |
    FetchAlertData |
    GenericAlertData |
    InvalidAlertData |
    ModuleAlertData |
    NoRecordAlertData
);
/* =================== CREATE SENTRY EVENT ================================== */
/** Sends Error object to Sentry, issue tracker. */
export function reportErr ( e: Error ): void {
    Sentry.captureException( e );
}
export function alertIssue ( tag: string, errData: AlertData ): void {
    if ( ifNotProdLogOnly( tag, errData ) ) return;                /*perm-log*/console.log( "       !!!alertIssue [%s] = %O", tag, errData );
    setSentryDebugContext( errData );
    handleUserAlert( tag );
    Sentry.captureException( new SentryError( tag, errData ) );
}
function ifNotProdLogOnly ( tag: string, errData: AlertData | null ): boolean {
    logAlertInDev( tag, errData );
    return $( 'body' ).data( 'env' ) !== 'prod';
}
function logAlertInDev ( tag: string, errData: AlertData | null ): void {
    try {
        _u.logInDevEnv( '!!ALERT ISSUE [%s] = %O', tag, errData );
    } catch ( e ) { /* When error occurs before module is fully loaded. */ }
}
function setSentryDebugContext ( errData: AlertData ): void {
    setBasicStateContext();
    setErrorContext( errData );
}
function setBasicStateContext (): void {
    if ( $( 'body' ).data( 'this-url' ) !== '/search' ) return;
    const state = _table( 'tableState' ).get();
    const base = { focus: state.curFocus, view: state.curView };
    Sentry.setContext( 'filter_state', { ...base, ..._filter( 'getFilterState' ) } );
}
function setErrorContext ( errData: AlertData ): void {
    Sentry.setContext( 'error_data', errData );
}
/* ---------------- EDITOR ISSUE REPORT ------------------------------------- */
/** Tag: editorReport */
type EditorReportAlertData = {
    summary: string;
    steps: string;
    etc: string;
    screenshots: string;
};
export function submitEditorIssue ( errData: EditorReportAlertData ): void {
    setEditorReportContextData( errData );
    Sentry.captureException( new SentryError( 'editorReport', errData.summary ) );
}
function setEditorReportContextData ( errData: EditorReportAlertData ): void {
    Sentry.setExtra( '1 Summary', errData.summary );
    Sentry.setExtra( '2 Steps to Reproduce', errData.steps );
    Sentry.setExtra( '3 Misc Info', errData.etc );
    Sentry.setExtra( '4 Screenshots', errData.screenshots );
}
/* ------------------------ Sentry Error Object ----------------------------- */
/** Extends the Error object to add debug data for the error.  */
class SentryError extends Error {
    constructor ( tag: string, debugData: AlertData | string, ...params: any[] ) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super( ...params );
        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if ( Error.captureStackTrace ) Error.captureStackTrace( this, SentryError );
        // Custom debugging information
        this.name = tag;
        this.message = JSON.stringify( debugData );
    }
}
/* ========================= ALERT USER ===================================== */
/**
 * IssueTags: alertHandler
 *     alertNoRcrdFound: noRcrdFoundInForms
 *     comboboxNotFound: showGeneralAlert
 *     dataPrepFail: (handled in form validation code)
 *     dataSyncFailure: (handled in form validation code)
 *     expectedDataNotFound: showGeneralAlert
 *     facadeErr: showGeneralAlert
 *     fetchIssue: showGeneralAlert
 *     forensicError: (handled in form validation code)
 *     invalidDataKeyType: showGeneralAlert
 *     noRcrdFound: (handled at relevant points through the code)
 *     undefinedDataKey: showGeneralAlert
 *
 * TEMP ISSUE TAGS FOR BUG TRACKING
 */
function handleUserAlert ( tag: string ): void {
    const silent = ['dataPrepFail', 'dataSyncFailure', '', 'noRcrdFound', 'TestIssue', 'editorReport', 'forensicError'];
    if ( silent.indexOf( tag ) !== -1 ) return;
    const map: { [key: string]: () => void; } = {
        alertNoRcrdFound: noRcrdFoundInForms,
    };
    if ( tag in map && map[tag] ) {
        map[tag]!();
    } else { showGeneralAlert(); }
}
function noRcrdFoundInForms (): void {
    alert( `Expected record not found. Try reloading the page.` );
}
function showGeneralAlert (): void {
    alert( `An error ocurred somewhere on the page.\n\n An automatic report is in progress ->\n  If you use an ad-blocker, whitelist batbase.org` );
}
/**
 * The form side-panel is used to display additional data about the entity
 * being edited: totals for all related entities.
 *
 * EXPORT
 *     fillEditEntitySidePanel
 *     getSubEntityEditDetailElems
 *
 * TOC
 *     INIT DETAIL-ELEMS
 *     EDIT-FORM RELATIONAL-DETAILS
 *         LOCATION
 *         SOURCE
 *         TAXON
 *     HELPERS
 */
import { _el, _u } from '~util';
import { _state } from '~form';
/* ===================== INIT DETAIL-ELEMS ================================== */
/** Returns the elems that will display the count of references to the entity. */
export function getSubEntityEditDetailElems( entity ) {               /*dbug-log*///console.log("--getSubEntityEditDetailElems for entity[%s]", entity);
    const div = _el.getElem( 'div', { 'id': 'det-cnt-cntnr' } );
    $( div ).append( _el.getElem( 'span' ) );
    $( div ).append( getCountElemForEachReferencedEntityType( entity ) );
    return [div];
}
function getCountElemForEachReferencedEntityType( entity ) {
    const referencedEntities = {
        Author: [ 'cit', 'int' ],
        Citation: [ 'int' ],
        Location: [ 'int' ],
        Publication: ['cit', 'int' ],
        Publisher: [ 'pub', 'int'],
        Taxon: [ 'ord', 'fam', 'gen', 'spc', 'int' ],
    };
    return referencedEntities[entity].map( initCountDiv );
}
function initCountDiv( ent ) {
    const div = getCntCntnr( ent );
    $( div ).append( getInitCntElem() );
    $( div ).append( getEntityNameElem( ent ) );
    return div;
}
function getCntCntnr( ent ) {
    const attr = { 'id': ent+'-det', 'class': 'cnt-div flex-row' };
    return _el.getElem( 'div', attr );
}
function getInitCntElem() {
    return _el.getElem( 'div', {'text': '0' } );
}
function getEntityNameElem( ent ) {
    const entities = {
        cit: 'Citations',
        fam: 'Families',
        gen: 'Genera',
        int: 'Interactions',
        loc: 'Locations',
        ord: 'Orders',
        pub: 'Publications',
        spc: 'Species',
        txn: 'Taxa',
    };
    return _el.getElem( 'span', {'text': entities[ent] } );
}
/* ================== EDIT-FORM RELATIONAL-DETAILS ========================== */
export function fillEditEntitySidePanel( id ) {                     /*dbug-log*///console.log('+--fillEditEntitySidePanel id[%s]', id);
    const entity = getFormEntity();
    const rcrd = _state( 'getRcrd', [entity, id] );
    const map = {
        Location: fillLocDetailData,
        Source: fillSrcDetailData,
        Taxon: fillTxnDetailData
    };
    map[entity]( _state( 'getFormState', ['top', 'name'] ), rcrd );
}
function getFormEntity() {
    const fState = _state( 'getFormState', ['top'] );
    return fState.core ? fState.core : fState.name;
}
/* ---------------------------- LOCATION ------------------------------------ */
function fillLocDetailData( entity, rcrd ) {
    addCntToSidePanel( { int: rcrd.interactions.length } );
}
/* ---------------------------- SOURCE -------------------------------------- */
/** Adds a count of all refences to the entity to the form side-panel. */
function fillSrcDetailData( entity, srcRcrd ) {                       /*dbug-log*///console.log('--fillSrcDataInDetailPanel. [%s]. srcRcrd = %O', entity, srcRcrd);
    const refObj = { int: getSrcIntCnt( entity, srcRcrd ) };
    addAddtionalRefs();                                             /*dbug-log*///console.log('   --refObj = %O', refObj);
    addCntToSidePanel( refObj );

    function addAddtionalRefs() {
        if ( entity === 'Citation' ) { return; }
        const ref = entity === 'Publisher' ? 'pub' : 'cit';
        refObj[ref] = srcRcrd.children.length || srcRcrd.contributions.length;
    }
}
function getSrcIntCnt( entity, rcrd ) {                               /*dbug-log*///console.log('--getSrcIntCnt. entity[%s] rcrd[%O]', entity, rcrd);
    return entity === 'Citation' ?
        rcrd.interactions.length : getAllSourceInts( rcrd );
}
function getAllSourceInts( rcrd ) {
    const srcRcrds = _state( 'getEntityRcrds', ['source'] );
    return getTtlIntCnt( rcrd, 'interactions', srcRcrds );
}
/* ------------------------ TAXON ------------------------------------------- */
function fillTxnDetailData( entity, rcrd ) {
    const txnRcrds = _state( 'getEntityRcrds', ['taxon'] );
    const refs = {
        'int': getTtlIntCnt( rcrd, 'objectRoles', txnRcrds ) +
            getTtlIntCnt( rcrd, 'subjectRoles', txnRcrds )
    };
    getTaxonChildRefs( rcrd, txnRcrds );
    addCntToSidePanel( refs );
    adjustSidePanelElems();

    function getTaxonChildRefs( txn ) {
        txn.children.forEach( id => addChildRefData( txnRcrds[id] ) );
    }
    function addChildRefData( child ) {
        const key = getRankKey( child )
        refs[key] += 1;
        getTaxonChildRefs( child );
    }
    function getRankKey( taxon ) {
        const ranks = {'Order':'ord','Family':'fam','Genus':'gen','Species':'spc'};
        const key = ranks[taxon.rank.displayName];
        if ( !refs[key] ) { refs[key] = 0; }
        return key;
    }
}
function adjustSidePanelElems() {
    $.each( $( '[id$="-det"] div' ), ( i, elem ) => {
        removeIfEmpty( elem );
        useSingularTenseIfNecessary( elem );
    } );
}
function removeIfEmpty( elem ) {
    if ( elem.innerText == 0 ) {  elem.parentElement.remove(); }
}
function useSingularTenseIfNecessary( elem ) {
    const singular = { 'Orders': 'Order', 'Families': 'Family', 'Genera': 'Genus',
        'Species': 'Species', 'Interactions': 'Interaction' };
    if ( elem.innerText == 1 ) {  elem.nextSibling.innerText = singular[elem.nextSibling.innerText]; }
}
/* ======================= HELPERS ========================================== */
/** Adds a count of realted entities to the edit form side-panel. */
function addCntToSidePanel( refObj ) {
    for ( let ent in refObj ) {
        $( '#'+ent+'-det div' )[0].innerText = refObj[ent];
    }
}
function getTtlIntCnt( rcrd, intProp, entityRcrds ) {                 /*dbug-log*///console.log('       --getTtlIntCnt prop[%s] rcrd[%O]', intProp, rcrd);
    let ints = rcrd[intProp].length;
    if ( rcrd.children.length ) { ints += getChildIntCnt( rcrd.children );}
    if ( rcrd.contributions ) { ints += getChildIntCnt( rcrd.contributions );}
    return ints;

    function getChildIntCnt( children ) {
        let cnt = 0;
        children.forEach( function( child ){
            child = entityRcrds[child];
            cnt += getTtlIntCnt( child, intProp, entityRcrds );
        } );
        return cnt;
    }
}
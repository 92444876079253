/**
 * Citation-form code.
 *
 * Export
 *     handleCitText
 *     initCreateForm
 *     initEditForm
 *     loadCitTypeFields
 *     setFinalCitation
 *
 * TOC
 *     ON SELECTION
 *     INIT FORM
 *         CREATE
 *             ON CLOSE
 *         EDIT
 *         SHARED
 *     FINISH BUILD
 *         FINISH REBUILD
 *     AUTOGENERATE CITATION
 *     HIGHTLIGHT EMPTY CITATION-FIELDS
 */
import { _cmbx } from '~util';
import { _data, _elems, _review, _state } from '~form';
import * as sForm from '../src-form-main.js';
import * as types from './cit-type-fields.js';
import * as cite from './regen-citation.js';

let timeout = null; //Prevents citation text being generated multiple times.

export function loadCitTypeFields() {
    return types.loadCitTypeFields( ...arguments );
}
/* ========================= INIT FORM ====================================== */
/* --------------------------- CREATE --------------------------------------- */
/** Init form when a new citation title is entered into the combobox. */
export function initCreateForm ( v ) {                                 /*dbug-log*///console.log('           >--Citation CREATE Form v[%s]', v);
    timeout = null;
    const params = getCreateParams( v );
    return _elems( 'initSubForm', [ params ] )
        .then( types.selectDefaultCitType )
        .then( () => finishCitFormInit( 'success', params ) );
}
function getCreateParams ( v ) {
    const createParams = {
        onFormClose: enablePubField,
        appendForm: form => $( '#CitationTitle_f' )[ 0 ].parentNode.after( form ),
        combo: 'CitationTitle',
        style: 'med-sub-form',
        vals: {
            ParentSource: _cmbx.getSelVal( 'Publication' ),
            DisplayName: ( v === 'create' ? '' : v ),
        }
    };
    return { ...createParams, ...getFormParams( 'sub', 'create' ) };
}
/* ______________________ ON CLOSE __________________________________________ */
/** When the Citation sub-form is exited, the Publication combo is reenabled. */
function enablePubField() {
    _cmbx.enableCombobox( 'Publication' );
}
/* ---------------------------- EDIT ---------------------------------------- */
/** Shows the Citation  sub-form and disables the publication combobox. */
export function initEditForm ( id ) {                                  /*dbug-log*///console.log('           >--Citation EDIT Form id[%s]', id);
    timeout = null;
    const params = getEditParams( id );
    return _elems( 'initForm', [ params ] )
        .then( status => finishCitFormInit( status, params ) );
}
function getEditParams ( id ) {
    const editParams = {
        id: id,
        style: 'lrg-form'
    };
    return { ...editParams, ...getFormParams( 'top', 'edit' ) };
}
/* --------------------------- REVIEW --------------------------------------- */
export function initReviewForm ( pRcrd ) {
    if ( ifPubInvalid( pRcrd ) ) { return skipCitationReview( pRcrd.id ); }
    const params = getReviewFormParams( pRcrd );
    return initReviewFormElems( pRcrd, params )
        .then( status => finishCitFormInit( status, params ) );
}
/**
 * If reviewing a new citation, within the interaction form, and the publication
 * has been returned/rejected, the citation is skipped and returned as well.
 */
function ifPubInvalid ( pRcrd ) {
    if ( pRcrd.inst.group === 'top' ) { return; }                     /*dbug-log*///console.log('-- ifPubInvalid pubStage[%s]', $('#sel-Publication').val())
    return [ 'rejected', 'returned', 'skipped' ].indexOf( $( '#sel-Publication' ).val() ) !== -1;
}
function skipCitationReview ( id ) {
    _data( 'pushPendingData', [ id, 'Skip', null, true ] );
    _review( 'setPendingComboOpt', [ 'CitationTitle', 'skipped' ] );
    return Promise.resolve( 'skipReview' );
}
/* ---------------------------- PARAMS -------------------------------------- */
function getReviewFormParams ( pRcrd ) {
    const action = _data( 'getPendingAction', [ pRcrd ] );             /*dbug-log*///console.log('           >--Citation REVIEW [%s] Form pRcrd[%O]', action, pRcrd);
    const p = action === 'create' ? getCreateParams( pRcrd ) : getEditParams( pRcrd.data.review.editId );
    p.pending = pRcrd;
    updateParentId( action, pRcrd.data.review.fields );
    delete p.vals;
    delete p.submit; //submit modal is used for the review process
    return p;
}
/* ---------------------------- BUILD --------------------------------------- */
function initReviewFormElems ( pRcrd, params ) {
    const init = pRcrd.inst.group === 'top' ? 'initForm' : 'initSubForm';
    return _elems( init, [ params ] );
}
function updateParentId ( action, fields ) {
    if ( action !== 'create' ) { return; }
    fields.ParentSource.value = $( '#sel-Publication' ).val();
}
/* --------------------------- SHARED --------------------------------------- */
function getFormParams ( fLvl, action ) {
    return {
        action: action,
        name: 'Citation',
        group: fLvl,
        initCombos: sForm.initCombos.bind( null, fLvl, 'Citation' ),
        submit: sForm.showSrcSubmitModal.bind( null, fLvl ),
    }
}
/* ======================== FINISH BUILD ==================================== */
function finishCitFormInit ( status, params ) {                        /*dbug-log*///console.log('           --finishCitFormInit status[%s]', status);
    if ( !status ) { return; } //Error handled elsewhere
    $( '#Description_f textarea' ).attr( 'disabled', true );
    disableCitationTypeFieldIfOnlyOneTypeAvailable();
    $( '#Abstract_f textarea' ).focus();
    if ( params.action !== 'create' ) { return; }
    _cmbx.enableCombobox( 'Publication', false );
}
function disableCitationTypeFieldIfOnlyOneTypeAvailable() {
    const typeCnt = _cmbx.getOptionTotal( 'CitationType' );
    if ( typeCnt > 1 ) { return; }
    _cmbx.enableCombobox( 'CitationType', false );
}
/* ======================= AUTO-GENERATE CITATION =========================== */
/** Note: to prevent multiple rebuilds, a timeout is used. */
export function handleCitText ( fLvl ) {                               /*dbug-log*///console.log('   --handleCitText [%s] timeout? [%s]', fLvl, !!timeout);
    if ( timeout ) { return; }
    timeout = window.setTimeout( buildCitTextAndUpdateField.bind( null, fLvl ), 750 );
}
function buildCitTextAndUpdateField ( fLvl ) {                         /*dbug-log*///console.log('           /--buildCitTextAndUpdateField [%s]', fLvl);console.trace();
    if ( !_state( 'ifStateActive', [ 'top' ] ) ) { return; }
    cite.buildCitTextAndUpdateField( fLvl )
        .then( () => ifReqFieldsFilledHighlightEmptyAndPrompt( fLvl ) )
        .then( () => { timeout = null; } );
}
export function setFinalCitation() {
    cite.setFinalCitation( ...arguments );
}
/* ================ HIGHTLIGHT EMPTY FIELDS ================================= */
/**
 * Highlights field continer if citation field is empty once all required fields
 * are filled. Removes hightlights when filled.
 */
function ifReqFieldsFilledHighlightEmptyAndPrompt ( fLvl ) {           /*dbug-log*///console.log('   --ifReqFieldsFilledHighlightEmptyAndPrompt fLvl[%s]', fLvl);
    if ( _state( 'getFormState', [ fLvl, 'action' ] ) === 'review' ) { return; }
    if ( !_elems( 'areRequiredFieldsFilled', [ fLvl ] ) ) { return; }
    const empty = $( `#Citation_fields div.${ fLvl }_f` ).filter( hightlightIfEmpty );/*dbug-log*///console.log('    --empty? [%O]', empty);
    if ( !empty.length && $( '.warn-msg' ).length ) { return $( '.warn-msg' ).remove(); }
    if ( $( '.warn-msg' ).length ) { return; }
    $( `#${ fLvl }-submit` ).before( '<div class="warn-msg warn">Please add highlighted data if available.</div>' );
}
function hightlightIfEmpty ( i, fContainer ) {
    const field = fContainer.children[1];
    if ( ifFieldShouldBeSkipped( field, ...field.children ) ) { return false; }
    $( field ).addClass( 'warn' );
    return true;
}
function ifFieldShouldBeSkipped ( el, label, input ) {
    const ignore = ['Author'];
    const skip = $( input ).val() || ignore.find( f => label.innerText.indexOf( f ) !== -1 );
    if ( skip && el.className.includes( 'warn' ) ) { $( el ).removeClass( 'warn' ); }
    return skip;
}

/**
 * Contains code specific to form maps.
 *
 * Export
 *     afterMapLoads
 *     addListenerToGpsFields
 *     addMapToLocForm
 *     autofillCoordinateFields
 *     focusParentAndShowChildLocs
 *     ifCoordsAddMapPinfunction
 *
 * TOC
 *     ADD MAP TO FORM
 *         ON MAP LOADED
 *         LOAD COUNTRY LOCATIONS
 *     COMBO FIELDS
 *         LISTENERS
 *         AUTOFILL
 *         ADD MAP PIN
 */
import { _cmbx, _el } from '~util';
import { _map } from '~db';
import { _confg, _elems, _state } from '~form';
/* ==================== ADD MAP TO FORM ===================================== */
export function addMapToLocForm( type, $form = $( '#Location_fields' ) ) {/*dbug-log*///console.log('--addMapToLocForm type[%s] form[%O]', type, $form);
    const pId = $( '#sel-Country-Region' ).val() || $( '#sel-Country' ).val();
    $form.after( _el.getElem( 'div', { id: 'form-map' } ) );
    if ( navigator.onLine === false ) return showNoInternetMsg();
    initLocFormMap( type, pId );
}
function showNoInternetMsg () {
    $( '#form-map' ).html( '<div>No Internet Connection</div>' );
}
function initLocFormMap( type, pId ) {
    const mData = {
        pId: pId,
        locRcrds:  _state( 'getEntityRcrds', ['location'] ),
        formConfg: _confg( 'getConfgData', ['Location', 'misc'] ),
        type: type
    };
    _map( 'initFormMap', [mData] );
}
/* ---------------------------- ON MAP LOADED ------------------------------- */
export function afterMapLoads( onLoadFunc, id ) {
    if ( $( '#form-map' ).data( 'loaded' ) ) {
        onLoadFunc( id );
        $( '#form-map' ).removeData( 'loaded' );
    } else {
        window.setTimeout( () => afterMapLoads( onLoadFunc, id ), 500 );
    }
}
/* ------------------------- LOAD COUNTRY LOCATIONS ------------------------- */
export function focusParentAndShowChildLocs( type, val ) {
    if ( !val ) { return; }                                           /*dbug-log*///console.log('               --focusParentAndShowChildLocs [%s] [%s]', type, val);
    initLocFormMap( type, val );
}
/** ====================== COORD FIELDS ===================================== */
/* ------------------------------ LISTENERS --------------------------------- */
export function addListenerToGpsFields( fLvl, params = [true] ) {     /*dbug-log*///console.log('--addListenerToGpsFields fLvl[%s] params[%O]', fLvl, params);
    $( '#Latitude_f input, #Longitude_f input' ).change( validateLocFields );

    function validateLocFields() {                                  /*dbug-log*///console.log('   @--validateLocFields', );
        const coords = getCoordVals().filter( c=>c );
        _elems( 'checkReqFieldsAndToggleSubmitBttn', [fLvl] );
        if ( coords.length === 1 ) { _elems( 'toggleSubmitBttn', [fLvl, false] ); }
        if ( coords.length !== 2 ) { _map( 'addVolatileMapPin', [false] ); }
        if ( coords.length === 2 ) { _map( 'addVolatileMapPin', params ); }
    }
}
function getCoordVals() {
    return ['Lat', 'Long'].map( l => lintCoord( l ) ).filter( v => v );
}
function lintCoord( prefix ) {
    const field = prefix+'itude';
    const input = $( `#${ field }_f input` )[0];
    return input.validity.valid ? input.value : null;
}
/* -------------------------------- AUTOFILL -------------------------------- */
export function autofillCoordinateFields( lat, lng, cntryId ) {
    const fLvl = _state( 'getSubFormLvl', ['sub'] );
    setCoordinateFieldData( fLvl, 'Latitude', lat.toFixed( 7 ) );
    setCoordinateFieldData( fLvl, 'Longitude', lng.toFixed( 7 ) );
    _elems( 'setSilentVal', [fLvl, 'Country', cntryId] );
    _elems( 'checkReqFieldsAndToggleSubmitBttn', [fLvl] );
}
function setCoordinateFieldData( fLvl, field, coord ) {
    $( `#${ field }_f input` ).val( coord );
    _state( 'setFieldState', [fLvl, field, coord] );
}
/* --------------------------- ADD MAP PIN ---------------------------------- */
export function ifCoordsAddMapPin ( id ) {                            /*dbug-log*///console.log(' --ifCoordsAddMapPin lat?[%s] id[%s]', $('#Latitude_f input').val(), id);
    if ( !$( '#Latitude_f input' ).val() ) { return; }
    _map( 'addVolatileMapPin', [ id, 'edit', _cmbx.getSelVal( 'Country' ) ] );
}
/**
 * Manages the source fields in the interaction form, Publication and Citation.
 *
 * Export
 *     clearCitationCombo
 *     fillCitationCombo
 *     onCitSelection
 *     onPubSelection
 *
 * TOC
 *     PUBLICATION
 *     CITATION
 *         FILL COMBOBOX
 *         SELECT CITATION
 */
import { _cmbx, _opts } from '~util';
import { _elems, _form,  _state } from '~form';
import * as iForm from '../int-form-main.js';
/* ======================= PUBLICATION ====================================== */
/**
 * When an existing publication is selected, the citation field is filled with
 * all current citations for the publciation. When a publication is created,
 * the citation form is automatically opened.
 */
export function onPubSelection( val ) {                               /*temp-log*///console.log('       +--onPubSelection[%s]', val);
    if ( val === 'create' ) { return _form( 'createEntity', ['Publication'] ); }
    if ( val === '' || isNaN( parseInt( val ) ) ) { return clearCitationCombo(); }
    fillCitationCombo( val );
    _elems( 'setSubEntityDetails', ['src', 'pub'] );
    const pub = _state( 'getRcrd', ['source', val] );                 /*dbug-log*///console.log('       +--newPubCreated? (if no children...)[%O]', pub);
    _state( 'setFieldPendingData', ['top', 'Publication', pub] );
    if ( newPubCreated( pub ) ) { return _form( 'createEntity', ['Citation'] ); }
    iForm.focusPinAndEnableSubmitIfFormValid( 'Publication' );
}
function newPubCreated( pub ) {
    const action = _state( 'getFormState', ['top', 'action'] );
    if ( action === 'review' ) { return; }  // Loaded via review module
    return pub ? !pub.children.length : null; //If no pub found, the issue was alerted to developer and editor
}

export function clearCitationCombo( clearAllSourceFields = true ) {
    _cmbx.enableCombobox( 'CitationTitle', false );
    _elems( 'clearSidePanelDetails', ['cit'] );
    $( `#CitationTitle_pin` ).prop( 'checked', false );
    _state( 'setFieldPendingData', ['top', 'CitationTitle', null] );
    if ( !clearAllSourceFields ) { return; }
    _cmbx.resetCombobox( 'CitationTitle' );
    $( `#Publication_pin` ).prop( 'checked', false );
    _state( 'setFieldPendingData', ['top', 'Publication', null] );
}
/* ======================== CITATION ======================================== */
/* ---------------------- FILL COMBOBOX ------------------------------------- */
/** Fills the citation combobox with all citations for the selected publication. */
export function fillCitationCombo( pubId ) {                          /*dbug-log*///console.log('-- fillCitationCombo pubId[%s]', pubId);
    _cmbx.enableCombobox( 'CitationTitle' );
    _cmbx.replaceSelOpts( 'CitationTitle', getPubCitationOpts( pubId ) );
}
/** Returns an array of option objects with citations for this publication.  */
function getPubCitationOpts( pubId ) {
    const pubRcrd = _state( 'getRcrd', ['source', pubId] );           /*dbug-log*///console.log('   -- getPubCitationOpts pubRcrd[%O]', pubRcrd);
    return _opts.buildSrcOpts( 'citation', pubRcrd.children );
}
/* ---------------------- SELECT CITATION ----------------------------------- */
/**
 * When a Citation is selected, both 'top' location fields are initialized
 * and the publication combobox is reenabled.
 */
export function onCitSelection( val ) {                               /*temp-log*///console.log('       +--onCitSelection [%s]', val);
    if ( val === 'create' ) { return _form( 'createEntity', ['Citation'] ); }
    if ( val === '' || isNaN( parseInt( val ) ) ) { return clearCitationCombo( false ); }
    _elems( 'setSubEntityDetails', ['src', 'cit'] );
    _cmbx.enableCombobox( 'Publication' );
    iForm.focusPinAndEnableSubmitIfFormValid( 'CitationTitle' );
    setSourceFieldPendingData( val );
}
function setSourceFieldPendingData( val ) {
    const cit = _state( 'getRcrd', ['source', val] );                 /*dbug-log*///console.log('       +--newPubCreated? (if no children...)[%O]', pub);
    _state( 'setFieldPendingData', ['top', 'CitationTitle', cit] );
}
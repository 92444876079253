/**
 * Combobox class and utilities. Select elements are initialized with selectize.js.
 */
import { OptionObject, objectValues } from "~types";
import { initCombobox } from "./init-combobox";
/* ========================= CONFIG TYPES =================================== */
interface BasicComboConfig {
    confgName?: string; // Specifies the config storage-key: (Multi-fields are appended with their ordinal & Data-review actions)
    create?: () => any;
    element?: HTMLElement; //Added during initCombobox
    id?: string;
    name: string;
    onChange?: ( value: string | string[] ) => void;
    onBlur?: () => any;
    placeholder?: string;
}
interface MultiSelectComboConfig extends BasicComboConfig {
    delimiter?: ',';
    maxItems?: number | undefined;
}
interface GroupedComboConfig extends BasicComboConfig {  // Groups the dropdown options by category
    options: HTMLOptionElement[];
    optgroupField: 'group';
    labelField: 'text';
    searchField: ['text'];
    sortField: [
        { field: 'group', direction: 'asc'; },
        { field: 'text', direction: 'asc'; },
        { field: '$score'; }
    ];
    render: { optgroup_header: typeof GroupHeaderRenderer; };
}
function GroupHeaderRenderer ( data: HTMLOptionElement, escape: Function ): string {
    return '<div class="optgroup-header">' + escape( data.text ) + '</div>';
}
export type ComboboxConfig = BasicComboConfig | MultiSelectComboConfig | GroupedComboConfig;

/* ========================= COMBOBOX CLASS ================================= */
export class Combobox {
    readonly configName: string;
    readonly element: HTMLSelectElement;
    readonly selectize: Selectize.IApi<any, any>;
    readonly id: string;
    private _lastValue: boolean | string | string[];   // Truthy for combos that must remain filled for the UI to stay synced.
    private _onChange: ComboboxConfig['onChange'] | null;
    readonly placeholder: string;
    /**  */
    constructor ( _config: ComboboxConfig ) {
        const values = initCombobox( _config, this );
        this.configName = values.configName;
        this.element = values.element;
        this.id = values.id;
        this._onChange = _config.onChange || null;
        this._lastValue = !!_config.onBlur;
        this.placeholder = values.placeholder;
        this.selectize = values.element.selectize;
    }
    destroy = () => {
        this.selectize.destroy();
    };
    /* ----------------- (EN/DIS)ABLE | FOCUS ------------------------------- */
    enable = ( enableCombo = true ): void => {
        if ( enableCombo ) return this.selectize.enable();
        this.selectize.disable();
    };
    focus = ( focusCombo = true ): void => {
        if ( focusCombo ) return this.selectize.focus();
        this.selectize.blur();
    };
    /* ------------------------- EVENTS ------------------------------------- */
    onChange = ( value: string | string[] ): void => {
        if ( !this._onChange ) return;
        if ( !this._lastValue || value ) this._onChange( value );
        this.storeValueIfNullInvalid( value );
    };
    updateChangeListener = ( event: ComboboxConfig['onChange'] ) => {
        this._onChange = event;
    };
    triggerChangeAndReturnPromise = ( value: string ): Promise<any> => {
        if ( !this._onChange ) throw Error( 'Cannot trigger change ' );
        this.setValue( value, true );
        return Promise.resolve( this._onChange( value ) );
    };
    /* ------------------------ OPTIONS ------------------------------------- */
    addOption = ( opt: OptionObject ) => this.selectize.addOption( opt );
    /**  */
    getOptionCount = () => Object.keys( this.selectize.options ).length;
    /**  */
    getOptionTextForValue = ( value: string ): string | false => {
        const opt = objectValues( this.selectize.options ).find( o => o.value === value );
        return opt ? opt.text : false;
    };
    getOptionValueForText = ( text: string ): string | false => {
        const opt = objectValues( this.selectize.options ).find( o => o.text === text );
        return opt ? opt.value : false;
    };
    removeOptions = ( values: string[] ) => {
        values.forEach( v => this.selectize.removeOption( v ) );
    };
    replaceOptions = ( opts: OptionObject[] | [] ) => {
        this.#clear( true );
        this.selectize.addOption( opts );
        this.selectize.refreshOptions( false ); //Don't trigger options-dropdown
        this.updatePlaceholder( opts.length );
    };
    /* ------------------------- RESET -------------------------------------- */
    reset = ( clearOptions:boolean ) => {
        this.#clear( clearOptions );
        this.updatePlaceholder();
    };
    #clear = ( clearOptions:boolean ) => {
        this.selectize.clear( true );
        if ( clearOptions ) this.selectize.clearOptions();
    };
    /**
     * Note: Combos that allow creating will always have that create option. The var
     * (optCnt = 0) is passed to set the placeholder as '- NONE -'.
     */
    updatePlaceholder = ( optCnt?: number ) => {
        const hasOpts = optCnt ?? this.selectize.hasOptions;
        const placeholder = hasOpts ? this.placeholder : '- None -';
        this.selectize.settings.placeholder = placeholder;
        this.selectize.updatePlaceholder();
    };
    /* ------------------------- VALUE -------------------------------------- */
    get text () { return getComboText( this.element.innerText ); }
    get value () { return this.selectize.getValue(); }
    /**  */
    setValue = ( value: string | string[], silent = false ): void => {
        setComboValue( this.element, value, !!silent );
        this.storeValueIfNullInvalid( value );
    };
    storeValueIfNullInvalid = ( value: string | string[] ) => {
        if ( !!value && this._lastValue ) this._lastValue = value;
    };
    /** onBlur for combos that must remain filled for the UI to stay synced. */
    ensureValueSelected = () => {
        if ( !this.value && typeof this._lastValue !== 'boolean' ) {
            setComboValue( this.element, this._lastValue, true );
        }
    };
}
/* ========================= UTILITIES ====================================== */
/** Removes asterisk added to indicate data-quarantined status. */
function getComboText ( txt: string | undefined ): string | null {
    if ( !txt ) return null;
    return txt[0] === '*' ? txt.split( '*' )[1]! : txt;
}
function setComboValue ( el: HTMLSelectElement, value: string | string[], silent: boolean ): void {
    if ( el.multiple ) {
        el.selectize.setValue( value, silent );
    } else if ( Array.isArray( value ) ) {
        value.forEach( v => el.selectize.addItem( v, silent ) );
    } else {
        el.selectize.addItem( value, silent );
    }
}
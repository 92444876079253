/**
 * Validates that the new parent is:
 * - a higher rank than the taxon
 * - its rank is available in the selected taxon-group
 *
 * Export
 *     onParentChange
 *     ifMissingParentTaxon
 *
 * TOC
 *    ON CHANGE
 *    PARENT VALIDATION
 *        MISSING PARENT RANK
 */
import { _cmbx, _u } from '~util';
import { _elems, _form, _state, _val } from '~form';
import { ifRankTooLow } from './rank-validate.js';

/* ======================= ON CHANGE ======================================== */
/** @param  {int|obj} val   Either an ID or a jQuery event obj. */
export function onParentChange ( val ) {
    const pTxn = getNewParentRecord( val );                         /*dbug-log*///console.log("--onParentChange pTxn[%O]", pTxn);
    const valData = buildParentValData( pTxn );
    if ( !validateParent( valData ) ) return;  //Issue alert shown
    _form( 'buildOptAndUpdateCombo', [ 'Parent', pTxn.id, 'silent' ] );
    _form( 'onTaxonFieldSelection', [ 'Parent', pTxn.id ] );
    updateGroupState( pTxn );
    _elems( 'checkReqFieldsAndToggleSubmitBttn', [ 'top' ] );
}
function getNewParentRecord ( val ) {
    return isNaN( val ) ? _form( 'getSelectedTaxon' ) : _state( 'getRcrd', [ 'taxon', val ] );
}
function updateGroupState ( pTxn ) {
    _state( 'setFieldState', [ 'top', 'Parent', pTxn.id ] );
    _state( 'setFieldState', [ 'top', 'Parent', pTxn.rank.displayName, 'misc' ] );
    _state( 'setFieldState', [ 'top', 'Group', pTxn.group.id ] );
    _state( 'setFieldState', [ 'top', 'Sub-Group', pTxn.group.subGroup.id ] );
}
/* ======================= PARENT VALIDATION ================================ */
function buildParentValData ( pTxn ) {
    const data = _state( 'getEntityRcrds', [ [ 'orderedRanks' ] ] );/*dbug-log*///console.log('    --buildParentValData data[%O]', data);
    data.rcrd = pTxn;
    data.newRank = pTxn.rank.displayName;
    data.childRank = _cmbx.getSelTxt( 'Rank' );
    data.parent
    return data;
}
function validateParent ( data ) {                                  /*dbug-log*///console.log("--validateParent data[%O]", data);
    const issues = {
        rankNotAvailableInNewGroup: ifInvalidGroupRank( data.childRank ),
        needsHigherRank: ifRankTooLow( data.newRank, data.childRank, data.orderedRanks ),
        needsGenusPrnt: ifSpeciesParentMustBeGenus( data.newRank, data.childRank )
    };                                                              /*dbug-log*///console.log('   --issues[%O]', issues);
    for ( let tag in issues ) {
        if ( issues[tag] ) return _val( 'showFormValAlert', ['Parent', tag, 'top'] );
    }
    _val( 'clearActiveAlert', ['top', 'Parent'] );
    return true;
}
function ifSpeciesParentMustBeGenus ( pRank, txnRank ) {
    return txnRank === 'Species' && pRank !== 'Genus';
}
function ifInvalidGroupRank ( txnRank ) {
    const sgField = _state( 'getFieldState', [ 'sub', 'Sub-Group', 'misc' ] );/*dbug-log*///console.log('--ifInvalidGroupRank? txnRank[%s] sgField[%O]', txnRank, sgField);
    return sgField.subRanks.indexOf( txnRank ) === -1;
}
/* -------------------- MISSING PARENT RANK --------------------------------- */
/** Validates that the specified parent-rank is filled, */
export function ifMissingParentTaxon ( rankName ) {
    const ranks = ['Family', 'Genus', 'Species'];
    const parentRank = getParentRank();                             /*dbug-log*///console.log( "--ifMissiongParentTaxon rankName[%s] parentRank[%s]", rankName, parentRank );
    if ( !parentRank || $( `#sel-${ parentRank }` ).val() ) return;
    _val( 'showFormValAlert', [rankName, `no${ parentRank }`, 'sub2'] );
    return true;

    function getParentRank() {
        const idx = ranks.indexOf( rankName );
        return idx > 0 ? ranks[idx-1] : false;
    }
}



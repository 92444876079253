/**
 * Returns the next set of pending-data prepared for the data-review process.
 *
 * Export
 *     clearSkippedRecordsMemory
 *     getFirstPendingDataSet
 *     getPendingAction
 *     ifPendingSubDataGetFirstGroup
 *     isSkipped
 *
 * TOC
 *     GET FIRST PENDING-DATA SET
 *     FIND NEXT PENDING-DATA SUB-ENTITY
 *     PREPARE NEXT RECORD
 *     PENDING-ENTITY DATA
 */
import { _db, _u } from '~util';
import { _data, _state } from '~form';
import getFirstFieldWithPendingData from './get-field-pending-data.js';
import prepareRecordForReview from './prepare-record.js';
/**
 * Data used within module to identify and prepare pending-data for review.
 * {array}   skipped    List of pending-data IDs processed or skipped in review session
 * {object}  user       Current user data object (Only set during data-review)
 */
let d = {
    skipped: [],
    user: null
};
export function clearSkippedRecordsMemory() {
    d.skipped = [];
}
export function isSkipped( pId ) {                                    /*dbug-log*///console.log('   -- isSkipped id[%s] skipped[%O]', pId, _u.snapshot(d.skipped));
    return d.skipped.indexOf( pId ) !== -1;
}
/* ================= GET FIRST PENDING-DATA SET ============================= */
/**
 * Returns the first set of pending-data for a root-record.
 * @return {promise}  Pending-data for the first root-set
 */
export function getFirstPendingDataSet( skipId ) {                    /*dbug-log*///console.log(' +--getFirstPendingDataSet skipId?[%s] skipped?[%O]', skipId, d.skipped);
    if ( skipId ) { d.skipped.push( skipId ); }
    _db.getData( 'user' ).then( user => d.user = user );
    return _db.syncAndReturnPendingData()
        .then( data => getFirstDataSetToReview( data.availablePending, data.pending ) );
}
function getFirstDataSetToReview( available, rcrds ) {                /*dbug-log*///console.log(' --getFirstDataSetToReview available[%O] rcrds[%O]', available, rcrds);
    const pRcrd = getFirstValidDataSet( available );
    if ( !pRcrd ) { return false; }
    _data( 'pushPendingData', [pRcrd.id, 'Lock'] );
    return prepareRecordForReview( rcrds, pRcrd, 'top' );
}
function getFirstValidDataSet( available ) {
    return Object.values( available ).find( ifValidMainDataSet );
}
function ifValidMainDataSet( pRcrd ) {
    const isMain = pRcrd.data.review.group === 'top';
    const isReady = _data( 'isReadyToReview', [pRcrd, getUser()] )
    return !isSkipped( pRcrd.id ) && isMain && isReady;
}
function getUser() {
    if ( !d.user ) { d.user = _state( 'getEntityRcrds', ['user'] ); }
    return d.user;
}
/* ================= FIND NEXT PENDING-DATA SUB-ENTITY ====================== */
/**
 * Called both during form-init to open the first branch of pending-data out to leaf,
 * and after form submit to either continue up branch or to look for next branch.
 * @param  {string}  prevLvl   Previously opened/submitted form-group
 * @param  {string}  skipId    ID to skip for remainder of current review session
 * @param  {boolean} leafOnly  During form-init when opening to leaf data-set
 * @return {promise}
 */
export function ifPendingSubDataGetFirstGroup( prevLvl, skipId, leafOnly = false ) {
    if ( skipId ) { d.skipped.push( skipId ); }                         /*dbug-log*///console.log('  +-- ifPendingSubDataGetFirstGroup prevLvl[%s] leafOnly?[%s]', prevLvl, leafOnly);
    const rcrds = _state( 'getEntityRcrds', ['pending'] );
    return getSubPendingData( prevLvl, leafOnly, rcrds );
}
function getSubPendingData( fLvl, leafOnly, rcrds ) {                 /*dbug-log*///console.log('       -- getSubPendingData fLvl[%s] leafOnly?[%s] rcrds[%O]', fLvl, leafOnly, rcrds);
    const pendingField = getFirstFieldWithPendingData( fLvl, rcrds );
    if ( !pendingField ) { return searchParentForm( fLvl, leafOnly, rcrds ); }
    const subLvl = _state( 'getFormLevel', ['child', fLvl] )
    return prepareNextRecord( pendingField, subLvl, rcrds );
}
/** Check the next form level for pending sub-data. */
function searchParentForm( fLvl, leafOnly, rcrds ) {                  /*dbug-log*///console.log('           -- searchParentForm fLvl[%s] leafOnly?[%s] rcrds[%O]', fLvl, leafOnly, rcrds);
    if ( fLvl === 'top' || leafOnly ) { return false; }
    const nextLvl = _state( 'getFormLevel', ['parent', fLvl] );
    return getSubPendingData( nextLvl, leafOnly, rcrds );
}
/* ===================== PREPARE NEXT RECORD ================================ */
function prepareNextRecord( f, fLvl, rcrds ) {                        /*dbug-log*///console.log('               -- prepareNextRecord fLvl[%s] field[%s][%O]', fLvl, f.name, _u.snapshot(f));
    const pRcrd = prepareRecordForReview( rcrds, f.record, fLvl, f.name )
    if ( f.entity === 'Contributor' ) { prepMultiFieldPending( f, pRcrd ); }
    delete f.record;
    return pRcrd;
}
function prepMultiFieldPending( f, pRcrd ) {
    if ( f.pending ) { return _state( 'setFieldPending', [f, pRcrd, f.ord] ); } // True unless replaced-pending is next
    delete f.ord;
}
/* ===================== PENDING-ENTITY DATA ================================ */
export function getPendingAction( pRcrd ) {                           /*dbug-log*///console.log('               -- getPendingAction pRcrd[%O]', pRcrd);
    return pRcrd.data.review.action;
}
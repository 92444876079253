/**
 * Generalized Ajax Methods.
 *
 * Export
 *     sendAjaxQuery
 *     logAjaxData
 */
/* ======================= DATA UTIL ======================================== */
export function sendAjaxQuery (
    dataPkg: object | string | null,
    url: string,
    successCb?: Function,
    errCb?: Function
): JQuery.jqXHR {
    logAjaxMsgToConsole( dataPkg, true, arguments );
    const envUrl = $( 'body' ).data( "base-url" );
    const ajaxConfig: JQuery.AjaxSettings = {
        method: "POST",
        url: envUrl + url,
        success: ( successCb || logAjaxData.bind( null, false ) ) as JQuery.Ajax.SuccessCallback<void>,
        error: ( errCb || ajaxError ) as JQuery.Ajax.ErrorCallback<any>,
        data: JSON.stringify( dataPkg )
    };
    return $.ajax( ajaxConfig );
}
export function logAjaxData (
    sending: boolean,
    dataPkg: string,
    _: JQuery.Ajax.SuccessTextStatus,
    _2: JQuery.jqXHR
): void {
    const data = typeof dataPkg === 'string' ? JSON.parse( dataPkg ) : dataPkg;
    logAjaxMsgToConsole( data, sending );
}
function logAjaxMsgToConsole ( data: object | string | null, sending: boolean, args: object = {} ): void {
    const action = sending ? 'S' : 'R';
    const isProd = $( 'body' ).data( 'env' ) === 'prod';
    const params = [getDefaultAjaxMsg( isProd ), action, data, args];
    if ( isProd ) params.pop();
    console.log( ...params );
}
function getDefaultAjaxMsg ( isProd: boolean ): string {
    const base = "           --[%s] Ajax data [%O]";
    return isProd ? base : base + " arguments = %O";
}
function ajaxError (
    jqXHR: JQuery.jqXHR,
    _1: JQuery.Ajax.SuccessTextStatus,
    _2: string
): void {
    console.log( "ajaxError. responseText = [%O] - jqXHR:%O", jqXHR.responseText, jqXHR );
}
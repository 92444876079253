/**
 * Validates taxon names.
 * - Species must have a binomial nomenclature that matches it's genus parent
 *
 * Once validated, the Global Name Verifier results for the name are displayed.
 *
 * Export
 *     validateAndVerifyTaxonym
 *
 * TOC
 *     SPECIES BINOMIAL NOMENCLATURE
 */
import { _cmbx, _u } from '~util';
import { _elems, _state, _val } from '~form';
import { FormGroup } from '@form/etc/form-types';
import { verifyTaxonymAndDisplayResults } from './taxonym-verify';

export function validateAndVerifyTaxonym ( fLvl: FormGroup ): void { /*dbug-log*///console.log( ' --validateAndVerifyTaxonym fLvl[%s] fState[%O]', fLvl, _state( 'getFormState', [fLvl] ) );
    if ( !isTaxonymValid( fLvl ) ) return;
    _elems( 'checkReqFieldsAndToggleSubmitBttn', [fLvl] );
    verifyTaxonymAndDisplayResults( fLvl );
}
function isTaxonymValid ( fLvl: FormGroup ): boolean {
    return getTaxonRank( fLvl ) !== 'Species' || validateSpeciesName( fLvl );
}
function getTaxonRank ( fLvl: FormGroup ): string {
    const rankValue = _state( 'getFieldState', [fLvl, 'Rank'] );
    return typeof rankValue === 'string' ? rankValue : rankValue.text;
}
/* ================== SPECIES BINOMIAL NOMENCLATURE ========================= */
function validateSpeciesName ( fLvl: FormGroup ): boolean {
    if ( hasCorrectBinomialNomenclature( fLvl ) ) {
        _val( 'clearActiveAlert', [fLvl, 'DisplayName'] );
        return true;
    } else {
        _val( 'showFormValAlert', ['DisplayName', 'needsGenusName', fLvl] );
        return false;
    }
}
function hasCorrectBinomialNomenclature ( fLvl: FormGroup ): boolean {
    const genus = getGenusName( fLvl );
    const species = $( '#DisplayName_f input' ).val();
    if ( typeof species !== 'string' ) throw Error( 'Invalid parameter.' );
    const speciesParts = species.split( ' ' );                      /*dbug-log*///console.log( ' --hasCorrectBinomialNomenclature genus[%s], speciesParts[%O]', speciesParts );
    return genus === speciesParts[0];
}
function getGenusName ( fLvl: FormGroup ): string {
    const parentId = _state( 'getFieldState', [fLvl, 'Parent'] );
    return _state( 'getRcrd', ['taxon', parentId] ).name;
}

/**
 * Functions that add data to local-storage.
 *
 * Exports
 *     addContribData
 *     addGroupDataToRcrd
 *     addInteractionRole
 *     addInteractionToEntity
 *     addObjGroupIdToRcrd
 *     addToParentRcrd
 *     addToRcrdAryProp
 *     addToRcrdProp
 *     addToTaxonNames
 *     updateDataProps
 */
import { EntityRecords, IdsByName, SerializedEntity, objectValues } from '~types';
import { _db, _u } from '~util';
import { getEntities, getEntity, getEntityData } from 'js/util/local-data/etc/temp-data';
/** Add the new record to the prop's stored records object.  */
export function addToRcrdProp (
    prop: string,
    rcrd: SerializedEntity,
    entity: string
): void {
    const rcrds = _db.getValue( prop ) as EntityRecords;           /*dbug-log*///console.log("               --addToRcrdProp. [%s][%O]. rcrd[%O]", prop, _u.snapshot(rcrds), _u.snapshot(rcrd));
    if ( !rcrds ) throw Error( `${ entity } records not found` );
    rcrds[rcrd.id] = rcrd;
    _db.storeData( prop, rcrds );
}
export function addGroupDataToRcrd ( prop: 'group', rcrd: SerializedEntity, _1: 'taxon' ): void {
    //Necessary to prevent overwriting data modified since the data-storage process began
    const [taxa, taxon] = getEntityData( 'taxon', rcrd.id );        /*dbug-log*///console.log( "               --addGroupDataToRcrd. taxa[%O] rcrd[%O]", _u.snapshot( taxa ), _u.snapshot( rcrd ) );
    taxon.group = taxon.group ?? getTaxonGroup( taxa, taxon );
    _db.storeData( 'taxon', taxa );
}
function getTaxonGroup ( taxa: EntityRecords, taxon: SerializedEntity ): SerializedEntity {
    const parent = getEntity( taxa, taxon.parent, 'taxon' );        /*dbug-log*///console.log("               --getTaxonGroup. taxon[%O] parent[%O] prev[%O]", _u.snapshot(taxon), _u.snapshot(parent), _u.snapshot(prev));
    if ( parent.group ) return parent.group;
    return getTaxonGroup( taxa, parent );
}
export function addGroupIdToRcrd (
    role: 'subject' | 'object',
    _1: string,
    rcrd: SerializedEntity,
    _2: 'interaction'
): void {                                                           /*dbug-log*///console.log("               --addObjGroupIdToRcrd. role[%s] rcrd[%O]", role, _u.snapshot(rcrd));
    const rcrdProp = ( role === 'object' ? 'obj' : 'subj' ) + 'GroupId';
    const [_, taxon] = getEntityData( 'taxon', rcrd[role] );
    rcrd[rcrdProp] = taxon.group.id;                                /*dbug-log*///console.log("               --addObjGroupIdToRcrd. taxon[%O] rcrd[%O]", taxon, _u.snapshot(rcrd));
}
/** Add the new record to the prop's stored records object.  */
export function addToRcrdAryProp (
    prop: 'authSrcs' | 'pubSrcs' | 'publSrcs',
    rcrd: SerializedEntity,
    _1: 'author' | 'publication' | 'publisher'
): void {
    const rcrds = _db.getValue( prop ) as number[];                /*dbug-log*///console.log( "               --addToRcrdAryProp. [%s] = %O. rcrd = %O", prop, rcrds, rcrd );
    if ( !ifNewRcrd( rcrds, rcrd.id ) ) return;
    rcrds.push( rcrd.id );
    _db.storeData( prop, rcrds );
}
function ifNewRcrd ( ary: number[], id: number ): boolean {
    return ary.indexOf( id ) === -1;
}
/** Adds a new child record's id to it's parent's 'children' array. */
export function addToParentRcrd (
    prop: 'location' | 'source' | 'taxon',
    rcrd: SerializedEntity,
    _1: 'location' | 'source' | 'taxon'
): void {
    if ( !rcrd.parent ) return;
    const [rcrds, parent] = getEntityData( prop, rcrd.parent );     /*dbug-log*///console.log( "               --addToParentRcrd. [%s] = %O. rcrd = %O", prop, rcrds, rcrd );
    if ( !ifNewRcrd( parent.children, rcrd.id ) ) return;
    parent.children.push( rcrd.id );
    _db.storeData( prop, rcrds );
}
/**
 * Adds the Taxon's name to the stored names for it's group and rank.
 * Note: 'group' is added above, so the taxon from storage is used rather than the rcrd.
 */
export function addToTaxonNames (
    _1: 'taxonNames',
    rcrd: SerializedEntity,
    _2: 'taxon`'
): void {                                                           /*dbug-log*///console.log('addToTaxonNames. prop[%s] rcrd[%O]', prop, rcrd);
    const [_, taxon] = getEntityData( 'taxon', rcrd.id );           /*dbug-log*///console.log('   taxon[%O]', taxon);
    const group = taxon.group.displayName;
    const subGroup = taxon.group.subGroup.name;
    const rank = taxon.rank.displayName;
    const nameProp = group + subGroup + rank + "Names";
    let data = ( _db.getValue( nameProp ) || {} ) as IdsByName;
    data[taxon.name] = taxon.id; //done here because taxa use a base 'name' property, as they display typically with the rank prepended
    _db.storeData( nameProp, data );
}
/** Adds the Interaction to the stored entity's collection.  */
export function addInteractionToEntity (
    prop: 'location' | 'source',
    rcrd: SerializedEntity,
    _1: 'interaction'
): void {                                                           /*dbug-log*///console.log( 'addInteractionToEntity. prop = [%s] rcrd = %O', prop, rcrd );
    if ( !rcrd[prop] ) return;
    const [rcrds, stored] = getEntityData( prop, rcrd[prop] );
    if ( !ifNewRcrd( stored.interactions, rcrd.id ) ) return;       /*dbug-log*///console.log('--adding interaction.')
    stored.interactions.push( rcrd.id );
    if ( prop === 'source' ) stored.isDirect = true;
    _db.storeData( prop, rcrds );
}
/** Adds the Interaction to the taxon's subject/objectRole collection.  */
export function addInteractionRole (
    prop: 'subject' | 'object',
    rcrd: SerializedEntity,
    _1: 'interaction'
): void {
    const [taxa, taxon] = getEntityData( 'taxon', rcrd[prop] );
    if ( !ifNewRcrd( taxon[prop + "Roles"], rcrd.id ) ) return;
    taxon[prop + "Roles"].push( rcrd.id );
    _db.storeData( "taxon", taxa );
}
/** When a Publication/Citation has been updated, add new author contributions. */
export function addContribData (
    prop: 'authors' | 'editors',
    rcrd: SerializedEntity,
    _1: 'publication' | 'citation'
): void {                                                           /*dbug-log*///console.log("               --addContribData. [%s] [%s]. rcrd = %O", prop, entity, rcrd);
    if ( !rcrd[prop] ) return;
    const rcrds = getEntities( 'source' );
    objectValues( rcrd[prop] ).forEach( id => addNewContribData( id, rcrds, rcrd ) );
    _db.storeData( 'source', rcrds );
}
function addNewContribData ( id: number, rcrds: EntityRecords, rcrd: SerializedEntity ): void {
    const author = getEntity( rcrds, id, 'source' );                /*dbug-log*///console.log("                   --addNewContribData ord[%s] authId[%s] rcrd[%O]", ord, author.id, rcrd);
    if ( !ifNewRcrd( author.contributions, rcrd.id ) ) return;
    author.contributions.push( rcrd.id );
}
/**
 * Fills complex interaction-form fields with init-data. (Edit and review forms)
 *
 * Export
 *     finishFieldFill
 */
import { _cmbx } from '~util';
import { _elems, _state } from '~form';
import * as iForm from '../int-form-main.js';

export default function finishFieldFill() {
    const fields = _state( 'getFormState', [ 'top', 'fields' ] );       /*dbug-log*///console.log('--finishFieldFill [%O]', fields);
    setSourceFields( fields.Publication, fields.CitationTitle );
    setLocationDetailPanel( fields.Location );
    setTypeAndTagFields( fields.InteractionType, fields.InteractionTags );
    setTaxonFields( fields.Subject, fields.Object );
    if ( fields.Source.value === 'Secondary' ) { setIntSecondarySource(); }
    if ( fields.Season.value ) { setIntSeasons( fields.Season.value ); }
}
/* ---------------------- LOCATION FIELDS ----------------------------------- */
function setLocationDetailPanel ( locField ) {
    if ( !locField.value ) { return; }
    const locRcrd = _state( 'getRcrd', [ 'location', locField.value ] );
    _elems( 'setSubEntityDetails', [ 'loc', locRcrd ] );
}
/* ---------------------- SOURCE FIELDS ------------------------------------- */
function setSourceFields ( pubField, citField ) {                      /*dbug-log*///console.log('--setSourceFields pub[%O] cit[%O]', pubField, citField);
    setPublicationFieldVal( pubField );
    setCitationFieldVal( citField );
}
function setPublicationFieldVal ( pubField ) {
    if ( !pubField.value ) { return; }
    iForm.fillCitationCombo( pubField.value );
}
function setCitationFieldVal ( citField ) {
    if ( !citField.value ) { return; }
    _cmbx.setSelVal( 'CitationTitle', citField.value );
}
/* ------------------------ TAGS FIELDS ------------------------------------- */
function setTypeAndTagFields ( typeField, tagsField ) {
    $( '#sel-InteractionType' ).data( 'init-val', typeField.value );
    sortAndSetTagsFields( tagsField );
}
/** Sorts through tags by type and sets their respective fields. */
function sortAndSetTagsFields ( tagsField ) {
    const tags = sortTagsByType( tagsField.value, _state( 'getEntityRcrds', [ 'tag' ] ) );
    if ( tags.source ) { setIntSecondarySource(); }
    if ( tags.season ) { setIntSeasons( tags.season ); }                /*dbug-log*///console.log('sortAndSetTagsFields tags[%O]', tags);
    if ( !tags[ 'interaction type' ] ) { return; }
    /** Note: Must happen before taxon fields filled for auto-type handling. */
    $( '#sel-InteractionTags' ).data( 'init-val', tags[ 'interaction type' ] );
}
function setIntSecondarySource() {
    _cmbx.setSelVal( 'Source', 'Secondary' );
}
function setIntSeasons ( seasonIds ) {
    _cmbx.setSelVal( 'Season', seasonIds );
}
function sortTagsByType ( tagIds, tagRcrds ) {                         /*dbug-log*///console.log('sortTagsByType tagIds[%O] tagRcrds[%O]', tagIds, tagRcrds);
    const sorted = {};
    if ( !tagIds ) { return sorted; }
    tagIds.forEach( sortTag );
    return sorted;

    function sortTag ( id ) {
        const type = tagRcrds[id].type;
        if ( !sorted[ type ] ) { sorted[ type ] = []; }
        sorted[ type ].push( id );
    }
}
/* ------------------------ TAXON FIELDS ------------------------------------ */
function setTaxonFields ( subjField, objField ) {
    setTaxonFieldVal( 'Subject', subjField );
    setTaxonFieldVal( 'Object', objField );
}
function setTaxonFieldVal ( fName, field ) {
    if ( !field.value ) { return; }
    iForm.buildOptAndUpdateCombo( fName, field.value );
}
/**
 * Manages IndexedDB, the Local Storage database.
 *
 * Export
 *     initDb
 *     downloadFullDb
 *     getData
 *     setData
 *
 * TOC
 *     INIT
 *     GETTERS
 *     SETTERS
 */
import { KeyedDataObject, SerializedEntity, OptionObject } from '~types';
import { _db, _u } from '~util';
import * as idb from 'idb-keyval'; //set, get, del, clear, Store
import { EntityStateDates } from '../sync/db-pg-load/db-pg-load-main';

const db_v = '.690'; //prod: .690

export type DataStorage = { [key: string]: StoredData; };
export type StoredData = { changed: boolean, value: StoredDataValue; };
export type StoredDataValue = SerializedEntity | OptionObject[] | object | string | string[] | number | number[] | boolean | null;
/** ----------------------- INIT -------------------------------------------- */
/**
 * Checks whether the dataKey exists in indexDB cache and downloads full DB if not.
 * Note: Testing clears idb on load, except as needed for data-entry testing. @addNewDataToStorage
 */
export function initDb () {
    getData( db_v, true ).then( resetDbIfNeeded );
}
function resetDbIfNeeded ( noResetNeeded: StoredDataValue ): void {
    return noResetNeeded ? checkForServerUpdates() : downloadFullDb();
}
function downloadFullDb ( reset?: true ): void {
    if ( reset ) return clearAndDownload( true );
    getAllStoredData().then( data => {
        const isReset = Object.keys( data ).length;
        clearAndDownload( !!isReset );
    } );
}
function clearAndDownload ( reset: boolean ): void {
    idb.clear();
    _db.initStoredData( reset ).then( () => idb.set( db_v, true ) );
}
/**
 * On page load, syncs local database with sever data.
 * If there they system data has updates more recent than the last sync, the
 * updated data is ajaxed and stored @syncUpdatedData. Once the database is ready,
 * db_sync calls @initSearchPage.
 */
function checkForServerUpdates () {
    getData( 'lclDataUpdtdAt' )
        .then( data => _db.syncLocalDbWithServer( data as EntityStateDates ) );
    // debugUpdate();
}
export function resetStoredData () {
    downloadFullDb( true );
}
/** ----------------------- GETTERS ----------------------------------------- */
export function getAllStoredData (): Promise<DataStorage> {
    return idb.entries()
        .then( buildDataStorageObj );
}
function buildDataStorageObj ( entries: [IDBValidKey, StoredDataValue][] ): DataStorage {
    const data: DataStorage = {};
    entries.forEach( prepLocalData );
    return data;

    function prepLocalData ( d: [IDBValidKey, StoredDataValue] ): void {
        const key = d[0].toString();
        data[key] = { value: d[1], changed: false };
    }
}
/**
 * Gets data from Indexed DB for each key passed. If an array
 * is passed, an object with each prop as the key for it's data is returned.
 * If a property is not found, false is returned.
 */
export function getData ( keys: string ): Promise<StoredDataValue | void>;
export function getData<T extends string[]> ( keys: [...T], returnUndefined?: true ): Promise<KeyedDataObject<T, StoredDataValue>>;
export function getData ( keys: string | string[], returnUndefined: true ): Promise<StoredDataValue>;
export function getData<T extends string[]> (
    keys: string | string[],
    returnUndefined?: true
): Promise<StoredDataValue | KeyedDataObject<T, StoredDataValue> | void> {
    if ( Array.isArray( keys ) ) return getStoredDataObj( keys, !!returnUndefined );
    return getStoredData( keys, !!returnUndefined );
}
function getStoredData ( key: string, returnUndefined: boolean ): Promise<StoredDataValue | void> {
    if ( ifInvalidKey( key ) ) return Promise.resolve( handleInvalidKey( key ) );
    return idb.get( key ).then( d => returnStoredData( d, key, returnUndefined ) );
}
function returnStoredData ( data: StoredDataValue, key: string, returnUndefined: boolean ): StoredDataValue | void {
    if ( data == undefined && !returnUndefined ) { return handleExpectedDataNotFound( key ); }
    return data;
}
function getStoredDataObj<T extends string[]> (
    keys: [...T],
    returnUndefined: boolean
): Promise<KeyedDataObject<T, StoredDataValue>> {
    const promises: Promise<StoredDataValue | void>[] = [];
    keys.forEach( key => promises.push( getStoredData( key, returnUndefined ) ) );
    return Promise.all( promises ).then( data => buildKeyedDataObj( keys, data ) );
}
function buildKeyedDataObj<T extends readonly string[]> (
    keys: [...T],
    data: ( StoredDataValue | void )[]
): KeyedDataObject<T, StoredDataValue> {
    const obj: Partial<KeyedDataObject<T, StoredDataValue>> = {};
    $( data ).each( ( i, d ) => {
        const key = keys[i];
        obj[key!] = d;
    } );
    return obj as KeyedDataObject<T, StoredDataValue>;
}
/* ----------------------- ERROR HANDLING ----------------------------------- */
function ifInvalidKey ( key: string | null | undefined ): boolean {
    return !key || typeof key !== 'string';
}
function handleInvalidKey ( key: string | null | undefined ): void {
    if ( !key ) return _u.alertIssue( 'undefinedDataKey', { key: key } );
    _u.alertIssue( 'invalidDataKeyType', {
        key: JSON.stringify( key ), type: typeof key
    } );
}
function handleExpectedDataNotFound ( key: string ): void {
    _u.alertIssue( 'expectedDataNotFound', { key: key } );
}
/** ----------------------- SETTERS ----------------------------------------- */
export function setData ( k: string, v: StoredDataValue ): Promise<void> {                                                 //console.log('         SET [%s] => [%O]', k, v);
    return idb.set( k, v );
}
// function removeData(k) {
//     idb.del(k);
// }
// function debugUpdate() {
//     const testDataState = {
//         Author: "2019-11-11 22:53:58",
//         Authority: "2017-02-04 11:24:08",
//         Citation: "2019-11-11 22:07:52",
//         CitationType: "2017-05-18 14:27:27",
//         ContentBlock: "2017-02-04 11:24:08",
//         Contribution: "2017-02-04 11:24:08",
//         Domain: "2017-02-04 11:24:08",
//         Feedback: "2017-02-04 11:24:08",
//         GeoJson: "2019-09-26 08:33:17",
//         HabitatType: "2017-02-04 11:24:08",
//         ImageUpload: "2017-02-04 11:24:08",
//         Interaction: "2019-11-11 00:40:46",
//         InteractionType: "2017-02-04 11:24:08",
//         Location: "2019-11-11 22:59:09",
//         LocationType: "2017-05-18 14:27:27",
//         Naming: "2017-02-04 11:24:08",
//         NamingType: "2017-02-04 11:24:08",
//         Publication: "2019-11-11 22:07:15",
//         PublicationType: "2017-02-04 11:24:08",
//         Rank: "2017-02-04 11:24:08",
//         Source: "2019-11-11 22:07:52",
//         SourceType: "2017-02-04 11:24:08",
//         System: "2019-11-11 22:07:52",
//         Tag: "2017-02-04 11:24:08",
//         Taxon: "2019-11-11 21:56:27",
//         Taxonym: "2017-02-04 11:24:08",
//     };
//     syncLocalDbWithServer(testDataState);
// }
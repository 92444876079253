/**
 * Sends the taxonymn to the Global Name Verifier and displays the results.
 *
 * Export
 *     verifyTaxonymAndDisplayResults
 *
 * TOC
 *     BUILD CONTAINER
 *     VERIFICATION
 *         FETCH
 *         DISPLAY
 *     BUILD ELEMS
 *         SIMPLE
 *         MATCH SOURCE
 *         MATCH TAXA
 */
import { _el, _u } from '~util';
import { _form, _state } from '~form';
import { FormGroup } from '@form/etc/form-types';
import { ApiResponse, MatchType, VerifierResult } from './global-names-verifier-types';

export function verifyTaxonymAndDisplayResults ( fLvl: FormGroup ): void {
    /*dbug-log*///console.group();
    addVerificationResultsContainer();
    if ( navigator.onLine === false ) return;
    verifyTaxonym()
        .then( results => displayVerificationResults( fLvl, results ) );
    /*dbug-log*///.then( () => console.groupEnd() );
}
/* -------------------------- BUILD CONTAINER ------------------------------- */
function addVerificationResultsContainer (): void {
    if ( $( '#taxonym-verification' ).length ) return;
    const container = buildVerificationResultsContainer();
    $( '#DisplayName_f' )[0]!.append( container );
    $( '#DisplayName_f' ).addClass( 'active-verification' );
}
function buildVerificationResultsContainer (): HTMLDivElement {
    const attrs = { class: 'flex-col', id: 'taxonym-verification' };
    const container = _el.getElem( 'div', attrs ) as HTMLDivElement;
    const msg = navigator.onLine ? 'Verifying...' : 'Can not verify taxonym while offline.';
    $( container ).html( msg );
    return container;
}
/* ========================== VERIFICATION ================================== */
/* ------------------------------ FETCH ------------------------------------- */
/**
 * @return {prm}         Promise: verification-response
 */
async function verifyTaxonym (): Promise<ApiResponse> {             /*dbug-log*///console.log( '  --verifyTaxonym' );
    const response = await fetch( buildTaxonymVerificationUrl() );
    return response.json();
}
function buildTaxonymVerificationUrl (): string {
    let url = 'https://verifier.globalnames.org/api/v1/verifications/';
    url += addEscapedTaxonym();                                     /*dbug-log*///console.log( '  -- buildTaxonymVerificationUrl url[%s]', url );
    return url;
}
function addEscapedTaxonym (): string {
    const name = $( '#DisplayName_f input' ).val();
    if ( typeof name !== 'string' ) throw Error( 'Invalid parameter.' );
    return name.trim().split( ' ' ).join( '+' );
}
/**
 * Handles the taxonym verification results.
 * Results schema: https://apidoc.globalnames.org/gnames-beta#/default/get_verifications__names_
 * @param  {json}  results  JSON results returned from the global names verifier
 */
function displayVerificationResults ( fLvl: FormGroup, results: ApiResponse ): void {
    const matchType = results.names[0].matchType;                   /*dbug-log*///console.log( '  -- displayVerificationResults type[%s] results[%O]', matchType, results );
    if ( matchType === 'NoMatch' ) return displayNoMatchResult();
    const match = results.names[0].bestResult;
    if ( !match ) throw Error( 'Invalid parameter value.' );
    displayMatchResult( fLvl, matchType, match );
}
/* ----------------------------- DISPLAY ------------------------------------ */
function displayNoMatchResult (): void {                            /*dbug-log*///console.log( '  -- displayNoMatchResult' );
    const elems = [
        getMatchType( 'NO MATCH' ),
        '<p class="b">Please recheck spelling.</p>',
    ];
    displayResults( elems );
}
/**
 * Match Types:
    NoMatch (no matched name found)
    PartialFuzzy (fuzzy partial match after removing some parts)
    PartialExact (match after removing last or middle epithets)
    Fuzzy (fuzzy match to a canonical form)
    Exact (exact match to a canonical form or a verbatim string)
    Virus (literal match of Viruses, plasmids, prions and other non-cellular entities)
    // Not being used until advanced species verification is implemented:
    // FacetedSearch (match by a faceted search: advanced query results) https://github.com/gnames/gnverifier#advanced-search
 */
function displayMatchResult (
    fLvl: FormGroup,
    matchType: MatchType,
    match: VerifierResult
): void {                                                           /*temp-log*/console.log( '  -- displayMatchResult match[%s][%O]', matchType, match );
    const elems = [
        getMatchType( matchType ),
        getMatchedNamed( matchType, match ),
        getMatchSourceLink( match ),
        getMatchRankAndFlagIfNotSameAsFormRank( fLvl, match ),
        getMatchTaxaHeir( fLvl, match ),
        match.isSynonym ? getMatchSynonym( match ) : ''
    ];
    displayResults( elems );
}
function displayResults ( elems: string[] ): void {
    $( '#taxonym-verification' ).empty().html( elems as any );
    $( '#taxonym-verification' ).append( getApiLink() );
}
/* ========================== BUILD ELEMS =================================== */
/* ---------------------------- SIMPLE -------------------------------------- */
function getMatchType ( type: string ): string {
    const name = type.includes( 'Partial' ) ? 'Partial' : type === 'Virus' ? 'Exact' : type;
    return `<p>Match: &nbsp;&emsp;${ name }</p>`;
}
function getApiLink () {
    return `<p id="api-verification">
        <a href="https://verifier.globalnames.org" target="_blank">Global Names Verifier</a></p>`;
}
/** Returns an element with the name of the partially/fuzzily-matched taxon. */
function getMatchedNamed ( matchType: MatchType, match: VerifierResult ): string {
    if ( ['Exact', 'Virus'].includes( matchType ) ) return '';
    return `<p>Matched: &nbsp;${ match.matchedCanonicalFull }</p>`;
}
/** Returns an element with the final rank in the match's classificationRanks. */
function getMatchRankAndFlagIfNotSameAsFormRank ( fLvl: FormGroup, match: VerifierResult ): string {
    const verifiedRank = getMatchRankName( match.classificationRanks );
    const alertClass = getAlertClassIfRankMisMatched( fLvl, verifiedRank );
    return `<p${ alertClass }>Rank:&emsp;&emsp;${ verifiedRank }</p>`;
}
function getMatchRankName ( matchRanks: string | undefined ): string {
    const rank = matchRanks?.split( '|' ).slice( -1 )[0];
    return rank ? _u.ucfirst( rank ) : 'Not Available';
}
function getAlertClassIfRankMisMatched ( fLvl: FormGroup, verifiedRank: string ): string {
    if ( verifiedRank === 'Not Available' ) return '';
    const formRank = _form( 'getRankName', [fLvl] );
    return verifiedRank === formRank ? '' : ' class="r"';
}
/* ------------------------- MATCH SOURCE ----------------------------------- */
/** Returns the match's source-link html. */
function getMatchSourceLink ( match: VerifierResult ): string {
    let txt = '<p>Source: &emsp;';
    txt += match.outlink ?
        `<a href="${ match.outlink ?? '#' }" target="_blank">${ match.dataSourceTitleShort }</a></p>`
        : `${ match.dataSourceTitleShort }</p>`;
    return txt;
}
function getMatchSynonym ( match: VerifierResult ): string {
    return `<p>(Synonym of "${ match.currentCanonicalFull }")</p>`;
}
/* -------------------------- MATCH TAXA ------------------------------------ */
function getMatchTaxaHeir ( fLvl: FormGroup, match: VerifierResult ): string {
    return `<p>Taxa: &emsp;&emsp;${ getMatchTaxaClassificationPath( fLvl, match ) }</p>`;
}
/**
 * Returns the pipe-separated names of taxa in the match's classification path
 * that are at ranks used in our database for the current sub-group.
 */
function getMatchTaxaClassificationPath ( fLvl: FormGroup, match: VerifierResult ): string {
    const pathPieces = explodeResultString( match.classificationPath );
    if ( pathPieces.length === 0 ) return 'Not Available';
    const rankPieces = explodeResultString( match.classificationRanks );
    return getRelevantClassificationPath( fLvl, pathPieces, rankPieces );
}
function getRelevantClassificationPath (
    fLvl: FormGroup,
    pathPieces: string[],
    rankPieces: string[]
): string {
    const [groupName, groupRanks] = getSubGroupInfo( fLvl );
    return `${ groupName } | ${ buildClassificationPath( groupRanks, pathPieces, rankPieces ) }`;
}
function getSubGroupInfo ( fLvl: FormGroup ): [string, string[]] {
    const subGroupRcrd = _state( 'getFieldState', [fLvl, 'Sub-Group', 'misc'] ).rcrd;
    if ( !subGroupRcrd ) throw Error( 'Invalid parameter.' );
    return [subGroupRcrd.name, subGroupRcrd.subRanks];
}
function explodeResultString ( string: string | undefined ): string[] {
    return !string ? [] : string.split( '|' ).reverse();
}
function buildClassificationPath (
    groupRanks: string[],
    pathPieces: string[],
    rankPieces: string[]
): string {                                                         /*dbug-log*///console.log( '  -- getRelevantClassificationPath pathPieces[%O] rankPieces[%O] ranks[%O]', pathPieces, rankPieces, groupRanks );
    const path: string[] = [];
    getRelevantNames();
    return path.join( ' | ' );

    function getRelevantNames (): void {
        for ( let i = pathPieces.length - 1; i >= 0; i-- ) {
            if ( !groupRanks.includes( _u.ucfirst( rankPieces[i]! ) ) ) continue;
            path.push( getTaxonNameAtRank( pathPieces[i]!, rankPieces[i]! ) );/*dbug-log*///console.log( ' Adding rank[%s] name[%s]', rankPieces[i], pathPieces[i] );
        }
    }
}
/** Note: Virus results do not always include the match species name. */
function getTaxonNameAtRank ( taxonName: string, rank: string ): string {
    if ( taxonName ) return taxonName;
    if ( rank === 'species' ) return $( '#DisplayName_f input' ).val()! as string;
    throw Error( 'Invalid argument value: Taxon name should have a value.' );
}

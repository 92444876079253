/**
 * Code specific to root-form builds.
 *
 * Export
 *     finishAndAppendRootForm
 *
 * TOC
 *     ASSEMBLE
 *         POPUP-EXIT ROW
 *     APPEND AND STYLE
 */
import { _el } from '~util';
import { _elems } from '~form';

/** [buildAndAppendRootForm description] */
export function finishAndAppendRootForm( form, fState, el ) {         /*dbug-log*///console.log('+--finishAndAppendRootForm form[%O] elems[%O] fState[%O]', form, el, fState);
    const finishedForm = buildRootForm( fState, form, el );
    appendAndStyleForm( finishedForm, fState.style );
}
/* ==================== ASSEMBLE ============================================ */
/**
 * Returns the form window elements - the form and side-panel.
 * section>(div#top-form(header, form), div#form-panel(hdr, pub, cit, loc), footer)
 */
function buildRootForm( fState, form, el ) {                          /*dbug-log*///console.log('--buildRootForm  form[%O] elems[%O] fState[%O]', form, el, fState);
    return [getExitButtonRow( el ), assembleFormAndPanel( fState, form )];
}
function assembleFormAndPanel( fState, form ) {                       /*dbug-log*///console.log('--assembleFormAndPanel form[%O] fState[%O]', form, fState);
    const cntnr = _el.getElem( 'div', { class: 'flex-row' } );
    $( cntnr ).append( [form, _elems( 'getSidePanelElems', [fState] )] );
    return cntnr;
}
/* ----------------------- POPUP-EXIT ROW ----------------------------------- */
function getExitButtonRow( el ) {
    const  row = _el.getElem( 'div', { class: 'exit-row' } );
    $( row ).append( el.exitBttn );
    return row;
}
/* ======================== APPEND AND STYLE ================================ */
/** Builds and shows the popup form's structural elements. */
function appendAndStyleForm( finishedForm, style ) {
    const classes = style + ' form-popup'
    _el.showPagePopup( classes, null, finishedForm, addFormStyleClass );
    /** Adds the width to both the popup window and the form element for each entity. */
    function addFormStyleClass() { //TODO: find a way to set the class when building the form element below
        $( '#top-form' ).addClass( style );
    }
}
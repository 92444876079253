/**
 * Data-entry form combobox methods.
 * TODO: DOCUMENT
 *
 * Export
 *     initFormCombos
 *     resetFormCombobox
 *     setSilentVal
 *
 * TOC
 *     GET INPUT
 *     COMBO UTIL
 */
import { _cmbx, _opts } from '~util';
import { _state } from '~form';
/* ========================== INIT ========================================== */
/**
 * Inits 'selectize' for each select elem in the form's 'selElems' array
 * according to the 'selMap' config. Empties array after intializing.
 */
export function initFormCombos ( fLvl, cConfg ) {
    const cFields = _state( 'getComboFields', [ fLvl ] );               /*dbug-log*///console.log('initFormCombos [%s] cConfg[%O] cFields[%O]', fLvl, cConfg, cFields);
    cFields.forEach( f => selectizeElem( f, cConfg[f.id] ) );
}
function selectizeElem ( fConfg, confg ) {                             /*dbug-log*///console.log("   Initializing [%s] selectizeConfg[%O]", fConfg.id, confg);
    fConfg.combo = getFormComboConfgName( fConfg, confg );
    confg.confgName = fConfg.combo;
    confg.id = getFormComboId( fConfg, confg );
    confg.name = getFormComboDisplayName( fConfg );
    _cmbx.initCombobox( confg );
    if ( !confg.create ) { _cmbx.removeOptions( fConfg.id, [ 'create' ] ); }
}
function getFormComboConfgName ( fConfg, confg ) {
    const name = confg.confgName ? confg.confgName : fConfg.id;
    return appendCountIfMultiInputField( name, fConfg.count );
}
function getFormComboId ( fConfg, confg ) {
    const id = confg.id ? confg.id : '#sel-' + fConfg.id;
    return appendCountIfMultiInputField( id, fConfg.count );
}
function getFormComboDisplayName ( fConfg ) {
    return fConfg.label ? fConfg.label : fConfg.name;
}
function appendCountIfMultiInputField ( string, count ) {
    return count ? string + count : string;
}
/* =========================== UTILITY ====================================== */
/* ---------------------------- SET ----------------------------------------- */
/** The change event is not triggered, so the field data is updated here. */
export function setSilentVal ( fLvl, field, val ) {
    _cmbx.setSelVal( field, val, 'silent' );
    _state( 'setFieldState', [ fLvl, field, val ] );
}
/**
 * Selects the form's entity in it's parent field. If a new entity has been created,
 * an option is created, added, and then selected in the form's parent field. The
 * combo's change event, wrapped in a promise, is returned to execute synchornously
 * @param {object} entity     Entity data returned from local storage.
 * @param {string} comboField Name of the form's parent-field combobox
 */
export function addAndSelectEntity ( entity, comboField ) {            /*dbug-log*///console.log('           -- addAndSelectEntity. entity?[%O] comboField[%s]', entity, comboField);
    if ( !entity ) { return; } //Set during review/pending-data processes
    const optExists = _cmbx.getOptionTextForValue( comboField, entity.id );
    if ( !optExists ) { addNewOptionForEntity( entity, comboField ); }
    _cmbx.enableCombobox( comboField );                               /*dbug-log*///console.log('               -- addAndSelectEntity comboField[%s] optExists?[%s]', comboField, optExists);
    return _cmbx.triggerComboChangeReturnPromise( comboField, entity.id );
}
function addNewOptionForEntity ( entity, comboField ) {
    const name = _opts.getComboEntityDisplayName( entity );
    const opt = { text: name, value: entity.id };                   /*dbug-log*///console.log('           -- [%s] New option[%O]', comboField, opt);
    _cmbx.addOpt( comboField, opt );
}

/* -------------------------------- RESET ----------------------------------- */
/**
 * Clears and enables the parent combobox for the exited form. Removes any
 * placeholder options and, optionally, brings it into focus.
 */
export function resetFormCombobox ( comboField, focus ) {              /*dbug-log*///console.log('resetFormCombobox comboField[%s] focus?[%s]', comboField, focus);
    if ( !comboField ) { return; }
    _cmbx.resetCombobox( comboField );
    _cmbx.enableCombobox( comboField );
    _cmbx.focusCombobox( comboField, focus );
}
/**
 * Sets field change-listeners, which update the field state with the entered data.
 *
 * Export
 *     setCustomFieldStoreListener
 *     setFieldChangeListeners
 *     storeMultiSelectValue
 *
 * TOC
 *     SET CHANGE-LISTENERS
 *         IF CITATION FORM, REGENERATE CITATION
 *         SET VALUE-STORE METHOD
 *     STORE FIELD-DATA
 *         STANDARD FIELD
 *         DYNAMIC FIELD
 */
import { _el, _u } from '~util';
import { _elems, _form, _state } from '~form';
/* ========================= SET CHANGE-LISTENERS =========================== */
export function setFieldChangeListeners( f ) {                        /*dbug-log*///console.log('   --setFieldChangeListeners f[%s][%O]', f.name, f);
    ifCitationFormAutoGenerateCitationOnChange( f );
    setOnChangeStoreFieldValue( f );
}
/* -------------- IF CITATION FORM, REGENERATE CITATION --------------------- */
function ifCitationFormAutoGenerateCitationOnChange( f ) {
    const entity = _state( 'getFormState', [f.group, 'name'] );
    if ( entity === 'Citation' ){                                     /*dbug-log*///console .log('     --setAutoGenerateCitationOnChange');
        $( f.input ).change( _form.bind( null, 'handleCitText', [f.group] ) );
    }
}
/* ----------------------- SET VALUE-STORE METHOD --------------------------- */
/** todo2: set multiple times on fields that get rebuilt? */
function setOnChangeStoreFieldValue( f ) {
    if ( ifCustomFieldStoreListener( f ) ) { return setCustomFieldStoreListener( f ); }
    $( f.input ).change( storeFieldValue.bind( null, f.input, f.name, f.group ) );
}
function ifCustomFieldStoreListener( f ) {
    return f.misc && f.misc.customValueStore;
}
export function setCustomFieldStoreListener( f ) {
    if ( !_el.isMultiField( f ) ) { return; } // Handled elsewhere
    if ( !f.value ) { f.value = {}; }  //Ensures the field-value object has been instantiated, necessary for multiFields.
}
/* ========================= STORE FIELD-DATA =============================== */
/* ------------------------- STANDARD FIELD --------------------------------- */
/**
 * todo2: Combo field value is set to ID everywhere but with edit and review init values...
 * Using the opt obj, {text, value}, makes sense, but it might be complicated with multi field values
 */
function storeFieldValue ( elem, fieldName, fLvl, e ) {             /*dbug-log*///console.log( '   --storeFieldValue [%s][%O]', fieldName, elem );
    let val = getValidFieldValue( elem );                           /*dbug-log*///console.log( '       --val[%O]', val );
    _state( 'setFieldState', [fLvl, fieldName, val] );
    _elems( 'checkReqFieldsAndToggleSubmitBttn', [fLvl] );
}
function getValidFieldValue ( elem ) {
    const v = $( elem ).val();
    return v == 'create' ? null : ( typeof v == 'string' ? getStringValue( elem, v ) : v );
}
/**
 * The value is trimmed of excess spaces. If the result is different from the
 * input's value, the input is updated accordingly.
 */
function getStringValue ( elem, val ) {
    const v = val.trim();
    if ( val !== v ) $( elem ).val( v );
    return v;
}
/* ------------------------- DYNAMIC FIELD ---------------------------------- */
/** [storeMultiSelectValue description] */
export function storeMultiSelectValue( fLvl, ord, fName, v ) {        /*dbug-log*///console.log('@--storeMultiSelectValue lvl[%s] ord[%s]fName[%s], v[%s]', fLvl, ord, fName, v);
    const vals = _state( 'getFieldState', [fLvl, fName] );            /*dbug-log*///console.log('   --prev[%O] cur[%O]', _u.snapshot(vals), vals);
    vals[ord] = v;
    if ( !v ) { deleteTrailingEmptyValues( ord, v, vals ); }
    _state( 'setFieldState', [fLvl, fName, vals] );                   /*dbug-log*///console.log('       --after[%O]', _u.snapshot(vals));
}
function deleteTrailingEmptyValues( ord, v, vals ) {                  /*dbug-log*///console.log('       --deleteTrailingEmptyValues ord[%s] v[%O] vals[%O]', ord, v, _u.snapshot(vals));
    for ( let i = Object.keys( vals ).length; i >= 1; i-- ) {
        if ( ord > i || vals[i] ) { return; }
        delete vals[i];
    }
}
/**
 * Returns the first pending-taxon that is not dependant on a pending parent-taxon.
 * Pending parents must be reviewed and approved before pending child-taxa can be.
 *
 * Export
 *     getFirstPendingRcrdToReview
 */
import { _data, _review, _state } from '~form';
/**
 * Returns the highest parent-record pending review with field instance properties.
 */
export function getFirstPendingRcrdToReview(pRcrd) {                /*dbug-log*///console.log(' -- getFirstPendingRcrdToReview. field pRcrd[%O]', pRcrd);
    return getPendingTxnRcrd(pRcrd);

    function getPendingTxnRcrd(pTxn, pendingChild = false) {
        const prnt = getPendingParent(pTxn);                        /*dbug-log*///console.log('       -- getPendingTxnRcrd pTxn[%O] prnt[%O]', pTxn, prnt);
        if (prnt) { return handleParent(prnt); }
        const rcrd = !pendingChild ? pRcrd : preparePendingParent(pTxn);
        return updateSubFields(rcrd);
    }
/** --------------------- GET PARENT RCRD ----------------------------------- */
    function getPendingParent(pTxn) {
        const pending = pTxn.data.review.fields.Parent.pending;
        if (!pending) { return false; }
        const rcrd = _state('getRcrd', ['pending', pending.id]);    /*dbug-log*///console.log('       -- getPendingParent? [%O]', rcrd)
        return _data('isReadyToReview', rcrd) ? rcrd : handleReviewed(rcrd);
    }
/** --------------------- HANDLE REVIEWED ----------------------------------- */
    /**
     * When a parent taxon is in an incompatible review stage, the field combo is set
     * and the review process continues.
     */
    function handleReviewed(rcrd) {                                 /*dbug-log*///console.log('       -- handleReviewed? rcrd[%O]', rcrd)
        return rcrd.entityId ? false : setComboAndSkipFieldReview(rcrd);
    }
    function setComboAndSkipFieldReview(rcrd) {
        _review('setPendingComboOpt', [pRcrd.inst.field, rcrd.stage.name]);
        const fPend = _state('getFieldState', ['top', pRcrd.inst.field, 'pending']);
        fPend.inst.rvwAction = { name: rcrd.stage.name };
        rcrd.skip = true;
        return rcrd;
    }
/** ----------------------- HANDLE PARENT ----------------------------------- */
    function handleParent(prnt) {
        return prnt.skip ? prnt : getPendingTxnRcrd(prnt, true);
    }
    function preparePendingParent(pTxn) {
        const prntRcrd = { ...pTxn, inst: { ...pRcrd.inst }};
        prntRcrd.inst.pChild = true;                                /*dbug-log*///console.log('      -- prntRcrd [%O]', prntRcrd);
        return prntRcrd;
    }
/** ----------------------- UPDATE FIELDS ----------------------------------- */
    /** Fills pending-rcrd fields with the latest data. */
    function updateSubFields(rcrd) {                                /*dbug-log*///console.log(' -- updateSubFields rcrd[%O]', rcrd);
        _data('updatePendingFields', [rcrd.data.review.fields]);
        return rcrd;
    }
}
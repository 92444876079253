/**
 * Inits the side-panel section that will contain misc data-review UI and info.
 *
 * EXPORT
 *     initReviewSidePanel
 *
 * TOC
 *     BUILD PANEL SECTION
 *         HEADER
 *     INFO
 *         INTRO
 *         ACTIONS
 */
import { _data } from '~form';
import { _el, _u } from '~util';
/* ==================== BUILD PANEL SECTION ================================= */
export default function initReviewSidePanel( fConfg, stage ) {
    const hdr = getReviewHeader();
    const info = getDataReviewInfoElems( fConfg, stage );
    return [ hdr, ...info ];
}
/* --------------------------- HEADER --------------------------------------- */
function getReviewHeader() {
    const attr = { class: 'b c', text: 'Data-Review' };
    return _el.getElem( 'h5', attr );
}
/* ============================ INFO ======================================== */
function getDataReviewInfoElems( fConfg, stage ) {
    const intro = getDataReviewIntro( stage );
    const actions = getReviewActionsInfo( fConfg.pending );
    return [ intro,  actions];
}
/* ---------------------------- INTRO --------------------------------------- */
function getDataReviewIntro( stage ) {
    const txt = getDataReviewIntroTxt( stage );
    const attr = { text: txt };                                     /*dbug-log*///console.log("-- getDataReviewIntro stage?[%s] txt[%s]", stage, txt);
    return _el.getElem( 'p', attr );
}
function getDataReviewIntroTxt( stage ) {
    if ( stage === 'Pending' ) { return; } //Not needed during data-manager review
    const map = {
        Approved: 'Data entered into BatBase.org.',
        Rejected: 'Data will not be entered into BatBase.org.',
        Returned: 'Data have been returned for further action.',
        Pending: `Data are reviewed and either approved, rejected, or returned for
            further action. Until approved, only the contributor can use the data.`
    };                                                              /*dbug-log*///console.log("-- getDataReviewIntroTxt stage[%s] txt?[%s]", stage, map[stage]);
    return stage in map ? map[stage] : map.Pending;
}
/* --------------------------- ACTIONS -------------------------------------- */
function getReviewActionsInfo( pRcrd ) {
    return _u.isManagerUser() ? getManagerIntro() : getContributorIntro( pRcrd );
}
function getManagerIntro() {
    const div = _el.getElem( 'pre' );
    $( div ).text( getManagerIntroTxt() );
    return div;
}
function getManagerIntroTxt() {
    return `ACTIONS
APPROVE: Modifies BatBase data.
PAUSE: Holds data until you continue.
REJECT:  Entity is deleted, data-set is returned.
RETURN:  Data is returned for further action.
SKIP: Ends your review of the data set.`;
}

function getContributorIntro( pRcrd ) {
    if ( pRcrd && _data( 'isApprovedOrRejected', [pRcrd] ) ) { return; }
    const attr = { text: getContributorIntroTxt() };
    return _el.getElem( 'p', attr );
}
function getContributorIntroTxt() {
    return `Once reviewed, results load in the "Review" panel.`;
}
/**
 * Auto-generates citation text for the citation forms and for the auto-updates
 * when any changes are made to related citation data (ie: author, publication, etc).
 *
 * Export
 *     generateCitationText
 */
import { EntityRecords, SerializedEntity } from '~types';
import { _db, _u } from '~util';
/**
 * All data needed to generate the citation.
 */
let d: CitationData;

type CitationData = CitationParams & { type: string; };
type CitationParams = {
    cit: SerializedEntity;
    citSrc: SerializedEntity;
    pubSrc: SerializedEntity;
    rcrds: {
        author: EntityRecords;
        source: EntityRecords;
        publisher: EntityRecords;
    };
    showWarnings?: boolean; //Shows text warning editor to fill in missing data.
};

/** Generates the citation text for the passed citation type. */
export function generateCitationText ( data: CitationParams ) {     /*dbug-log*///console.log('+--generateCitationText data[%O]', data);
    d = Object.assign( data, { type: data.cit.citationType.displayName } ) as CitationData;
    const citationTextBuilder = getTypeCitationGenerator( d.type ); /*dbug-log*///console.log("   --type[%s]", d.type);
    if ( !citationTextBuilder ) throw Error( `Citation generator not found for [${ d.type }]` );
    return citationTextBuilder( d.type );
}
function getTypeCitationGenerator ( type: string ) {
    const map: { [key: string]: ( type: string ) => string; } = {
        Article: buildArticleCite,
        Book: buildBookCite,
        Chapter: buildChapterCite,
        'Ph.D. Dissertation': buildDissertThesisCite,
        Other: buildOtherCite,
        Report: buildOtherCite,
        "Master's Thesis": buildDissertThesisCite,
        'Museum Record': buildOtherCite
    };
    return map[type];
}
/**
 * Articles, Museum Records, etc.
 * Citation example with all data available:
 *     1st Author [Last name, Initials.], 2nd+ Author(s) & Last Author
 *     [Initials. Last]. Year. Title of article. Title of Journal
 *     Volume (Issue): Page-Pages.
 */
function buildArticleCite ( _1: string ): string {
    const athrs = getCitAuthors();
    const year = d.citSrc.year;
    const title = _u.stripString( d.cit.title );
    const pub = _u.stripString( d.pubSrc.displayName! );
    const vip = getCiteVolumeIssueAndPages();
    let fullText = [athrs, year, title].map( addPunc ).join( ' ' ) + ' ';
    fullText += vip ? ( pub + ' ' + vip ) : pub;
    return fullText + '.';
}
/**
 * Citation example with all data available:
 *     1st Author [Last name, Initials.], 2nd+ Author(s) & Last Author
 *     [Initials. Last]. Year. Book Title (Editor 1 [initials, last name],
 *      & Editor X [initials, last name], eds.). Edition. Publisher Name,
 *      City, Country.
 */
function buildBookCite ( _1: string ): string {
    const athrs = getPubSrcAuthors() || getCitAuthors();
    const year = d.pubSrc.year;
    const titlesAndEds = getCitTitlesAndEditors();
    const ed = d.cit.publicationVolume;
    const pages = getCitBookPages();
    const publ = buildPublString( d.pubSrc ) || ifWarning( '[NEEDS PUBLISHER DATA]' );
    const allFields = [athrs, year, titlesAndEds, ed, pages, publ];
    return allFields.filter( f => f ).map( addPunc ).join( ' ' );
}
/**
 * Citation example with all data available:
 *     1st Author [Last name, Initials.], 2nd+ Author(s) & Last Author
 *     [Initials. Last]. Year. Chapter Title. In: Book Title (Editor 1
 *     [initials, last name], & Editor X [initials, last name], eds.).
 *     pp. pages. Publisher Name, City, Country.
 */
function buildChapterCite ( _1: string ): string {
    const athrs = getPubSrcAuthors() || getCitAuthors();
    const year = d.pubSrc.year;
    const titlesAndEds = getCitTitlesAndEditors();
    const pages = getCitBookPages();
    const publ = buildPublString( d.pubSrc ) || ifWarning( '[NEEDS PUBLISHER DATA]' );
    const allFields = [athrs, year, titlesAndEds, pages, publ];
    return allFields.filter( f => f ).join( '. ' ) + '.';
}
/**
 * Citation example with all data available:
 *     1st Author [Last name, Initials.], 2nd+ Author(s) & Last Author
 *     [Initials. Last]. Year. Title.  Academic degree. Academic
 *     Institution, City, Country.
 */
function buildDissertThesisCite ( type: string ): string {
    const athrs = getPubSrcAuthors();
    const year = d.pubSrc.year;
    const title = _u.stripString( d.cit.title );
    const degree = type === "Master's Thesis" ? 'M.S. Thesis' : type;
    const publ = buildPublString( d.pubSrc ) || ifWarning( '[NEEDS PUBLISHER DATA]' );
    return [athrs, year, title, degree, publ].join( '. ' ) + '.';
}
/**
 * Citation example with all data available:
 *     1st Author [Last name, Initials.], 2nd+ Author(s) & Last Author
 *     [Initials. Last]. Year. Title. Volume (Issue): Page-Pages. Publisher
 *     Name, City, Country.
 */
function buildOtherCite ( _1: string ): string {
    const athrs = getCitAuthors() || getPubSrcAuthors();
    const year = d.citSrc.year ? d.citSrc.year : d.pubSrc.year;
    const title = _u.stripString( d.cit.title );
    const vip = getCiteVolumeIssueAndPages();
    const publ = buildPublString( d.pubSrc );
    return [athrs, year, title, vip, publ].filter( f => f ).join( '. ' ) + '.';
}
/** ---------- citation full text helpers ----------------------- */
function getCitBookPages (): string | false {
    if ( !d.cit.publicationPages ) return false;
    return 'pp. ' + _u.stripString( d.cit.publicationPages );
}
function getCitAuthors (): string | false {
    const auths = d.citSrc.authors;                               /*dbug-log*///console.log('auths = %O', auths);
    if ( !Object.keys( auths ).length ) return false;
    return getFormattedAuthorNames( auths, false );
}
function getPubSrcAuthors (): string | false {
    const auths = d.pubSrc.authors;
    if ( !auths ) return false;
    return getFormattedAuthorNames( auths, false );
}
function getPubEditors (): string | false {
    const eds = d.pubSrc.editors;                                   /*dbug-log*///console.log('getPubEditors eds[%O]', eds);
    if ( !eds ) return false;
    const names = getFormattedAuthorNames( eds, true );
    const edStr = Object.keys( eds ).length > 1 ? ', eds.' : ', ed.';
    return '(' + names + edStr + ')';
}
/**
 * Returns: Chapter title. In: Publication title [if there are editors,
 * they are added in parentheses here.].
 */
function getCitTitlesAndEditors () {
    const chap = d.type === 'Chapter' ? _u.stripString( d.cit.title ) : false;
    const pub = _u.stripString( d.pubSrc.displayName! );
    const titles = chap ? ( chap + '. In: ' + pub ) : pub;
    const eds = getPubEditors();
    return eds ? ( titles + ' ' + eds ) : titles;
}
/**
 * Formats volume, issue, and page range data and returns either:
 *     Volume (Issue): pag-es || Volume (Issue) || Volume: pag-es ||
 *     Volume || (Issue): pag-es || Issue || pag-es || null
 * Note: all possible returns wrapped in parentheses.
 */
function getCiteVolumeIssueAndPages (): string | null {
    const iss = d.cit.publicationIssue ? '(' + d.cit.publicationIssue + ')' : null;
    const vol = getCitValue( 'publicationVolume' );
    const pgs = getCitValue( 'publicationPages' );
    return vol ? formatTextWithVol( vol, iss, pgs ) :
        iss ? formatTextWithIssue( iss, pgs ) :
            pgs ?? null;
}
function getCitValue ( prop: string ): string | null {
    return d.cit[prop] ?? null;
}
function formatTextWithVol ( vol: string, iss: string | null, pgs: string | null ): string {
    return vol && iss && pgs ? ( vol + ' ' + iss + ': ' + pgs ) :
        vol && iss ? ( vol + ' ' + iss ) :
            vol && pgs ? ( vol + ': ' + pgs ) :
                vol;
}
function formatTextWithIssue ( iss: string, pgs: string | null ): string {
    return iss && pgs ? ( iss + ': ' + pgs ) : iss;
}
/** ======================= FORMAT PUBLISHER ================================ */
/** Formats publisher data and returns the Name, City, Country. */
function buildPublString ( pubSrc: SerializedEntity ): string | false {
    if ( !pubSrc.parent ) return false;
    const publ = getPublisher( pubSrc );
    return formatPublisherText( publ );
}
function getPublisher ( pubSrc: SerializedEntity ): SerializedEntity {
    const publSrc = _db.getEntity( d.rcrds.source, pubSrc.parent, 'source' );
    const publ = _db.getEntity( d.rcrds.publisher, publSrc.publisher, 'publisher' );
    return publ;
}
function formatPublisherText ( publ: SerializedEntity ): string {
    const name = publ.displayName;
    const city = publ.city ? publ.city : ifWarning( '[ADD CITY]' );
    const cntry = publ.country ? publ.country : ifWarning( '[ADD COUNTRY]' );
    return [name, city, cntry].filter( p => p ).join( ', ' );
}
/* =================== FORMAT AUTHOR|EDITOR ================================= */
type AuthorFieldValue = {
    [ord: number]: string;
};
/**
 * Returns a string with all author names formatted with the first author
 * [Last, Initials.], all following authors as [Initials. Last], and each
 * are separated by commas until the final author, which is separated
 * with '&'. If the names are of editors, they are returned [Initials. Last].
 * If >= 4 authors, returns first author [Last, Initials.] + ', et al';
 */
function getFormattedAuthorNames (
    auths: AuthorFieldValue,
    eds: boolean
): string {                                                         /*dbug-log*///console.log('getFormattedAuthorNames. auths = %O, eds [%s]', _u.snapshot(auths), eds);
    if ( Object.keys( auths ).length > 3 ) return getFirstAuthorEtAl( auths[1]!, eds );
    const ttl = Object.keys( auths ).length;
    let athrs = '';
    for ( let ord in auths ) {
        athrs += getFormattedAuthorName( ord, auths[ord], eds, ttl );
    }
    return _u.stripString( athrs );

}
function getFormattedAuthorName (
    ord: string,
    id: string | undefined,
    eds: boolean,
    ttl: number
): string | null {
    if ( id === 'create' ) return null;
    if ( !id ) throw Error( 'Author ID not found' );
    const name = getFormattedName( ord, id, eds );
    return getAuthorName( name, parseInt( ord ), ttl );

}
/* ------------------- FIRST AUTHOR ET ALL ---------------------------------- */
function getFirstAuthorEtAl ( authId: string, eds: boolean ): string {
    const name = getFormattedName( '1', authId, eds );
    return name + ', et al';
}
/* ----------------------- FORMAT NAME -------------------------------------- */
function getFormattedName (
    ord: string,
    srcId: string,
    eds: boolean
): string {                                                         /*dbug-log*///console.log('getFormattedName cnt[%s] id[%s]', i, srcId);
    const src = _db.getEntity( d.rcrds.source, parseInt( srcId ), 'source' );    /*dbug-log*///console.log('  -- author[%O', src);
    const athrId = src[_u.lcfirst( src.sourceType.displayName )];
    const athr = _db.getEntity( d.rcrds.author, parseInt( athrId ), 'author' );
    return getCitAuthName( parseInt( ord ), athr, eds );
}
/**
 * Returns the last name and initials of the passed author. The first
 * author is formatted [Last, Initials.] and all others [Initials. Last].
 * If editors (eds), [Initials. Last].
 */
function getCitAuthName (
    ord: number,
    a: SerializedEntity,
    eds: boolean
): string {                                                         /*dbug-log*///console.log('getCitAuthName. ord[%s], auth = %O, eds?[%s] ', ord, a, eds);
    const last = a.lastName;
    const initials = getInitials( a );
    return ord > 1 || eds ? initials + ' ' + last : last + ', ' + initials;
}
function getInitials ( a: SerializedEntity ): string {
    const initials = ['firstName', 'middleName'].map( getInitial );
    return initials.filter( i => i ).join( ' ' );

    function getInitial ( prop: string ): string | null {
        return a[prop] ? a[prop].charAt( 0 ) + '.' : null;
    }
}
function getAuthorName ( name: string, ord: number, authCnt: number ): string {
    return ord === 1 ? name : `${ getPunctuation() } ${ name }`;

    function getPunctuation () {
        return ord != authCnt ? ',' : ' &';
    }
}
/** ======================== HELPERS ======================================== */
/** Handles adding the punctuation for the data in the citation. */
function addPunc ( data: string ): string {
    return /[.!?,;:]$/.test( data ) ? data : data + '.';
}
function ifWarning ( warningTxt: string ): string | null {
    return d.showWarnings ? warningTxt : null;
}
/**
 * Sub-enttiy data must be reviewed before the referencing entity, root form.
 * Sub-data is reviewed in the order of the fields in the current form view.
 *
 * Export
 *     initRvwForm
 */
import { _cmbx, _u } from '~util';
import { _elems, _form, _review } from '~form';
import * as fields from './fields/init-review-fields.js';
import modifyReviewFormHandlers from './review-form-events.js';
import handleReviewSubmitElems from './review-submit-elems.js';
/* ======================= INIT FORM ======================================== */
export function initRvwForm( pRcrd ) {
    return _form( 'reviewEntity', [pRcrd] )
        .then( ifFormReviewValidContinueInit );

    function ifFormReviewValidContinueInit( skipReview = false ) {    /*dbug-log*///console.log('ifFormReviewValidContinueInit skipReview?[%s] pRcrd[%O]', skipReview, pRcrd);
        return skipReview ?
            _review( 'continueReview', [pRcrd.inst.group, pRcrd.id] ) :
            finishReviewFormInit( pRcrd );
    }
}
export function finishReviewFormInit( pRcrd ) {
    return _elems( 'fillComplexFormFields', [pRcrd.inst.group] )
        .then( () => fields.finishReviewFieldInit( pRcrd ) )
        .then( () => modifyReviewFormHandlers( pRcrd.inst.group, pRcrd.id ) )
        .then( () => handleReviewSubmitElems( pRcrd ) )
        .then( () => _review( 'loadIfSubPendingData', [pRcrd.inst.group] ) );
}
/* ======================== SUB-FORM ======================================== */
/**
 * Handles loading a form-field's pending-data. Parent-form review-elems are disabled
 * when sub-data is open for review and reenabled when the last sub-set is reviewed.
 * @param  {object} pSub  The form's next field's pending-data set
 * @param  {stirng} pLvl  The parent-form level
 * @return {promise}      Form-build chain (Only when loading sub-form pending-data)
 */
export function loadRvwSubForm( subPend, pLvl ) {                     /*dbug-log*///console.log('   +--loadRvwSubForm pLvl[%s] subPend[%O]', pLvl, subPend);
    if ( !subPend ) { return toggleFormReviewElems( pLvl ); }
    toggleFormReviewElems( pLvl, false );
    return initRvwForm( subPend );
}
function toggleFormReviewElems( fLvl, enable = true ) {               /*dbug-log*///console.log('toggleFormReviewElems fLvl[%s] enable?[%s]', fLvl, enable);
    if ( !_u.isManagerUser() || hasNoReviewElems( fLvl ) ) { return; }
    _elems( 'toggleFormReviewNotes', [fLvl, enable] );
    _cmbx.enableCombobox( fLvl + 'Action', enable );
}
/** Taxon select-form (not directly reviewed) */
function hasNoReviewElems( fLvl ) {
    return !$( `#${ fLvl }-form .rvw-action-cmbx` ).length;
}
/* =========================== MISC ========================================= */
export function setPendingComboOpt() {
    return fields.setPendingComboOpt( ...arguments );
}
export function reinitReviewFormatting() {
    fields.reinitReviewFormatting( ...arguments );
}
/**
 * After form-submit-success, local data is updated and the form-process continues.
 *
 * Export
 *     onSubmitError
 *     onSubmitSuccess
 *     syncLocalDataAndUpdateForm
 *
 * TOC
 *     ON SUBMIT ERROR
 *         ENTITY CONSTRAINT VIOLATION
 *     ON SUBMIT SUCCESS
 *         UPDATE LOCAL-STORAGE
 *         UPDATE FORM ENTITY-RECORDS
 */
import { _db, _u } from '~util';
import { _elems, _state, _val } from '~form';
/* =================== ON SUBMIT ERROR ====================================== */
export function onSubmitError( fLvl, jqXHR, textStatus ) {            /*temp-log*/console.log( '+-- onSubmitError fLvl[%s] jqXHR[%O] textStatus[%s] args[%O]', fLvl, jqXHR, textStatus, arguments );
    handleError( fLvl, jqXHR.responseJSON, textStatus );
    _elems( 'toggleWaitOverlay', [false] );
}
function handleError( fLvl, errData, textStatus ) {                   /*temp-log*///console.log('-- handleError errData[%O] textStatus[%s]', errData, textStatus);
    _val( 'formSubmitError', [fLvl] );
}
/* ------------- ENTITY CONSTRAINT VIOLATION -------------------------------- */
function onViolation( fails, fLvl ) {
    Object.keys( fails ).forEach( type => handleFail( type, fails[type], fLvl ) );
    _elems( 'toggleWaitOverlay', [false] )
}
function handleFail( fail, data, fLvl ) {                             /*dbug-log*///console.log('-- handleFail fLvl[%s] fail[%s] data[%O] ', fLvl, fail, data);
    const handlers = {
        Duplicate: handleDuplicateEntity,
        noChanges: showSubmitAlert,
        Delete: showSubmitAlert,
    };
    const type = Object.keys( handlers ).find( t => fail.includes( t ) ); /*dbug-log*///console.log('   -- type[%s]', type);
    handlers[type]( fail, data, fLvl );
}
function handleDuplicateEntity( msg, id, fLvl ) {                     /*dbug-log*///console.log('-- handleDuplicateEntity fLvl[%s] msg[%s] id[%s]', fLvl, msg, id);
    const fState = _state( 'getFormState', [fLvl] );
    if ( !fState.misc ) { fState.misc = {}; }
    fState.misc.existingEntity = getExistingEntity( msg, id );
    showSubmitAlert( 'existingEntity', null, fLvl );
}
function getExistingEntity( msg, id ) {
    return _state( 'getRcrd', [msg.split( 'Duplicate ' )[1], id] );
}
function showSubmitAlert( fail, data, fLvl ) {
    _val( 'formSubmitError', [fLvl, fail] );
}
/* =================== ON SUBMIT SUCCESS ==================================== */
export function onSubmitSuccess( fLvl, results ) {                    /*temp-log*/console.log( '       +-- onSubmitSuccess fLvl[%s] results[%O] args[%O]', fLvl, _u.snapshot( results ), arguments );
    if ( results.fail ) { return onViolation( results.fail, fLvl ); }
    syncLocalDataAndUpdateForm( fLvl, results );
}
export function syncLocalDataAndUpdateForm( fLvl, data ) {
    flagFormSubmitted();
    const confg = _state( 'getFormState', [fLvl] );
    return _db.syncLocalDataAfterDataEntry( data, confg )
        .then( data => onDataSynced( fLvl, data, confg ) );
}
function flagFormSubmitted() {
    const rootForm = _state( 'getFormState', ['top'] );
    rootForm.submitted = true; //Triggers special-handling on root-form exit
}
/* ------------- UPDATE LOCAL-STORAGE --------------------------------------- */
function onDataSynced ( fLvl, data, confg ) {                        /*temp-log*/console.log( '       --onDataSynced [%s][%O] fConfg[%O]', fLvl, data, confg );
    if ( !_state( 'getStateProp' ) ) { return; } //form closed.
    if ( data.fails ) { return _val( 'errUpdatingData', ['dataSyncFailures', data.fails] ); }
    return Promise.resolve( updateDataStoredInFormMemory( data, confg.action ) )
        .then( () => closeOrResetForm( fLvl, data ) );
}
function closeOrResetForm( fLvl, data ) {
    const afterSubmit = _state( 'getFormHandler', [fLvl, 'afterSubmit'] );/*dbug-log*///console.log('       --closeOrResetForm [%s][%O] afterSubmit?[%O]', fLvl, data, afterSubmit);
    return afterSubmit ? afterSubmit() : _elems( 'handleFormClose', [fLvl, null, data] );
}
/* ------------- UPDATE FORM ENTITY-RECORDS --------------------------------- */
function updateDataStoredInFormMemory( data, action ) {
    if ( !data.coreEntity ) { return; } //Review-form with no entity-data updates
    return data.core ? addDataToStoredRcrds( data.core, data.detail ) : null;
}
function addDataToStoredRcrds( entity, detailEntity ) {               /*dbug-log*///console.log('--addDataToStoredRcrds. [%s] (detail ? [%s])', entity, detailEntity);
    return _state( 'updateFormRecordState', [entity] )
        .then( syncDetailEntityRecords );
    /** Source & Location's detail entities: publications, citations, authors, geojson */
    function syncDetailEntityRecords() {                            /*dbug-log*///console.log('   --syncDetailEntityRecords detailEntity?[%s]', detailEntity);
        return detailEntity ? addDataToStoredRcrds( detailEntity ) : null;
    }
}
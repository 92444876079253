/**
 * Utility methods for the various source types.
 */
import {submitForm, _state} from '~form';
import { _el } from '~util';
export { loadSrcTypeFields } from './sub-type-fields.js';

/* ==================== SUBMIT CONFIRMATION-MODAL =========================== */
/**
 * If a URL is entered in the form, a modal is shown prompting the editor to
 * double check the links work before submitting.
 */
export function showSrcSubmitModal( fLvl ) {
    const linkHtml = buildConfirmationModalHtml( fLvl );
    if ( !linkHtml ) return submitForm( fLvl );
    _el.showSaveModal( buildModalConfg( fLvl, linkHtml ), getButtonConfig( fLvl ) );
    $( `#${ fLvl }-submit` ).css( {opacity: .5, cursor: 'not-allowed'} )
}
/* ---------------------- INIT MODAL ---------------------------------------- */
function buildConfirmationModalHtml( fLvl ) {
    return ['Doi', 'Website'].map( buildUrlLink ).filter( l=>l ).join( '<br><br>' );

    function buildUrlLink( field ) {
        const url = _state( 'getFieldState', [fLvl, field] );
        return url ? `<b>${ field }:</b> <a href="${ url }"" target="_blank">${ url }</a>` : null;
    }
}
function buildModalConfg( fLvl, linkHtml ) {
    return {
        html: linkHtml,
        position: 'left',
        selector: `#${ fLvl }-submit`,
        title: 'Please double-check links'
    };
}
/* ------------------------- SUBMIT BUTTON ---------------------------------- */
function getButtonConfig( fLvl ) {
    return {
        button: cloneSubmitButtonForModal( fLvl ),
        onConfirm: () => submitForm( fLvl )
    };
}
function cloneSubmitButtonForModal( fLvl ) {
    const $button = $( `#${ fLvl }-submit` ).clone();
    $button[0].id += '_modal';
    $button.val( `Confirm & ${ $button.val() }` )
    return $button;
}
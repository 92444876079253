/**
 * Modifies and sorts user-data for local storage.
 * - savedFilters - an object with each filter's id (k) and data (v)
 * - savedFiltersNames - an object with each filter's name(k) and { id, focus-view }
 * - dataLists - an object with each list's id (k) and data (v)
 * - dataListNames - an object with each list's name (k) and id (v).
 * - user - current user's data or { name & role (k) 'visitor' (v) }
 *
 * - editorNames - an object with each editor's name(k) and id(v)
 *
 * Export
 *     getFilterListGroupString
 *     modifyUsrDataForLocalDb
 *
 * TOC
 *     CUSTOM USER LIST AND FILTER DATA
 *     USER ENTITY
 */
import { EntityRecords, IdsByName, SerializedEntity, User, objectKeys } from '~types';
import { _db } from '~util';
import { getNameObj } from '../init-helpers';
/* ====================== CUSTOM USER LIST AND FILTER DATA ================== */
type UserServerData = {
    lists: SerializedEntity[];
    users: EntityRecords;
};
export function modifyUsrDataForLocalDb ( data: UserServerData ): void {
    if ( !data.lists || !data.users ) throw Error( 'Invalid user data downloaded' );
    storeUserNamedData( data.lists as SerializedEntity[] );
    _db.storeData( 'editorNames', getEditors( data.users ) );
    setCurrentUserData( Object.values( data.users ) );
}
function storeUserNamedData ( lists: SerializedEntity[] ): void {
    const filters: EntityRecords = {};
    const filterIds: number[] = [];
    const intLists: EntityRecords = {};
    const intListIds: number[] = [];

    lists.forEach( addToDataObjs );
    _db.storeData( 'savedFilters', filters );
    _db.storeData( 'savedFilterNames', getFilterOptionGroupObj( filterIds, filters ) );
    _db.storeData( 'dataLists', intLists );
    _db.storeData( 'dataListNames', getNameObj( intListIds, intLists ) );
    _db.removeData( 'list' );

    function addToDataObjs ( l: SerializedEntity ): void {
        const entities = l.type == 'filter' ? filters : intLists;
        const idAry = l.type == 'filter' ? filterIds : intListIds;
        entities[l.id] = l;
        idAry.push( l.id );
    }
}
function getFilterOptionGroupObj (
    ids: number[],
    filters: EntityRecords
): { [name: string]: { value: number; group: string; }; } {
    const data: { [name: string]: { value: number, group: string; }; } = {};
    ids.forEach( buildOptObj );
    return data;

    function buildOptObj ( id: number ): void {
        const filter = filters[id];
        if ( !filter?.displayName ) throw Error( 'Filter error' );
        data[filter.displayName] = {
            value: id, group: getFilterListGroupString( filter )
        };
    }
}
export function getFilterListGroupString ( list: SerializedEntity ): string {
    list.details = JSON.parse( list.details );
    const map = {
        srcs: 'Source',
        locs: 'Location',
        taxa: 'Taxon',
    } as const;
    const view = list.details.view ? list.details.view.text : false;
    const focus = map[list.details.focus as keyof typeof map];
    return !view ? focus : `${ focus } - ${ view }`;
}
/* =========================== USER ENTITY ================================== */

/**
 * In order to handle user-impersonation, the UI user is the user local data uses
 */
function setCurrentUserData ( users: SerializedEntity[] ): void {
    const name = $( 'body' ).data( 'user-name' );
    const user = !name ? getVisitor() : users.find( u => u.username === name );
    if ( !user ) throw Error( 'Could not find user data' );
    _db.storeData( 'user', user );
}
function getVisitor (): User {
    return { username: null, role: 'visitor' };
}
function getEditors ( users: EntityRecords ): IdsByName {
    const editorIds = objectKeys( users ).filter( isEditor );
    const data: IdsByName = {};
    editorIds.forEach( id => {
        const username: string = users[id]?.fullname;
        data[username] = id;
    } );
    return data;

    function isEditor ( id: number ): boolean {
        return users[id]?.role == 'editor';
    }
}
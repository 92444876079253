/**
 * Updates the quarantined data with any changes made during review or to related
 * quarantined data.
 *
 * Export
 *     updateQuarantined
 *
 * TOC
 *     SYNC FIELD-CHANGES
 *         STANDARD FIELD
 *         MULTI-FIELD
 *         GET UNQUARANTINED
 *     SET CURRENT FIELD-DATA
 *         COMPLEX UPDATE HANDLERS
 *         AUTHOR\EDITOR
 *         LOCATION
 *         SOURCE
 *         TAXON
 */
import { _db, _el, _u } from '~util';
import { EntityRecords, SerializedEntity, objectKeys, objectValues } from '~types';
import { PendingDataEntity, MultiFieldPendingData, NormalizedPendingData } from './process-pending';
import { DataEntryResults, FormFieldConfig } from '../data-entry/data-entry-sync';
import { FieldConfig } from 'js/util/elems/elems-main';
/** All PendingData records. */
let PendingData: EntityRecords | null;
/**
 * Reviewed related-data is updated and quarantined data is readded to local storage.
 * Approved: it is stored in local storage without it's quarantined temp ID
 * Rejected: will not be stored and needs to be removed/replaced (TODO)
 *     todo: ensure latest field-values are set in qData (case: data replaced by manager)
 *         options: replace here, or repush through server entity processing
 *      replace here. otherwise, there would be issues with lots of form validation
 *          that only really matters during approval and contributor submission
 */
export function updateQuarantined (
    pRcrd: PendingDataEntity,
    rcrds: EntityRecords
): DataEntryResults {                                               /*dbug-log*///console.log( '   +-- updateQuarantined entity name?[%s] id[%s] pRcrd[%O] allPendingData[%O]', pRcrd.data.quarantined.coreEntity.displayName, pRcrd.data.quarantined.coreId, pRcrd, rcrds );
    PendingData = rcrds;
    const qData = _u.snapshot( pRcrd.data.quarantined );
    updateQuarantinedFieldData( pRcrd, qData );
    normalizePendingRecord( pRcrd, qData );
    PendingData = null;
    return qData;
}
/**
 * This data is added before storing in local storage. This property is used as a
 * flag at various points in the data-entry process.
 */
function normalizePendingRecord ( pRcrd: PendingDataEntity, qData: DataEntryResults ) {
    qData.coreEntity.pending = { id: pRcrd.id, stage: pRcrd.stage.name };
}
/* ====================== SYNC FIELD-CHANGES ================================ */
function updateQuarantinedFieldData (
    pRcrd: PendingDataEntity,
    qData: DataEntryResults
): void {
    const fields = pRcrd.data.review.fields;
    objectValues( fields ).forEach( field => syncQuarantinedData( field, qData ) );
}
/** Updates the field's data in the quarantined record. */
function syncQuarantinedData ( field: FormFieldConfig, qData: DataEntryResults ): void {/*dbug-log*///console.log( '       +-syncQuarantinedData field[%O]', field );
    if ( !field.pending || ( !field.changed && !field.replacedPending ) ) return;  //Flagged during review submit
    const syncHandler = getSyncHandler( field );                    /*dbug-log*///console.log('       --syncQuarantinedData field[%s][%O]', fName, field);
    syncHandler( field, qData );
}
function getSyncHandler ( field: FormFieldConfig ): typeof syncField {
    const isMulti = _el.isMultiField( field as unknown as FieldConfig );
    return isMulti ? syncMultiField : syncField;
}
/* ---------------------- STANDARD FIELD ------------------------------------ */
/**
 * Syncs changes made during review to quarantined field-values. If a pending field
 * was approved or rejected, the quarantined IDs are replaced with the approved data
 * or with temporary data when necessary to prevent local storage errors.
 */
function syncField ( field: FormFieldConfig, qData: DataEntryResults ): void {
    const pRcrd = getPendingDataRecord( field );
    const val = pRcrd ? pRcrd.entityId : field.value;               /*dbug-log*///console.log('           --syncField pRcrd[%s][%O] field[%O] val[%O]', (pRcrd ? pRcrd.data.quarantined.coreId : null) , field.pending, field, val);
    syncEntityData( qData, pRcrd, field.prop, val );
}
function getPendingDataRecord ( field: FormFieldConfig ) {
    if ( !( 'pending' in field ) ) return null;
    return getFinishedRecord( ( field.pending as NormalizedPendingData ).id );
}
/* ----------------------- MULTI-FIELD -------------------------------------- */
/** Handles fields with multiple possible entities. */
function syncMultiField ( field: FormFieldConfig, qData: DataEntryResults ): void {
    processMultiPendingData( field.pending as MultiFieldPendingData );
    if ( field.replacedPending ) processMultiPendingData( field.replacedPending );

    function processMultiPendingData ( multi: MultiFieldPendingData ) {
        objectKeys( multi ).forEach( k => handleMultiUpdate( k, multi[k]! ) );
    }
    function handleMultiUpdate ( ord: number, pending: NormalizedPendingData ): void {
        const pRcrd = getFinishedRecord( pending.id );                /*dbug-log*///console.log('           --handleMultiUpdate ord[%s] pRcrd[%s][%O] field[%O]', ord, (pRcrd ? pRcrd.data.quarantined.coreId : null) , pending, field);
        const val = pRcrd ? pRcrd.entityId : field.value[ord];
        syncEntityData( qData, pRcrd, field.prop, val, ord );
    }
}
/* --------------------- GET UNQUARANTINED ---------------------------------- */
/** If data is no longer quarantined, the reviewed record is returned. */
function getFinishedRecord ( id: number ): PendingDataEntity | null {
    const pRcrd = _db.getEntity( PendingData!, id, 'pending' ) as PendingDataEntity;                                 /*dbug-log*///console.log('               --getFinishedRecord pRcrd[%s][%O] PendingData[%O]', id, pRcrd, PendingData);
    return ifNotPendingReview( pRcrd.stage.name ) ? pRcrd : null;
}
function ifNotPendingReview ( stage: string ): boolean {
    return ['Approved', 'Completed', 'Rejected'].indexOf( stage ) !== -1;
}
/* ====================== SET CURRENT FIELD-DATA ============================ */
/**
 * Updates the quarantined data with any changes made during review or to related
 * quarantined data.
 * TODO2: Ensure that, if this entity gets deleted, it is removed from storage: parent rcrds, etc.
 */
function syncEntityData (
    qData: DataEntryResults,
    pRcrd: PendingDataEntity | null,
    props: FormFieldConfig["prop"],
    val: any,
    ord?: number
): void {                                                           /*dbug-log*///console.log('               --syncEntityData qData[%O] pRcrd?[%O] props[%O] ord?[%s] newVal?[%s]', qData, pRcrd, props, ord, val);
    if ( !props ) return;  //Synced via a related property (eg, publication is synced via citation)
    objectKeys( props ).forEach( setEachProperty );

    function setEachProperty ( type: 'core' | 'detail' ) {
        setProperty( props![type]!, type, qData, pRcrd, val, ord );
    }
}
/** Sync value in simple quarantined-fields or call complex-field's handler. */
function setProperty (
    prop: string,
    type: 'core' | 'detail',
    qData: DataEntryResults,
    pRcrd: PendingDataEntity | null,
    val: any,
    ord?: number
) {                                                                 /*dbug-log*///console.log('                   --setEntityDataProp type[%s] prop[%s] value[%s]', type, prop, val);
    const eData = qData[type + 'Entity' as keyof DataEntryResults] as SerializedEntity;
    const handler = getQuarantinedPropertyUpdateHandler( qData.core, prop, ord );
    if ( handler ) return handler( prop, eData, pRcrd, val );
    eData[prop] = val;
}
/* ------------------ COMPLEX UPDATE HANDLERS ------------------------------- */
/** Returns the handler for data with complex updates. */
function getQuarantinedPropertyUpdateHandler (
    entity: string,
    prop: string,
    ord?: number
): Function | null {
    const map: { [key: string]: { [key: string]: Function; }; } = {
        interaction: {
            object: updateTaxonFieldData,
            subject: updateTaxonFieldData,
            location: handleUnspecifiedLocation,
            source: handleChangedSource
        },
        source: {
            authors: updateAuthorFieldData.bind( null, ord ),
            editors: updateAuthorFieldData.bind( null, ord ),
            // todo1: check source required parents when cleared...
        },
        taxon: {
            group: Function.prototype, //Group is set during local storage update
            parent: updateTaxonFieldData,
        }
    };
    return entity in map ? map[entity as keyof typeof map]![prop]! : null;
}
/* ---------------------- AUTHOR\EDITOR ------------------------------------- */
/** Sync author/editor data: Update ordinal value and update contributor data. */
function updateAuthorFieldData (
    ord: number | undefined,
    prop: string,
    eData: SerializedEntity,
    pRcrd: PendingDataEntity | null,
    v: any
): void {                                                           /*dbug-log*///console.log('               --updateAuthorFieldData prop[%s][%s] eData[%O] pRcrd?[%O] v[%s]', prop, ord, eData, pRcrd, v);
    if ( !ord ) return console.log( 'HANDLE DELETED AUTHORS' ); //todo1
    const value = v ? v : getFirstSourceTypeId( 'authSrcs' );
    eData[prop][ord] = value;
    handleContributorProperyUpdate( eData, pRcrd, value );
}
function getFirstSourceTypeId ( prop: string ): number {
    const ids = _db.getValue( prop ) as number[];
    return ids[0]!;
}
/** TODO: Handle when authors have been deleted or more have been added. */
function handleContributorProperyUpdate (
    eData: SerializedEntity,
    pRcrd: PendingDataEntity | null,
    value: number
): void {                                                           /*dbug-log*///console.log('                   --handleContributorProperyUpdate eData[%O] pRcrd?[%O] value[%s]', eData, pRcrd, value);
    const prevId = pRcrd ? pRcrd.data.quarantined.coreId : null;
    if ( !prevId ) return;
    const contrib = eData.contributors[prevId];
    eData.contributors[value] = contrib;
    delete eData.contributors[prevId];
}
/* -------------------------- LOCATION -------------------------------------- */
/** Returns the default location record: unspecified. */
function handleUnspecifiedLocation (
    prop: string,
    eData: SerializedEntity,
    _1: PendingDataEntity | null,
    val: any
): void {
    eData[prop] = val ?? getUnspecifiedLoc();
}
function getUnspecifiedLoc () {
    const regions = _db.getEntities( 'topRegionNames' );           /*dbug-log*///console.log('               --handleUnspecifiedLocation regions[%O]', regions);
    const unspecifiedLoc = objectKeys( regions ).find( r => r === 'Unspecified' );
    if ( !unspecifiedLoc ) throw Error( 'Unspecified location entity not found' );
    return regions[unspecifiedLoc];
}
/* --------------------------- SOURCE --------------------------------------- */
function handleChangedSource (
    prop: string,
    eData: SerializedEntity,
    _1: PendingDataEntity | null,
    val: any
): void {
    const value = val ?? getFirstSourceTypeId( 'citSrcs' );         /*dbug-log*///console.log('               --handleChangedSource value[%s]', value);
    eData[prop] = value;
}
/* -------------------------- TAXON ----------------------------------------- */
/** Sync taxon data. If quarantined parent was rejected, replace with temp parent. */
function updateTaxonFieldData (
    prop: string,
    eData: SerializedEntity,
    pRcrd: PendingDataEntity | null,
    val: any
): void {                                                           /*dbug-log*///console.log('               --updateTaxonFieldData prop[%s] eData[%O] pRcrd?[%O] val[%s]', prop, eData, pRcrd, val);
    eData[prop] = val ?? getTempParentId( pRcrd );                  /*dbug-log*///console.log('                       -- tempParentId value[%s]', value);
}
/** Returns the ID for the first valid parent in the taxonomy. */
function getTempParentId ( pRcrd: PendingDataEntity | null ): number {
    if ( !pRcrd ) throw Error( 'Pending Data record not found' );
    const parentField = pRcrd.data.review.fields.Parent;
    if ( !parentField.pending ) return parentField.value;
    return findNextPendingParent( parentField.pending.id );
}
function findNextPendingParent ( id: number ): number {
    const pendingParent = _db.getEntity( PendingData!, id, 'PendingData' ) as PendingDataEntity;
    return pendingParent.entityId ?? findValidParent( pendingParent );
}
function findValidParent ( pendingParent: PendingDataEntity ): number {
    return pendingParent.stage.name === 'Rejected' ?
        getTempParentId( pendingParent ) : pendingParent.data.quarantined.coreId;
}
/**
 * Handles exiting the root|sub forms and calling the related event-handlers. .
 *
 * Export
 *     handleFormClose
 *     exitRootForm
 *
 * TOC
 *     ROOT-FORM
 *     SUB-FORM
 *     CLOSE HANDLERS
 *         PENDING-DATA
 */
import { _el } from '~util';
import { _filter, _table, _ui } from '~db';
import { _data, _elems, _review, _state } from '~form';
import { boldLeafFormHeader } from './build-form.js';
/**
 * @param  {string}  fLvl  Form-group string
 * @param  {boolean} param Flag used during form-group close
 * @param  {object}  data  Entity-data from the submitted sub-form
 * @return {promise}       Form-close promise-chain
 */
export function handleFormClose( fLvl, param, data ) {                /*perm-log*/console.log( '           --handleFormClose fLvl[%s] param?[%O] data?[%O]', fLvl, param, data );
    exitFormAndUpdateUi( fLvl, param, data )
    return Promise.resolve( ifPendingDataSubmitted( fLvl, data ) )
        .then( status => handleOnCloseChain( fLvl, status ) )
        .then( () => _elems( 'toggleWaitOverlay', [false] ) );
}
function exitFormAndUpdateUi( fLvl, param, data ) {
    if ( fLvl === 'top' ) { return exitRootForm( null, param === 'skipTableReset' ); }
    exitSubForm( fLvl, param, data );
}
/* ======================== ROOT-FORM ======================================= */
/**
 * Returns popup and overlay to their original/default state.
 * Note: called directly during form-cancel
 */
export function exitRootForm( e, skipReset ) {                        /*perm-log*/console.log( '               --exitRootForm skipReset?[%s]', skipReset );
    if ( !skipReset ) { refocusTableIfFormWasSubmitted(); }
    _el.hidePagePopup()
}
/**
 * If the form was not submitted the table does not reload. Otherwise, if exiting
 * the edit-forms, the table will reload with the current focus; or, after creating
 * an interaction, the table will refocus into source-view. Exiting the interaction
 * forms also sets the 'int-updated-at' filter to 'today'.
 */
function refocusTableIfFormWasSubmitted() {
    const confg = _state( 'getFormState', ['top'] );                  /*dbug-log*///console.log('--refocusTableIfFormWasSubmitted confg[%O]', confg);
    if ( !confg.submitted ) { return; }
    if ( confg.name === 'Interaction' && confg.action !== 'delete' ) { return refocusAndShowUpdates(); }
    _table( 'reloadTableWithCurrentFilters' );
}
function refocusAndShowUpdates() {                                  /*dbug-log*///console.log('refocusAndShowUpdates.')
    if ( _state( 'getFormState', ['top', 'action'] ) === 'create' ) {
        _filter( 'showTodaysUpdates', ['srcs'] );
    } else {
        _table( 'reloadTableWithCurrentFilters' );
    }
}
/* ========================= SUB-FORM ======================================= */
/**
 * Removes the sub-form elements. Updates the parent-form: the field-combobox,
 * cancel and submit buttons, and field formatting during a data-review process.
 */
function exitSubForm( fLvl, focus, data ) {
    $( `#${ fLvl }-form` ).remove();                                /*temp-log*///console.log("               --exitSubForm fLvl[%s] data?[%O]", fLvl, data);
    boldLeafFormHeader( _state( 'getFormLevel', ['parent', fLvl] ) )
    const fState = _state( 'getFormState', [fLvl] );
    _elems( 'resetFormCombobox', [fState.combo, !!focus] );
    if ( !data ) { return; } //form-canceled
    addSubFormCloseHandlers( data.coreEntity, fState );
}
/* --------------------- ADD CLOSE HANDLERS --------------------------------- */
/** NOte: order is important here. */
function addSubFormCloseHandlers( entity, fState ) {                  /*dbug-log*///console.log("-- addSubFormCloseHandlers fLvl[%s] entity[%O] fState[%O]", fState.group, entity, fState);
    updateFieldValueAfterFormClosed( entity, fState );
    updateComboAfterFormClosed( fState.group, entity, fState.combo );
    updateParentFormUiAfterFormClosed( fState.group );
}
/* ------------ FIELD VALUE ------------ */
function updateFieldValueAfterFormClosed( entity, fState ) {          /*dbug-log*///console.log("   -- updateFieldValueAfterFormClosed entity[%O] fState[%O]", entity, fState);
    if ( fState.count || fState.name === 'Taxon' ) { return; } // Edge cases. Value stored in the combobox change event.
    const pLvl = _state( 'getFormLevel', ['parent', fState.group] );
    const setField = setEntityInParentField.bind( null, pLvl, fState.combo, entity );
    _state( 'addFormHandler', [fState.group, 'onFormClose', setField] );
}
/**
 * Triggers the input's change event, setting field value. (The combobox change
 * event is triggered later.)
 */
function setEntityInParentField( pLvl, combo, entity ) {
    _state( 'setFieldState', [pLvl, combo, ( entity ? entity.id : null )] );
}
/* ------------ FIELD COMBO ------------ */
function updateComboAfterFormClosed( fLvl, entity, combo ) {          /*dbug-log*///console.log("   -- updateComboAfterFormClosed fLvl[%s] comboField[%s] entity[%O]", fLvl, combo, entity);
    const setEntity = _elems.bind( null, 'addAndSelectEntity', [entity, combo] );
    _state( 'addFormHandler', [fLvl, 'onFormClose', setEntity ] );
}
/* ------------ FORM UI ------------ */
function updateParentFormUiAfterFormClosed( fLvl ) {
    const updateParentForm = handleParentFormUi.bind( null, fLvl );
    _state( 'addFormHandler', [fLvl, 'onFormClose', updateParentForm] );
}
function handleParentFormUi( fLvl ) {                                 /*dbug-log*///console.log("               --handleParentFormUi fLvl[%s]", fLvl);
    const pLvl = _state( 'getFormLevel', ['parent', fLvl] );
    handleParentCancelAndSubmitButtons( pLvl );
    handleFieldFormatDuringDataReview( pLvl );
}
function handleParentCancelAndSubmitButtons( pLvl ) {
    _elems( 'checkReqFieldsAndToggleSubmitBttn', [pLvl] );
    _elems( 'toggleCancelBttn', [pLvl, true] );
}
function handleFieldFormatDuringDataReview( pLvl ) {
    if ( _state( 'getFormAction', [pLvl] !== 'review' ) ) { return; }
    _review( 'reinitReviewFormatting', [pLvl] );
}
/* ======================== CLOSE HANDLERS ================================== */
function handleOnCloseChain( fLvl, skipClose = false ) {              /*dbug-log*///console.log('   -- handleOnCloseChain fLvl[%s] skipClose?[%s]', fLvl, skipClose)
    if ( skipClose ) { return; } //Closing data-set from nested sub-form. Root-form will handle close.
    let afterClose;
    return Promise.resolve( _state( 'getFormHandler', [fLvl, 'beforeFormClose', true] )() )
        .then( handleClose )
        .then( () => afterClose() );

    function handleClose() {
        afterClose = _state( 'getFormHandler', [fLvl, 'afterFormClose', true] );
        const onClose = _state( 'getFormHandler', [fLvl, 'onFormClose', true] );
        _state( 'clearFormGroupState', [fLvl] );                      /*dbug-log*///console.log('       -- handleClose handlers[%O]', onClose)
        return onClose();
    }
}
/* ------------------------ PENDING-DATA ------------------------------------ */
function ifPendingDataSubmitted( fLvl, data ) {                       /*temp-log*///console.log('           -- ifPendingDataSubmitted fLvl[%s] pendingData?[%O]', fLvl, data);
    if ( !data || !data.pending ) { return; }  // continue to the form's close-chain
    _ui( 'loadPendingDataStats' );//handled async
    if ( fLvl === 'top' ) { return; }
    const fState = _state( 'getFormState', [fLvl] );
    return _state( 'updateFormRecordState', ['pending'] )
        .then( () => _data( 'afterPendingDataSubmitted', [fState, data] ) );
}

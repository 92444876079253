/**
 * Builds field-data necessary for the data-review process. Tracks changes to data
 * during the review.
 *
 * Export
 *     getFieldReviewData
 *
 * TOC
 *     FIELD DATA
 *         FIELD VALUE
 *         TRACK CHANGES
 *     PENDING FIELD-DATA
 *         GET PENDING-RECORD
 *         REPLACED PENDING-DATA
 */
import { _u } from '~util';
import { _state } from '~form';
/**
 * @param  {object} formState   State object for the form being submitted
 * @return {object}             Field-data necessary for the data-review process.
 */
export default function getFieldReviewData( formState ) {             /*dbug-log*///console.log('       -- getFieldReviewData formState[%O]', formState);
    const formRcrd = getFormPendingDataRcrd( formState.pending );
    const data = {};
    Object.values( formState.fields ).forEach( setFieldReviewData );
    return data;
    /**
     * Gets the core data needed for the field's review process.
     * Note: It is important to include all fields, whether active or not.
     */
    function setFieldReviewData( field ) {
        data[field.name] = buildReviewField( field, formRcrd );       /*dbug-log*///console.log('           -- setFieldReviewData data[%s][%O] changed?[%s]', field.name, data[field.name], data[field.name].changed);
    }
}
function getFormPendingDataRcrd( formPendingData ) {
    if ( !formPendingData ) { return false; } // Contributor is submitting the initial entry
    return _state( 'getRcrd', ['pending', formPendingData.id, false] );
}
/* ========================== FIELD DATA ==================================== */
function buildReviewField( field, formRcrd ) {                        /*dbug-log*///console.log('               -- buildReviewField field[%s][%O] formRcrd?[%O]', field.name, field, formRcrd);
    const data = getCurrentFieldData( field );
    data.changed = isValueChanged( field, formRcrd, data );
    data.pending = getPendingSubData( field );
    data.replacedPending = getReplacedPendingSubData( field, formRcrd );
    return data;
}
function getCurrentFieldData( field ) {
    return {
        notes: field.notes,
        prop: field.prop,
        value: getQuarantinedOrFinalValue( field ),
    };
}
/* ------------------------ FIELD VALUE ------------------------------------- */
/**
 * Resets the field value to the Contrubutor's quarantined values unless they
 * have been replaced with approved data.
 */
function getQuarantinedOrFinalValue( field ) {                        /*dbug-log*///console.log('   -- getQuarantinedOrFinalValue field[%s][%O]', field.name, field);
    if ( field.count ) { return field.value ? getMultiFieldValue( field ) : null; }
    if ( field.value ) { return field.value; }
    const pData = getPendingRcrd( field )
    return pData ? pData.qId : field.value;
}
function getMultiFieldValue( field ) {
    const data = {};
    for ( let ord = field.count; ord >= 1; ord-- ) { //count is always one higher than the fields with values (the trailing combo)
        data[ord] = getFieldOrdValue( field, ord );
    }
    return data;
}
function getFieldOrdValue( field, ord ) {                             /*dbug-log*///console.log('   -- getFieldOrdValue field[%s][%O] ord[%s]', field.name, field, ord);
    if ( field.value[ord] ) { return field.value[ord]; }
    const pData = getPendingRcrd( field, 'source', ord );             /*dbug-log*///console.log('       -- getFieldOrdValue field[%s][%O] ord[%s] pData[%O]', field.name, field, ord, pData);
    return pData ? pData.qId : field.value[ord];
}
/* ------------------------ TRACK CHANGES ----------------------------------- */
/**
 * True if quarantined field-value was changed during data-review process. This
 * flag is used to trigger update handling when synced by the contributor.
 * @param  {object} field  Field config
 * @param  {object} fData  Form PendingData entity
 * @return {boolean}
 */
function isValueChanged( field, fData, reviewField ) {                /*dbug-log*///console.log('                   -- isValueChanged field[%s][%O] reviewField[%O] fData?[%O]', field.name, field, reviewField, fData);
    if ( !fData || field.value === undefined ) { return false; } //initial submit or unused field
    const prev = fData.data.review.fields[field.name].value;
    const changed = field.changed || prev !== reviewField.value;    /*dbug-log*///console.log('                       -- changed? current[%O] prev[%O] changed?[%s]', reviewField.value, prev, changed);
    return changed;
}
/* ====================== PENDING FIELD-DATA ================================ */
function getPendingSubData( field ) {
    if ( ifNoPendingDataToAdd( field ) ) { return false; }              /*dbug-log*///console.log('   -- getPendingSubData field[%s][%O]', field.name, field);
    return field.count ? getPendingMulti( field ) : getPending( field );
}
function ifNoPendingDataToAdd( field ) {
    return !field.pending || field.pending.completed;
}
function getPending( field ) {
    const pData = getPendingRcrd( field );
    afterManagerReviewRestoreQuarantinedValue( field, pData.qId );
    return pData;
}
function getPendingMulti( field ) {
    const data = {};
    for ( let i = field.count; i > 0; i-- ) { //count is always one higher than the fields with values (the trailing combo)
        setIfPendingData( i );
    }
    return data;

    function setIfPendingData( ord ) {                                /*dbug-log*///console.log('   -- getPendingMulti ord[%s] field[%O]', ord, field);
        const pData = getPendingRcrd( field, 'source', ord );
        if ( !pData ) { return; }
        data[ord] = pData;
        afterManagerReviewRestoreQuarantinedValue( field, pData.qId, ord );
        if ( !field.value[ord] ) { field.value[ord] = pData.qId; }
    }
}
/* ------------------------ GET PENDING-RECORD ------------------------------ */
/**
 * Returns the field's normalized pending-data.
 * @param  {object} field  Field configuration
 * @param  {string} ent    Entity classname
 * @param  {string} ord    Ordinal for fields with multiple values
 * @return {object}        Normalized data
 */
function getPendingRcrd( field, ent, ord ) {                          /*dbug-log*///console.log('   -- getPendingRcrd field[%O] ent?[%s] ord?[%s]', field, ent, ord);
    const pRcrd = getFieldPendingData( field.pending, ord );
    if ( !pRcrd ) { return; } // MultiFields without pending data
    const qId = getFieldQuarantinedId( field, ord, pRcrd );
    const entity = ent ? ent :_u.lcfirst( field.entity );
    return { id: pRcrd.id, entity: entity, qId: qId };
}
function getFieldPendingData( fPending, ord ) {
    return ord && fPending ? fPending[ord] : fPending;
}
function getFieldQuarantinedId( field, ord, pRcrd ) {
    if ( !pRcrd.data ) { return getQuarantinedValue( field, ord ); } //quarantine selected
    const qId = getRecordQuarantinedId( pRcrd.id );
    return qId ? qId : getQuarantinedValue( field, ord )
}
function getRecordQuarantinedId( pId ) {
    const storedRcrd = _state( 'getRcrd', ['pending', pId, false] ); // Get latest updates
    return storedRcrd ? storedRcrd.data.quarantined.coreId : false;
}
function getQuarantinedValue( field, ord ) {
    return ord ? field.value[ord] : field.value;
}
/**
 * Restores the contriutor's quarantined value after data-manager review.
 * @param  {object} field  Field configuration
 * @param  {string} qId    ID of the contributor's quarantined record
 * @param  {string} ord    Ordinal for fields with multiple values
 */
function afterManagerReviewRestoreQuarantinedValue( field, qId, ord ) {
    if ( field.value ) { return; }
    if ( ord ) { return field.value[ord] = qId; }
    field.value = qId;
}
/* ------------------------ REPLACED PENDING-DATA --------------------------- */
/**
 * If the field had PendingData replaced, it will be stored for the contributor review,
 * @param  {object} reviewField  Data related to the review field.
 * @param  {object} formRcrd     PendingData entity for the containing form.
 * @return {object}              Replaced PendingData { id (PendingData), entity }
 */
function getReplacedPendingSubData( field, formRcrd ) {               /*dbug-log*///console.log('   -- getReplacedPendingSubData field[%O] formRcrd?[%O]', field, formRcrd);
    if ( !formRcrd ) { return; } // Contributor is submitting the initial entry
    const prevField = formRcrd.data.review.fields[field.name];      /*dbug-log*///console.log('        -- prevField[%O]', prevField);
    if ( !prevField.pending ) { return; }
    const handler = field.count ? getReplacedPendingMultiData : getReplacedPendingData;
    return handler( prevField, field );
}
function getReplacedPendingData( prevField, field ) {                 /*dbug-log*///console.log('       -- getReplacedPendingData current[%O] previous[%O]', field, prevField);
    const current = field.pending ? field.pending.id : false;
    const previous = prevField.pending.id;
    return current === previous ? false : prevField.pending;
}
function getReplacedPendingMultiData( prevField, field ) {            /*dbug-log*///console.log('       -- getReplacedPendingMultiData current[%O] previous[%O]', field, prevField);
    const replaced = {};
    Object.keys( prevField.pending ).forEach( addIfReplaced );
    return Object.keys( replaced ).length ? replaced : null;

    function addIfReplaced( ord ) {
        const current = field.pending ? field.pending[ord] : false;
        const previous = prevField.pending[ord];                    /*dbug-log*///console.log('           -- addIfReplaced current[%O] previou[%O]', current, previous);
        if ( current && current.id === previous.id ) { return; }  // Not replaced
        replaced[ord] = previous;
    }
}
/**
 * Downloads all data for each entity updated since the last local-data update.
 * TODO: Add 'fail' callback for server errors. Send back any errors and
 * describe them to the user.
 *
 * Export
 *     downloadAndStoreUpdatedData
 *
 * TOC
 *     ENTITY DATA
 *         SIMPLE ENTITY
 *         INTERACTION
 *     DOWNLOAD DATA
 *         SYNC LOCAL DATA
 *     PENDING DATA
 */
import { _db, _u } from '~util';
import { EntitySyncData } from './db-pg-load-main';
import { addCoreEntityData, addDetailEntityData, retryFailedUpdates, retryIssuesAndReportFailures } from '../db-sync-main';
import { EntityRecords, objectKeys } from '~types';

export function downloadAndStoreUpdatedData ( entities: EntitySyncData[] ): Promise<void> {
    /*perm-log*/console.log( '   --downloadAndStoreNewData[%O]', _u.snapshot( entities ) );
    return syncEntityData( entities )
        .then( () => handlePendingDataUpdates( entities ) )
        .then( retryIssuesAndReportFailures );
}
/* ====================== ENTITY DATA ======================================= */
function syncEntityData ( entities: EntitySyncData[] ): Promise<void> {
    return getAllSimpleEntityUpdates( entities )
        .then( () => downloadIntUpdates( entities ) )
        .then( retryFailedUpdates )
        .then( _db.setNewDataInLocalStorage );
}
/* ----------------------- SIMPLE ENTITY ------------------------------------ */
/** Before interactions can be synced, all related data must be available. */
function getAllSimpleEntityUpdates ( entities: EntitySyncData[] ): Promise<void[]> {
    const updateFirst = getSimpleEntities( entities );
    const promises = updateFirst.map( handleEntityDataSync );
    return Promise.all( promises );
}
/**
 * Returns all entities that can be synced first. The following will sync after:
 * Interaction - All sub-entity data must be downloaded first.
 * PendingData - Downloaded for data-managers after database download complete.
 */
function getSimpleEntities ( entities: EntitySyncData[] ): EntitySyncData[] {
    const skip = ['Interaction', 'PendingData'];
    return entities.filter( sync => skip.indexOf( sync.name ) === -1 );
}
function handleEntityDataSync ( syncData: EntitySyncData ): Promise<void> {
    return getNewData( syncData )
        .then( processUpdatedEntityData );
}
function ifUpdatesGetSyncData ( entities: EntitySyncData[], name: string ): EntitySyncData | undefined {
    return entities.find( d => d.name === name );
}
/* ----------------------- INTERACTION -------------------------------------- */
function downloadIntUpdates ( entities: EntitySyncData[] ): void | Promise<void> {
    const syncData = ifUpdatesGetSyncData( entities, 'Interaction' );
    if ( !syncData ) return;
    return getNewData( syncData )
        .then( processUpdatedEntityData );
}
/* ====================== DOWNLOAD DATA ===================================== */
type Updated = { [entity: string]: { [key: number]: string; }; };
function getNewData ( syncData: EntitySyncData ): Promise<Updated> {/*dbug-log*///console.log('getting new data for %O', syncData);
    return _db.fetchServerData( 'sync-data', getPushParams( syncData ) );
}
function getPushParams ( syncData: EntitySyncData ): { method: 'POST', body: string; } {
    const data = { entity: syncData.name, updatedAt: syncData.updated };
    return { method: 'POST', body: JSON.stringify( data ) };
}
function processUpdatedEntityData ( data: Updated ): void {
    const entity = Object.keys( data )[0];
    if ( !entity ) throw Error( 'Entity name not found' );
    return storeUpdatedData( parseEntityData( data[entity]! ), entity );
}
function parseEntityData ( dataObj: { [key: number]: string; } ): EntityRecords {
    const rcrds = _db.parseData( dataObj );
    return rcrds as unknown as EntityRecords;
}
/* ---------------------- SYNC LOCAL DATA ----------------------------------- */
/** Sends the each updated record to the update handler for the entity. */
function storeUpdatedData ( rcrds: EntityRecords, entity: string ) {
    /*perm-log*/logBasedOnEnv( entity, rcrds );
    objectKeys( rcrds ).forEach( id => storeUpdatedDataRecord( id, rcrds, entity ) );
}
function logBasedOnEnv ( entity: string, entityData: EntityRecords ): void {
    const env = $( 'body' ).data( 'env' );
    if ( env === 'prod' ) {
        console.log( "       --processUpdatedEntityData [%s][%s]", Object.keys( entityData ).length, entity );
    } else {
        console.log( "       --processUpdatedEntityData [%s][%s] = %O", Object.keys( entityData ).length, entity, entityData );
    }
}
function storeUpdatedDataRecord ( id: number, rcrds: EntityRecords, entity: string ): void {
    const syncRecordData = getEntityUpdateFunc( entity );
    syncRecordData( _u.lcfirst( entity ), rcrds[id] );
}
function getEntityUpdateFunc ( entity: string ): ( ...args: any[] ) => any {
    const coreEntities = ['Interaction', 'Location', 'Source', 'Taxon'];
    return coreEntities.indexOf( entity ) !== -1 ?
        addCoreEntityData : addDetailEntityData;
}
/* ======================= PENDING DATA ===================================== */
/**
 * Currently redownloading all pending-data when there are any updates (TODO: UPDATES ONLY)
 */
function handlePendingDataUpdates ( entities: EntitySyncData[] ): null | Promise<any> {
    if ( !_u.isContributorUser() || _u.isManagerUser() ) return null;/*dbug-log*///console.log(' -- handlePendingDataUpdates delayed?[%O]', delayed);
    const syncData = ifUpdatesGetSyncData( entities, 'PendingData' );
    return syncData ? _db.syncAndReturnPendingData() : null;
}
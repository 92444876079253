/**
 * Removes data invalidated by edits to the entity.
 *
 * Export
 *     removeInvalidatedData
 *     hasEdits
 *
 * TOC
 *     REMOVE ENTITY DATA
 *     REMOVE HANDLERS
 */
import { CoreEntity, SerializedEntity, objectKeys } from '~types';
import { DataEntryResults, EditObj } from '../../data-entry/data-entry-sync';
import { updateData } from '../execute-update';
import * as r from './rmv-funcs';

/* ======================== REMOVE ENTITY-DATA ============================== */
export function removeInvalidatedData ( data: DataEntryResults ): void {
    if ( !hasEdits( data.coreEdits ) ) return;
    const updateFuncs = getRmvDataPropHndlrs( data.core );
    updateDataProps( data.core, data.coreEntity, data.coreEdits, updateFuncs );
}
export function hasEdits ( editObj: EditObj | undefined ): editObj is EditObj {
    return editObj ? Object.keys( editObj ).length > 0 : false;
}
/* ------------------------ REMOVE HANDLERS --------------------------------- */
/** Returns an object with relational properties and their removal handlers. */
function getRmvDataPropHndlrs ( entity: CoreEntity ): RemoveLocalDataConfig {
    return {
        interaction: {  //todo: lcfirst edited props? Why ucfirst?
            Location: r.rmvIntAndAdjustTotalCnts,
            Source: r.rmvIntFromEntity,
            Subject: r.rmvIntFromTaxon,
            Object: r.rmvIntFromTaxon,
        },
        location: {
            ParentLocation: r.rmvFromParent,
        },
        source: {
            Contributor: r.rmvContrib,
            ParentSource: r.rmvFromParent,
        },
        taxon: {
            DisplayName: r.rmvFromNameProp,
            Group: r.rmvFromNameProp,
            ParentTaxon: r.rmvFromParent,
            SubGroup: r.rmvFromNameProp,
            Rank: r.rmvFromNameProp,
        }
    }[entity];
}
/* ======================== EXECUTE UPDATES ================================= */
type RemoveLocalDataConfig = {
    [key: string]: ( ...args: any[] ) => void;
};
// export type RemoveMethod = <T extends string, U extends string>(
//     prop: T,
//     rcrd: SerializedEntity,
//     entity: U,
//     edits: EditObj
// ) => void;
/** Updates relational storage props for the entity. */
function updateDataProps (
    entity: string,
    rcrd: SerializedEntity,
    edits: EditObj,
    updateFuncs: RemoveLocalDataConfig
): void {
    const params = { edits: edits, entity: entity, rcrd: rcrd, stage: 'rmvData' as const };
    objectKeys( updateFuncs ).forEach( prop => {
        if ( !edits[prop] ) return;
        updateData( updateFuncs[prop]!, prop, params );
    } );
}
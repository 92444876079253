/**
 * Utility methods for the data-review panel.
 *
 * Export
 *     initReviewPanelUtil
 *
 * TOC
 *
 */
import * as stats from './pending-stats.js'
import * as select from './review-row-select.js';
import * as toggle from './toggle-panel.js'

/* ========================= INIT =========================================== */
export function initReviewPanelUtil() {
    select.initTableRowSelect();
}
/* ========================= MISC =========================================== */
export function loadPendingDataStats() {
    stats.loadPendingDataStats();
}
export function toggleReviewPanel() {
    toggle.toggleReviewPanel();
}
export function toggleDataOptsBarButtons() {
    toggle.toggleDataOptsBarButtons(...arguments);
}
/**
 * Stores data temporarily while updating to reduce async db calls.
 */
import { _db } from '~util';
import { EntityRecords, SerializedEntity } from '~types';
import { DataStorage, StoredDataValue, StoredData } from './idb-util';

let tempStore: DataStorage = {};
/* ======================== INIT/RESET ====================================== */
export function initMemoryDataObj () {
    if ( getValue( 'taxon' ) ) return Promise.resolve();
    return _db.getAllStoredData().then( initTempMemory );
}
export function initTempMemory ( obj: DataStorage ): void {
    tempStore = obj;
}
export function clearMemory (): void {
    tempStore = {};
}
/* ========================= GET/SET ======================================== */
/* ------------------------ GET --------------------------------------------- */
export function getValue ( key: string ): StoredDataValue {
    const data = tempStore[key]?.value;
    return data ?? null;
}
export function getEntityData (
    entity: string,
    id: number
): [EntityRecords, SerializedEntity] {
    const entities = getEntities( entity );
    return [entities, getEntity( entities, id, entity )];
}
export function getEntities ( classname: string ) {
    const entities = getValue( classname ) as EntityRecords;
    if ( !entities ) throw Error( `${ classname } records not found` );
    return entities;
}
export function getEntity (
    entities: EntityRecords,
    id: number,
    entity: string
): SerializedEntity {
    const rcrd = entities[id];
    if ( !rcrd ) throw Error( `${ entity } ${ id } not found` );
    return rcrd;
}
/* ------------------------ SET --------------------------------------------- */
export function storeData ( key: string, data: StoredDataValue ): void { /*dbug-log*///console.log( 'Adding to tempStore [%s] = [%O]', key, data );
    const mmryObj: StoredData = tempStore[key] ?? { changed: true, value: null };
    mmryObj.value = data;
    mmryObj.changed = true;
    tempStore[key] = mmryObj;
}
export function removeData ( key: string ): void {
    delete tempStore[key];
}
/* ========================= LOCAL STORAGE ================================== */
export function setNewDataInLocalStorage (): Promise<void> {         /*dbug-log*///console.log( '               --setting data = [%O]', _u.snapshot( tempStore ) );
    return Object.keys( tempStore ).reduce( ( p, key ) => {
        return !tempStore[key]?.changed ? p : resetChangeFlagAndStoreValue( p, key, tempStore[key] );
    }, Promise.resolve() );
}
function resetChangeFlagAndStoreValue (
    p: Promise<void>,
    key: string,
    data: StoredData | undefined
): Promise<void> {
    if ( !data ) throw Error( 'Memory data object not found' );     /*dbug-log*///console.log( '               --setting [%s] data = [%O]', key, _u.snapshot( tempStore[key]! ) );
    data.changed = false;
    return p.then( _db.setData.bind( null, key, data.value ) );
}
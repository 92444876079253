/**
 * Manages the site's modal-functionality utilizing the intro.js library.
 *
 * Export
 *     exitModal
 *     showInfoModal
 *     showFormTutorialModal
 *     showSaveModal
 *
 * TOC
 *     INIT INTROJS
 *     INFO MODAL
 *     SAVE MODAL
 *     FORM TUTORIAL
 *     SHARED
 *         START INTRO
 *         EXIT INTRO
 *         TOGGLE ELEM-VISIBILITY
 */
import introJs, { IntroJs } from 'intro.js';
import { objectKeys } from '~types';
import { _el } from '~util';

let instance: introJs.IntroJs | null;
/* =========================== INIT INTROJS ================================= */
type IntroEvent = ( instance?: IntroJs | null ) => void;
/**
 * @param {} [onafterchange]  Called after each step is loaded
 * @param {} [onexit]         Called on modal close
 */
type IntroJsEvents = {
    onafterchange?: IntroEvent,
    onexit?: IntroEvent,
};
function initIntroJs (
    limitToSelection: string | null,
    options: introJs.Options = {},
    events?: IntroJsEvents,
): void {
    instance = limitToSelection ? introJs( limitToSelection ) : introJs();
    setIntroEvents( events );
    instance.setOptions( buildIntroOptions( options ) );
}
function setIntroEvents ( events: IntroJsEvents = {} ): void {
    if ( !instance ) return;
    objectKeys( events ).forEach( k => setConfigEvent( k, events[k] ) );
    if ( !( 'onexit' in events ) ) setExitHandler();
}
function setConfigEvent ( k: keyof IntroJsEvents, event: IntroEvent | undefined ): void {
    if ( !instance || !event ) return;
    if ( k === 'onexit' ) return setExitHandler( event );
    instance[k]( event.bind( null, instance ) );
}
function setExitHandler ( event?: IntroEvent ): void {
    if ( !instance ) return;
    instance.onexit( handleExit.bind( null, event ) );
}
function buildIntroOptions ( options: introJs.Options ): introJs.Options {
    const defaults = {
        tooltipClass: 'intro-tips',
        disableInteraction: false,
    };
    objectKeys( defaults ).forEach( k => {
        if ( !options[k] ) setOption( k, defaults[k], options );
    } );
    return options;
}
function setOption<K extends keyof introJs.Options, V extends introJs.Options[K]> (
    prop: K,
    value: V,
    obj: introJs.Options,
) {
    obj[prop] = value;
}
/* =========================== INFO MODAL =================================== */
/**
 * Shows a section of the intro-tutorial on the database page.
 * @export
 * @param {*} key
 * @return {*}
 */
export function showInfoModal ( steps: introJs.Step[] ): void {
    if ( instance ) return;
    initIntroJs( null, getInfoModalOpts( steps ) );
    startIntro();
}
function getInfoModalOpts ( steps: introJs.Step[] ): introJs.Options {
    return {
        showBullets: false,
        showStepNumbers: false,
        steps: steps,
        tooltipClass: 'intro-tips'
    };
}
export function showIntroTutorial ( options: introJs.Options, events?: IntroJsEvents ): void {
    initIntroJs( null, options, events );
    startIntro();
}
/* =========================== SAVE MODAL =================================== */
/**
 * @param {} [button]    Text to add to submit button, "Done" by default.
 * @param {} html        HTML to show in the modal.
 * @param {} position    Relative to the anchor element.
 * @param {} selector    Element selector to anchor the modal to.
 */
type SaveModalOpts = {
    button?: string;
    html?: string;
    position: 'left' | 'right' | 'top' | 'bottom';
    selector: string;
    title?: string;
};
type ModalButtonConfig = {
    text?: string;
    onConfirm?: () => void;
    button?: HTMLElement;
};
/**
 * Shows a modal with a submit/done button.
 * @export
 * @param {SaveModalOpts} config
 * @param {ModalConfirmButton} button
 * @param {IntroJsEvents} events
 */
export function showSaveModal ( config: SaveModalOpts, bConfig?: ModalButtonConfig, events?: IntroJsEvents, ): void {
    if ( instance ) return;
    initIntroJs( null, getModalOptions( config ), events );
    startIntro();
    if ( bConfig ) attachConfirmationButton( bConfig );
    $( '.modal-msg' ).css( { width: 'max-content' } );
}
function getModalOptions ( config: SaveModalOpts ): introJs.Options {
    return {
        hideNext: true,
        showBullets: false,
        showStepNumbers: false,
        steps: getSlideConfig( config ),
        tooltipClass: 'modal-msg',
    };
}
function getSlideConfig ( config: SaveModalOpts ): introJs.Step[] {
    return [{
        element: config.selector,
        intro: config.html ?? '',
        position: config.position,
        title: config.title,
    }];
}
function attachConfirmationButton ( config: ModalButtonConfig ): void {
    const button = config.button || getModalButton( config );
    const onConfirm = config.onConfirm;
    if ( onConfirm ) $( button ).on( 'click', () => exitModal( onConfirm ) );
    $( '.introjs-tooltipbuttons' ).append( button );
}
function getModalButton ( config: ModalButtonConfig ) {
    const text = config.text ?? 'Confirm';
    return _el.getElem( 'input', { type: 'button', value: text } );
}
/* =========================== FORM TUTORIAL ================================ */
/**
 * Shows the modal-tutorial for the form-group.
 * @export
 * @param {*} group - Form group
 */
export function showFormTutorialModal ( group: 'top' | 'sub' | 'sub2' ): void {
    if ( instance ) instance.exit();
    initIntroJs( `#${ group }-form`, { showBullets: false } );
    startIntro();
}
/* ========================== SHARED ======================================== */
/* ----------------------- START INTRO -------------------------------------- */
function startIntro (): void {
    if ( !instance ) throw Error( 'IntroJs instance not initialized.' );
    instance.start();
    refreshIntro();
}
function refreshIntro (): void {
    window.setTimeout( () => { if ( instance ) instance.refresh(); }, 250 );
}
/* ------------------------ EXIT INTRO -------------------------------------- */
export function exitModal ( cb?: () => void ): void {
    if ( instance ) instance.exit();
    handleExit( cb );
}
function handleExit ( cb?: () => void ): void {
    if ( cb ) cb();
    instance = null;
}
/**
 * Shows alerts related to general features/validation in the data-entry forms.
 *
 * TOC
 *     FORM-INIT ALERT
 *         SUB-FORM ALREADY OPEN
 *     FORM SUBMIT
 *         DATA-PREPARATION
 *     DUPLICATE ENTITY
 *         GET EXISTING-ENTITY
 *         HANDLE PENDING-REJECT
 *     DATA-STORAGE ALERT
 */
import { _cmbx, _db, _el } from '~util';
import { _data, _elems, _state } from '~form';
import * as alert from './alerts.js';
/* ===================== FORM-INIT ALERT ==================================== */
/**
 * When an issue prevents a form init, an alert is shown to the editor and the
 * combobox that triggered the form is reset.
 */
export function formInitAlert ( field, tag, fLvl ) {                   /*perm-log*/console.log( "       --[%s]formInitAlert [%s][%s]", fLvl, field, tag );
    const el = alert.getAlertElem( field, fLvl );
    alert.showAlert( fLvl, el, getInitAlertMsg( tag, fLvl, el ) );
}
function getInitAlertMsg ( tag, fLvl, el ) {
    const map = {
        forensicError: handleForensicErrorAndReturnAlertMsg,
        openSubForm: handleOpenSubFormAndReturnAlertMsg,
    };
    return map[tag]( el, fLvl );
}
function handleForensicErrorAndReturnAlertMsg ( el, fLvl ) {
    return `<p>There was an issue retrieving forensic data for this record. A
        report has been submitted.</p>`;
}
/* ----------------- SUB-FORM ALREADY OPEN ---------------------------------- */
/** A sub-form is already open. */
export function alertFormOpen ( fLvl ) {
    const entity = _state( 'getFormState', [fLvl, 'combo'] );         /*dbug-log*///console.log("       --open[%s]FormAlert [%s]", fLvl, entity);
    window.setTimeout( () => _cmbx.resetCombobox( entity ), 10 );
    return formInitAlert( entity, 'openSubForm', fLvl );
}
function handleOpenSubFormAndReturnAlertMsg ( el, fLvl ) {
    window.setTimeout( () => alert.clearAlert( el, fLvl ), 2000 );
    return '<p>Please finish the open sub-form.</p>';
}
/* ========================= FORM SUBMIT ==================================== */
/** Alerts the editor to isues with the submit process. */
export function formSubmitError ( fLvl, tag = 'genSubmitErr' ) {       /*dbug-log*///console.log('    --formSubmitError fLvl[%s] tag[%s]', fLvl, tag);
    const el = alert.getAlertElem( null, fLvl );
    const handlers = getSubmitAlertHandlers( tag );
    alert.showAlert( fLvl, el, handlers.show( el, fLvl ), handlers.clear );
}
function getSubmitAlertHandlers ( tag ) {
    const map = {
        existingEntity: {
            show: handleExistingEntityndReturnAlertMsg,
            clear: clearExistingEntityAlert
        },
        genSubmitErr: {
            show: () => '<span>An Error occured and the developer has been notified.</span>',
            clear: alert.clrFormLvlAlert
        },
        noChanges: {
            show: () => '<span>No changes detected.</span>',
            clear: alert.clrFormLvlAlert
        },
        unableToDelete: {
            show: () => '<span>Entity has dependent-data and can not be deleted.</span>'
        }
    };
    return map[tag];
}
/* ----------------------- DATA-PREPARATION --------------------------------- */
export function dataPrepFailure ( fails ) {                            /*perm-log*/console.log( '           !!!dataPrepFailure [%s]', JSON.stringify( fails ) );
    diableFormButtons();
    const cntnr = _el.getElem( 'div', { class: 'flex-col', id: 'data_alert' } );
    $( cntnr ).append( [getDataPrepFailAlert( fails ), buildResetButton( reloadPage )] );
    $( '#top-hdr' ).after( cntnr );
}
function getDataPrepFailAlert ( fails ) {
    return `<span>An error occured and the developer has been notified.
        <br>The page will be reloaded. If this error persists, please create a new
        Bug Report and include the following info:</span><br>
        <span><center>[${ JSON.stringify( fails ) }]</center></span>`;
}
function reloadPage () {
    location.reload( true );
}
export function getRequiredFieldsEmptyAleryMsg ( elem, fLvl ) {
    return `<span>Please fill required fields and submit again.</span>`;
}
export function handleInvalidRangeAndReturnAlertMsg ( elem, fLvl ) {
    alert.setOnChangeClearAlert( elem.id.split( '_alert' )[0], elem, fLvl );
    return '<span>Invalid range entered.</span>';
}
/* ======================= DUPLICATE ENTITY ================================= */
/**
 * Returns a message prompting the user to select existing entity. The alert is
 * cleared when any field changes, via the exit button, or when the user clicks
 * the text to select toe existing entity.
 */
function handleExistingEntityndReturnAlertMsg ( elem, fLvl ) {
    const fState = _state( 'getFormState', [fLvl] );
    const entity = fState.misc.existingEntity;
    addTempHandlerToWindow();
    setOnFieldChangeClearAlert();                                   /*dbug-log*///console.log('-- handleExistingEntityndReturnAlertMsg fState[%O]', fState);
    return getExistingEntityAlertMsg();
    /** Note: removed from window @clearExistingEntityAlert */
    function addTempHandlerToWindow () {
        const params = [fLvl, entity.id, fState.combo, fState.pending];
        window.selectExistingEntity = selectExistingEntity.bind( null, ...params );
    }
    function setOnFieldChangeClearAlert () {
        const handleClear = clearExistingEntityAlert.bind( null, elem, fLvl );
        alert.setOnChangeClearAlert( fState.combo, elem, fLvl, handleClear );
    }
}
function getExistingEntityAlertMsg () {
    return `<p>Duplicate data detected.</p><p onClick="selectExistingEntity()"
        style="cursor:pointer;"> &nbsp;&nbsp; CANCEL CREATE AND <BR><u>SELECT EXISTING</u></p>`;
}
/* -------------------- GET EXISTING-ENTITY --------------------------------- */
function selectExistingEntity ( fLvl, id, field, isPending ) {         /*dbug-log*///console.log('-- selectExistingEntity [%s] field[%s] id[%s]', fLvl, field, id);
    const fState = _state( 'getFormState', [fLvl] );
    _state( 'addFormHandler', [fLvl, 'onFormClose', getSelectFunc( fState )] );
    if ( isPending ) {
        handleDuplicatePendingRejected( fState );
    } else {
        _elems( 'handleFormClose', [fLvl, false] );
    }
    $( `#${ field }_pin` ).focus();
    delete window.selectExistingEntity;
}
function getSelectFunc ( fState ) {
    return setEntityInField.bind( null, fState.group, fState.combo, fState.misc.existingEntity.id );
}
/** Selects existing entity in the field's combobox. */
function setEntityInField ( fLvl, comboField, id ) {                   /*dbug-log*///console.log("   --setEntityInField fLvl[%s] combo[%s] id[%s]", fLvl, comboField, id);
    return _cmbx.triggerComboChangeReturnPromise( comboField, id );
}
function clearExistingEntityAlert ( elem, fLvl ) {
    delete window.selectExistingEntity;
    alert.clrFormLvlAlert( elem, fLvl );
}
/* -------------------- HANDLE PENDING-REJECT ------------------------------- */
/**
 * Handles rejecting the pending-data-set and selecting the existing entity.
 * TODO2: Handle showing the rejected data to the contributor...
 */
function handleDuplicatePendingRejected ( fState ) {                   /*dbug-log*///console.log("   --handleDuplicaePendingRejected fState[%O]", fState);
    logDataRejected( fState.group, fState.combo );
    fState.pending.inst.rvwAction = { name: 'Reject' };
    fState.pending.entityId = fState.misc.existingEntity.id;
    _data( 'pushFormPendingData', [fState] );
}
function logDataRejected ( fLvl, comboField ) {
    const logMsg = 'DUPLICATE DATA DETECTED. REPLACED WITH EXISTING ENTITY.<br>';
    $( `#${ fLvl }_rvw-log` ).append( '>> ' + logMsg );
    if ( fLvl === 'top' ) { return; }
    $( `#${ fLvl }_rvw-log` ).append( `>> ${ comboField }: ${ logMsg }` );
}
/* ===================== DATA-STORAGE ALERT ================================= */
export function errUpdatingData ( errTag ) {                           /*perm-log*/console.log( '           !!!errUpdatingData [%s]', errTag );
    diableFormButtons();
    const cntnr = _el.getElem( 'div', { class: 'flex-col', id: 'data_alert' } );
    $( cntnr ).append( [buildAlertMsg(), buildResetButton( reloadAndRedownloadData )] );
    $( '#top-hdr' ).after( cntnr );
}
function buildAlertMsg () {
    return `<span>An error occured and the developer has been notified.
        <br>All stored data will be redownloaded.</span>`;
}
function buildResetButton () {
    const confirm = _el.getElem( 'span', {
        class: 'flex-row',
        'text': `Please click "OK" to continue.`
    } );
    const bttn = _el.getElem( 'input', { type: 'button', value: 'OK', class: 'exit-bttn' } );
    $( bttn ).on( 'click', reloadAndRedownloadData );
    $( confirm ).append( bttn );
    return confirm;
}
function diableFormButtons () {
    $( '#top-submit, #top-cancel, #exit-form' ).off( 'click' ).css( 'disabled', 'disabled' )
        .fadeTo( '400', 0.5 );
}
function reloadAndRedownloadData () {
    _elems( 'handleFormClose', ['top', 'skipTableReset'] );
    _db.resetStoredData();
}
/**
 * Functions that remove data from local-storage.
 *
 * Export
 *     rmvContrib
 *     rmvFromNameProp
 *     rmvFromParent
 *     rmvIntAndAdjustTotalCnts
 *     rmvIntFromEntity
 *     rmvIntFromTaxon
 */
import { EntityRecords, IdsByName, SerializedEntity } from '~types';
import { _db, _u } from '~util';
import { getEntityData, getEntities, getEntity } from 'js/util/local-data/etc/temp-data';
import { EditObj, EditedIds } from '../../data-entry/data-entry-sync';

/* ======================== HELPERS ========================================= */
function getDataEdits ( edits: EditObj, prop: string, nullOk: true, type?: 'old' | 'new' ): number | false;
function getDataEdits ( edits: EditObj, prop: string, nullOk?: false, type?: 'old' | 'new' ): number;
function getDataEdits ( edits: EditObj, prop: string, nullOk?: boolean, type: 'old' | 'new' = 'old' ): number | false {
    if ( !edits[prop] ) throw Error( 'Edited data missing' );
    if ( !edits[prop]![type] && !nullOk ) throw Error( 'Previous entity ID missing' );
    return edits[prop]![type] ?? false;
}
/** Removes the id from the ary. */
function rmvIdFromAry ( ary: number[], id: number ): void {
    ary.splice( ary.indexOf( id ), 1 );
}
/* ======================== REMOVERS ======================================== */
/* ------------------------- PARENT ----------------------------------------- */
/** Removes a record's id from the previous parent's 'children' array. */
export function rmvFromParent (
    prop: 'ParentLocation' | 'ParentSource' | 'ParentTaxon',
    rcrd: SerializedEntity,
    entity: 'location' | 'source' | 'taxon',
    edits: EditObj
): void {
    const oldId = getDataEdits( edits, prop, true );
    if ( !oldId ) return;
    const entityClass = _u.lcfirst( entity );
    const [rcrds, parent] = getEntityData( entityClass, oldId );
    rmvIdFromAry( parent.children, rcrd.id );
    _db.storeData( entityClass, rcrds );
}
/* -------------------------- INTERACTION ----------------------------------- */
/** Removes the Interaction from the stored entity's collection. */
export function rmvIntFromEntity (
    prop: 'Source',
    rcrd: SerializedEntity,
    _1: 'interaction',
    edits: EditObj
): void {
    const oldId = getDataEdits( edits, prop );
    const [rcrds, source] = getEntityData( 'source', oldId );
    rmvIdFromAry( source.interactions, rcrd.id );
    _db.storeData( 'source', rcrds );
}
/** Removes the Interaction and updates parent location total counts.  */
export function rmvIntAndAdjustTotalCnts (
    prop: 'Location',
    rcrd: SerializedEntity,
    _1: string,
    edits: EditObj
): void {
    const [rcrds, oldLoc] = getEntityData( 'location', getDataEdits( edits, prop ) );
    const newLoc = getEntity( rcrds, getDataEdits( edits, prop, false, 'new' ), 'location' );
    rmvIdFromAry( oldLoc.interactions, rcrd.id );
    adjustLocCnts( oldLoc, newLoc, rcrds );
    _db.storeData( 'location', rcrds );
}
function adjustLocCnts (
    oldLoc: SerializedEntity,
    newLoc: SerializedEntity,
    rcrds: EntityRecords
): void {
    adjustLocAndParentCnts( oldLoc, false );
    adjustLocAndParentCnts( newLoc, true );

    function adjustLocAndParentCnts ( loc: SerializedEntity, addTo: boolean ): void {
        addTo ? ++loc.totalInts : --loc.totalInts;
        const parent = loc.parent ? getEntity( rcrds, loc.parent, 'location' ) : null;
        if ( parent ) adjustLocAndParentCnts( parent, addTo );
    }
}
/** Removes the Interaction from the taxon's subject/objectRole collection. */
export function rmvIntFromTaxon (
    prop: 'Object' | 'Subject',
    rcrd: SerializedEntity,
    _1: 'interaction',
    edits: EditObj
): void {
    const [rcrds, taxon] = getEntityData( 'taxon', getDataEdits( edits, prop ) );
    rmvIdFromAry( taxon[`${ _u.lcfirst( prop ) }Roles`], rcrd.id );
    _db.storeData( 'taxon', rcrds );
}
/* ---------------------- SOURCE CONTRIBUTOR -------------------------------- */
export function rmvContrib (
    _1: 'Contributor',
    rcrd: SerializedEntity,
    _2: 'source',
    edits: EditObj
): void {
    const rcrds = getEntities( 'source' );
    const contributorEdits = edits?.Contributor as unknown as EditObj[];
    if ( !contributorEdits ) throw Error( 'Contributor edit data not found.' );
    processContributorEdits( contributorEdits, rcrd.id, rcrds );
    _db.storeData( 'source', rcrds );
}
function processContributorEdits (
    contributorEdits: EditObj[],
    contribId: number,
    rcrds: EntityRecords
): void {
    contributorEdits.forEach( edit => {
        const oldId = getDataEdits( edit, 'authId', true );
        if ( !oldId ) return;
        const oldAuthor = getEntity( rcrds, oldId, 'source' );
        rmvIdFromAry( oldAuthor.contributions, contribId );
    } );
}
/* ---------------------- TAXON NAMES --------------------------------------- */
/** Note: Edits to taxon-groups with one root call this for subGroup redundantly. */
export function rmvFromNameProp (
    _1: string,
    rcrd: SerializedEntity,
    _2: 'taxon',
    edits: EditObj
): void {
    const taxonName = getTaxonName( edits, rcrd );
    const nameProp = getNameProp( edits, rcrd );
    const nameObj = _db.getValue( nameProp ) as IdsByName;
    if ( !nameObj ) throw Error( 'Name object not found' );
    delete nameObj[taxonName];
    _db.storeData( nameProp, nameObj );
}
function getTaxonName ( edits: EditObj, rcrd: SerializedEntity ): string {
    return edits.Name ? edits.Name.old : rcrd.name;
}
function getNameProp ( edits: EditObj, rcrd: SerializedEntity ): string {
    const group = getGroup( edits.Group, rcrd );
    const subGroup = getSubGroup( edits.SubGroup, rcrd );
    const rank = getRank( edits.Rank, rcrd );
    return group + subGroup + rank + 'Names';
}
function getGroup ( groupEdits: EditedIds | undefined, rcrd: SerializedEntity ): string {
    return !groupEdits ? rcrd.group.displayName : groupEdits.old;
}
function getSubGroup ( subGroupEdits: EditedIds | undefined, rcrd: SerializedEntity ): string {
    return !subGroupEdits ? rcrd.group.subGroup.name : subGroupEdits.old;
}
function getRank ( rankEdits: EditedIds | undefined, rcrd: SerializedEntity ): string {
    if ( !rankEdits ) return rcrd.rank.displayName;
    const ranks = getEntities( 'rank' );
    const oldRankName = ranks[rankEdits.old]?.displayName;
    if ( !oldRankName ) throw Error( 'Previous taxon-rank name not found' );
    return oldRankName;
}